export const moduleIcons = [
    { name: 'mdiFileDocumentOutline' },
    { name: 'mdiViewDashboardVariantOutline' },
    { name: 'mdiFileChartOutline' },
    { name: 'mdiSpeedometer' },
    { name: 'mdiClipboardListOutline' },
    { name: 'mdiClipboardAccountOutline' },
    { name: 'mdiClipboardClockOutline' },
    { name: 'mdiClipboardTextMultipleOutline' },
    { name: 'mdiChartBoxOutline' },
    { name: 'mdiSecurity' },
    { name: 'mdiLockOutline' },
    { name: 'mdiWaterOutline' },
    { name: 'mdiMonitorDashboard' },
    { name: 'mdiCogOutline' },
    { name: 'mdiOfficeBuilding' },
    { name: 'mdiCellphone' }
]