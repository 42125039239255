import React from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC<{data:{mes:number, ano:number} | undefined}> = ({data}) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '24px',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        text: {
            fontSize: '8px',
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        },
    })

    return(
        <>
        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>Leitura dos Medidores de Volume</Text>
                <Text style = {styles.subTitle}>{`DAE - (${data?.mes.toString()}/${data?.ano})`}</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>

        </>
    )

}

export default TableBanner