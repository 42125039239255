import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setRequestCreateData, setRequestCreateError, setRequestCreateLoading, setRequestCreateMessage } from "../../Reducers/GrupoAcessoReducer"
import { postApi } from "../../../../../utils/requestApi"
import createAlert from "../../../../../scripts/alert/createAlert"
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"

export default async function create(dispatch: Function, nome: string, idUsuario: number){
    try {
        dispatch(setRequestCreateLoading())

        const endpoint = `${DOMAIN}/GrupoAcesso`
        const data = { nome, idUsuario }
        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestCreateData(response))
            if(response.flagErro) throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
        dispatch(setRequestCreateMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestCreateError())
    }
}