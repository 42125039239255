import { StyleSheet, View, Text } from '@react-pdf/renderer'
import React from 'react'

const TableFooter: React.FC = () => {

    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: '18px',
            left: '20px',
            right: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            borderTop: '1px solid black',
        },
        leftText: {
            fontSize: '8px',
        },
        rightText: {
            fontSize: '8px',
            fontWeight: 'bold',
        }
    })


    return(
<>



<Text fixed wrap={false} style={{ bottom: '27px',fontSize:'8px', position: 'absolute', left: '40%', marginTop: '48px'}}>015_FMEI Rev.08 - Apr. Nov/23</Text>
    <View style = {styles.container} fixed>

        <Text style = {styles.leftText} render={({ pageNumber, totalPages }) => (`pág ${pageNumber} de ${totalPages}`)} fixed />
    </View>


        </>
    )

}

export default TableFooter