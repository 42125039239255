import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { DefaultType } from '../../../../../../../store/types/DefaultType'

const TableRow: React.FC <DefaultType> = props => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },          
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '8px',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })

    const Column: React.FC <{text: string, width: string, last?: boolean, align?: "center" | "left" | "right" | "justify"}> = ({text, width, last, align}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0, textAlign: align ?? 'center'}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

        <View style = {styles.row}>
            <Column text = {props.id.toString()} width = "6%" />
            <Column text = {props.descricao} width = "74%" />
            <Column text = {props.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'} width = "20%" last />
        </View>

    )

}

export default TableRow