import { setRequestError, setRequestMessage } from "../Reducers/EnderecoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setListaColetas, setListaCor, setIdCor } from "../Reducers/ClientsReducer"


export default async function getColor(dispatch: Function, data: any){
    try {
        dispatch(setIdCor(0))
        dispatch(setListaColetas(''))
        dispatch(setListaCor('1'))
        const endpoint = `${DOMAIN}/HistoricoColeta/color/?idPessoa=${data}`

        const response: RequestResponsePattern<any> = await getApi({ endpoint, params:'' })

        if(response){

            if(!response.flagErro) {
                dispatch(setIdCor(response.listaResultados.id))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestError())
    }
}