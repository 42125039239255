import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from "../../../store/reducers/default/DefaultReducer"
import { RequestResponsePattern } from "../../../types/Request"
import { postApi } from "../../../utils/requestApi"
import { DOMAIN } from "../../../var_env."
import { DefaultType } from '../../../store/types/DefaultType'
import { EditParams } from "../types"

export default async function edit(apiController: string, dispatch: Function, values: EditParams){
    try {
        dispatch(setRequestEditLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`
        const data = { id: values.id, descricao: values.descricao, flagAtivo: values.flagAtivo ? 1 : 0, idUsuario: values.idUsuario }
        const response: RequestResponsePattern<DefaultType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestEditData(response))
            if(response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}