import { ParametroType } from "../../Parametro/Types/ParametroType";

export default function conformidadePDF(ParametroName: string, values: any) {

    //console.log(ParametroName)

    if (ParametroName === "temperatura") ParametroName = "°C"
    if (ParametroName === "Rs") ParametroName = "SS"

    let valorParametro: Number = 0
    if (typeof values === 'number') valorParametro = Number(values)
    if (typeof values === 'string') valorParametro = Number(values.replace('<', ''))
    if (values === null || values === "" || Number(values) === 0) return false
    const parametros: ParametroType[] | null = JSON.parse(localStorage.getItem("Parametro")!)

    const oParametro = parametros?.find(x => x.descricao.toUpperCase() === ParametroName.toUpperCase() || x.sigla.toUpperCase() === ParametroName.toUpperCase())

    if (Number(oParametro?.maximo) === 0 && Number(oParametro?.minimo) === 0) return false
    if (valorParametro > oParametro?.maximo || valorParametro < oParametro?.minimo) return true
    return false
}