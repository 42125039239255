import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"


interface State {
    requestGet : RequestStatusPattern<RequestResponsePattern<any[]>>
    requestSave: RequestStatusPattern<RequestResponsePattern<any>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<string>>
    MonAnualAtual : any | null
    MonAnual : any[] | null
}

const initialState: State = {
    requestGet: {
        data: null,
        loading: false,
        error: false,
        message: null,
    }, 
    requestSave: {
        data: null,
        loading: false,
        error: false,
        message: null,
    }, 
    requestRemove: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    MonAnualAtual: null,
    MonAnual: null
}

const MonAnualSlice = createSlice({
    name: 'MonAnual',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<any[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<string>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestSaveData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestSave.data = action.payload
            state.requestSave.loading = false
            state.requestSave.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },
        setMonAnual: (state, action: PayloadAction<any[]>) => {
            state.MonAnual = action.payload
        },
        setMonAnualAtual: (state, action: PayloadAction<any>) => {
            state.MonAnualAtual = action.payload
        },
    }
})

export const {  
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,setMonAnual
    ,setRequestSaveData, setMonAnualAtual, setRequestRemoveData
} = MonAnualSlice.actions
export default MonAnualSlice.reducer