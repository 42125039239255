import { RequestResponsePattern } from '../../../../../../types/Request'
import { deleteApi } from '../../../../../../utils/requestApi'
import { DOMAIN } from '../../../../../../var_env.'
import { setRequestRemoveData, setRequestRemoveError, setRequestRemoveLoading, setRequestRemoveMessage } from '../../Reducers/PessoaHidrometroReducer'
import { PessoaHidrometroType, RemoveParams } from '../../Types/PessoaHidrometroType'

export default async function remove(apiController: string, dispatch: Function, values: RemoveParams){
    try {
        dispatch(setRequestRemoveLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`
        const params = { id: values.id, idUsuario: values.idUsuario }
        const response: RequestResponsePattern<PessoaHidrometroType> = await deleteApi({ endpoint, params })
        
        if(response){
            
            dispatch(setRequestRemoveData(response))
            if(!response.flagErro) dispatch(setRequestRemoveData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestRemoveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestRemoveError())
    }
}