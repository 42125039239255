import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { historico, relatorio } from "../types"

interface State {
    requestStatusGetHistorico: RequestStatusPattern<RequestResponsePattern<historico[]>>

    Historico: relatorio | null
    historicosDiversos:  relatorio[]  | null
    HistoricoExcel: relatorio | null
    historicosDiversosExcel:  relatorio[]  | null
}

const requestStatusInitial = {
    data: null,
    loading: false,
    error: false,
    message: null,
}

const initialState: State = {
    requestStatusGetHistorico: requestStatusInitial,

    Historico: null,
    historicosDiversos: null,
    HistoricoExcel: null,
    historicosDiversosExcel: null,
   
}

const HistoricoSlice = createSlice({
    name: 'HistoricoReducer',
    initialState,
    reducers: {
        setRequestGetHistoricoData: (state, action: PayloadAction<RequestResponsePattern<historico[]>>) => {
            state.requestStatusGetHistorico.data = action.payload
            state.requestStatusGetHistorico.loading = false
            state.requestStatusGetHistorico.error = false
        },
        setRequestGetHistoricoLoading: (state) => {
            state.requestStatusGetHistorico.loading = true
            state.requestStatusGetHistorico.error = false
        },
        setRequestGetHistoricoError: (state) => {
            state.requestStatusGetHistorico.loading = false
            state.requestStatusGetHistorico.error = true
        },
        setRequestGetHistoricoMessage: (state, action: PayloadAction<string>) => {
            state.requestStatusGetHistorico.message = action.payload
        },
        setHistorico: (state, action: PayloadAction<relatorio>) => {
            state.Historico = action.payload
        },
        setHistoricoDiversos: (state, action: PayloadAction<relatorio[]>) => {
            state.historicosDiversos = action.payload
        },
        setHistoricoExcel: (state, action: PayloadAction<relatorio>) => {
            state.HistoricoExcel = action.payload
        },
        setHistoricoDiversosExcel: (state, action: PayloadAction<relatorio[]>) => {
            state.historicosDiversosExcel = action.payload
        }
    }
})

export const {  
    setRequestGetHistoricoData, setRequestGetHistoricoLoading, setRequestGetHistoricoError, setRequestGetHistoricoMessage,
    setHistorico, setHistoricoDiversos, setHistoricoDiversosExcel, setHistoricoExcel
} = HistoricoSlice.actions
export default HistoricoSlice.reducer