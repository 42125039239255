import { RequestResponsePattern } from '../../../../../../types/Request'
import { postApi } from '../../../../../../utils/requestApi'
import { DOMAIN } from '../../../../../../var_env.'
import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from '../../Reducers/PessoaHidrometroReducer'
import { EditHidParams, PessoaHidrometroType } from '../../Types/PessoaHidrometroType'

export default async function edit(apiController: string, dispatch: Function, values: EditHidParams){
    try {
        dispatch(setRequestEditLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`

        const data = { 
            id: values.id, 
            idPessoa: values.idCliente,
            registro: values.registro,
            uc: values.uc,
            idFonteAbastecimento: values.idFonteAbastecimento,
            flagAtivo: values.flagAtivo ? 1 : 0, 
            idUsuario: values.idUsuario,
            flagMedidorVazao: values.flagMedidorVazao
        }
        
        const response: RequestResponsePattern<PessoaHidrometroType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestEditData(response))
            if(response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}