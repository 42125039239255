import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from "../Reducers/RamoAtividadeReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { EditParams } from "../types"
import { RamoAtividadeType } from "../Types/RamoAtividadeType"

export default async function edit(dispatch: Function, values: EditParams){
    try {
        dispatch(setRequestEditLoading())
        
        const endpoint = `${DOMAIN}/RamoAtividade`
        const data = { id: values.id, descricao: values.descricao, flagAtivo: values.flagAtivo ? 1 : 0, idUsuario: values.idUsuario }
        const response: RequestResponsePattern<RamoAtividadeType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestEditData(response))
            if(!response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}