import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import {  setListaFiltered, setRequestFilterData, setRequestFilterLoading, setRequestGetError, setRequestGetMessage } from "../Reducers/RelatorioRankingReducer"
import { getApi } from "../../../../../utils/requestApi"
import { RelatorioRankingType } from "../types"

export default async function filter(dispatch: Function, values:any, valor: any){
    try {
        dispatch(setRequestFilterLoading())
        dispatch(setListaFiltered([]))
        const endpoint = DOMAIN
        const params = `/RankingRelatorio/?ano=${values}&valor=${valor}`
        const response: RequestResponsePattern<RelatorioRankingType[]> = await getApi({ endpoint, params })
        const response2: RequestResponsePattern<RelatorioRankingType[]> = await getApi({ endpoint, params: `/RankingRelatorio/?ano=${values}&valor=1`})
        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) {

                    dispatch(setListaFiltered(response.listaResultados))

                localStorage.setItem('relatorioRanking', JSON.stringify(response2.listaResultados))
                localStorage.setItem('anoRef', JSON.stringify({ano: values}))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {

        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}