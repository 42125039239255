//import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { EnderecoType } from "../../Parametro/Types/EnderecoType"
import { setLista, setRequestGetData, setRequestGetError, setRequestGetLoading, setRequestGetMessage } from "../Reducers/EnderecoReducer"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

export default async function getRamoAtividade(dispatch: Function, idCliente: any){
    try {
        dispatch(setRequestGetLoading())
        dispatch(setLista([]))
        const endpoint = `${DOMAIN}`
        const params = `/PessoaEndereco/?idPessoa=${idCliente}`
        const response: RequestResponsePattern<EnderecoType[]> = await getApi({ endpoint, params })

        if(response){
            dispatch(setRequestGetData(response))
            if(!response.flagErro) {
                dispatch(setLista(response.listaResultados))
                localStorage.setItem("EnderecoHistorico", JSON.stringify(response.listaResultados[0]??null))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}