import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import { setRequestUpdateGrupoAcessoData, setRequestUpdateGrupoAcessoError, setRequestUpdateGrupoAcessoLoading, setRequestUpdateGrupoAcessoMessage } from "../../Reducers/GrupoAcessoReducer"
import { postApi } from "../../../../../utils/requestApi"
import createAlert from "../../../../../scripts/alert/createAlert"

export default async function updateGrupoAcesso(dispatch: Function, id: number, idUsuario: number, nome: string, ativo: string){
    try {
        dispatch(setRequestUpdateGrupoAcessoLoading())

        const endpoint = `${DOMAIN}/GrupoAcesso`
        const data = { 
            id, 
            idUsuario,
            nome: nome,
            flagAtivo: ativo === '1' ? true : false
        }
        
        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestUpdateGrupoAcessoData(response))
            if(!response.flagErro) dispatch(setAlert(createAlert('success', 'Dados alterados com sucesso')))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestUpdateGrupoAcessoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestUpdateGrupoAcessoError())
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
    }
}