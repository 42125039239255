import { DOMAIN } from "../../../../../../var_env."

import { putApi } from "../../../../../../utils/requestApi"

export default async function updateModule(data: {nome: string, path: string, icone: string}, handleReloadModules: Function, id: number){
    try {
        const endpoint = `${DOMAIN}/Modulo`
        const params = `/${id.toString()}`
        const response = await putApi({endpoint, params, data})
        if(response) await handleReloadModules()
    } catch (error) {
        console.log(error)
    }
}