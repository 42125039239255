import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { RamoAtividadeEspecifica } from "../Types/RamoAtividadeEspecifica"
import { RamoAtividadeType } from "../Types/RamoAtividadeType"

interface State {
    requestGet: RequestStatusPattern<RequestResponsePattern<RamoAtividadeType[]>>
    requestSave: RequestStatusPattern<RequestResponsePattern<RamoAtividadeType>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<RamoAtividadeType>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<RamoAtividadeType>>
    requestFilter: RequestStatusPattern<RequestResponsePattern<RamoAtividadeType[]>>
    requestGetRamoAtividade: RequestStatusPattern<RequestResponsePattern<RamoAtividadeEspecifica>>
    requestSaveRamoAtividade: RequestStatusPattern<RequestResponsePattern<RamoAtividadeEspecifica>>

    lista: RamoAtividadeType[] | null
    ramoAtividade: RamoAtividadeEspecifica | null
    filteredLista: RamoAtividadeType[] | null
    fieldFiltered: string
    fieldFilteredFlagAtivo: any
    currentData: RamoAtividadeType | null
}

const initialState: State = {
    requestGet: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestSave: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestEdit: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestRemove: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestFilter: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestGetRamoAtividade: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestSaveRamoAtividade: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    lista: null,
    ramoAtividade: null,
    filteredLista: [],
    fieldFiltered: '',
    fieldFilteredFlagAtivo: 'null',
    currentData: null,
}

const RamoAtividadeSlice = createSlice({
    name: 'RamoAtividade',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeType[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },

        setRequestGetRamoAtividadeData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeEspecifica>>) => {
            state.requestGetRamoAtividade.data = action.payload
            state.requestGetRamoAtividade.loading = false
            state.requestGetRamoAtividade.error = false
        },
        setRequestGetRamoAtividadeLoading: (state) => {
            state.requestGetRamoAtividade.loading = true
            state.requestGetRamoAtividade.error = false
        },
        setRequestGetRamoAtividadeError: (state) => {
            state.requestGetRamoAtividade.loading = false
            state.requestGetRamoAtividade.error = true
        },
        setRequestGetRamoAtividadeMessage: (state, action: PayloadAction<string>) => {
            state.requestGetRamoAtividade.message = action.payload
        },

        setRequestData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeType>>) => {
            state.requestSave.data = action.payload
            state.requestSave.loading = false
            state.requestSave.error = false
        },
        setRequestLoading: (state) => {
            state.requestSave.loading = true
            state.requestSave.error = false
        },
        setRequestError: (state) => {
            state.requestSave.loading = false
            state.requestSave.error = true
        },
        setRequestMessage: (state, action: PayloadAction<string>) => {
            state.requestSave.message = action.payload
        },

        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeType>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeType>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },

        setRequestFilterData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeType[]>>) => {
            state.requestFilter.data = action.payload
            state.requestFilter.loading = false
            state.requestFilter.error = false
        },
        setRequestFilterLoading: (state) => {
            state.requestFilter.loading = true
            state.requestFilter.error = false
        },
        setRequestFilterError: (state) => {
            state.requestFilter.loading = false
            state.requestFilter.error = true
        },
        setRequestFilterMessage: (state, action: PayloadAction<string>) => {
            state.requestFilter.message = action.payload
        },

        setRequestSaveRamoAtividadeData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeEspecifica>>) => {
            state.requestSaveRamoAtividade.data = action.payload
            state.requestSaveRamoAtividade.loading = false
            state.requestSaveRamoAtividade.error = false
        },
        setRequestSaveRamoAtividadeLoading: (state) => {
            state.requestSaveRamoAtividade.loading = true
            state.requestSaveRamoAtividade.error = false
        },
        setRequestSaveRamoAtividadeError: (state) => {
            state.requestSaveRamoAtividade.loading = false
            state.requestSaveRamoAtividade.error = true
        },
        setRequestSaveRamoAtividadeMessage: (state, action: PayloadAction<string>) => {
            state.requestSaveRamoAtividade.message = action.payload
        },

        setLista: (state, action: PayloadAction<RamoAtividadeType[]>) => {
            state.lista = action.payload
            localStorage.setItem('ramoAtividade', JSON.stringify(action.payload))
        },
        setRamoAtividade: (state, action: PayloadAction<RamoAtividadeEspecifica>) => {
            state.ramoAtividade = action.payload
        },
        setListaFiltered: (state, action: PayloadAction<RamoAtividadeType[]>) => {
            state.filteredLista = action.payload
        },
        setFieldFiltered: (state, action: PayloadAction<string>) => {
            state.fieldFiltered = action.payload
        },
        setFieldFilteredFlagAtivo: (state, action: PayloadAction<any>) => {
            state.fieldFilteredFlagAtivo = action.payload
        },
        setCurrentData: (state, action: PayloadAction<RamoAtividadeType>) => {
            state.currentData = action.payload
        }
    }
})

export const { 
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,
    setRequestGetRamoAtividadeData, setRequestGetRamoAtividadeLoading, setRequestGetRamoAtividadeError, setRequestGetRamoAtividadeMessage,
    setRequestData, setRequestLoading, setRequestError, setRequestMessage,
    setRequestEditData, setRequestEditLoading, setRequestEditError, setRequestEditMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,
    setRequestFilterData, setRequestFilterLoading, setRequestFilterError, setRequestFilterMessage,
    setRequestSaveRamoAtividadeData, setRequestSaveRamoAtividadeLoading, setRequestSaveRamoAtividadeError, setRequestSaveRamoAtividadeMessage,
    setLista, setFieldFiltered, setListaFiltered, setCurrentData, setFieldFilteredFlagAtivo, setRamoAtividade
} = RamoAtividadeSlice.actions

export default RamoAtividadeSlice.reducer