import { DOMAIN } from "../../../../../../var_env."

import { postApi } from "../../../../../../utils/requestApi"
import { setGrupoLoading } from "../../../Reducers/GrupoAcessoReducer"

const handleSubmitAccess = async (dispatch: Function, values: any, handleReloadModules: Function, id: number, fields: any) => {
    try {
        dispatch(setGrupoLoading(true))


        const permissoes = (Object.keys(values).filter(function (index) { return values[index] !== false })).map(data => Number(data))
        const menusPodeEditar : number[]= []
        for (const iterator of permissoes) {
            const doc = document.getElementById(iterator.toString()) as HTMLInputElement
            if(doc){
                if(doc.checked)menusPodeEditar.push(iterator)
            }
        }
        const data = {
            idGrupo: id,
            antigasPermissoes: (Object.keys(fields).filter(function (index) { return fields[index] !== false })).map(data => Number(data)),
            novasPermissoes: (Object.keys(values).filter(function (index) { return values[index] !== false })).map(data => Number(data)),
            menusPodeEditar
        }
        const response = await postApi({ endpoint: DOMAIN + '/GrupoAcessoMenu/salvarNovasPermissoes', data })
        if(response) await handleReloadModules()
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(setGrupoLoading(false))
    }
}

const handleSubmitMenus = async (dispatch: Function, values: any, handleReloadModules: Function, moduloId: number) => {
    try {
        dispatch(setGrupoLoading(true))

        const data = { nome: values.menuName, path: values.menuPath, modulo: moduloId }
        const response = await postApi({ endpoint: DOMAIN + '/ModuloMenu', data })
        if(response) await handleReloadModules()
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(setGrupoLoading(false))
    }
}

const handleSubmitModules = async (dispatch: Function, values: any, handleReloadModules: Function) => {
    try {
        dispatch(setGrupoLoading(true))

        const data = { nome: values.moduleName, path: values.modulePath }
        const response = await postApi({ endpoint: DOMAIN + '/Modulo', data })
        if(response) await handleReloadModules()
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(setGrupoLoading(false))
    }
}

export { handleSubmitAccess, handleSubmitMenus, handleSubmitModules }