import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ModuloType } from "../../../types/Modules"
import { RequestResponsePattern, RequestStatusPattern } from "../../../types/Request"

interface State {
    requestModules: RequestStatusPattern<RequestResponsePattern<ModuloType[]>>
    requestAuthorizedModules: RequestStatusPattern<RequestResponsePattern<ModuloType[]>>

    modules: ModuloType[] | null,
    authorizedModules: ModuloType[] | null,
    accessAuthorizedModules: ModuloType[] | null
    currentModule: ModuloType | null

    routes: number[] | null

    formFields: any
    fields: any
}

const initialState: State = {
    requestAuthorizedModules: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestModules: {
        data: null,
        loading: false,
        error: false,
        message: null
    },

    modules: null,
    authorizedModules: null,
    accessAuthorizedModules: null,
    currentModule: null,

    routes: null,
    formFields: null,
    fields: null,
}

const modulesSlice = createSlice({
    name: 'modules',
    initialState,
    reducers: {
        setRequestAuthorizedModulesData: (state, action: PayloadAction<RequestResponsePattern<ModuloType[]>>) => {
            state.requestAuthorizedModules.data = action.payload
            state.requestAuthorizedModules.loading = false
            state.requestAuthorizedModules.error = false
        },
        setRequestAuthorizedModulesLoading: (state) => {
            state.requestAuthorizedModules.loading = true
            state.requestAuthorizedModules.error = false
        },
        setRequestAuthorizedModulesError: (state) => {
            state.requestAuthorizedModules.loading = false
            state.requestAuthorizedModules.error = true
        },
        setRequestAuthorizedModulesMessage: (state, action: PayloadAction<string>) => {
            state.requestAuthorizedModules.message = action.payload
        },
        
        setRequestModulesData: (state, action: PayloadAction<RequestResponsePattern<ModuloType[]>>) => {
            state.requestModules.data = action.payload
            state.requestModules.loading = false
            state.requestModules.error = false
        },
        setRequestModulesLoading: (state) => {
            state.requestModules.loading = true
            state.requestModules.error = false
        },
        setRequestModulesError: (state) => {
            state.requestModules.loading = false
            state.requestModules.error = true
        },
        setRequestModulesMessage: (state, action: PayloadAction<string>) => {
            state.requestModules.message = action.payload
        },

        setRoutes: (state, action: PayloadAction<number[]>) => {
            state.routes = action.payload
        },
        setModules: (state, action: PayloadAction<ModuloType[]>) => {
            state.modules = action.payload
        },
        setCurrentModule: (state, action: PayloadAction<ModuloType>) => {
            state.currentModule = action.payload
        },
        setAuthorizedModules: (state, action: PayloadAction<ModuloType[]>) => {
            state.authorizedModules = action.payload
            state.routes = action.payload.map(modulo => modulo.ModuloMenu.map(menu => menu.id)).flat(1).map(obj => obj)
        },
        setAccessAuthorizedModules: (state, action: PayloadAction< ModuloType[]>) => {
            state.fields = null
            state.formFields = null
            state.accessAuthorizedModules = action.payload
        },
        setFields: (state, action: PayloadAction<any>) => {
            state.formFields = action.payload
            state.fields = action.payload
        },
    }
})

export const { 
    setRequestAuthorizedModulesData, setRequestAuthorizedModulesLoading, setRequestAuthorizedModulesError, setRequestAuthorizedModulesMessage,
    setRequestModulesData, setRequestModulesLoading, setRequestModulesError, setRequestModulesMessage,
    setRoutes, setModules, setAuthorizedModules, setAccessAuthorizedModules, setFields, setCurrentModule,
} = modulesSlice.actions
export default modulesSlice.reducer