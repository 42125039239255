import { setRequestData, setRequestError, setRequestLoading, setRequestMessage } from "../Reducers/RamoAtividadeReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { SendParams } from "../types"
import { RamoAtividadeType } from "../Types/RamoAtividadeType"

export default async function send(dispatch: Function, values: SendParams){
    try {
        dispatch(setRequestLoading())
        
        const endpoint = `${DOMAIN}/RamoAtividade`
        const data = { descricao: values.descricao, flagAtivo: values.flagAtivo ? 1 : 0, idUsuario: values.idUsuario }
        const response: RequestResponsePattern<RamoAtividadeType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestData(response))
            if(!response.flagErro) dispatch(setRequestData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestError())
    }
}