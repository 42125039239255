import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { useAppSelector } from '../../../../../../store/hooks'
import List, { ListColumn, ListRow, ListSection } from '../../../../../../components/List'

const ListarClientes: React.FC = () => {

    const { requestGet, clients } = useAppSelector(s => s.ClientsReducer)

    const listGrid = "80px 1fr 1fr 100px 140px"

    const SHOW_LOADING = requestGet.loading
    const SHOW_DATA = !SHOW_LOADING && !!clients

    return(

        <>
            <List name="clients">
                <ListSection type="header">
                    <ListRow grid={listGrid}>
                        {["Código", "Cliente", "Situação", "Data Cadastro"].map((column, index) => <ListColumn key={index} text={column} />)}
                    </ListRow>
                </ListSection>
                <ListSection type="main">
                    {SHOW_LOADING && (
                        <ListRow grid='1fr'>
                            <ListColumn center><CircleSpinner size={32} color="grey" /></ListColumn>
                        </ListRow>
                    )} 
                    {SHOW_DATA && clients.map((item, index) => (
                        <ListRow key={item.id} link={`/medidor-vazao/historico/${item.id}`} zebra={index % 2 === 0} grid={listGrid}>
                            <ListColumn text={item.codigo} />
                            <ListColumn text={item.nome} />
                            <ListColumn text={(item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado')} />
                            <ListColumn text={new Date(item.dtCadastro).toLocaleDateString()} />
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>

    )

}

export default ListarClientes