import { mdiLock } from '@mdi/js';
import { Icon as MdiIcon } from '@mdi/react';
import { Field, Form as FormikContainer, Formik, ErrorMessage } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import Banner from '../../../assets/images/cadeado.png';
import { setAuthRedirect } from '../../../store/reducers/auth/authReducer';
import { RequestResponsePattern } from '../../../types/Request';
import { getApi } from '../../../utils/requestApi';
import { DOMAIN } from '../../../var_env.';
import { saveNewPassword } from '../ChangePassword/scripts/saveNewPassword';
import '../ChangePassword/styles.scss';
import InvalidLink from './components/InvalidLink';
import { recoverPasswordSchema } from './scripts/constantes';
import './styles.scss'

const RecoverPassword: React.FC = () => {

    const [redirect, setRedirect] = useState(false)
    const [linkValido, setLinkValido] = useState(false)
    const dispatch = useDispatch()
    const { userUuid, linkUuid } = useParams<any>()

    const handleSubmit = useCallback(async values => {

        const user = await saveNewPassword(values.confirmarNovaSenha, userUuid!)
        if (user) { dispatch(setAuthRedirect(false)); setRedirect(true) }

    }, [userUuid, dispatch])

    useEffect(() => {
        (async () => {

            const response : RequestResponsePattern<{msg: string}> = await getApi({ endpoint: DOMAIN + `/users/verifyRecoverPasswordLink/${linkUuid}`, params: '' })
            if (response.listaResultados.msg === 'Link válido') setLinkValido(true)
        })()
    }, [linkUuid])

    const SHOW_LINK_VALIDO = linkValido


    return (
        <>
            {redirect && <Navigate to="/" />}

            {!SHOW_LINK_VALIDO && <InvalidLink />}

            {SHOW_LINK_VALIDO && <div id="formChangeContainer">
                <div className="loginContent">
                    <img src={Banner} alt="Banner" />
                </div>
                <div className="loginContent">
                    <Formik
                        initialValues={{ novaSenha: '', confirmarNovaSenha: '' }}
                        onSubmit={values => handleSubmit(values)}
                        validationSchema={recoverPasswordSchema}
                    >
                        <FormikContainer>
                            <div id="formContainer">
                                <div className="formTitle">
                                    <h3>Redefinir senha</h3>
                                    <h4>Preencha os campos abaixo.</h4>
                                </div>
                                <div className="inputContainer">
                                    <MdiIcon path={mdiLock} size="24px" />
                                    <Field name="novaSenha" type="password" placeholder="Nova senha" />
                                </div>
                                <ErrorMessage name='novaSenha' />
                                <div className="inputContainer">
                                    <MdiIcon path={mdiLock} size="24px" />
                                    <Field name="confirmarNovaSenha" type="password" placeholder="Confirme a nova senha" />
                                </div>
                                <ErrorMessage name='confirmarNovaSenha' />
                                <input type="submit" value="Confirmar" />
                            </div>
                        </FormikContainer>
                    </Formik>
                </div>
            </div>}
        </>
    )
}

export default RecoverPassword;