import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { EditParams } from "../types"
import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from "../../Reducers/UsuarioReducer"
import { postApi } from "../../../../../utils/requestApi"

export default async function edit(dispatch: Function, values: EditParams){
    try {
        dispatch(setRequestEditLoading())

        const endpoint = `${DOMAIN}/Usuario/update/${values.id}`
        const data = {...values}
        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })
        
        if(response){
            dispatch(setRequestEditData(response))
            if(!response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}