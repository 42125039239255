import { DOMAIN } from "../../../../../var_env."
import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from "../Reducers/EnderecoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { EnderecoType } from "../../Parametro/Types/EnderecoType"
import { EditParams } from "../types"

export default async function edit(dispatch: Function, values: EditParams){
    try {
        dispatch(setRequestEditLoading())
        
        const endpoint = `${DOMAIN}/PessoaPontoColeta`
        const data = { 
            id: values.id,
            idPessoa:values.flagAtivo,
            idUsuario:values.idUsuario,
            descricao:values.descricao.descricao,
            planoAmostragem:values.descricao.planoAmostragem
        }
        
        const response: RequestResponsePattern<EnderecoType> = await postApi({ endpoint, data })
        
        if(response){
            
            dispatch(setRequestEditData(response))
            if(!response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}