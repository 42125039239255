import { DOMAIN } from '../../var_env.'
import { setAccessAuthorizedModules } from '../../store/reducers/modules/modulesReducer'
import { getApi } from '../../utils/requestApi'
import { RequestResponsePattern } from '../../types/Request'
import { ModuloType } from '../../types/Modules'

export default async function getAppAccessAuthorizedModules(dispatch: Function, idGrupoAcesso: number, setarAtual: boolean = false){

    try {
        const result : RequestResponsePattern<ModuloType[]> = await getApi({ endpoint: DOMAIN, params: `/Modulo/${idGrupoAcesso}`})

        if(setarAtual){
        localStorage.setItem('modulosAutorizados', JSON.stringify(result.listaResultados))

    }
    dispatch(setAccessAuthorizedModules(result.listaResultados))
    } catch (error) {
        console.log(error)
    }

}