import React from 'react'
import { NavLink } from 'react-router-dom'
//import { Tooltip } from '@material-ui/core'

import { SidebarLinkProps } from '../types'

const Link: React.FC <SidebarLinkProps> = ({ link, tooltip, label }) => {

    return(

        <>
            <li className = "sidebarOptionContainer default">
                <NavLink to = {link} className = "sidebarOptionContent">
                    <div className = "sidebarOptionIcon" />
                    <span className = "sidebarOptionLabel">{label}</span>
                </NavLink>
            </li>
        </>

    )

}

export default Link