import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { mdiClipboardTextClock, mdiClipboardTextOff } from '@mdi/js'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { useAppSelector } from '../../../../store/hooks'
import { TabData } from '../../../../components/Tab/types'
import Section, { SectionBox, SectionBoxData, SectionOption } from '../../../../components/Section'
import Modal from '../../../../components/Modal'
import getHistoricoColeta from './scripts/getHistoricoColeta'
import getHistoricoColetaNaoConformidade from './scripts/getHistoricoColetaNaoConformidade'
import TableListarHistoricoColeta from './Components/TableListarHistoricoColeta'
import Tab from '../../../../components/Tab'
import Dados from './tabs/dados'
import MonMensal from './tabs/monMensal'
import MonAnual from './tabs/monAnual'
import Endereco from './tabs/endereco'
import Contato from './tabs/contato'
import PontoColeta from './tabs/pontoColeta'
import getPontosColeta from './scripts/getPontosColeta'
import getAbastecimento from './scripts/getAbastecimento'
import TabHidrometro from './tabs/hidrometro'
import TableListarHistoricoNaoConformidade from './Components/TableListarHistoricoNaoConformidade'
import getRoteiro from './scripts/getRoteiro'
import getRamoAtividade from './scripts/getRamoAtividade'
import get from '../Parametro/Scripts/get'
import getColor from './scripts/getColor'
import { Formik, Form as FormikContainer } from 'formik'
import Form from '../../../../components/Form'
import createParams from './scripts/createParams'
import editHistorico from './scripts/editHistorico'
import { setHistoricoColeta } from './Reducers/ClientsReducer'
import Select from '../../../../components/Select'
import SnackbarAlert from '../../../../components/SnackbarAlert'
import removeHistorico from './scripts/removeHistorico'
import isEditable from '../../../../scripts/modules/isEditable'
import saveHistorico from './scripts/saveHistorico'

const Client: React.FC = () => {

    const { idCliente } = useParams<any>()

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'cliente')
    
    const dispatch = useDispatch()
    const { requestGetHistoricoColeta, requestGetHistoricoColetaNaoConformidade, historicoColeta, historicoColetaNaoConformidade, cliente } = useAppSelector(state => state.ClientsReducer)

    const [openRelatorioColeta, setOpenRelatorioColeta] = useState<boolean>(false)
    const [edit, setEdit] = useState<boolean>(false)
    const [remove, setRemove] = useState<boolean>(false)
    const [valueHistorico, setValueHistorico] = useState<any>(null)
    const [openRelatorioNaoColeta, setOpenRelatorioNaoColeta] = useState<boolean>(false)
    const [tabIndex, setTabIndex] = React.useState<number>(0)

    const [chuva, setChuva] = useState<string>('')
    const parametros = ['DQO', 'DBO', 'pH', 'temperatura', 'Ag', 'As_', 'Cd', 'CN', 'Cr', 'Cr6', 'Cu', 'Fe', 'Fenol', 'Hg', 'Ni', 'N', 'Pb', 'P', 'Se', 'Sn', 'Zn', 'Rs', 'Og', 'Sulfato', 'Sulfeto', 'Fluoreto', 'Toxicidade']

    const clientDataTabs: TabData[] = [
        { label: 'Dados', component: <Dados canEdit={canEdit} /> },
        { label: 'Endereço', component: <Endereco canEdit={canEdit} /> },
        { label: 'Contatos', component: <Contato canEdit={canEdit}/> },
        { label: 'Pontos Coleta', component: <PontoColeta canEdit={canEdit} /> },
        { label: 'Mon. Mensal', component: <MonMensal  /> },
        { label: 'Mon. Anual', component: <MonAnual  canEdit={canEdit} /> },
        { label: 'Med. Vazão', component: <TabHidrometro canEdit={canEdit} /> },
    ]

    const handleFecharModal = useCallback( () => {
        setRemove(false)
        setValueHistorico(null)
    }, [])

    const handleRemover = useCallback(async () => {
        if (!valueHistorico) return
        const data = {
            identificacao: valueHistorico.identificacao,
        }
        await removeHistorico(dispatch, data)

        await getHistoricoColeta(dispatch, Number(idCliente))
        setRemove(false)
    }, [dispatch, idCliente,valueHistorico ])

    const handleSave = useCallback(async (data: any) => {
        data.flagChuva = Boolean(Number(chuva))
        if (valueHistorico===null) {
            await saveHistorico(dispatch, data)
        }else{
            await editHistorico(dispatch, data)
        }
  
        //
        // const index = historicoColeta.result.findIndex(x => x.identificacao === data.identificacao)
        // if (!index) return
        // historicoColeta.result.splice(index, 1, data)

        await getHistoricoColeta(dispatch, Number(idCliente))
        setEdit(false)
    }, [dispatch, chuva, valueHistorico, idCliente])


    const handleDate = (data: Date) => {
        const novaData = new Date(data.toISOString().replace(/\.\d{3}Z$/, ''))
        const date = new Date((novaData.getTime() - (1.08e7)))
        const a = `${date.getFullYear()}-${`${date.getMonth() +
            1}`.padStart(2, '0')}-${`${date.getDate()}`.padStart(
                2,
                '0'
            )}T${`${date.getHours()}`.padStart(
                2,
                '0'
            )}:${`${date.getMinutes()}`.padStart(2, '0')}`
        return a
    }

    useEffect(() => {
        getRoteiro(dispatch)
        getRamoAtividade(dispatch)
        getAbastecimento(dispatch)
        getPontosColeta(dispatch, Number(idCliente))
        get("Parametro", dispatch)
        getColor(dispatch, Number(idCliente))
    }, [dispatch, idCliente])
    /*
    const handleSalvarDadosParaPDF = useCallback(async () => {
        await getDadosAnalise(dispatch, Number(idCliente))
    }, [dispatch, idCliente])

    const handleSalvarDadosDBOParaPDF = useCallback(async () => {
        await getDadosDBO( Number(idCliente))
    }, [idCliente])*/

    return (
        <>
            <Section name="data">
                <SectionBox
                    title={`${ Number(idCliente) ===0  ? 'Criar Cliente' : (cliente? 'Cliente ' + cliente.nome : 'Criar Cliente')}`}
                    padding={false}
                    goBack
                    right={
                        <>
                            <SectionOption icon={mdiClipboardTextOff} tooltip="Histórico de coletas não conformes" onClick={() => { getHistoricoColetaNaoConformidade(dispatch, Number(idCliente)); setOpenRelatorioNaoColeta(true) }} />
                            <SectionOption icon={mdiClipboardTextClock} tooltip="Histórico de coletas" onClick={() => { getHistoricoColeta(dispatch, Number(idCliente)); setOpenRelatorioColeta(true) }} />
                        </>
                    }
                >
                    <Tab data={clientDataTabs} index={tabIndex} setIndex={setTabIndex} />
                </SectionBox>
            </Section>
            <>
                <Modal width={`100%`} open={openRelatorioColeta} setOpen={setOpenRelatorioColeta} title="Histórico de Coletas">
                    <TableListarHistoricoColeta
                        id={Number(idCliente)}
                        lista={historicoColeta}
                        setValues={setValueHistorico}
                        loading={requestGetHistoricoColeta.loading}
                        setEdit={setEdit}
                        setRemove={setRemove}

                    />
                </Modal>
                <Modal width={`80%`} open={openRelatorioNaoColeta} setOpen={setOpenRelatorioNaoColeta} title="Histórico de Coletas Não Conformidades">
                    <TableListarHistoricoNaoConformidade
                        id={Number(idCliente)}
                        lista={historicoColetaNaoConformidade}
                        loading={requestGetHistoricoColetaNaoConformidade.loading}
                    />
                </Modal>

                <Modal width={600} open={remove} setOpen={setRemove} title="excluir historico">
                <span className = "modalBoxMessage">Deseja remover esse item do historico?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default`} onClick = {() => handleRemover()}>Remover</button>
                    </div>
                </Modal>
                <Modal width={`100%`} open={edit} setOpen={setEdit} title="Editar historico">
                    { <>

                        <Formik
                            initialValues={{
                                identificacao:!valueHistorico? '': valueHistorico.identificacao,
                                pontoColeta:!valueHistorico? '': valueHistorico.pontoColeta,
                                dtInstalaco:!valueHistorico? '': handleDate(new Date(valueHistorico.dtInstalaco)),
                                tempoInstalacao:!valueHistorico? '': valueHistorico.tempoInstalacao,
                                dtRetirada:!valueHistorico? '': handleDate(new Date(valueHistorico.dtRetirada)),
                                qtdeColeta:!valueHistorico? '': valueHistorico.qtdeColeta,
                                codigoAmostra: !valueHistorico? '':valueHistorico.codigoAmostra,
                                flagChuva:!valueHistorico? 0: valueHistorico.flagChuva??0,
                                medidorInicial:!valueHistorico? '': valueHistorico.medidorInicial,
                                medidorFinal:!valueHistorico? '': valueHistorico.medidorFinal,
                                volumeMedido:!valueHistorico? '': valueHistorico.volumeMedido,
                                idClienteSave: Number(idCliente),
                                ...createParams(parametros, valueHistorico)
                            }}
                            onSubmit={values => handleSave(values)}
                        >
                            <FormikContainer>
                                <Form.Container padding={false}>
                                    <SectionBoxData title="Dados">
                                        <Form.Row columns={4} grid="250px 1fr 235px 235px 155px">
                                            <Form.Group inputID='pontoColeta' inputName='pontoColeta' label='Ponto coleta' />
                                            <Form.Group inputType='datetime-local' inputID='dtInstalaco' inputName='dtInstalaco' label='Data instalação' />
                                            <Form.Group inputID='tempoInstalacao' inputName='tempoInstalacao' label='Tempo instalação' />
                                            <Form.Group inputType='datetime-local' inputID='dtRetirada' inputName='dtRetirada' label='Data retirada' />
                                            <Form.Group inputID='qtdeColeta' inputName='qtdeColeta' label='Quantidade coleta' />
                                        </Form.Row>
                                        <Form.Row columns={5} grid="150px 1fr 235px 235px 235px">

                                            <Form.Group inputID='codigoAmostra' inputName='codigoAmostra' label='Código amostra' />
                                            <Select type='outline' value={chuva} setValue={setChuva} defaultValue={(valueHistorico && valueHistorico.flagChuva)? valueHistorico?.flagChuva.toString():'0'} label='Chuva' items={[{ value: '1', label: 'Sim' }, { value: '0', label: 'Não' }]} />

                                            <Form.Group inputID='medidorInicial' inputName='medidorInicial' label='Medidor Inicial' />
                                            <Form.Group inputID='medidorFinal' inputName='medidorFinal' label='Medidor Final' />
                                            <Form.Group inputID='volumeMedido' inputName='volumeMedido' label='Volume medido' />
                                        </Form.Row>
                                        <Form.Row columns={5} grid="repeat(5, 1fr)">

                                            {parametros.map((item, index) => {
                                                let label = item
                                                if (item === 'temperatura') label = '°C'
                                                if (item === 'As_') label = 'As'
                                                if (item === 'Rs') label = "SS"
                                                return <Form.Group inputID={item} inputName={item} key={index} label={label} />
                                            })}

                                        </Form.Row>
                                    </SectionBoxData>

                                    <div className="formActionsContainer">
                                        <button type="submit"> Salvar</button>
                                    </div>
                                </Form.Container>
                            </FormikContainer>
                        </Formik>
                    </>}
                </Modal>
            </>
            <SnackbarAlert />
        </>
    )

}

export default Client