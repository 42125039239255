import React, { useEffect, useState } from 'react'
import { Page, View, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableBanner from './table/banner'
import TableHeader from './table/header'
import TableRow from './table/row'
import TableSignature from './table/signature'
import TableFooter from './table/footer'

const CargaPdf: React.FC = () => {

    const [cliente, setCliente] = useState<any[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            paddingTop: '20px',
            paddingBottom: '60px',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setCliente(JSON.parse(localStorage.getItem('MonitoramentoCargas')!)) })()
    }, [])

    return(

        <>
            {!!cliente && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} wrap={false} break>
                            <View style={styles.section}>
                                <TableBanner />
                                <TableHeader />
                                 <TableRow  relatorios={cliente} />

                                <TableSignature />
                                <TableFooter />
                            </View>
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )


}

export default CargaPdf