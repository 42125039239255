import { DOMAIN } from '../../var_env.'

import { RequestResponsePattern } from '../../types/Request'
import { ModuloType } from '../../types/Modules'

import { setAuthorizedModules, setRequestAuthorizedModulesData, setRequestAuthorizedModulesError, setRequestAuthorizedModulesLoading, setRequestAuthorizedModulesMessage } from '../../store/reducers/modules/modulesReducer'

import { getApi } from '../../utils/requestApi'

export default async function getAppAuthorizedModules(dispatch: Function, idGrupoAcesso: number){
    try {
        dispatch(setRequestAuthorizedModulesLoading())

        const endpoint = DOMAIN
        const params = `/Modulo/${idGrupoAcesso}`
        const response: RequestResponsePattern<ModuloType[]> = await getApi({ endpoint, params })

        if(response){
            dispatch(setRequestAuthorizedModulesData(response))
            if(!response.flagErro) dispatch(setAuthorizedModules(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestAuthorizedModulesMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestAuthorizedModulesError())
    }
}