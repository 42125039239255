import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { RelatorioRegiaoType } from "../Types/RelatorioRegiao"
import { setRequestGetRelatorioRegiaoLoading, setRequestGetRelatorioRegiaoData, setRequestGetRelatorioRegiaoError, setRequestGetRelatorioRegiaoMessage, setRelatorioRegiao } from "../Reducers/RelatorioRegiaoReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getRelatorioRegiao(dispatch: Function){
    try {
        dispatch(setRequestGetRelatorioRegiaoLoading())

        const endpoint = `${DOMAIN}/RelatorioPessoa`
        const response: RequestResponsePattern<RelatorioRegiaoType[]> = await getApi({ endpoint, params: '' })

        if(response){
            dispatch(setRequestGetRelatorioRegiaoData(response))
            if(!response.flagErro){
                dispatch(setRelatorioRegiao(response.listaResultados))
                localStorage.setItem("RelatorioRegiao", JSON.stringify(response.listaResultados))
            }else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetRelatorioRegiaoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetRelatorioRegiaoError())
    }
}