import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/RelatorioDBOReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { RelatorioDbo } from "../Types"
    
export default async function getDados(dispatch: Function, values: any){
    try {
        if(values.inicio==="" || values.fim==="")return null
        dispatch(setRequestFilterLoading())
        const response: RequestResponsePattern<any> = await getApi({ endpoint: `${DOMAIN}/relatorioAtualizacaoDBO/`, params: `${values.id}` });

        if(response){
            const relatorio:RelatorioDbo[]  = JSON.parse(response.listaResultados) 
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) {
                dispatch(setListaFiltered(relatorio))
                localStorage.setItem('RelatorioDboAtualizacao', JSON.stringify(relatorio))
                localStorage.setItem('dataBuscaRelatorioDbo', JSON.stringify({inicio:values.inicio, fim: values.fim}))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}