import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'

const LogUserPDF: React.FC = () => {

    const [parametro, setParametro] = useState<any[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '60px',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setParametro(JSON.parse(localStorage.getItem('logUser')!)) })()
    }, [])

    return(

        <>
            {!!parametro && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} break>

                                <TableBanner />
                                <TableHeader />
                                {parametro.map((item, index) => <TableRow key = {index} {...item} />)}

                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default LogUserPDF