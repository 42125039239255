import React, { useEffect, useState } from 'react'
import { Page, View, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import { RelatorioRegiaoType } from '../../Types/RelatorioRegiao'
import TableBanner from './table/Banner'
import TableHeader from './table/header'
import TableInfo from './table/row'
import TableSection from './table/Section'
import TableFooter from './table/footer'

const RelatorioRegiaoPdf: React.FC = () => {

    const [dados, setDados] = useState<RelatorioRegiaoType[] | null>(null)
    const [agrupados, setAgrupados] = useState<{dados: RelatorioRegiaoType[], titulo: string}[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '55px',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    const agroupDadosByDescricaoRoteiro = (dados: RelatorioRegiaoType[]) => {
        const roteiros: string[] = []

        dados.filter(dado => dado.descricaoRoteiro !== null).map(item => {
            if(!roteiros.includes(item.descricaoRoteiro!)) return roteiros.push(item.descricaoRoteiro!)
            else return roteiros
        })

        const agroupByRoteiros = dados.filter(dado => dado.descricaoRoteiro !== null).reduce((acc: any, item) => {
            if(!acc[item.descricaoRoteiro!]){
                acc[item.descricaoRoteiro!] = []
            }
            acc[item.descricaoRoteiro!].push(item)
            return acc
        }, {})

        const formatted = Object.keys(agroupByRoteiros).map(key => {
            return { titulo: key, dados: agroupByRoteiros[key] }
        })

        setAgrupados(formatted)
    }

    useEffect(() => {
        (async() => { 
            setDados(JSON.parse(localStorage.getItem('RelatorioRegiao')!)) 
        })()
    }, [])

    useEffect(() => {
        if(dados) agroupDadosByDescricaoRoteiro(dados)
    }, [dados])

    return(

        <>
            {!!dados && !!agrupados && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page}  break>

                                <TableBanner />
                                {agrupados.map((item, index) => (
                                    <React.Fragment key = {index}>
                                        <TableSection>{item.titulo}</TableSection>
                                        <TableHeader />
                                        {item.dados.map((dado, index) => (
                                            <TableInfo key = {index} {...dado} />
                                        ))}
                                        <View style = {{marginBottom: '24px'}} />
                                    </React.Fragment>
                                ))}
                                <TableHeader />
                                {dados.filter(item => item.descricaoRoteiro === null && item.flagAtivo===1).map((item, index) => (
                                    <TableInfo key = {index} {...item} />
                                ))}
                                <TableFooter/>

                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioRegiaoPdf