import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"

const TableHeader: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '8px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        doubleColumnContainer: {
            width: '20%',
            border: "1px solid black",
            borderRight: '0',
        },
        doubleColumnTitle: {
            paddingVertical: '4px',
            fontSize: '8px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        doubleColumnItem: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            borderTop: '1px solid black',
            fontSize: '8px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })

    const Column: React.FC <{text: string, width: string, last?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )

    const Column2: React.FC <{text: string, width: string, last?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.doubleColumnItem, {width, borderRight: last ? 0 : '1px solid black'}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

        <View style = {styles.row}>
            <Column text = "#" width = "5%" />
            <Column text = "Cliente" width = "35%" />
            <Column text = "UC" width = "10%" />
            <View style = {styles.doubleColumnContainer}>
                <Text style = {styles.doubleColumnTitle}>Leitura</Text>
                <View style = {{display: 'flex', flexDirection: 'row'}}>
                    <Column2 text = "Anterior" width = "50%" />
                    <Column2 text = "Atual" width = "50%" last />
                </View>
            </View>
            <Column text = "Volume Medidio" width = "10%" />
            <Column text = "Volume Corrigido" width = "10%" />
            <Column text = "OBS" width = "15%" last />
        </View>

    )

}

export default TableHeader