import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setLista, setRequestGetData, setRequestGetError, setRequestGetLoading, setRequestGetMessage } from "../Reducers/ParametroReducer"
import { ParametroType } from "../Types/ParametroType"

export default async function get(apiController: string, dispatch: Function){
    try {
        dispatch(setRequestGetLoading())

        const endpoint = DOMAIN
        const params = `/${apiController}`
        const response: RequestResponsePattern<ParametroType[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestGetData(response))
            if(!response.flagErro) {
                dispatch(setLista(response.listaResultados))
                localStorage.setItem(apiController, JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}