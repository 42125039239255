import { DOMAIN } from "../../../../../../var_env."
import { RequestResponsePattern } from "../../../../../../types/Request"
import {  setRequestRemoveData } from "../../Reducers/MonAnualReducer"
import {  postApi } from "../../../../../../utils/requestApi"

export default async function deleteMon(dispatch: Function, usuarioId:number, id:number ){
    try {

        const response: RequestResponsePattern<string> = await postApi({ endpoint: `${DOMAIN}/RelatorioMonitoramento/anual/remove`, data: {id, idUsuario:usuarioId} })
        
        console.log(response)
        if(response){
            if(!response.flagErro) {
                dispatch(setRequestRemoveData(response))
               // localStorage.setItem(apiController, JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {

    }
}