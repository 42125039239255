import React, { useCallback } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Icon from '@mdi/react'
import { mdiClose } from '@mdi/js'
import { ModalProps } from './types'
import './styles.scss'
import ReactPortal from '../ReactPortal'

const Modal: React.FC <ModalProps> = ({ children, width, title, open, setOpen, actions, onClose }) => {

    const handleClose = useCallback(() => {
        if(open) setOpen(false)
    }, [setOpen, open])

    return(

        <>
            {(open &&
                <ReactPortal>
                    <div className = "modalWrapper">
                        <ClickAwayListener onClickAway = {() => {}}>
                            <ul className = {`modalBox`} style = {{width, marginTop:"5px"}}>
                                <li className = "modalBoxHeader">
                                    <h1 style={{paddingTop:"5px"}}>{title}</h1>
                                    <span className = "iconContainer" onClick = {handleClose}>
                                        <Icon path = {mdiClose} size = "24px" />
                                    </span>
                                </li>
                                <li className = "modalBoxMain customScroll">
                                    <div className = "modalBoxMainContainer">
                                        {children}
                                        {!!actions && actions.length > 0 && (
                                            <div className = "modalBoxMainActionsContainer">
                                                {actions.map((action, index) => (
                                                    <button key = {index} className = {action.theme ?? 'default'} onClick = {() => action.onClick()}>{action.label}</button>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </ClickAwayListener>
                    </div>
                </ReactPortal>
            )}
        </>

    )

}

export default Modal