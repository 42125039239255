import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { RelatorioRegiaoType } from "../Types/RelatorioRegiao"

interface State {
    requestStatusGetRelatorioRegiao: RequestStatusPattern<RequestResponsePattern<RelatorioRegiaoType[]>>

    relatorioRegiao: RelatorioRegiaoType[] | null
}

const requestStatusInitial = {
    data: null,
    loading: false,
    error: false,
    message: null,
}

const initialState: State = {
    requestStatusGetRelatorioRegiao: requestStatusInitial,

    relatorioRegiao: null,
}

const RelatorioRegiaoSlice = createSlice({
    name: 'RelatorioRegiaoReducer',
    initialState,
    reducers: {
        setRequestGetRelatorioRegiaoData: (state, action: PayloadAction<RequestResponsePattern<RelatorioRegiaoType[]>>) => {
            state.requestStatusGetRelatorioRegiao.data = action.payload
            state.requestStatusGetRelatorioRegiao.loading = false
            state.requestStatusGetRelatorioRegiao.error = false
        },
        setRequestGetRelatorioRegiaoLoading: (state) => {
            state.requestStatusGetRelatorioRegiao.loading = true
            state.requestStatusGetRelatorioRegiao.error = false
        },
        setRequestGetRelatorioRegiaoError: (state) => {
            state.requestStatusGetRelatorioRegiao.loading = false
            state.requestStatusGetRelatorioRegiao.error = true
        },
        setRequestGetRelatorioRegiaoMessage: (state, action: PayloadAction<string>) => {
            state.requestStatusGetRelatorioRegiao.message = action.payload
        },
        setRelatorioRegiao: (state, action: PayloadAction<any[]>) => {
            state.relatorioRegiao = action.payload
        },
    }
})

export const {  
    setRequestGetRelatorioRegiaoData, setRequestGetRelatorioRegiaoLoading, setRequestGetRelatorioRegiaoError, setRequestGetRelatorioRegiaoMessage,
    setRelatorioRegiao,
} = RelatorioRegiaoSlice.actions
export default RelatorioRegiaoSlice.reducer