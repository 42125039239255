import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import {vazaoLancamento } from '../../types'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import TableSignature from './Table/signature'
import TableFooter from './Table/footer'
import dataToReference from '../../scripts/dataToReference'

const VazaoLancamentoPdf: React.FC = () => {

    const [parametro, setParametro] = useState<vazaoLancamento[] | null>(null)
    const [mesAno, setMesAno] = useState<{mes:number, ano:number}>()

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '55px',
            backgroundColor: '#FFFFFF',
        },
    })

    useEffect(() => {
        (async() => { 
            setParametro(JSON.parse(localStorage.getItem('ListaVazao')!)) 
            setMesAno(JSON.parse(localStorage.getItem('anoMes')!))
        })()
    }, [])

    return(

        <>
            {!!parametro && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} orientation={"landscape"} break>
                            <TableBanner data={mesAno} />
                            <TableHeader />
                            {parametro.filter(item => item.flagMedidorVazao === 1).map((item, index) => <TableRow key = {index} props={item} id={index+1} zebra={index % 2 === 0} />)}
                            <TableSignature />
                            <TableFooter dataReferente={dataToReference(mesAno?.mes, mesAno?.ano)} />
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default VazaoLancamentoPdf