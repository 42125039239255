import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { Users } from '../../Types/Users'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'

interface Props {
    users: Users[] | null
    loading: boolean
}

const TableRelatorioListarUsuarios: React.FC <Props> = ({ users, loading }) => {

    const SHOW_DATA = !!users && !loading
    const SHOW_LOADING = loading
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)

    return (
        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead >
                    <TableRow >
                        {["ID", "Nome", "Login", "Situação", "Data de Cadastro"].map((item, index) => <TableCell key = {index}>{item}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody >
                    {SHOW_LOADING && (
                        <TableRow >
                            <TableCell > <CircleSpinner size = {32} color = "grey" /></TableCell>
                        </TableRow>
                    )}
                    {SHOW_DATA && users.map((item, index) => (
                        <TableRow key = {index} className = {selectedLine?.nome === item.nome ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell>{item.nome}</TableCell>
                            <TableCell>{item.login}</TableCell>
                            <TableCell>{item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'}</TableCell>
                            <TableCell>{new Date(item.dtCadastro).toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default TableRelatorioListarUsuarios