import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { RelatorioPessoaType } from '../../../types'
import Column from '../../../../../../../components/PDF/Column'

const TableRow: React.FC<{ relatorios: RelatorioPessoaType[] }> = ({ relatorios }) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '6px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })



    return (
        <>
            {relatorios.map((relatorio) =>
                <View style={styles.row} wrap={false}>
                    <Column text={relatorio.nome} width="40%"  />
                    <Column text={relatorio.cep??" "} width="15%" />
                    <Column text={`${relatorio.logradouro??" "}, ${relatorio.numero??" "}`} width="45%"  />
                    <Column text={"Jundiaí"} width="15%" />
                    <Column text={"SP"} width="5%" />
                    <Column text={relatorio.linkMapa??" "} width="40%" />
                    <Column text={relatorio.NomeContato} width="40%" />
                    <Column text={relatorio.telefone} width="20%" />
                    <Column text={relatorio.nroDocumento} width="20%" />
                    <Column text={relatorio.email} width="20%" />
                    <Column text={(relatorio?.flagAtivo === 1 ? "Ativo" : "Inativo").toString()} width="20%"  />
                    <Column text={relatorio.descricaoRamoAtividade??" "} width="20%" last/>
                </View>
            )}
        </>
    )

}

export default TableRow