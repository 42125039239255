import React from 'react'

import { HeaderContentProps } from '../types'

const Content: React.FC <HeaderContentProps> = ({ children, name }) => {

    return <li className = {`headerContent ${name}`}>{children}</li>

}

export default Content