import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import TableFooter from './Table/footer'
import { RelatorioPessoaType } from '../../../RelatorioPessoa/types'
import TableHeader from './Table/header'

const RelatorioPessoaPdf: React.FC = () => {

    const [relatorio, setRelatorio] = useState<RelatorioPessoaType[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '60px',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setRelatorio(JSON.parse(localStorage.getItem('RelatorioPessoa')!)) })()
    }, [])

    return(

        <>
            {!!relatorio && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page  size="A4" style={styles.page} orientation={"landscape"} break>
        
                                <TableBanner />
                                <TableHeader/>
                                <TableRow relatorios={relatorio}/>
                                <TableFooter/>

                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioPessoaPdf