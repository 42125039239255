import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { dadosRelatorioDBO } from '../types'

const TableSection: React.FC<{relatorioDBO:dadosRelatorioDBO[]}> = ({relatorioDBO}) => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            paddingTop: '4px',
            marginBottom: '24px',
            borderTop: '1px solid black',
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
        },
        textContainer: {
            flexDirection: 'row',
            alignItems: 'baseline',
            marginBottom: '4px',
        },
        title: {
            marginRight: '8px',
            fontSize: '10px',
        },
        text: {
            fontSize: '10px',
        },
    })

    const TextContainer: React.FC <{title: string, text: string[]}> = ({title, text}) => (
        <View style = {styles.textContainer}>
            <Text style = {styles.title}>{title}</Text>
            {text.map((item, index) => <Text key = {index} style = {styles.text}>{`${item}`}</Text>)}
        </View>
    )

    return(

        <>
        {relatorioDBO.map((relatorio) =>
            <View style = {styles.container}>
                <View style = {styles.row}>
                    <TextContainer title = "Indústria:" text = {[relatorio.nome]} />
                </View>
                <View style = {styles.row}>
                    <TextContainer title = "Endereço:" text = {[`${relatorio.logradouro??""}, ${relatorio.bairro??""}, ${relatorio.numero??""}`]} />
                </View>
                <View style = {styles.row}>
                    <TextContainer title = "UC:" text = {["123", "321", "111", "222", "333"]} />
                    <TextContainer title = "DBO (Kg/m³):" text = {[`${Number(relatorio.mediaDBO).toFixed(2)??"0.00"}`]} />
                </View>
            </View>
            )}
        </>

    )

}

export default TableSection