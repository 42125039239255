import React from 'react'

import { SectionBoxDataProps } from '../types'

const SectionBoxData: React.FC <SectionBoxDataProps> = ({ children, title, padding }) => {

    return(

        <div className = {`sectionBoxDataContainer`}>
            {title && <h3 className = "sectionBoxDataTitle">{title}</h3>}
            <div className = {`sectionBoxDataContent ${typeof padding === 'undefined' ? 'padding' : padding ? 'padding' : ''}`}>{children}</div>
        </div>

    )

}

export default SectionBoxData