import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const [data, setData] = useState<{inicio:string, fim:string}>()
    
    useEffect(() => {
        (async() => { setData(JSON.parse(localStorage.getItem('dataBuscaCarga')!)) })()
    }, [])
    
    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginBottom: '24px',
            borderBottom: '1px solid black',

        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        text: {
            fontSize: '10px',
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        }
    })

    return(
        <View>


        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>MONITORAMENTO DE CARGA</Text>
                <Text style = {styles.subTitle}>{`PERÍODO DE: ${new Date(`${data?.inicio} 00:00:00`).toLocaleDateString()} ATÉ ${new Date(`${data?.fim} 00:00:00`).toLocaleDateString()}`}</Text>
            </View>

        </View>

        <Text style={{fontSize:'8px', position: 'absolute', left: '80%', top:'45px'}}>{`${new Date().toLocaleString()}`}</Text>
        </View>

    )

}

export default TableBanner