import React from 'react'
import {mdiDelete} from '@mdi/js'
import { uploadArquivoImportacao } from '../tipes'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'

interface Props {
    lista: uploadArquivoImportacao[] | null
    handleApagarFile: (idDelete: number|undefined) => void
    canEdit: boolean
}

const TableListarArquivos: React.FC <Props> = ({ lista, handleApagarFile, canEdit }) => {

    const grids = "50px 1fr 150px 60px"
    const SHOW_TABLE = !!lista 

    return(
        <>
            <List name = "Arquivos">
                <ListSection type = "header">
                    <ListRow grid = {grids}>
                        {["ID", "Nome do arquivo", "Data de envio",canEdit? "Opções": ""].map((column, index) => <ListColumn key = {index} text = {column} />)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_TABLE && lista.map((arquivos) => (
                        <ListRow key={arquivos.id} grid = {grids}>
                            <ListColumn text = {arquivos.id.toString()} />
                            <ListColumn text = {arquivos.nomeArquivo} />
                            <ListColumn text = {new Date(arquivos.dtCadastro).toLocaleDateString()} />
                            {canEdit &&<ListColumn>
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleApagarFile(arquivos.id)}/>
                            </ListColumn>}
                        </ListRow>
                    ))}
                </ListSection>
            </List>

        </>
    )

}

export default TableListarArquivos