import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../types/Request"
import { Grupo } from "../Types/Grupo"

interface State {
    requestGetGrupoAcesso: RequestStatusPattern<RequestResponsePattern<Grupo[]>>
    requestCreate: RequestStatusPattern<RequestResponsePattern<Grupo>>
    requestDelete: RequestStatusPattern<RequestResponsePattern<any>>
    requestUpdate: RequestStatusPattern<RequestResponsePattern<any>>

    grupos: Grupo[] | null
    grupoLoading: boolean
    currentGrupo: Grupo | null
}

const requestInitial = {
    data: null,
    loading: false,
    error: false,
    message: null,
}

const initialState: State = {
    requestGetGrupoAcesso: requestInitial,
    requestCreate: requestInitial,
    requestDelete: requestInitial,
    requestUpdate: requestInitial,

    grupos: null,
    grupoLoading: false,

    currentGrupo: null,
}

const Slice = createSlice({
    name: 'GrupoAcesso',
    initialState,
    reducers: {
        setRequestGetGrupoAcessoData: (state, action: PayloadAction<RequestResponsePattern<Grupo[]>>) => {
            state.requestGetGrupoAcesso.data = action.payload
            state.requestGetGrupoAcesso.loading = false
            state.requestGetGrupoAcesso.error = false
        },
        setRequestGetGrupoAcessoLoading: (state) => {
            state.requestGetGrupoAcesso.loading = true
            state.requestGetGrupoAcesso.error = false
        },
        setRequestGetGrupoAcessoError: (state) => {
            state.requestGetGrupoAcesso.loading = false
            state.requestGetGrupoAcesso.error = true
        },
        setRequestGetGrupoAcessoMessage: (state, action: PayloadAction<string>) => {
            state.requestGetGrupoAcesso.message = action.payload
        },

        setRequestCreateData: (state, action: PayloadAction<RequestResponsePattern<Grupo>>) => {
            state.requestCreate.data = action.payload
            state.requestCreate.loading = false
            state.requestCreate.error = false
        },
        setRequestCreateLoading: (state) => {
            state.requestCreate.loading = true
            state.requestCreate.error = false
        },
        setRequestCreateError: (state) => {
            state.requestCreate.loading = false
            state.requestCreate.error = true
        },
        setRequestCreateMessage: (state, action: PayloadAction<string>) => {
            state.requestCreate.message = action.payload
        },

        setRequestDeleteGrupoAcessoData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestDelete.data = action.payload
            state.requestDelete.loading = false
            state.requestDelete.error = false
        },
        setRequestDeleteGrupoAcessoLoading: (state) => {
            state.requestDelete.loading = true
            state.requestDelete.error = false
        },
        setRequestDeleteGrupoAcessoError: (state) => {
            state.requestDelete.loading = false
            state.requestDelete.error = true
        },
        setRequestDeleteGrupoAcessoMessage: (state, action: PayloadAction<string>) => {
            state.requestDelete.message = action.payload
        },

        setRequestUpdateGrupoAcessoData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestUpdate.data = action.payload
            state.requestUpdate.loading = false
            state.requestUpdate.error = false
        },
        setRequestUpdateGrupoAcessoLoading: (state) => {
            state.requestUpdate.loading = true
            state.requestUpdate.error = false
        },
        setRequestUpdateGrupoAcessoError: (state) => {
            state.requestUpdate.loading = false
            state.requestUpdate.error = true
        },
        setRequestUpdateGrupoAcessoMessage: (state, action: PayloadAction<string>) => {
            state.requestUpdate.message = action.payload
        },

        setGrupos: (state, action: PayloadAction<Grupo[]>) => {
            state.grupos = action.payload
        },
        setGrupoLoading: (state, action: PayloadAction<boolean>) => {
            state.grupoLoading = action.payload
        },

        setCurrentGrupo: (state, action: PayloadAction<Grupo>) => {
            state.currentGrupo = action.payload
        }
    }
})

export const {  
    setRequestGetGrupoAcessoData, setRequestGetGrupoAcessoLoading, setRequestGetGrupoAcessoError, setRequestGetGrupoAcessoMessage,
    setRequestCreateData, setRequestCreateLoading, setRequestCreateError, setRequestCreateMessage,

    setRequestDeleteGrupoAcessoData, setRequestDeleteGrupoAcessoLoading, setRequestDeleteGrupoAcessoError, setRequestDeleteGrupoAcessoMessage,
    setRequestUpdateGrupoAcessoData, setRequestUpdateGrupoAcessoLoading, setRequestUpdateGrupoAcessoError, setRequestUpdateGrupoAcessoMessage,
    setGrupos, setGrupoLoading, setCurrentGrupo,
} = Slice.actions
export default Slice.reducer