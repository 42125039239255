import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"

const TableHeader: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '12px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })

    const Column: React.FC <{text: string, width: string, last?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

        <View style = {styles.row}>
            <Column text = "Usuário" width = "40%" />
            <Column text = "Ação" width = "40%" />
            <Column text = "Data" width = "20%" last />
        </View>

    )

}

export default TableHeader