import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAINmylims } from "../../../../../var_env."

import { setListaNovaColeta, setRequestSaveError, setRequestSaveLoading, setRequestSaveMessage } from "../Reducers/NovasColetasReducer"
import { NovasColetas } from "../types"


export default async function sendNovasColetas(dispatch: Function, data: any){
    try {
        dispatch(setRequestSaveLoading())
        setListaNovaColeta([])
        const endpoint = `${DOMAINmylims}/MigracaoVarios`

        const response: RequestResponsePattern<NovasColetas[]> = await postApi({ endpoint, data })
        console.log(response.listaResultados)
        if(response){
 
            if(!response.flagErro) dispatch(setListaNovaColeta(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestSaveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestSaveError())
    }
}