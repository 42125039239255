import { DOMAIN } from "../../../../../var_env."
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditLoadingObs, setRequestEditMessage } from "../Reducers/ClienteDadosReducer"
import { postApi } from "../../../../../utils/requestApi"
import createAlert from "../../../../../scripts/alert/createAlert"

export default async function editClienteDados(dispatch: Function, id: number, values: any, ramoAtividade: string, roteiro: string, status: string, idUsuario: number, obsAtual:string|undefined){
    try {
        dispatch(setRequestEditLoading())

        const endpoint = `${DOMAIN}/Pessoa`
        const data = {
            id,
            codigo: values.code,
            nome: values.nome,
            flagAtivo: status === "1" ? 1 : 0,
            razaoSocial: values.fantasyName ?? '',
            rg: values.rg ?? '',
            nroDocumento: values.cpf ?? '',
            inscEstadual: values.inscEst ?? '',
            ufRG: values.uf ?? '',
            obsPrincipal: values.obsPrincipal ?? '',
            idRamoAtividade: Number(ramoAtividade)===0? null: Number(ramoAtividade),
            idRoteiro: Number(roteiro)===0? null: Number(roteiro),
            idUsuario,
            flagTipoPessoa: values.tipoPessoa==="Jurídica"? "J": "F",
            idIntegracao: values.idIntegracao
        }
        if(obsAtual === values.obsPrincipal && status==="0"){
                 dispatch(setAlert(createAlert('error', 'Altere as observações')))
                 dispatch(setRequestEditLoadingObs())
        }else{
            const response = await postApi({ endpoint, data })
            if(response){
                dispatch(setRequestEditData(response))
                if(!response.flagErro) {
                    if(id===0){
                        
                        dispatch(setAlert(createAlert('success', 'Cliente cadastrado om sucesso')))
                        return window.location.href = `/cadastro/cliente/${response.listaResultados.id}`

                    }
                    dispatch(setAlert(createAlert('success', 'Dados alterados com sucesso')))
                }
                else throw new Error(response.listaMensagens[0])
            }else throw new Error('Erro na requisição')
        }
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
    }
}