import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form as FormikContainer } from 'formik'
import { mdiPlusCircle } from '@mdi/js'
import { useAppSelector } from '../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import Modal from '../../../../components/Modal'
import Form from '../../../../components/Form'
import TableListarComponents from './Components/TableListarComponents'
import Select from '../../../../components/Select'
import getGrupoAcesso from './scripts/getGrupoAcesso'
import create from './scripts/create'
import removeGrupoAcesso from './scripts/removeGrupoAcesso'
import { activeItems } from '../../../../constants/selects'
import updateGrupoAcesso from './scripts/updateGrupoAcesso'
import isEditable from '../../../../scripts/modules/isEditable'

const GrupoAcesso: React.FC = () => {

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'grupo-acesso')

    const dispatch = useDispatch()
    const { userData } = useAppSelector(state => state.auth)
    const { requestGetGrupoAcesso, requestCreate, requestDelete, requestUpdate, grupos, currentGrupo } = useAppSelector(state => state.GrupoAcessoReducer)

    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)

    const [flagAtivo, setFlagAtivo] = useState<string>('')

    const FormActions: React.FC <{label: string}> = ({ label }) => {
        const isLoading = requestCreate.loading || requestDelete.loading || requestUpdate.loading
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>
        )
    }

    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenEditar(false)
        setOpenRemover(false)
        setFlagAtivo('')
    }, [])

    const handleCriar = useCallback(async (nome: string) => {
        await create(dispatch, nome, userData!.id)
        handleFecharModal()
    }, [dispatch, handleFecharModal, userData])

    const handleRemover = useCallback(async () => {
        await removeGrupoAcesso(dispatch, currentGrupo!.id, userData!.id)
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentGrupo, userData])

    const handleEditar = useCallback(async (nome: string) => {
        await updateGrupoAcesso(dispatch, currentGrupo!.id, userData!.id, nome, flagAtivo)
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentGrupo, userData, flagAtivo])

    useEffect(() => {
        (async() => { await getGrupoAcesso(dispatch) })()
    }, [dispatch, requestCreate.data, requestUpdate.data, requestDelete.data])

    return (
        <>
            <Section name="grupoAcesso">
                <SectionBox title="Grupos de Acesso" padding = {false} right={canEdit ?<SectionOption icon={mdiPlusCircle} tooltip="Criar" onClick={() => setOpenCriar(true)} /> : <></>}>
                    <TableListarComponents 
                        grupo = {grupos} 
                        loading = {requestGetGrupoAcesso.loading}
                        setOpenEditar = {setOpenEditar}
                        setOpenRemover = {setOpenRemover} 
                        canEdit ={canEdit}
                    />
                </SectionBox>
            </Section>
            <>
                <Modal width={600} open={openCriar} setOpen={setOpenCriar} title="Adicionar grupo de acesso">
                    <Formik initialValues = {{nome: ''}} onSubmit = {values => handleCriar(values.nome)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                </Form.Row>
                                <FormActions label = "Criar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openEditar} setOpen = {setOpenEditar} title = "Editar grupo de acesso">
                    <Formik initialValues = {{nome: currentGrupo?.nome}} onSubmit = {values => handleEditar(values.nome!)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                    <Select type = "outline" value = {flagAtivo} setValue = {setFlagAtivo} defaultValue = {currentGrupo ? currentGrupo.flagAtivo ? '1' : '0' : '0'} label = "Status" items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover grupo de acesso">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button type = "reset" className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestDelete.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestDelete.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>
            </>
        </>
    )
}

export default GrupoAcesso