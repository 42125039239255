import getAppAccessAuthorizedModules from "./getAppAccessAuthorizedModules"
import getAppAuthorizedModules from "./getAppAuthorizedModules"
import getAppModules from "./getAppModules"

export default async function reloadModules(dispatch: Function, idGrupoAcessoUser: number, idGrupoAcesso: number){
    try {
        await getAppModules(dispatch, true)
        await getAppAuthorizedModules(dispatch, idGrupoAcessoUser)
        await getAppAccessAuthorizedModules(dispatch, Number(idGrupoAcesso), idGrupoAcessoUser===idGrupoAcesso)
    } catch (error) {
        console.log(error)
    }
}