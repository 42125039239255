import { StyleSheet, View, Text } from '@react-pdf/renderer'
import React from 'react'

const TableFooter: React.FC = () => {

    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: '-26px',
            left: '20px',
            right: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            borderTop: '1px solid black',
        },
        leftText: {
            fontSize: '8px',
        },
        rightText: {
            fontSize: '8px',
            fontWeight: 'bold',
        },foot:{
            position: 'absolute',
            bottom: '50px',
            left: '20px',
            right: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%'}
    })


    return(
<>


<Text fixed style={{bottom: '-10px',fontSize:'8px', position: 'absolute', left: '40%'}} >011_FMEI Rev.06 - Apr. Abr/24</Text>

    <View style = {styles.container} fixed>
        <Text style = {styles.leftText} render={({ pageNumber, totalPages }) => (`pág ${pageNumber} de ${totalPages}`)} fixed />
    </View>

        </>
    )

}

export default TableFooter