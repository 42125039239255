import { RequestResponsePattern } from "../../../../../../types/Request"
import { postApi } from "../../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../../var_env."


export default async function BuscaDados( Year: any, idCliente:string){
    try {

        
        const endpoint = `${DOMAIN}/RelatorioMonitoramento/anual/AnoAnterior`
        const data = {
            idPessoa:idCliente,
            ano:Year
        }
        

        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })
        
        if(response){

            if(!response.flagErro) return response.listaResultados
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {

    }
}