import { MedVazaoOneFormType } from "../../Types/MedVazaoOneForm"

export default function formValues(values: MedVazaoOneFormType){
    return {
        registro: values.registro ?? '', 
        data: values.data ? new Date(values.data).toISOString().substring(0, 10) : '', 
        calibracao: values.dtAtualCalibragem ? new Date(values.dtAtualCalibragem).toISOString().substring(0, 10) : '', 
        verificar: values.dtProximaCalibragem ? new Date(values.dtProximaCalibragem).toISOString().substring(0, 10) : '', 
        verificado: values.obsAtualCalibragem ?? '', 
        validade: values.dtValidadeCalibragem ? new Date(values.dtValidadeCalibragem).toISOString().substring(0, 10) : '', 
        fonte: values.fonte ?? '', 
        nroSerie: values.nroSerie ?? '', 
        codEquipamento: values.codigoEquipamento ?? '', 
        modelo: values.modelo ?? '',
        motivo: values.obsCobranca ?? '',
        lacre: values.lacre ?? ''
    }
}