import React from 'react'
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'
import { Tooltip } from '@material-ui/core'

const Search: React.FC = () => {

    const inputRef = React.useRef<HTMLInputElement>(null)
    const [value, setValue] = React.useState('')

    return(

        <div id = "headerSearch">
            <input ref = {inputRef} id = "headerSearchInput" name = "headerSearchInput" placeholder = "Pesquise aqui" onChange = {value => setValue(value.target.value)} />
            <Tooltip title = {`Pesquisar ${value}`}>
                <span id = "headerSearchIcon" onClick = {() => {}}>
                    <Icon path = {mdiMagnify} size = "24px" />
                </span>
            </Tooltip>
        </div>

    )

}

export default Search