interface Action {
    type: 'TOGGLE_OPEN'
}

interface State {
    open: boolean
}

export default function sidebarReducer(state: State, action: Action){

    const { type } = action

    switch(type){
        case 'TOGGLE_OPEN':
            return {
                ...state,
                open: !state.open
            }
        default:
            throw new Error(`Unhandled action type: ${type}`)
    }

}