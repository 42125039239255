//import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { EnderecoType } from "../../Parametro/Types/EnderecoType"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

export default async function getPessoaEndereco( idCliente: any){
    try {
        
        const endpoint = `${DOMAIN}`
        const params = `/PessoaEndereco/?idPessoa=${idCliente}`
        const response: RequestResponsePattern<EnderecoType[]> = await getApi({ endpoint, params })
        
        if(response){

            if(!response.flagErro) {
                localStorage.setItem("EnderecoHistorico", JSON.stringify(response.listaResultados[0]??null))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)

    }
}