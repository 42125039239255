import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/logUserReducer"


export default async function filter(apiController: string, dispatch: Function, values: any){
    try {
        dispatch(setRequestFilterLoading())

        const response: RequestResponsePattern<any[]> = await getApi({ endpoint: `${DOMAIN}/${apiController}`, params: `/?pesquisa=${values.descricao}&dtPesquisa=${values.dtPesquisa}&dtPesquisaFim=${values.fim}` });

        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro){
                 dispatch(setListaFiltered(response.listaResultados))
                 localStorage.setItem("logUser", JSON.stringify(response.listaResultados))
                }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}