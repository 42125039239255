import React from 'react'

import { SectionProps } from '../types'

import '../styles.scss'

import firstLetterToUpper from '../../../utils/firstLetterToUpper'

const Section: React.FC <SectionProps> = ({ children, name }) => {

    return(

        <section id = {`section${firstLetterToUpper(name)}`}>
            <div className = "sectionContainer">{children}</div>
        </section>

    )

}

export default Section