import React from 'react'
import { useDispatch } from 'react-redux'
import Section, { SectionBox } from '../../../../components/Section'
import getClients from '../Cliente/scripts/getClients'
import ListarClientes from './components/ListarClientes'

const VazaoHistoricos: React.FC = () => {

    const dispatch = useDispatch()

    React.useEffect(() => {
        getClients("Pessoa", dispatch, true)
    }, [dispatch])

    return(

        <>
            <Section name = "vazaoHistoricos">
                <SectionBox
                    title = "Clientes"
                    padding = {false}
                >
                    <ListarClientes />
                </SectionBox>
            </Section>
        </>

    )

}

export default VazaoHistoricos