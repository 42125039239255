import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiEye, mdiPencil } from '@mdi/js'
import { setCurrentData, } from '../Reducers/RamoAtividadeReducer'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'
import { RamoAtividadeType } from '../Types/RamoAtividadeType'

interface Props {
    lista: RamoAtividadeType[] | null
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
    canEdit : boolean
}

const TableListarComponents: React.FC<Props> = ({ lista, loading, setOpenEditar, setOpenRemover, canEdit }) => {

    const dispatch = useDispatch()
    
    const grid = "100px repeat(2, 1fr) 92px"

    const SHOW_TABLE = !!lista && !loading
    const SHOW_LOADING = loading

    const handleEdit = useCallback((item: RamoAtividadeType) => {
        dispatch(setCurrentData(item))
        setOpenEditar(true)
    }, [dispatch, setOpenEditar])

    const handleRemove = useCallback((item: RamoAtividadeType) => {
        dispatch(setCurrentData(item))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    return(
        <>
            <List name = "categoriaCobranca">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["ID", "Descrição", "Situação", canEdit && "Opções"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow key = {item.id} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.id}</ListColumn>
                            <ListColumn>{item.descricao}</ListColumn>
                            <ListColumn>{item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'}</ListColumn>
                            <ListColumn>
                                {canEdit && <><ListOption icon = {mdiEye} link = {`/cadastro/ramo-atividade/${item.id}`} onClick = {() => {}} />
                                <ListOption icon = {mdiPencil} onClick = {() => handleEdit(item)} />    
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemove(item)} /></>}
                            </ListColumn>
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>
    )

}

export default TableListarComponents