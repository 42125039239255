import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { Formik, Form as FormikContainer } from 'formik'
import { mdiPlusCircle } from '@mdi/js'
import { useAppSelector } from '../../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../../components/Section'
import Modal from '../../../../../components/Modal'
import edit from '../scripts/editPontoColeta'
import send from '../scripts/sendPontoColeta'
import remove from '../scripts/removePontoColeta'
import Form from '../../../../../components/Form'
import TableListarComponents from './components/TableListarPontosColeta'
import getPontosColeta from '../scripts/getPontosColeta'

const PontoColeta: React.FC<{canEdit: boolean}> = ({canEdit}) => {

    const dispatch = useDispatch()
    const { requestGet,pontosColeta, currentData, requestEdit  } = useAppSelector(state => state.ClientsReducer )
    const { requestSave, requestRemove } = useAppSelector(state => state.EnderecoReducer )
    const { idCliente } = useParams<any>()
    const { userData } = useAppSelector(state => state.auth)

    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)

    const FormActions: React.FC<{ label: string }> = ({ label }) => {
        const isLoading = requestEdit.loading || requestRemove.loading || requestSave.loading
        return (
            <div className="modalBoxMainActionsContainer">
                <button type="reset" className="outline" onClick={() => handleFecharModal()}>Cancelar</button>
                <button type="submit" className={`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className="spinner" />}{label}</button>
            </div>
        )
    }

    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenEditar(false)
        setOpenRemover(false)
    }, [])

    const handleCriar = useCallback(async (descricao: any) => {
        await send(dispatch, { descricao  , flagAtivo: Number(idCliente), idUsuario: userData!.id} )
        handleFecharModal()
    }, [dispatch, handleFecharModal, idCliente, userData])

    const handleEditar = useCallback(async (descricao: any) => {
        await edit(dispatch, { descricao, flagAtivo: Number(idCliente), idUsuario: userData!.id, id: currentData!.id })
        handleFecharModal() 
    }, [dispatch, handleFecharModal, currentData, userData, idCliente])

    const handleRemover = useCallback(async () => {
        await remove(dispatch, { id: currentData!.id, idUsuario: userData!.id })
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentData, userData])

    useEffect(() => {
        (async () => { await getPontosColeta(dispatch,Number(idCliente)) })()
    }, [dispatch,idCliente, requestEdit, requestRemove, requestSave])

    return (
        <>
        <Section name="Endereco">
            <SectionBox title="Pontos de Coleta" padding={false}  right={
            <>

            {canEdit && <SectionOption icon={mdiPlusCircle} tooltip="Criar" onClick={() => setOpenCriar(true)} />}
            </>
        }>
                <TableListarComponents canEdit={canEdit} lista={pontosColeta} loading = {requestGet.loading} setOpenEditar = {setOpenEditar} setOpenRemover={setOpenRemover} />
            </SectionBox>
        </Section>
            <>
                <Modal width={800} open={openCriar} setOpen={setOpenCriar} title="Adicionar Ponto de Coleta">
                    <Formik initialValues={{descricao:'', planoAmostragem:''}} enableReinitialize={true} onSubmit={values => handleCriar(values)}>
                            <FormikContainer>
                                <Form.Container padding={false} >
                                    <Form.Row columns={2} grid = "1fr 1fr">
                                        <Form.Group inputID="descricao" inputName="descricao" label="Ponto de Coleta" />
                                        <Form.Group inputID="planoAmostragem" inputName="planoAmostragem" label="Plano de Amostragem" />
                                    </Form.Row>
                                    <FormActions label="Adicionar" />
                                </Form.Container>
                            </FormikContainer>
                    </Formik>
                </Modal>

                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover Ponto de Coleta">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>

                <Modal width = {600} open = {openEditar} setOpen = {setOpenEditar} title = "Editar Ponto de Coleta">
                    <Formik enableReinitialize={true} initialValues = {{descricao:currentData?.descricao, planoAmostragem:currentData?.planoAmostragem }} onSubmit = {values => handleEditar(values)}>
                        <FormikContainer>
                            <Form.Container padding={false} >
                                <Form.Row columns={2} grid = "1fr 1fr">
                                    <Form.Group inputID="descricao" inputName="descricao" label="Ponto de Coleta" />
                                    <Form.Group inputID="planoAmostragem" inputName="planoAmostragem" label="Plano de Amostragem" />
                                </Form.Row>
                                <FormActions label="Adicionar" />
                            </Form.Container>
                    </FormikContainer>
                    </Formik>
                </Modal>
            </>
        </>
    )

}

export default PontoColeta