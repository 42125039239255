import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableInfoTextContainer } from './types'
import { HistoricoAnalise } from '../types'

const TableInfo: React.FC <{dadosEmpresa:HistoricoAnalise | null}> = ({dadosEmpresa}) => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: '24px',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '8px',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            marginRight: '4px',
            fontSize: '10px',
        },
        textField: {
            flex: 1,
            padding: '2px',
            border: '1px solid black',
        },
        text: {
            fontSize: '10px',
        },
    })
    
    const TextContainer: React.FC <TableInfoTextContainer> = ({ label, text, containerWidth, labelWidth, marginRight }) => (
        <View style = {[styles.textContainer, {width: containerWidth ?? '100%', marginRight: marginRight ? '8px' : '0'}]}>
            <Text style = {[styles.label, {width: labelWidth ?? 'auto'}]}>{label}</Text>
            <View style = {styles.textField}>
                <Text style = {styles.text}>{text}</Text>
            </View>
        </View>
    )

    return(

        <View style = {styles.container}>
            <View style = {styles.row}>
                <TextContainer 
                    label = "Cliente:" 
                    text = {`${dadosEmpresa?.nome}`} 
                    labelWidth = "40px"
                />
            </View>
            <View style = {styles.row}>
                <TextContainer 
                    label = "CEP:" 
                    text = {`${dadosEmpresa?.CEP??" "} ${dadosEmpresa?.logradouro??""}`} 
                    labelWidth = "40px"
                    marginRight 
                />
                <TextContainer 
                    label = "Nº:" 
                    text = {`${dadosEmpresa?.numero}`}
                    containerWidth = "35%"
                    marginRight 
                />
                <TextContainer 
                    label = "Compl:" 
                    text = {`${dadosEmpresa?.complemento??" "}`}
                    containerWidth = "35%"
                />
            </View>
            <View style = {styles.row}>
                <TextContainer 
                    label = "Bairro:" 
                    text = {`${dadosEmpresa?.bairro}`} 
                    labelWidth = "40px"
                    marginRight 
                />
                <TextContainer label = "Cidade:" text = {`${dadosEmpresa?.cidade}`} marginRight />
                <TextContainer 
                    label = "UF" 
                    text = {`${dadosEmpresa?.UF}`}
                    containerWidth = "25%"
                />
            </View>
            <View style = {styles.row}>
                <TextContainer 
                    label = "P1" 
                    text = {`${dadosEmpresa?.pontosDeColeta[0]??" "}`}
                    labelWidth = "40px"
                    marginRight 
                />
                <TextContainer label = "P2" text = {`${dadosEmpresa?.pontosDeColeta[1]??" "}`} marginRight />
                <TextContainer label = "P3" text = {`${dadosEmpresa?.pontosDeColeta[2]??" "}`} marginRight />
                <TextContainer label = "P4" text = {`${dadosEmpresa?.pontosDeColeta[3]??" "}`} marginRight />
                <TextContainer label = "P5" text = {`${dadosEmpresa?.pontosDeColeta[4]??" "}`} />
            </View>
            <View style = {[styles.row, {justifyContent: 'flex-end'}]}>
                <TextContainer
                    label = "DBO média considerada para cobrança (kg/m³):" 
                    text = {`${Number(dadosEmpresa?.mediaDBO).toFixed(2)}`}  
                    containerWidth = "50%"
                />
            </View>
        </View>

    )

}

export default TableInfo