import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiPencil, mdiPlusCircle } from '@mdi/js'
import { ParametroType } from '../Types/ParametroType'
import { setCurrentData } from '../Reducers/ParametroReducer'
import getSinonimo from '../Scripts/getSinonimo'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'

interface Props {
    lista: ParametroType[] | null
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
    setOpenCriarS: (open: boolean) => void
    canEdit: boolean
}
   
const TableListarParametroComponents: React.FC<Props> = ({ lista, loading, setOpenEditar, setOpenRemover, setOpenCriarS, canEdit }) => {

    const dispatch = useDispatch()
    
    const grid = "50px 80px 1fr 80px 80px 80px 80px 90px"

    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !loading

    const handleCreate = useCallback(async (item: ParametroType) => {
        await getSinonimo("RamoAtividadeParametroSinonimo", dispatch, item.id)
        dispatch(setCurrentData(item))
        setOpenCriarS(true)
    }, [dispatch, setOpenCriarS])

    const handleEdit = useCallback((item: ParametroType) => {
        dispatch(setCurrentData(item))
        setOpenEditar(true)
    }, [dispatch, setOpenEditar])

    const handleRemove = useCallback((item: ParametroType) => {
        dispatch(setCurrentData(item))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    return(
    
        <>
            <List name = "list">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["ID", "Sigla", "Descrição", "Mínimo", "Máximo", "Incerteza", "Situação", canEdit && "Opções"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow key = {item.id} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.id}</ListColumn>
                            <ListColumn>{item.sigla}</ListColumn>
                            <ListColumn>{item.descricao}</ListColumn>
                            <ListColumn>{item.minimo}</ListColumn>
                            <ListColumn>{item.maximo}</ListColumn>
                            <ListColumn>{item.incerteza}%</ListColumn>
                            <ListColumn>{item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'}</ListColumn>
                            {canEdit && <ListColumn>
                                <ListOption icon={mdiPlusCircle} onClick={() => handleCreate(item)} />
                                <ListOption icon = {mdiPencil} onClick = {() => handleEdit(item)} />    
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemove(item)} />
                            </ListColumn>}
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>

    )

}

export default TableListarParametroComponents