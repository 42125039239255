import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import getClients from './scripts/getClients'
import { mdiMagnify } from '@mdi/js'
import { TextField } from '@material-ui/core'
import { setFieldFiltered } from './Reducers/AtualizacoDboReducer'
import filter from './scripts/filter'
import TableListarClientes from './Components/TableListarClientes'
import { setAnoFinal,setAnoInicial } from './Reducers/AtualizacoDboReducer'

const AnaliseDBO: React.FC = () => {  

    const dispatch = useDispatch()
    const { requestGet, clients, fieldFiltered, anoInicial,anoFinal,filteredLista, requestFilter } = useAppSelector(state => state.AtualizacoDboReducer)


    const API_CONTROLLER = "Pessoa"
    const PAGE_NAME = "Atualização de Valores de DBO"

    const SHOW_DATA = !!clients 
    const SHOW_FILTERED = SHOW_DATA && !!filteredLista && requestFilter.data

    const handleFilter = useCallback(async () => {
        await filter(dispatch, {descricao: fieldFiltered, anoInicial: anoInicial, anoFinal:anoFinal})
    }, [dispatch, fieldFiltered, anoInicial,anoFinal])

    useEffect(() => {
        (async() => {await getClients(API_CONTROLLER, dispatch)})()
    }, [dispatch])

    const keyPress =(async (e:any) => {
        if(e.keyCode === 13){
            handleFilter()
         }
    })

    return (
        <>
            <Section name = "clients">

                <SectionBox
                    title = {PAGE_NAME}
                    padding = {false} 
                    right = {
                        <>
                            <TextField id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Cliente" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value))} onKeyDown={keyPress}/>

                            <TextField type="date" id="filterAnoInicial" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoInicial} onChange={e => dispatch(setAnoInicial(e.target.value))}/>
                            <TextField type="date" id="filterAnoFinal" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoFinal} onChange={e => dispatch(setAnoFinal(e.target.value))}/>

                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                        </>
                    }
                > 
                    <TableListarClientes lista={SHOW_FILTERED ? filteredLista : clients} loading = {requestGet.loading} />
                </SectionBox>
            </Section>
        </>
    )

}

export default AnaliseDBO;