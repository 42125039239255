import React from 'react'

import { ListProps } from '../types'

import '../styles.scss'  

import firstLetterToUpper from '../../../utils/firstLetterToUpper'

const List: React.FC <ListProps> = ({ children, name }) => {

    return <div id = {`list${firstLetterToUpper(name)}`} className = "listContainer">{children}</div>

}

export default List