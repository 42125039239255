import { setLoadingListaVazao, setRequestData, setRequestError, setRequestLoading, setRequestMessage } from "../Reducers/VazaoLancamentoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import {  vazaoLancamento } from "../types"
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import createAlert from "../../../../../scripts/alert/createAlert"
import { DOMAIN } from "../../../../../var_env."

export default async function send(dispatch: Function, values: any){
    try {
        dispatch(setLoadingListaVazao(true))
        dispatch(setRequestLoading())
        
        const endpoint = `${DOMAIN}/PessoaHidrometroLancamento`
        const data = values
        const response: RequestResponsePattern<vazaoLancamento> = await postApi({ endpoint, data })

        if(response){
            if(!response.flagErro){
                dispatch(setRequestData(response))
                dispatch(setAlert(createAlert('success', 'Vazão cadastrada com sucesso')))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setRequestMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestError())
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
    }
}