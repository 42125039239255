import { Text, View } from '@react-pdf/renderer'
import React from 'react'

const TableSignature: React.FC = () => {

    return(

        <View  style = {{marginTop:"30px", width: '30%', borderTop: '1px solid black', left: '65%'}} >
            <Text style = {{marginBottom: '20px', fontSize: '10px'}}>FABIO HENRIQUE ERCOLIN</Text>
            <Text style = {{fontSize: '10px'}}>Coordenador de Monitoramento Industrial</Text>
            <Text style = {{fontSize: '10px'}}>fabio@saneamento.com.br</Text>
            <Text style = {{fontSize: '10px'}}>monitoramento@saneamento.com.br</Text>
        </View>



    )

}

export default TableSignature