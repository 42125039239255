import { DOMAIN } from "../../../../../../var_env."
import { RequestResponsePattern } from "../../../../../../types/Request"
import { setRequestCreateMedVazaoData, setRequestCreateMedVazaoError, setRequestCreateMedVazaoLoading, setRequestCreateMedVazaoMessage } from "../../Reducers/ClientsReducer"
import { postApi } from "../../../../../../utils/requestApi"
import { medVazaoFormValues, medVazaoSelects } from "../constants"
import { setAlert } from "../../../../../../store/reducers/alert/alertReducer"
import createAlert from "../../../../../../scripts/alert/createAlert"

export default async function createMedVazao(dispatch: Function, values: typeof medVazaoFormValues, id:any, selects: medVazaoSelects, idPessoa: number, idUsuario: number){
    try {
        dispatch(setRequestCreateMedVazaoLoading())
            
        const endpoint = `${DOMAIN}/PessoaMedidorVazao`
        const data = { 
            id:id,
            idPessoa, 
            idUsuario,
            registro: values.registro,
            data: values.data !== '' ? new Date(values.data).toISOString() : null,
            idUsuarioTecnico: selects.tecnico,   
            idTipo: selects.tipo,
            dtAtualCalibragem: values.calibracao !== '' ? new Date(values.calibracao).toISOString() : null,
            dtProximaCalibragem: values.verificar !== '' ? new Date(values.verificar).toISOString() : null,
            dtValidadeCalibragem: values.validade !== '' ? new Date(values.validade).toISOString() : null,
            obsAtualCalibragem: values.verificado,
            flagCertificado: Number(selects.certificado),
            fonte: values.fonte,
            nroSerie: values.nroSerie,
            codigoEquipamento: values.codEquipamento,
            modelo: values.modelo,
            idCalculoHorimetro: Number(selects.tipoHorimetro),
            //idFonteAbastecimento: Number(selects.fonteAbastecimento),
            obsCobranca: values.motivo,
            flagAtivo: 1,
            lacre: values.lacre
        }
        
        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestCreateMedVazaoData(response))
            if(!response.flagErro) dispatch(setAlert(createAlert('success', response.listaMensagens[0] ?? 'Medidor de vazão cadastrado com sucesso!')))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestCreateMedVazaoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestCreateMedVazaoError())
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
    }
}