import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import PageRender from '../../components/PageRender'

const NotFound: React.FC <{route: string}> = ({ route }) => {

    const location = useLocation()

    return(
    
        <PageRender renderType = "center" pageName = "NotFound" pageTitle = "Página não encontrada">
            <h1>404</h1>
            <h4>{location.pathname} não foi encontrada em {route}</h4>
            <Link to = "">Voltar</Link>
        </PageRender>

    )

}

export default NotFound