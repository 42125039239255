import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { ClienteType} from '../../../../Parametro/Types/ClienteType'
import Column from '../../../../../../../components/PDF/Column'

const TableRow: React.FC <ClienteType> = props => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        }
    })

    return(

        <View style = {styles.row} wrap={false}>
            <Column text = {props.codigo} width = "6%" />
            <Column text = {props.nome} align = "left" width = "35%" />
            <Column text = {props.razaoSocial} width = "35%" />
            <Column text = {props.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'} width = "10%" />
            <Column text = {new Date(props.dtCadastro).toLocaleDateString()} width = "14%" last />
        </View>

    )

}

export default TableRow