import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"



export default async function getUltimoSequencial(){
    try {


        const endpoint = DOMAIN
        const params = `/RelatorioSequencial/Ultimo`
        const response: RequestResponsePattern<number> = await getApi({ endpoint, params })

        
        return response.listaResultados

    } catch (error: any) {
        console.log(error)

    }
}