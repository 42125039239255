import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form as FormikContainer } from 'formik'
import { mdiPlusCircle } from '@mdi/js'
import { useAppSelector } from '../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import TableListarUsuarios from './Components/TableListarUsuarios'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Modal from '../../../../components/Modal'
import generatePassword from '../../../../utils/password'
import getUsuarios from './scripts/getUsuarios'
import getGrupos from './scripts/getGrupos'
import create from './scripts/create'
import edit from './scripts/edit'
import remove from './scripts/remove'
import isEditable from '../../../../scripts/modules/isEditable'

const Usuario: React.FC = () => {

    const dispatch = useDispatch()
    const { requestGetUsers, requestGetGroups, requestEdit, requestCreate, usuarios, currentUsuario, grupos, currentGrupo, requestRemove } = useAppSelector(state => state.UsuarioReducer)
    
    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    const [grupo, setGrupo] = useState<string>('1')

    const FormActions: React.FC <{label: string}> = ({ label }) => {
        const isLoading = requestEdit.loading || requestCreate.loading
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>  
        )
    }

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'usuarios')
    
    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenEditar(false)
        setOpenRemover(false)
        setGrupo('1')
    }, [])
    
    const handleCreate = useCallback(async (values: {nome: string, login: string, email: string}) => {
        await create(dispatch, {...values, idGrupo: Number(grupo), senha: generatePassword()})
        handleFecharModal()
    }, [dispatch, handleFecharModal, grupo])

    const handleEdit = useCallback(async (values: {nome: string, login: string}) => {
        await edit(dispatch, {...values, idGrupo: Number(grupo), id: currentUsuario!.id})
        handleFecharModal()
    }, [dispatch, handleFecharModal, grupo, currentUsuario])

    const handleRemover = useCallback(async () => {
        await remove(dispatch, currentUsuario!.id)
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentUsuario])

    useEffect(() => {
        (async() => {
            await getUsuarios(dispatch)
            await getGrupos(dispatch)
        })()
    }, [dispatch, requestEdit.data, requestCreate.data, requestRemove.data])

    return (
        <>
            <Section name = "usuarios">
                <SectionBox title = "Usuários" padding = {false} right = { canEdit ? <SectionOption icon = {mdiPlusCircle} tooltip = "Criar" onClick = {() => setOpenCriar(true)} /> : <></> }>
                    <TableListarUsuarios canEdit={canEdit} users = {usuarios} loading = {requestGetUsers.loading || requestGetGroups.loading} setOpenEditar = {setOpenEditar} setOpenRemover = {setOpenRemover} />
                </SectionBox>
            </Section>
            <>
                <Modal width = {800} open = {openCriar} setOpen = {setOpenCriar} title = "Adicionar usuário">
                    <Formik initialValues = {{nome: '', login: '', email: ''}} onSubmit = {values => handleCreate(values)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                </Form.Row>
                                <Form.Row columns = {3}>
                                    <Form.Group inputID = "login" inputName = "login" label = "Login" />
                                    <Form.Group inputID = "email" inputName = "email" inputType = "email" label = "E-mail" />
                                    <Select 
                                        type = "outline" 
                                        label = "Grupo"
                                        value = {grupo}
                                        setValue = {setGrupo}
                                        items = {grupos ? grupos.map(grupo => ({ label: grupo.nome, value: grupo.id.toString() })) : []}
                                    />
                                </Form.Row> 
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openEditar} setOpen = {setOpenEditar} title = "Editar usuário">
                    <Formik initialValues = {{nome: currentUsuario?.nome ?? '', login: currentUsuario?.login ?? '', email:currentUsuario?.email??""}} onSubmit = {values => handleEdit(values)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {1}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome" />
                                </Form.Row>
                                <Form.Row columns = {3}>
                                    <Form.Group inputID = "login" inputName = "login" label = "Login" />
                                    <Form.Group inputID = "email" inputName = "email" inputType = "email" label = "E-mail" />
                                    <Select 
                                        type = "outline" 
                                        label = "Grupo"
                                        defaultValue = {currentGrupo && currentGrupo.id ? currentGrupo.id.toString() : undefined} 
                                        value = {currentGrupo?.id.toString()!}
                                        setValue = {setGrupo}
                                        items = {grupos ? grupos.map(grupo => ({ label: grupo.nome, value: grupo.id.toString() })) : []}
                                    />
                                </Form.Row>
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover cobrança">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>
            </>
        </>
    )   

}

export default Usuario