import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import ReactHtmlParser from 'react-html-parser'; 
const TableRow: React.FC <any> = props => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },          
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '8px',
            fontfamily: 'Arial',
            textAlign: 'center',
            
        }
    })

    const Column: React.FC <{text: string, width: string, last?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

        <View style = {styles.row} wrap={false}>
            <Column text = {props.login} width = "40%" />
            <View style = {[styles.column, {width:"40%"}]}>
            {props.acao.split("<br/>").map((item: any)=> (
                                <Text>
                                {ReactHtmlParser(item.replace("class=\"negrito\"","style={{fontWeight:'bold'}}"))}
                                <br/>
                                </Text>
                            ))
                            
                            }
             </View>
            <Column text = {new Date(props.dtAcao)?.toLocaleString()} width = "20%" last />
        </View>

    )

}

export default TableRow