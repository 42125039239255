import { DOMAIN } from "../../../../../../var_env."

import { deleteApi } from "../../../../../../utils/requestApi"
import { setGrupoLoading } from "../../../Reducers/GrupoAcessoReducer"

export default async function removeMenu(dispatch: Function, id: number, handleReloadModules: Function){
    try {
        dispatch(setGrupoLoading(true))

        const endpoint = `${DOMAIN}/ModuloMenu`
        const params = {id}
        const response = await deleteApi({endpoint, params})
        if(response) await handleReloadModules()
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(setGrupoLoading(false))
    }
}