import ReactDOM from 'react-dom'

import reportWebVitals from './reportWebVitals'

import App from './App'

ReactDOM.render(
    <App />,
    document.getElementById('root')
)

reportWebVitals()