import { setRequestData, setRequestContatoError, setRequestLoading ,setRequestContatoMessage } from "../Reducers/ClienteContatoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { Contato } from "../Types/Contatos"

export default async function sendContato(dispatch: Function, data: any){
    try {
        dispatch(setRequestLoading())
        
        const endpoint = `${DOMAIN}/PessoaContato`
      
        const response: RequestResponsePattern<Contato> = await postApi({ endpoint, data })

        
        if(response){
            dispatch(setRequestData(response))
            if(!response.flagErro) dispatch(setRequestData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestContatoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestContatoError())
    }
}