import { postApi } from "../../../../utils/requestApi"
import { DOMAIN } from "../../../../var_env."

export async function saveNewPassword(senha: string, id: number | string) {

    try {

        if (typeof id === 'number') return await postApi({ endpoint: `${DOMAIN}/Usuario/updatePassword/${id}`, data: { senha } })
        if (typeof id === 'string') return await postApi({ endpoint: `${DOMAIN}/Usuario/updatePassword/${0}`, data: { senha, uuid: id } })

    } catch (error) {
        console.log(error)
    }
}