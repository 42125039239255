import { DOMAIN } from "../../../../../../var_env."
import { RequestResponsePattern } from "../../../../../../types/Request"
import { setMonAnual, setRequestGetLoading, setRequestGetData } from "../../Reducers/MonAnualReducer"
import { getApi } from "../../../../../../utils/requestApi"

export default async function getMon(dispatch: Function, idCliente:string ){
    try {
        dispatch(setRequestGetLoading())


        const response: RequestResponsePattern<any[]> = await getApi({ endpoint: `${DOMAIN}/RelatorioMonitoramento/anual/${idCliente}`, params: '' })
        
        console.log(response)
        if(response){
            dispatch(setRequestGetData(response))
            if(!response.flagErro) {
                dispatch(setMonAnual(response.listaResultados))
               // localStorage.setItem(apiController, JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {

    }
}