import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import Pagination from '../../../../../components/Pagination'
import getHistoricoColeta from '../scripts/getMonitoramentoCargas'


interface Props {
    id: number
    lista: any
    loading: boolean
}

const TableListarMonitoramentoCargas: React.FC <Props> = ({ id, lista, loading }) => {
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)

    const SHOW_LOADING = false 

 

    return(
        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {['Cliente','DBO Anterior', 'DBO Atual', 'Diferença','Coletas', 'Desenquadramento'].map((item, index) => <TableCell key = {index}>{item}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {SHOW_LOADING && (
                        <TableRow>
                            <TableCell >
                                <div className = "center">
                                    <CircleSpinner size = {32} color = "grey" />
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {lista?.map((item :any, index:any) => 
                    (
                        <TableRow key = {index} className = {selectedLine?.nomeCliente === item.nomeCliente ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                            <TableCell>{item.nomeCliente}</TableCell>
                            <TableCell>{item.dboAnterior}</TableCell>
                            <TableCell>{item.dboAtual}</TableCell>
                            <TableCell>{item.diferenca}</TableCell>
                            <TableCell>{item.coletas}</TableCell>
                            <TableCell>{item.parametrosNaoConforme}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                {lista && lista.paginas > 0 && <Pagination pages = {lista.paginas} id = {id} onClick = {getHistoricoColeta} />}
            </div>
        </TableContainer>
    )

}

export default TableListarMonitoramentoCargas