import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiPencil } from '@mdi/js'
import { Users } from '../../Types/Users'
import { setCurrentGrupo, setCurrentUsuario } from '../../Reducers/UsuarioReducer'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'

interface Props {
    users: Users[] | null
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
    canEdit : boolean
}

const TableListarUsuarios: React.FC <Props> = ({ users, loading, setOpenEditar, setOpenRemover, canEdit }) => {

    const dispatch = useDispatch()

    const grid = "100px repeat(4, 1fr) 70px"

    const SHOW_DATA = !!users && !loading
    const SHOW_LOADING = loading

    const handleEdit = useCallback((user: Users) => {
        dispatch(setCurrentGrupo(user.GrupoAcesso))
        dispatch(setCurrentUsuario(user))
        setOpenEditar(true)
    }, [dispatch, setOpenEditar])

    const handleRemove = useCallback((user: Users) => {
        dispatch(setCurrentGrupo(user.GrupoAcesso))
        dispatch(setCurrentUsuario(user))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    return (
        <>
            <List name = "usuarios">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["ID", "Nome", "Login", "Grupo", "Data de Cadastro", canEdit && "Opções"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_DATA && users.map((item, index) => (
                        <ListRow key = {item.id} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.id}</ListColumn>
                            <ListColumn>{item.nome}</ListColumn>
                            <ListColumn>{item.login}</ListColumn>
                            <ListColumn>{item.GrupoAcesso.nome}</ListColumn>
                            <ListColumn>{new Date(item.dtCadastro).toLocaleString()}</ListColumn>
                            {canEdit && <ListColumn>
                                <ListOption icon = {mdiPencil} onClick = {() => handleEdit(item)} />
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemove(item)} />
                            </ListColumn>}
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>
    )

}

export default TableListarUsuarios