import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiPencil } from '@mdi/js'
import { setCurrentData, } from '../../Reducers/EnderecoReducer'
import { Formik, Form as FormikContainer } from 'formik'
import { ListColumn, ListRow } from '../../../../../../components/List'
import { faturamentoFields } from '../../scripts/constants'
import SectionBoxData, { SectionBox, SectionOption }  from '../../../../../../components/Section'
import Form from '../../../../../../components/Form'

interface Props {
    lista: any
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
    canEdit : boolean
}

const TableListarComponents: React.FC<Props> = ({ lista, loading, setOpenEditar, setOpenRemover, canEdit }) => {

    const dispatch = useDispatch()

    const SHOW_ENDERECO = !!lista && !loading
    const SHOW_LOADING = loading

    const handleEdit = useCallback((item: any) => {
        dispatch(setCurrentData(item.endereco))
        setOpenEditar(true)
    }, [dispatch, setOpenEditar])

    const handleRemove = useCallback((item: any) => {
        dispatch(setCurrentData(item.endereco))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    const SectionHeaderRight=  (endereco:any) => (
        <>
            <SectionOption icon = {mdiPencil} tooltip = "" onClick = {() => handleEdit(endereco)} />    
            <SectionOption icon = {mdiDelete} tooltip = "" onClick = {() => handleRemove(endereco)} />
        </>
    )

    return(
        <>
            {SHOW_LOADING && (
                <ListRow grid = '1fr'>
                    <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                </ListRow>
            )}
            {SHOW_ENDERECO &&
                <>
                    {lista.map((endereco: any, index: number) => (
                        <Formik
                            key = {index}
                            initialValues={{ endereco: endereco.logradouro, numero: endereco.numero, complemento: endereco.complemento, cep: endereco.cep, bairro: endereco.bairro, cidade: endereco.cidade, uf: endereco.uf, latitude: endereco.latitude, linkMapa: endereco.linkMapa, longitude: endereco.longitude }}
                            onSubmit={values => console.log(values)}
                            enableReinitialize = {true}
                        >
                            <SectionBox title={" "} right={
                            
                            <>{canEdit && <SectionHeaderRight endereco={endereco} /> }</>} >
                                <FormikContainer>
                                    <SectionBoxData name={"Campos"} >
                                        <Form.Container padding={false}>
                                            <Form.Render render={faturamentoFields} />
                                        </Form.Container>
                                    </SectionBoxData>
                                </FormikContainer>
                            </SectionBox>
                        </Formik>
                    ))}
                </>
            }
        </>
    )

}

export default TableListarComponents