import React, { useEffect, useState } from 'react'
import { Page, View, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableBanner from './Table/banner'
import TableSection from './Table/section'
import TableInfo from './Table/info'
import TableFooter from './Table/footer'
import { dadosRelatorioDBO } from './types'

const DboPdf: React.FC = () => {

    const [dados, setDados] = useState<dadosRelatorioDBO[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setDados(JSON.parse(localStorage.getItem('DADOS_DBO')!)) })()
    }, [])

    return(

        <>
            {!!dados && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} break>
                            <View style={styles.section}>
                                <TableBanner />
                                <TableInfo />
                                <TableSection relatorioDBO={dados} />
                                <TableFooter />
                            </View>
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default DboPdf