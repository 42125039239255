import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { PaginationProps } from './types'
import './styles.scss'

const Pagination: React.FC <PaginationProps> = ({ pages, id, onClick }) => {

    const dispatch = useDispatch()

    const [currentPage, setCurrentPage] = React.useState<number>(1)
    const [indicatorsPages, setIndicatorsPages] = React.useState<number[]>([])

    const handleClick = (page: number) => {
        setCurrentPage(page)
        onClick(dispatch, id, page)
    }

    useEffect(() => {
        setIndicatorsPages(Array.from(Array(pages).keys()).map(item => item+1))
    }, [pages])

    return(

        <ul className = "pagination">
            {indicatorsPages.map(item => <li key = {item} className = {`paginationItem${currentPage === item ? ' active' : ''}`} onClick = {() => handleClick(item)}>{item}</li>)}
        </ul>

    )

}

export default Pagination