import { ApiDeleteRequestPattern, ApiGetRequestPattern, ApiPostRequestPattern, ApiPutRequestPattern } from "./types"

async function getApi({endpoint, params, authorization}: ApiGetRequestPattern){

    try {
        if(authorization){

            console.log(`${endpoint}${params}`)

            const response = await fetch(`${endpoint}${params}`, {
                method: 'GET',
                headers: {
                    Authorization: authorization,
                }
            })
            const data = await response.json()
            return data
        }

        const response = await(await fetch(`${endpoint}${params}`)).json()
        return response    
    } catch (error) {
        console.log('GET API ERROR',error)
        return null
    }

}

async function postApi<T>({endpoint, data}: ApiPostRequestPattern<T>){
    

    try {
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const json = await response.json()
        return json
    } catch (error) {
        console.log('POST API ERROR',error)
        return null
    }    

}

async function deleteApi({endpoint, params}: ApiDeleteRequestPattern){

    try {
        const response = await fetch(`${endpoint}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })
        const json = await response.json()
        return json
    } catch (error) {
        console.log('DELETE API ERROR',error)
        return null
    }    

}

async function putApi<T>({endpoint, params, data}: ApiPutRequestPattern<T>){

    try {
        const response = await fetch(`${endpoint}${params ?? ''}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        const json = await response.json()
        return json
    } catch (error) {
        console.log('PUT API ERROR',error)
        return null
    }

}

export { getApi, postApi, deleteApi, putApi }