import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/RelatorioDBOReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { RelatorioDbo } from "../Types"
    
export default async function filter(dispatch: Function, values: any){
    try {
        if(values.inicio==="" || values.fim==="")return null
        dispatch(setListaFiltered([]))
        dispatch(setRequestFilterLoading())
        const response: RequestResponsePattern<RelatorioDbo[]> = await getApi({ endpoint: `${DOMAIN}/relatorioAtualizacaoDBO`, params: `/?dtInicio=${values.inicio}&dtFim=${values.fim}` });

        if(response){
            
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) {
                dispatch(setListaFiltered(response.listaResultados))
                localStorage.setItem('RelatorioDboAtualizacao', JSON.stringify(response.listaResultados))
                localStorage.setItem('dataBuscaRelatorioDbo', JSON.stringify({inicio:values.inicio, fim: values.fim}))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}