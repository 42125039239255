import { DOMAIN } from "../../../var_env."
import { RequestResponsePattern } from "../../../types/Request"
import { DefaultType } from "../../../store/types/DefaultType"
import { setLista, setRequestGetData, setRequestGetError, setRequestGetLoading, setRequestGetMessage } from "../../../store/reducers/default/DefaultReducer"
import { getApi } from "../../../utils/requestApi"

export default async function get(apiController: string, dispatch: Function){
    try {
        dispatch(setRequestGetLoading())

        const endpoint = DOMAIN
        const params = `/${apiController}`
        const response: RequestResponsePattern<DefaultType[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestGetData(response))
            if(!response.flagErro) {
                dispatch(setLista(response.listaResultados))
                localStorage.setItem(apiController, JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}