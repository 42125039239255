import React, { useEffect, useState } from 'react'
import { Page, View, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import { DefaultType } from '../../../../../../store/types/DefaultType'

const CategoriaCobrancaPdf: React.FC = () => {

    const [Default, setDefault] = useState<DefaultType[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#E4E4E4'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setDefault(JSON.parse(localStorage.getItem('CategoriaCobranca')!)) })()
    }, [])

    return(

        <>
            {!!Default && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} break>
                            <View style={styles.section}>
                                <TableBanner />
                                <TableHeader />
                                {Default.map((item, index) => <TableRow key = {index} {...item} />)}
                            </View>
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default CategoriaCobrancaPdf