import React, { useEffect, useState } from 'react'
import { Page, View, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'


import TableRow from './Table/row'
import TableBanner from './Table/banner'
import TableInfo from './Table/info'
import TableSignature from './Table/signature'
import TableFooter from './Table/footer'
import { useParams } from 'react-router-dom'
import { HistoricoColetaElement } from '../../../Types/HistoricoColeta'

const HistoricoSimplesPdf: React.FC = () => {


    const { flagF } = useParams<any>()
    const [coletas, setColetas] = useState<HistoricoColetaElement[] | null>(null)
    const [historicoNaoConforme, setHistoricoNaoConforme] = useState<{data:string, maximo:string, encontrado:string}[] | null>(null)
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#FFFFFF',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '50px',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { 
            setColetas(JSON.parse(localStorage.getItem('HistoricoColeta')!))
            setHistoricoNaoConforme(JSON.parse(localStorage.getItem('HistoricoColetaNaoConforme')!))
        })()
    }, [])

    return(

        <>
                {coletas && 
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                <Doc>
                    <Page size="A4" style={styles.page} break>
                        <View style={styles.section}>
                            <TableBanner />
                            <TableInfo flagMostrar={flagF}/>
                            <View style  = {{marginBottom: '24px'}} />
                            <TableRow flagMostrar={flagF}  naoConforme={historicoNaoConforme!} />
                            <TableSignature/>
                        </View>
                            <TableFooter/>
                    </Page>
                </Doc>
            </PDFViewer>
                }


        </>

    )

}

export default HistoricoSimplesPdf