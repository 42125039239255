import React, { useCallback } from 'react'
import { mdiDelete, mdiPencil } from '@mdi/js'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../../components/List'
import { Contato } from '../../Types/Contatos'
import { setCurrentData } from '../../Reducers/ClienteContatoReducer'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'

interface Props {
    lista: Contato[] | null,
    setOpenRemover: any,
    setOpenEditar: any,
    loading: boolean
    canEdit: boolean
}

const TableListarComponentsContatos: React.FC<Props> = ({ lista, setOpenRemover, setOpenEditar, loading, canEdit }) => {
    const dispatch = useDispatch()

    const handleRemove = useCallback((item: any) => {
        dispatch(setCurrentData(item))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])


    const handleEditar = useCallback((item: any) => {
        dispatch(setCurrentData(item))
        setOpenEditar(true)
    }, [dispatch, setOpenEditar])

    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !loading

    const grids = "repeat(4, 1fr) 100px 64px "


    return (
        <>
            <List name="list">
                <ListSection type="header">
                    <ListRow grid={grids}>
                        {["Departamento", "Nome", "E-mail", "Telefone", "OBS", canEdit &&"Opções"].map((item, index) => <ListColumn key={index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type="main">
                    {SHOW_LOADING && (
                        <ListRow grid='1fr'>
                            <ListColumn center><CircleSpinner size={32} color="grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow key={item.id} zebra={index % 2 === 0} grid={grids}>
                            <ListColumn>{item.departamento}</ListColumn>
                            <ListColumn>{item.nome}</ListColumn>
                            <ListColumn>{item.email}</ListColumn>
                            <ListColumn>{item.telefone}</ListColumn>
                            <ListColumn>{item.obs}</ListColumn>
                            {canEdit && <ListColumn>
                                <ListOption icon={mdiPencil} onClick={() => handleEditar(item)} />
                                <ListOption icon={mdiDelete} status="error" onClick={() => handleRemove(item)} />
                            </ListColumn>}
                        </ListRow>
                    ))}
                </ListSection>
            </List>


        </>
    )

}

export default TableListarComponentsContatos

