import { RequestResponsePattern } from "../../../../../types/Request"
import {  postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setRequestRemoveData, setRequestRemoveError, setRequestRemoveLoading, setRequestRemoveMessage } from "../Reducers/RelatorioDBOReducer"


export default async function remove(dispatch: Function, data: any){
    try {
        dispatch(setRequestRemoveLoading())
        
        const endpoint = `${DOMAIN}/relatorioAtualizacaoDBO/remove`
        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })
        
        if(response){
            dispatch(setRequestRemoveData(response))
            if(!response.flagErro) dispatch(setRequestRemoveData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestRemoveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestRemoveError())
    }
}