import React, { useCallback, useEffect, useState } from 'react'
import { MenuItem, Select as SelectMUI, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { mdiFilePdfBox, mdiMagnify, mdiMicrosoftExcel, mdiPlusCircle } from '@mdi/js'
import { Formik, Form as FormikContainer } from 'formik'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../../../store/hooks'
import { setFieldFiltered, setFieldFilteredFlagAtivo, setListaFiltered } from './Reducers/RamoAtividadeReducer'
import TableListarUsuarios from './Components/TableListarComponents'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import Modal from '../../../../components/Modal'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import { activeItems } from '../../../../constants/selects'
import edit from './Scripts/edit'
import filter from './Scripts/filter'
import send from './Scripts/send'
import remove from './Scripts/remove'
import getRamoAtividade from './Scripts/getRamoAtividade'
import { genearateRamoAtividadeExcel } from './Scripts/genearateRamoAtividadeExcel'
import isEditable from '../../../../scripts/modules/isEditable'

const RamoAtividade: React.FC = () => {

    const dispatch = useDispatch()
    const { currentData, lista, requestGet, requestSave, requestEdit, requestRemove, fieldFiltered, filteredLista, fieldFilteredFlagAtivo, requestFilter } = useAppSelector(state => state.RamoAtividadeReducer)
    const { userData } = useAppSelector(state => state.auth)
    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'ramo-atividade') 
    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    const [flagAtivo, setFlagAtivo] = useState<string>('1')

    const SHOW_TABLE = !!lista
    const SHOW_FILTERED = SHOW_TABLE && !!filteredLista && requestFilter.data

    const FormActions: React.FC <{label: string}> = ({ label }) => {
        const isLoading = requestEdit.loading || requestRemove.loading || requestSave.loading
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>  
        )
    }

    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenEditar(false)
        setOpenRemover(false)
        setFlagAtivo('1')
    }, [])

    const handleCriar = useCallback(async (descricao: string) => {
        await send(dispatch, {descricao, flagAtivo: Number(flagAtivo), idUsuario: userData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, userData])

    const handleEditar = useCallback(async (descricao: string) => {
        await edit(dispatch, {descricao, flagAtivo: Number(flagAtivo), idUsuario: userData!.id, id: currentData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, currentData, userData])

    const handleRemover = useCallback(async () => {
        await remove(dispatch, {id: currentData!.id, idUsuario: userData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentData, userData])
    
    const handleFilter = useCallback(async () => {
        await filter(dispatch, {descricao: fieldFiltered, flagAtivo: fieldFilteredFlagAtivo})
    }, [dispatch, fieldFiltered, fieldFilteredFlagAtivo])
    
    useEffect(() => {
        (async () => {await getRamoAtividade(dispatch)})()
    }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data])  

    const keyPress =(async (e:any) => {
        if(e.keyCode === 13){
            handleFilter()
         }
    }) 

    return (
        <>
            <Section name = "ramoAtividade">
                <SectionBox
                    title = "Ramo de Atividade" 
                    padding = {false} 
                    right = {
                        <>  
                            <TextField id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Descrição" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value))} onKeyDown={keyPress} />
                            <SelectMUI id="filterAtivo" value={fieldFilteredFlagAtivo} onChange={e => dispatch(setFieldFilteredFlagAtivo(e.target.value as any))}>
                                <MenuItem value={'null'}>Todos</MenuItem>
                                <MenuItem value={1}>Habilitado</MenuItem>
                                <MenuItem value={0}>Desabilitado</MenuItem>
                            </SelectMUI>
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {genearateRamoAtividadeExcel(lista)}} />
                            {canEdit &&  <SectionOption icon = {mdiPlusCircle} tooltip = "Criar" onClick = {() => setOpenCriar(true)} />}
                        </>
                    }
                >   
                    <TableListarUsuarios lista={SHOW_FILTERED ? filteredLista : lista} loading = {requestGet.loading} setOpenEditar = {setOpenEditar} setOpenRemover={setOpenRemover} canEdit={canEdit} />
                </SectionBox>
            </Section>
            <>
                <Modal width = {600} open = {openCriar} setOpen = {setOpenCriar} title = "Adicionar ramo">
                    <Formik initialValues = {{descricao: ''}} onSubmit = {values => handleCriar(values.descricao)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2} grid = "1fr 140px">
                                    <Form.Group inputID = "descricao" inputName = "descricao" label = "Descrição" />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Adicionar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openEditar} setOpen = {setOpenEditar} title = "Editar ramo">
                    <Formik initialValues = {{descricao: currentData?.descricao ?? ''}} onSubmit = {values => handleEditar(values.descricao)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2} grid = "1fr 140px">
                                    <Form.Group inputID = "descricao" inputName = "descricao" label = "Descrição" />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover ramo">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>
            </>
        </>
    )
}

export default RamoAtividade
