import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiPencil } from '@mdi/js'
import { setCurrentData, } from '../../Reducers/ClientsReducer'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../../components/List'

interface Props {
    lista: any
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
    canEdit: boolean
}

const TableListarComponents: React.FC<Props> = ({ lista, loading, setOpenEditar, setOpenRemover, canEdit }) => {

    const dispatch = useDispatch()

    const SHOW_PONTOCOLETA = !!lista && !loading
    const SHOW_LOADING = loading
    const grids = "repeat(2, 1fr) 100px "

    const handleEdit = useCallback((item: any) => {
        dispatch(setCurrentData(item))
        setOpenEditar(true) 
    }, [dispatch, setOpenEditar])

    const handleRemove = useCallback((item: any) => {
        dispatch(setCurrentData(item))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    return(
        <>
            <List name="list">
                <ListSection type="header">
                    <ListRow grid={grids}>
                        {["Ponto de Coleta", "Plano de Amostragem", canEdit && "Opções"].map((item, index) => <ListColumn key={index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type="main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_PONTOCOLETA && lista.map((item :any, index: number) => (
                        <ListRow key={item.id} zebra={index % 2 === 0} grid={grids}>
                            <ListColumn>{item.descricao}</ListColumn>
                            <ListColumn>{item.planoAmostragem}</ListColumn>
                            {canEdit && <ListColumn>
                                <ListOption icon={mdiPencil} onClick={() => handleEdit(item)} />
                                <ListOption icon={mdiDelete} status="error" onClick={() => handleRemove(item)} />
                            </ListColumn>}
                        </ListRow>
                    ))
                    }
                </ListSection>
            </List>
        </>
    )

}

export default TableListarComponents