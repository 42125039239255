import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import conformidadePDF from '../../../scripts/conformidadePDF'


const TableRow: React.FC <{clientes: any[]}> = ({  clientes }) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },          
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '5px',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        columnVermelha: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '5px',
            fontfamily: 'Arial',
            textAlign: 'center',
            backgroundColor: 'red',
            color:"#FFFFFF"
        }
    })

    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0 || Number(value)=== 0 ? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    const Column: React.FC <{text: string, width: string, last?: boolean, flagVermelho?: any}> = ({text, width, last, flagVermelho}) => (
        <View style = {[flagVermelho? styles.columnVermelha :styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

<>
        {clientes.map(cliente =>(
                <View style = {styles.row} wrap={false}>
                <Column text = {cliente.nome} width = "10%" />
                <Column text = {`${cliente.enderecoCompleto && `${cliente.enderecoCompleto.split("-")[0]}-${cliente.enderecoCompleto.split("-")[1]}`} `} width = "11%" />
                <Column text = {cliente.nroDocumento} width = "10%" />
                <Column text = {new Date (cliente.dtExecucao).toLocaleDateString()} width = "9%" />
                <Column text = {handleValue(cliente.DQO)} flagVermelho={conformidadePDF("DQO",cliente.DQO )} width = "4%" />
                <Column text = {handleValue(cliente.DBO)} flagVermelho={conformidadePDF("DBO",cliente.DBO )} width = "4%" />
                <Column text = {handleValue(cliente.pH)} flagVermelho={conformidadePDF("Ph",cliente.pH )}  width = "4%" />
                <Column text = {handleValue(cliente.temperatura)} flagVermelho={conformidadePDF("°C",cliente.temperatura )}  width = "4%" />
                <Column text = {handleValue(cliente.Rs)} flagVermelho={conformidadePDF("Rs",cliente.Rs )}  width = "4%" />
                <Column text = {handleValue(cliente.Og)} flagVermelho={conformidadePDF("Og",cliente.Og )}  width = "4%" />
                <Column text = {handleValue(cliente.N)} flagVermelho={conformidadePDF("N",cliente.N )}  width = "4%" />
                <Column text = {handleValue(cliente.P)} flagVermelho={conformidadePDF("P",cliente.P )} width = "4%" />
                <Column text = {handleValue(cliente.Cd)} flagVermelho={conformidadePDF("Cd",cliente.Cd )} width = "4%" />
                <Column text = {handleValue(cliente.Cu)} flagVermelho={conformidadePDF("Cu",cliente.Cu )} width = "4%" />
                <Column text = {handleValue(cliente.Cr)} flagVermelho={conformidadePDF("Cr",cliente.Cr )} width = "4%" />
                <Column text = {handleValue(cliente.Ni)} flagVermelho={conformidadePDF("Ni",cliente.Ni )}  width = "4%" />
                <Column text = {handleValue(cliente.Pb)} flagVermelho={conformidadePDF("Pb",cliente.Pb )} width = "4%" />
                <Column text = {handleValue(cliente.Zn)} flagVermelho={conformidadePDF("Zn",cliente.Zn )}  width = "4%" />
                <Column text = {handleValue(cliente.Ag)} flagVermelho={conformidadePDF("Ag",cliente.Ag )}  width = "4%" />
                <Column text = {handleValue(cliente.As_)} flagVermelho={conformidadePDF("As",cliente.As_ )} width = "4%" />
                <Column text = {handleValue(cliente.Hg)} flagVermelho={conformidadePDF("Hg",cliente.Hg )} width = "4%" />
                <Column text = {handleValue(cliente.Se)} flagVermelho={conformidadePDF("Se",cliente.Se )}  width = "4%" />
                <Column text = {handleValue(cliente.Sn)} flagVermelho={conformidadePDF("Sn",cliente.Sn )}  width = "4%" />
                <Column text = {handleValue(cliente.Fe)} flagVermelho={conformidadePDF("Fe",cliente.Fe )} width = "4%" />
                <Column text = {handleValue(cliente.Sulfato)} flagVermelho={conformidadePDF("Sulfato",cliente.Sulfato )}  width = "4%" />
                <Column text = {handleValue(cliente.Sulfeto)} flagVermelho={conformidadePDF("Sulfeto",cliente.Sulfeto )}  width = "4%" />
                <Column text = {handleValue(cliente.Fenol)} flagVermelho={conformidadePDF("Fenol",cliente.Fenol )} width = "4%" />
                <Column text = {handleValue(cliente.Fluoreto)} flagVermelho={conformidadePDF("Fluoreto",cliente.Fluoreto )} width = "4%" />
                <Column text = {handleValue(cliente.Cr6)} flagVermelho={conformidadePDF("Cr6",cliente.Cr6 )} width = "4%" />
                <Column text = {handleValue(cliente.CN)} flagVermelho={conformidadePDF("CN",cliente.CN )} width = "4%" />
                <Column text = {handleValue(cliente.Toxicidade)} flagVermelho={conformidadePDF("Toxidade",cliente.Toxicidade )}  width = "4%" last />
            </View>

        ))}

</>


    )

}

export default TableRow