export default  function generateColor(index: number){

  const arrayOfColors = [
    '#F44336',
    '#2196F3',
    '#FFEB3B',
    '#4CAF50',
    '#3F51B5',
    '#FF9800',
    '#9C27B0',
]
  return arrayOfColors[index]
}