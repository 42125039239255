import { DOMAIN } from "../../../../../../var_env."

import { putApi } from "../../../../../../utils/requestApi"

export default async function updateMenu(data: {nome: string, path: string}, handleReloadModules: Function, id: number,){
    try {
        const response = await putApi({endpoint: DOMAIN + '/ModuloMenu/', params: id.toString(), data})
        if(response) await handleReloadModules()
    } catch (error) {
        console.log(error)
    }
}