import { SelectItem } from "../components/Select/types"

export const activeItems: SelectItem[] = [
    {label: 'Habilitado', value: '1'},
    {label: 'Desabilitado', value: '0'},
]

export const medVazao: SelectItem[] = [
    {label: 'Sim', value: '1'},
    {label: 'Não', value: '0'},
]

export const validItems: SelectItem[] = [
    {label: 'Válido', value: '1'},
    {label: 'Inválido', value: '0'},
]

export const idTipoEnderecoItems: SelectItem[] = [
    {label: 'Endereço de Faturamento/Correspôndencia', value: '1'},
    {label: 'Endereço de Cobrança', value: '0'},
]