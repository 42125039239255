import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import get from './scripts/get'
import TableRelatorioListarUsuarios from './Components/TableRelatorioListarUsuarios'
import { Link } from 'react-router-dom'
import { mdiFilePdfBox, mdiMicrosoftExcel } from '@mdi/js'
import { genearateRelatorioTecnicosExcel } from './scripts/genearateRelatorioTecnicosExcel'

const RelatorioUsuarioTecnico: React.FC = () => {

    const dispatch = useDispatch()
    const { requestGetUsers, requestGetGroups, requestEdit, requestCreate, usuarios, requestRemove } = useAppSelector(state => state.UsuarioReducer)
    
    const API_CONTROLLER = "Usuario"
    const PAGE_NAME = "Técnicos"

    useEffect(() => {
        (async() => {
            await get(API_CONTROLLER, dispatch)
        })()
    }, [dispatch, requestEdit.data, requestCreate.data, requestRemove.data])

    return (
        <>
            <Section name = "SectionUsuarioTecnico">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {false} 
                    right = {
                        <>
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {genearateRelatorioTecnicosExcel(usuarios)}} />
                        </>
                    }
                >
                    <TableRelatorioListarUsuarios users = {usuarios} loading = {requestGetUsers.loading || requestGetGroups.loading} />
                </SectionBox>
            </Section>
        </>
    )   
}

export default RelatorioUsuarioTecnico