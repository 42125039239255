import React from 'react'
//import { Tooltip } from '@material-ui/core'
import { Icon as MdiIcon } from '@mdi/react'
import { mdiMenuRight } from '@mdi/js'

import { SidebarOptionProps } from '../types'

const Option: React.FC <SidebarOptionProps> = ({ type, tooltip, icon, label, open, func }) => {

    return(

        <>
            <li className = {`sidebarOptionContainer ${type ?? 'default'}${open ? ' focus' : ''}`} onClick = {() => func ? func() : null}>
                <div className = "sidebarOptionContent">
                    <div className = "sidebarOptionIcon">
                        {icon && <MdiIcon path = {icon} size = "28px" />}
                    </div>
                    <span className = "sidebarOptionLabel">{label}</span>
                    {icon && open !== undefined &&
                        <div className = {`sidebarOptionIconOpen${open ? ' open' : ''}`}>
                            <MdiIcon path = {mdiMenuRight} size = "24px" />
                        </div>
                    }
                </div>
            </li>
        </>

    )

}

export default Option