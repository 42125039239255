import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { RelatorioRegiaoType } from '../../../Types/RelatorioRegiao'
import Column from '../../../../../../../components/PDF/Column'

const TableInfo: React.FC <RelatorioRegiaoType> = ({ id, nome, enderecoCompleto, codigoCliente }) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },
    })

    return(

        <View style = {styles.row} wrap={false}>
            <Column  text = {codigoCliente} width = "10%" />
            <Column text = {nome} align = "left" width = "40%"  />
            <Column text = {enderecoCompleto ?? 'N/A'} width = "50%" last />
        </View>

    )

}

export default TableInfo