import React from 'react'
import { Link } from 'react-router-dom'
import { ListRowProps } from '../types'

const ListRow: React.FC <ListRowProps> = props => {

    return(

        <li className = {`listRow ${!!props.zebra ? 'zebra' : ''}`}>
            {(props.link && <Link to = {props.link}><Container {...props} /></Link>) || <Container {...props} />}
        </li>

    )

}

const Container: React.FC <ListRowProps> = ({ children, padding, grid, status }) => {
    const newPadding = padding ?? true

    return <ul className = {`listRowContainer ${newPadding ? 'padding' : 'noPadding'}${status ? ` status ${status}` : ''}`} style = {{gridTemplateColumns: grid}}>{children}</ul>
}

export default ListRow