import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import { RelatorioRankingType } from '../../types'
import TableFooter from './Table/footer'


const RelatorioRankingPdf: React.FC = () => {

    const [relatorio, setRelatorio] = useState<RelatorioRankingType[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            paddingTop: '20px',
            paddingBottom: '55px',
            paddingHorizontal: '40px',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setRelatorio(JSON.parse(localStorage.getItem('relatorioRanking')!)) })()
    }, [])

    return(

        <>
            {!!relatorio && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page}  wrap={false} break>

                                <TableBanner />
                                <TableHeader  />
                                <TableRow relatorios={relatorio }/>
                                <TableFooter />

                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioRankingPdf