import React, { ChangeEvent, useCallback, useEffect } from 'react'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import TableListarRelatorio from './components/TableListarRelatorio'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import get from './scripts/get'
import { Link } from 'react-router-dom'
import { mdiFilePdfBox, mdiMicrosoftExcel } from '@mdi/js'
import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'
import { Checkbox, FormControlLabel } from '@material-ui/core'

const RelatorioPontoColeta: React.FC = () => {

  const dispatch = useDispatch()
  const {lista,requestGet }= useAppSelector(state => state.RelatorioPontoColetaReducer)

    useEffect(() => {
        (async () => {await get(dispatch)})()
    }, [dispatch])    
 
    const handleEditarCheck = useCallback((Checkbox: ChangeEvent<HTMLInputElement>) => {
 
        if(Checkbox.target.checked) { 
            get(dispatch, false)
            
        }else{
            get(dispatch)
        }


        
    }, [dispatch])


    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {"MONITORAMENTO DE EFLUENTES INDÚSTRIAIS"}
                    padding = {false} 
                    right = {
                        <>
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {defaultRelatorioExcel(lista, 'ponto_coleta')}} />
                       
                            <FormControlLabel control={<Checkbox className='sectionBoxHeaderOption' defaultChecked={false}  onChange={(e) => handleEditarCheck(e)}  />} label={`Desabilitadas`} />
                        </>
                    }
                >
    
                    <>
                    <TableListarRelatorio
                    lista={lista}
                    loading={requestGet.loading}
                    />
                    </>
                    
                </SectionBox>
            </Section>

        </>
    )
}

export default RelatorioPontoColeta

