import { DOMAIN } from '../../../../../var_env.'
import { RequestResponsePattern } from "../../../../../types/Request"
import { DefaultType } from '../../../../../store/types/DefaultType'
import { setRequestGetRoteiroData, setRequestGetRoteiroError, setRequestGetRoteiroLoading, setRequestGetRoteiroMessage, setRoteiro } from '../Reducers/ClientsReducer'
import { getApi } from '../../../../../utils/requestApi'

export default async function getRoteiro(dispatch: Function){
    try {
        dispatch(setRequestGetRoteiroLoading())

        const endpoint = `${DOMAIN}/Roteiro`
        const params = ``
        const response: RequestResponsePattern<DefaultType[]> = await getApi({ endpoint, params })

        if(response){
            dispatch(setRequestGetRoteiroData(response))
            if(!response.flagErro) dispatch(setRoteiro(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetRoteiroMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetRoteiroError())
    }
}