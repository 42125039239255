import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

    
export default async function sendMailTeste(dispatch: Function, email: string){
    try {

        const response = await getApi({ endpoint: `${DOMAIN}/envioEmailAmostra`, params: `/?email=${email}` });
        console.error(response)
    } catch (error: any) {

    }
}