import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { HistoricoColetaNaoConformidade } from '../Types/HistoricoColetaNaoConformidade'

interface Props {
    id: number
    lista: HistoricoColetaNaoConformidade[] | null
    loading: boolean
}

const TableListarHistoricoNaoConformidade: React.FC <Props> = ({ id, lista, loading }) => {

    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !SHOW_LOADING

    return(

        <>
            <table className = "min customTable">
                <thead>
                    <tr>
                        {['Parâmetro', 'Valor', 'Máximo', 'Mínimo'].map((item, index) => <th key = {index}>{item}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {SHOW_LOADING && (
                        <tr>
                            <td colSpan = {30}>
                                <div className = "center">
                                    <CircleSpinner size = {32} color = "grey" />
                                </div>
                            </td>
                        </tr>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <tr key = {index}>
                            <td>{item.descricaoParametro}</td>
                            <td>{item.valorParametro}</td>
                            <td>{item.maximo}</td>
                            <td>{item.minimo}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>

    )

}

export default TableListarHistoricoNaoConformidade