import React from 'react'
import { TabData } from '../../../../components/Tab/types'
import Section, { SectionBox } from '../../../../components/Section'
import Tab from '../../../../components/Tab'
import TabDados from './Tabs/Dados'
import TabParametro from './Tabs/Parametro'

const RamoAtividadeData: React.FC = () => {

    const [tabIndex, setTabIndex] = React.useState(0)

    const tabs: TabData[] = [
        {label: 'Dados', component: <TabDados />},
        {label: 'Parâmetros', component: <TabParametro />}
    ]

    return(

        <>
            <Section name = "ramoAtividade">
                <SectionBox title='Ramo de Atividade' padding = {false} goBack >
                    <Tab data = {tabs} index = {tabIndex} setIndex = {setTabIndex} />
                </SectionBox>
            </Section>
        </>

    )

}

export default RamoAtividadeData