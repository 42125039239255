import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../types/Request"
import { Grupo, Users } from "../Types/Users"

interface State {
    requestGetUsers: RequestStatusPattern<RequestResponsePattern<Users[]>>
    requestGetGroups: RequestStatusPattern<RequestResponsePattern<Grupo[]>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<any>>
    requestCreate: RequestStatusPattern<RequestResponsePattern<any>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<any>>

    usuarios: Users[] | null
    currentUsuario: Users | null
    grupos: Grupo[] | null
    currentGrupo: Grupo | null
}

const initialState: State = {
    requestGetUsers: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestGetGroups: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestEdit: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestCreate: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestRemove: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },

    usuarios: null,
    currentUsuario: null,
    grupos: null,
    currentGrupo: null,
}

const UsuarioSlice = createSlice({
    name: 'Usuario',
    initialState,
    reducers: {
        setRequestGetUsersData: (state, action: PayloadAction<RequestResponsePattern<Users[]>>) => {
            state.requestGetUsers.data = action.payload
            state.requestGetUsers.loading = false
            state.requestGetUsers.error = false
        },
        setRequestGetUsersLoading: (state) => {
            state.requestGetUsers.loading = true
            state.requestGetUsers.error = false
        },
        setRequestGetUsersError: (state) => {
            state.requestGetUsers.loading = false
            state.requestGetUsers.error = true
        },
        setRequestGetUsersMessage: (state, action: PayloadAction<string>) => {
            state.requestGetUsers.message = action.payload
        },

        setRequestGetGroupsData: (state, action: PayloadAction<RequestResponsePattern<Grupo[]>>) => {
            state.requestGetGroups.data = action.payload
            state.requestGetGroups.loading = false
            state.requestGetGroups.error = false
        },
        setRequestGetGroupsLoading: (state) => {
            state.requestGetGroups.loading = true
            state.requestGetGroups.error = false
        },
        setRequestGetGroupsError: (state) => {
            state.requestGetGroups.loading = false
            state.requestGetGroups.error = true
        },
        setRequestGetGroupsMessage: (state, action: PayloadAction<string>) => {
            state.requestGetGroups.message = action.payload
        },

        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },

        setRequestCreateData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestCreate.data = action.payload
            state.requestCreate.loading = false
            state.requestCreate.error = false
        },
        setRequestCreateLoading: (state) => {
            state.requestCreate.loading = true
            state.requestCreate.error = false
        },
        setRequestCreateError: (state) => {
            state.requestCreate.loading = false
            state.requestCreate.error = true
        },
        setRequestCreateMessage: (state, action: PayloadAction<string>) => {
            state.requestCreate.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },

        setUsuarios: (state, action: PayloadAction<Users[]>) => {
            state.usuarios = action.payload
        },
        setCurrentUsuario: (state, action: PayloadAction<Users>) => {
            state.currentUsuario = action.payload
        },
        setGrupos: (state, action: PayloadAction<Grupo[]>) => {
            state.grupos = action.payload
        },
        setCurrentGrupo: (state, action: PayloadAction<Grupo>) => {
            state.currentGrupo = action.payload
        }
    }
})

export const {  
    setRequestGetUsersData, setRequestGetUsersLoading, setRequestGetUsersError, setRequestGetUsersMessage,
    setRequestGetGroupsData, setRequestGetGroupsLoading, setRequestGetGroupsError, setRequestGetGroupsMessage,
    setRequestEditData, setRequestEditLoading, setRequestEditError, setRequestEditMessage,
    setRequestCreateData, setRequestCreateLoading, setRequestCreateError, setRequestCreateMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,
    setUsuarios, setCurrentUsuario, setGrupos, setCurrentGrupo
} = UsuarioSlice.actions
export default UsuarioSlice.reducer