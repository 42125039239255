import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import {mdiFilePdfBox, mdiMagnify, mdiMicrosoftExcel  } from '@mdi/js'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { useAppSelector } from '../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
//import getMonitoramentoCargas from './scripts/getMonitoramentoCargas'
import TableListarMonitoramentoCargas from './Components/TableListarMonitoramentoCargas'
import { Link } from 'react-router-dom'
import { TextField } from '@material-ui/core'
import {setAnoInicial, setAnoFinal } from './Reducers/CargasReducer'
import filter from './scripts/filter'
//import get from '../Parametro/Scripts/get'
import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'

const MonitoramentoCargas: React.FC = () => {    

    const { idCliente } = useParams<any>()
    const dispatch = useDispatch()
    const {anoInicial, anoFinal, requestGetHistoricoColeta, historicoColeta, filteredLista, requestFilter } = useAppSelector(state => state.CargasReducer)

    useEffect(() => {
        // getMonitoramentoCargas(dispatch)
        // get("Parammetros", dispatch)
    }, [dispatch])

    const handleFilter = useCallback(async () => {
        await filter(dispatch, { dtInicio:anoInicial , dtFim: anoFinal})
    }, [dispatch,anoInicial, anoFinal ])

    const SHOW_DATA = !!historicoColeta 
    const SHOW_FILTERED = SHOW_DATA && !!filteredLista && requestFilter.data

    return(
        <> 
            <Section name = "data">
                <SectionBox 
                    title = {`011_FMEI_Monitoramento Cargas`} 
                    padding = {false} 
                    goBack
                    right={
                        <>
                            <TextField type="date" id="filterAnoInicial" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoInicial} onChange={e => dispatch(setAnoInicial(e.target.value))}/>
                            <TextField type="date" id="filterAnoFinal" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoFinal} onChange={e => dispatch(setAnoFinal(e.target.value))}/>
                            
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {defaultRelatorioExcel(filteredLista, 'monitoramento_carga')}} />
                        </>
                    }
                >
                    <TableListarMonitoramentoCargas 
                    id = {Number(idCliente)}
                    lista = {filteredLista} 
                    loading = {requestGetHistoricoColeta.loading} 
                    />
                </SectionBox>
            </Section>
        </>
    )

}

export default MonitoramentoCargas