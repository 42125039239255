import React, { useCallback, useEffect, useRef, useState } from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Icon as MdiIcon } from '@mdi/react'
import { mdiMenuDown } from '@mdi/js'
import { SelectItem, SelectProps } from './types'
import './styles.scss'

const Select: React.FC <SelectProps> = ({ type, defaultValue, label, setValue, value, status, items }) => {

    const labelRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [positions, setPositions] = useState<DOMRect>()

    const handleOpen = useCallback(() => {
        if(open) setOpen(false)
        else setOpen(true)
    }, [open])

    const handleClick = useCallback((item: SelectItem) => {
        setValue(item.value)
        setOpen(false)
    }, [setValue])

    useEffect(() => {
        defaultValue && setValue(defaultValue.toString())
    }, [defaultValue, setValue])

    useEffect(() => {
        if(labelRef && labelRef.current) setPositions(labelRef.current.getBoundingClientRect())
    }, [items, open])

    return(

        <ClickAwayListener onClickAway = {() => setOpen(false)}>
            <div className = "selectWrapper">
                {!!label && <span className = "selectTitleLabel">{label}</span> }
                <div ref = {labelRef} className = {`selectLabelContainer ${type ?? 'default'}${status && type === 'outline' ? ` status ${status}` : ''}${open ? ' open' : ''}`} onClick = {handleOpen}>
                    <span className = "selectLabel">
                        {value ? (items.find(item => item.value === value) ? items.find(item => item.value === value)!.label : label ?? 'Selecionar') : label ?? 'Selecionar'}
                    </span>
                    <span className = {`selectIcon${open ? ' open' : ''}`}>
                        <MdiIcon path = {mdiMenuDown} size = "24px" />
                    </span>
                </div>
                {open && 
                    <ul 
                        className = {`selectItemsContainer scroll${open ? ' open' : ''}`} 
                        style = {{
                            top: positions?.top, 
                            left: positions?.left,
                            right: positions?.right,
                            width: positions?.width
                        }}
                    >
                        {items.map((item, index) => 
                            <li key = {index} className = {`selectItem${item.value === value ? ' selected' : ''}`} onClick = {() => handleClick(item)}>{item.label}</li>
                        )}
                    </ul>
                }
            </div>
        </ClickAwayListener>

    )

}

export default Select