import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import { SectionBoxData } from '../../../../../../components/Section'
import getHistoricoLancamento from '../../scripts/getHistoricoLancamento'
import HistoricoChart from '../HistoricoChart'

const ListarHistorico: React.FC = () => {

    const { idCliente } = useParams<any>()
    const dispatch = useAppDispatch()
    const { Historico } = useAppSelector(s => s.HistoricoLancamentoReducer)

    useEffect(() => {
        getHistoricoLancamento(idCliente, dispatch)
    }, [ dispatch, idCliente])
    
    return(

        <>
            <SectionBoxData title = "Histórico">
                <table className = "customTable">
                    <thead>
                        <tr>
                            {Historico?.val?.map(item => <td >{item.ref}</td>)}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {Historico?.val?.map(item => <td >{item.volume}</td>)}
                        </tr>
                    </tbody>
                </table>
            </SectionBoxData>
            <SectionBoxData title = "Gráfico">
                <HistoricoChart values = {Historico?.val?.map(item => item.volume)} labels={Historico?.val?.map(item => item.ref)}/>
            </SectionBoxData>
        </>

    )

}

export default ListarHistorico