import { RequestResponsePattern } from "../../../../../types/Request"
import { deleteApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

import { setRequestRemove, setRequestContatoError, setRequestRemoveLoading, setRequestContatoMessage } from "../Reducers/ClienteContatoReducer"
import { Contato } from "../Types/Contatos"


export default async function removeContato(dispatch: Function, values: any){
    try {
        dispatch(setRequestRemoveLoading())
        
        const endpoint = `${DOMAIN}/PessoaContato`
        const params = { id: values.id, idUsuario: values.idUsuario }
        const response: RequestResponsePattern<Contato> = await deleteApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestRemove(response))
            if(!response.flagErro) dispatch(setRequestRemove(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestContatoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestContatoError())
    }
}