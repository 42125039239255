import { setRequestData, setRequestError, setRequestMessage } from "../Reducers/EnderecoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import createAlert from "../../../../../scripts/alert/createAlert"


export default async function saveHistorico(dispatch: Function, data: any){
    try {

        
        const endpoint = `${DOMAIN}/HistoricoColeta/save-historico`

        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestData(response))
            if(!response.flagErro) {
                dispatch(setRequestData(response))
                dispatch(setAlert(createAlert('success', 'Historico alterado com sucesso')))
            }
            else throw new Error('Erro ao atualizar gistorico')
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestError())
    }
}