import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import './styles/base/app.scss'
import './styles/modules/status.scss'   
import 'bootstrap/dist/css/bootstrap.min.css'
import store from './store/store'

import AppRoute from './routes'

const App: React.FC = () => {

    return(

        <ReduxProvider store = {store}>
            <AppRoute />
        </ReduxProvider>

    )

}

export default App