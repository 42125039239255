import React, { useCallback, useEffect, useState } from 'react'
import {  mdiEye, mdiContentSave, mdiDelete, mdiFileMultiple, mdiFilePdfBox, mdiMagnify, mdiMicrosoftExcel } from '@mdi/js'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import TableListarRelatorioDBO  from './Components/TableListarRelatorioDBO'
import  { Icon as MdiIcon } from '@mdi/react'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'

import { Link } from 'react-router-dom'
import filter from './scripts/filter'
import { TextField } from '@material-ui/core'
import { setAnoFinal, setAnoInicial, setCurrentData } from './Reducers/RelatorioDBOReducer'
import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'
import getDados from './scripts/getDados'
import Modal from '../../../../components/Modal'
import get from './scripts/get'
import send from './scripts/send'
import remove from './scripts/remove'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { CircleSpinner } from 'react-spinners-kit'


const RelatorioDbo: React.FC = () => {

    const dispatch = useDispatch()
    const {currentData,  lista,requestGet, filteredLista, requestFilter, anoInicial, anoFinal, requestRemove, requestSave } = useAppSelector(state => state.RelatorioDBOReducer)
    const { userData } = useAppSelector(state => state.auth)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
 
    const PAGE_NAME = "Relatório de Atualização de DBO"

    const [openLista, setOpenLista] = useState<boolean>(false)
    // useEffect(() => {
    //     (async () => {await getSinonimo(API_CONTROLLERS, dispatch, currentData?.id)})();
    // }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data, currentData])

    const handleFilter = useCallback(async () => {
        await filter(dispatch, {inicio:anoInicial, fim:anoFinal})
    }, [dispatch, anoInicial, anoFinal])



    const handleSave = useCallback(async () => {
        if(filteredLista?.length===0) return null
        const data = {
            idUsuario:userData!.id,
            inicio: anoInicial,
            fim: anoFinal,
            dados: JSON.stringify(filteredLista)
        }
        await send(dispatch, data)
        setOpenLista(true)
    }, [dispatch, anoInicial, anoFinal, filteredLista, userData, setOpenLista])

    useEffect(() => {
        (async () => {await get(dispatch)})()
    }, [dispatch, requestRemove.data, requestSave.data])  

    const handleRemove = useCallback(async () => {
        const data = {
            id: currentData!.id, idUsuario: userData!.id
        }
        await remove(dispatch, data)
        setOpenRemover(false)
    }, [dispatch, userData, currentData])


    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {false} 
                    right = {
                        <>
                        <SectionOption icon = {mdiFileMultiple} tooltip = "Mostrar Lista Salva" onClick = {() =>  setOpenLista  (true)} />
                                {!requestSave?.loading && <SectionOption icon = {mdiContentSave } tooltip = "Salvar Lista Atual" onClick = {handleSave} />}
                                {requestSave?.loading && <CircleSpinner  size = {26} color = "grey" />}
                               <TextField type="date" id="filterAnoInicial" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoInicial} onChange={e => dispatch(setAnoInicial(e.target.value))}/>
                            <TextField type="date" id="filterAnoFinal" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoFinal} onChange={e => dispatch(setAnoFinal(e.target.value))}/>
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                           <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {defaultRelatorioExcel(filteredLista, 'relatorio_AtualizacaoDBO')}} />

                        </>
                    }
                >
                    <TableListarRelatorioDBO 
                        lista={ filteredLista } 
                        loading = {requestGet.loading || requestFilter.loading} 
                    />
                </SectionBox>
            </Section>
            <>  
                
            <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover relatório salvo">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => setOpenRemover(false)}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {handleRemove}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>

            <Modal width = {600} open = {openLista} setOpen = {setOpenLista } title = "Lista de relatórios salvos">

            <Table >
                <TableHead >
                    <TableRow >
                        {["Apagar", "Inicio", "Fim", "Visualizar"].map((column, index) => <TableCell key={index}>{column}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody >

                    { lista?.map((item, index) => (
                        <TableRow >
                            <TableCell>
                            <div style={{ cursor:"pointer", marginLeft:"5px", marginRight:"5px"}} onClick={() => {dispatch(setCurrentData(item)); setOpenRemover(true)}}>
                                <MdiIcon className={`status  error`} path={mdiDelete} size="24px" />
                                </div>
                            </TableCell>
                            <TableCell  >{new Date(`${item.inicio} 00:00:00`).toLocaleDateString()}</TableCell>
                            <TableCell >{new Date(`${item.fim} 00:00:00`).toLocaleDateString()}</TableCell>
                            <TableCell>


                                <div style={{ cursor:"pointer"}} onClick={() =>{
                                     getDados(dispatch, {id:item.id, inicio:item.inicio, fim:item.fim})
                                     dispatch(setAnoInicial(item.inicio))
                                     dispatch(setAnoFinal(item.fim))
                                     }}>
                                <MdiIcon   className={`status  success`} path={mdiEye} size="24px" />
                                </div>

                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

                </Modal>

            </>
        </>
    )
}

export default RelatorioDbo
