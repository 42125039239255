import React, { useEffect } from 'react'
import { Navigate } from 'react-router'

import { PageRenderProps } from './types'

import './styles.scss'

import { useAppSelector } from '../../store/hooks'

import ScrollReset from '../../utils/scrollReset'
import Header from '../Header'
import Sidebar from '../Sidebar'

const PageRender: React.FC <PageRenderProps> = ({ children, renderType, authID, pageName, pageTitle, className, renderComponent, renderRoute }) => {

    const { theme } = useAppSelector(state => state.theme)
    const { sidebarOpen } = useAppSelector(state => state.layout)
    //const { routes } = useAppSelector(state => state.modules)

    const [isAuth/*, setIsAuth*/] = React.useState(true)

    // useEffect(() => {
    //     if(!!routes && !!authID) setIsAuth(routes.includes(authID))
    //     else setIsAuth(true)
    // }, [routes, authID])

    useEffect(() => {
        if(pageTitle && !renderRoute) document.title = pageTitle 
    }, [pageTitle, renderRoute])

    return(

        <>
            {(!isAuth && <Navigate to = "/" />) || (
                <div 
                    id = "wrapper" 
                    className = {`${renderType ?? 'default'} ${theme}${className ?? ''} ${sidebarOpen ? 'sidebar-open' : ''}`} 
                    data-render = {renderType ?? 'default'} 
                    data-page = {pageName} 
                    data-theme = {theme}
                >
                    {(renderType && ['center', 'form'].includes(renderType) && (renderComponent ?? children)) || (
                        <>
                            <Header />
                            <Sidebar />
                            <main id = {`main${pageName}`} className = "scroll">
                                <div id = "mainContainer">{renderComponent ?? children}</div>
                            </main>
                        </>
                    )}
                    <ScrollReset />
                </div>
            )}
            
        </>

    )

}

export default PageRender