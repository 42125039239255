import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { FonteAbastecimentoType } from "../Types/FonteAbastecimento"
import { HistoricoColeta } from "../Types/HistoricoColeta"
import { Medicao } from "../Types/Medicoes"
import { PessoaMedidorVazaoType } from "../Types/PessoaMedidorVazaoType"
import { MedVazaoOneType } from "../Types/MedVazaoOneType"
import { PontoColetaType } from "../Types/PontosColeta"
import { ClienteType } from "../../Parametro/Types/ClienteType"
import { PessoaHidrometroType } from "../Types/PessoaHidrometroType"
import { HistoricoColetaNaoConformidade } from "../Types/HistoricoColetaNaoConformidade"
import { DefaultType } from "../../../../../store/types/DefaultType"
import { RamoAtividadeType } from "../../RamoAtividade/Types/RamoAtividadeType"
 
interface State {
    //Cliente
    requestGet: RequestStatusPattern<RequestResponsePattern<ClienteType[]>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<any>>
    anoInicial:any
    anoFinal: any
    
    //Medidor Vazao
    requestCreateMedVazao: RequestStatusPattern<RequestResponsePattern<any>>
    requestGetMedVazao: RequestStatusPattern<RequestResponsePattern<PessoaMedidorVazaoType[]>>
    requestEditMedVazao: RequestStatusPattern<RequestResponsePattern<any>>

    //Hidrometro
    requestCreatePessoaHidrometro: RequestStatusPattern<RequestResponsePattern<PessoaHidrometroType[]>>
    requestGetPessoaHidrometro: RequestStatusPattern<RequestResponsePattern<PessoaHidrometroType[]>>
    requestEditPessoaHidrometro: RequestStatusPattern<RequestResponsePattern<any>>
    requestRemovePessoaHidrometro: RequestStatusPattern<RequestResponsePattern<any>>

    //
    requestGetHistoricoColeta: RequestStatusPattern<RequestResponsePattern<HistoricoColeta>>
    requestGetHistoricoColetaNaoConformidade: RequestStatusPattern<RequestResponsePattern<HistoricoColetaNaoConformidade[]>>
    requestGetPontosColeta: RequestStatusPattern<RequestResponsePattern<PontoColetaType[]>>
    requestGetAbastecimento: RequestStatusPattern<RequestResponsePattern<FonteAbastecimentoType[]>>
    requestFilter: RequestStatusPattern<RequestResponsePattern<ClienteType[]>>
    requestGetRoteiro: RequestStatusPattern<RequestResponsePattern<DefaultType[]>>
    requestGetRamoAtividade: RequestStatusPattern<RequestResponsePattern<RamoAtividadeType[]>>

    //
    clients: ClienteType[] | null
    
    //
    medVazao: PessoaMedidorVazaoType[] | null
    currentMedVazao: MedVazaoOneType | null
    
    //
    currentPessoaHidrometro: PessoaHidrometroType | null
    pessoaHidrometro: PessoaHidrometroType[] | null

    //
    requestSave: RequestStatusPattern<RequestResponsePattern<PontoColetaType>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<PontoColetaType>>

    pontosColeta: PontoColetaType[] | null
    currentData: PontoColetaType | null

    medicoes: Medicao[] | null
    roteiro: DefaultType[] | null
    ramoAtividade: RamoAtividadeType[] | null
    historicoColeta: any
    historicoColetaNaoConformidade: HistoricoColetaNaoConformidade[] | null
    abastecimento: FonteAbastecimentoType[] | null
    filteredLista: ClienteType[] | null
    fieldFiltered: string
    fieldFilteredFlagAtivo: any
    fieldFilteredFlagMedidor: any
}

const requestStatusInitial = {
    data: null,
    loading: false,
    error: false,
    message: null,
}

const initialState: State = {
    requestGet: requestStatusInitial,
    requestRemove: requestStatusInitial,
    requestGetPontosColeta: requestStatusInitial,
    requestGetMedVazao: requestStatusInitial,
    requestCreateMedVazao: requestStatusInitial,
    requestCreatePessoaHidrometro: requestStatusInitial,
    requestGetPessoaHidrometro: requestStatusInitial,
    requestGetHistoricoColeta: requestStatusInitial,
    requestGetHistoricoColetaNaoConformidade: requestStatusInitial,
    requestGetAbastecimento: requestStatusInitial,
    requestFilter: requestStatusInitial,
    requestEditMedVazao: requestStatusInitial,
    requestEditPessoaHidrometro: requestStatusInitial,
    requestRemovePessoaHidrometro: requestStatusInitial,
    requestSave: requestStatusInitial,
    requestEdit: requestStatusInitial,
    requestGetRoteiro: requestStatusInitial,
    requestGetRamoAtividade: requestStatusInitial,

    filteredLista: [],
    fieldFiltered: '',

    anoInicial: '',
    anoFinal: '',

    fieldFilteredFlagAtivo: 'null',
    fieldFilteredFlagMedidor: 'null',
    
    //
    clients: null,

    //
    currentMedVazao: null,
    medVazao: null,

    //
    pessoaHidrometro: null,
    currentPessoaHidrometro: null,

    //
    pontosColeta: null,
    ramoAtividade: null,
    roteiro: null,
    currentData: null,
    historicoColeta: null,
    historicoColetaNaoConformidade: null,
    abastecimento: null,
    medicoes: null,
}

const MonitoramentoSlice = createSlice({
    name: 'MonitoramentoReducer',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<ClienteType[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },

        setRequestGetMedVazaoData: (state, action: PayloadAction<RequestResponsePattern<PessoaMedidorVazaoType[]>>) => {
            state.requestGetMedVazao.data = action.payload
            state.requestGetMedVazao.loading = false
            state.requestGetMedVazao.error = false
        },
        setRequestGetMedVazaoLoading: (state) => {
            state.requestGetMedVazao.loading = true
            state.requestGetMedVazao.error = false
        },
        setRequestGetMedVazaoError: (state) => {
            state.requestGetMedVazao.loading = false
            state.requestGetMedVazao.error = true
        },
        setRequestGetMedVazaoMessage: (state, action: PayloadAction<string>) => {
            state.requestGetMedVazao.message = action.payload
        },

        //Hidrometro
        setCurrentPessoaHidrometro: (state, action: PayloadAction<PessoaHidrometroType>) => {
            state.currentPessoaHidrometro = action.payload
        },
        setCurrentData: (state, action: PayloadAction<PontoColetaType>) => {
            state.currentData = action.payload
        },
        setPessoaHidrometro: (state, action: PayloadAction<PessoaHidrometroType[]>) => {
            state.pessoaHidrometro = action.payload
        },
        setRequestGetPessoaHidrometroData: (state, action: PayloadAction<RequestResponsePattern<PessoaHidrometroType[]>>) => {
            state.requestGetPessoaHidrometro.data = action.payload
            state.requestGetPessoaHidrometro.loading = false
            state.requestGetPessoaHidrometro.error = false
        },
        setRequestGetPessoaHidrometroLoading: (state) => {
            state.requestGetPessoaHidrometro.loading = true
            state.requestGetPessoaHidrometro.error = false
        },
        setRequestGetPessoaHidrometroError: (state) => {
            state.requestGetPessoaHidrometro.loading = false
            state.requestGetPessoaHidrometro.error = true
        },
        setRequestGetPessoaHidrometroMessage: (state, action: PayloadAction<string>) => {
            state.requestGetPessoaHidrometro.message = action.payload
        },
        setRequestCreatePessoaHidrometroData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestCreatePessoaHidrometro.data = action.payload
            state.requestCreatePessoaHidrometro.loading = false
            state.requestCreatePessoaHidrometro.error = false
        },
        setRequestCreatePessoaHidrometroLoading: (state) => {
            state.requestCreatePessoaHidrometro.loading = true
            state.requestCreatePessoaHidrometro.error = false
        },
        setRequestCreatePessoaHidrometroError: (state) => {
            state.requestCreatePessoaHidrometro.loading = false
            state.requestCreatePessoaHidrometro.error = true
        },
        setRequestCreatePessoaHidrometroMessage: (state, action: PayloadAction<string>) => {
            state.requestCreatePessoaHidrometro.message = action.payload
        },
        setRequestEditPessoaHidrometroData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestEditPessoaHidrometro.data = action.payload
            state.requestEditPessoaHidrometro.loading = false
            state.requestEditPessoaHidrometro.error = false
        },
        setRequestEditPessoaHidrometroLoading: (state) => {
            state.requestEditPessoaHidrometro.loading = true
            state.requestEditPessoaHidrometro.error = false
        },
        setRequestEditPessoaHidrometroError: (state) => {
            state.requestEditPessoaHidrometro.loading = false
            state.requestEditPessoaHidrometro.error = true
        },
        setRequestEditPessoaHidrometroMessage: (state, action: PayloadAction<string>) => {
            state.requestEditPessoaHidrometro.message = action.payload
        },
        setRequestRemovePessoaHidrometroData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestRemovePessoaHidrometro.data = action.payload
            state.requestRemovePessoaHidrometro.loading = false
            state.requestRemovePessoaHidrometro.error = false
        },
        setRequestRemovePessoaHidrometroLoading: (state) => {
            state.requestRemovePessoaHidrometro.loading = true
            state.requestRemovePessoaHidrometro.error = false
        },
        setRequestRemovePessoaHidrometroError: (state) => {
            state.requestRemovePessoaHidrometro.loading = false
            state.requestRemovePessoaHidrometro.error = true
        },
        setRequestRemovePessoaHidrometroMessage: (state, action: PayloadAction<string>) => {
            state.requestRemovePessoaHidrometro.message = action.payload
        },

        //
        setRequestGetPontosColetaData: (state, action: PayloadAction<RequestResponsePattern<PontoColetaType[]>>) => {
            state.requestGetPontosColeta.data = action.payload
            state.requestGetPontosColeta.loading = false
            state.requestGetPontosColeta.error = false
        },
        setRequestGetPontosColetaLoading: (state) => {
            state.requestGetPontosColeta.loading = true
            state.requestGetPontosColeta.error = false
        },
        setRequestGetPontosColetaError: (state) => {
            state.requestGetPontosColeta.loading = false
            state.requestGetPontosColeta.error = true
        },
        setRequestGetPontosColetaMessage: (state, action: PayloadAction<string>) => {
            state.requestGetPontosColeta.message = action.payload
        },

        //Medidor de Vazao
        setRequestCreateMedVazaoData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestCreateMedVazao.data = action.payload
            state.requestCreateMedVazao.loading = false
            state.requestCreateMedVazao.error = false
        },
        setRequestCreateMedVazaoLoading: (state) => {
            state.requestCreateMedVazao.loading = true
            state.requestCreateMedVazao.error = false
        },
        setRequestCreateMedVazaoError: (state) => {
            state.requestCreateMedVazao.loading = false
            state.requestCreateMedVazao.error = true
        },
        setRequestCreateMedVazaoMessage: (state, action: PayloadAction<string>) => {
            state.requestCreateMedVazao.message = action.payload
        },

        setRequestEditMedVazaoData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestEditMedVazao.data = action.payload
            state.requestEditMedVazao.loading = false
            state.requestEditMedVazao.error = false
        },
        setRequestEditMedVazaoLoading: (state) => {
            state.requestEditMedVazao.loading = true
            state.requestEditMedVazao.error = false
        },
        setRequestEditMedVazaoError: (state) => {
            state.requestEditMedVazao.loading = false
            state.requestEditMedVazao.error = true
        },
        setRequestEditMedVazaoMessage: (state, action: PayloadAction<string>) => {
            state.requestEditMedVazao.message = action.payload
        },

        //
        setRequestGetAbastecimentoData: (state, action: PayloadAction<RequestResponsePattern<FonteAbastecimentoType[]>>) => {
            state.requestGetAbastecimento.data = action.payload
            state.requestGetAbastecimento.loading = false
            state.requestGetAbastecimento.error = false
        },
        setRequestGetAbastecimentoLoading: (state) => {
            state.requestGetAbastecimento.loading = true
            state.requestGetAbastecimento.error = false
        },
        setRequestGetAbastecimentoError: (state) => {
            state.requestGetAbastecimento.loading = false
            state.requestGetAbastecimento.error = true
        },
        setRequestGetAbastecimentoMessage: (state, action: PayloadAction<string>) => {
            state.requestGetAbastecimento.message = action.payload
        },

        setRequestFilterData: (state, action: PayloadAction<RequestResponsePattern<ClienteType[]>>) => {
            state.requestFilter.data = action.payload
            state.requestFilter.loading = false
            state.requestFilter.error = false
        },
        setRequestFilterLoading: (state) => {
            state.requestFilter.loading = true
            state.requestFilter.error = false
        },
        setRequestFilterError: (state) => {
            state.requestFilter.loading = false
            state.requestFilter.error = true
        },
        setRequestFilterMessage: (state, action: PayloadAction<string>) => {
            state.requestFilter.message = action.payload
        },

        setRequestHistoricoColetaData: (state, action: PayloadAction<RequestResponsePattern<HistoricoColeta>>) => {
            state.requestGetHistoricoColeta.data = action.payload
            state.requestGetHistoricoColeta.loading = false
            state.requestGetHistoricoColeta.error = false
        },
        setRequestHistoricoColetaLoading: (state) => {
            state.requestGetHistoricoColeta.loading = true
            state.requestGetHistoricoColeta.error = false
        },
        setRequestHistoricoColetaError: (state) => {
            state.requestGetHistoricoColeta.loading = false
            state.requestGetHistoricoColeta.error = true
        },
        setRequestHistoricoColetaMessage: (state, action: PayloadAction<string>) => {
            state.requestGetHistoricoColeta.message = action.payload
        },

        setRequestHistoricoColetaNaoConformidadeData: (state, action: PayloadAction<RequestResponsePattern<HistoricoColetaNaoConformidade[]>>) => {
            state.requestGetHistoricoColetaNaoConformidade.data = action.payload
            state.requestGetHistoricoColetaNaoConformidade.loading = false
            state.requestGetHistoricoColetaNaoConformidade.error = false
        },
        setRequestHistoricoColetaNaoConformidadeLoading: (state) => {
            state.requestGetHistoricoColetaNaoConformidade.loading = true
            state.requestGetHistoricoColetaNaoConformidade.error = false
        },
        setRequestHistoricoColetaNaoConformidadeError: (state) => {
            state.requestGetHistoricoColetaNaoConformidade.loading = false
            state.requestGetHistoricoColetaNaoConformidade.error = true
        },
        setRequestHistoricoColetaNaoConformidadeMessage: (state, action: PayloadAction<string>) => {
            state.requestGetHistoricoColetaNaoConformidade.message = action.payload
        },

        setRequestGetRoteiroData: (state, action: PayloadAction<RequestResponsePattern<DefaultType[]>>) => {
            state.requestGetRoteiro.data = action.payload
            state.requestGetRoteiro.loading = false
            state.requestGetRoteiro.error = false
        },
        setRequestGetRoteiroLoading: (state) => {
            state.requestGetRoteiro.loading = true
            state.requestGetRoteiro.error = false
        },
        setRequestGetRoteiroError: (state) => {
            state.requestGetRoteiro.loading = false
            state.requestGetRoteiro.error = true
        },
        setRequestGetRoteiroMessage: (state, action: PayloadAction<string>) => {
            state.requestGetRoteiro.message = action.payload
        },

        setRequestGetRamoAtividadeData: (state, action: PayloadAction<RequestResponsePattern<RamoAtividadeType[]>>) => {
            state.requestGetRamoAtividade.data = action.payload
            state.requestGetRamoAtividade.loading = false
            state.requestGetRamoAtividade.error = false
        },
        setRequestGetRamoAtividadeLoading: (state) => {
            state.requestGetRamoAtividade.loading = true
            state.requestGetRamoAtividade.error = false
        },
        setRequestGetRamoAtividadeError: (state) => {
            state.requestGetRamoAtividade.loading = false
            state.requestGetRamoAtividade.error = true
        },
        setRequestGetRamoAtividadeMessage: (state, action: PayloadAction<string>) => {
            state.requestGetRamoAtividade.message = action.payload
        },
        
        setRamoAtividade: (state, action: PayloadAction<RamoAtividadeType[]>) => {
            state.ramoAtividade = action.payload
        },
        setRoteiro: (state, action: PayloadAction<DefaultType[]>) => {
            state.roteiro = action.payload
        },
        setClients: (state, action: PayloadAction<ClienteType[]>) => {
            state.clients = action.payload
        },
        setMedVazao: (state, action: PayloadAction<PessoaMedidorVazaoType[]>) => {
            state.medVazao = action.payload
        },
        setCurrentMedVazao: (state, action: PayloadAction<MedVazaoOneType>) => {
            state.currentMedVazao = action.payload
        },
        setMedicoes: (state, action: PayloadAction<Medicao[]>) => {
            state.medicoes = action.payload
        },
        setHistoricoColeta: (state, action: PayloadAction<any>) => {
            state.historicoColeta = action.payload
        },
        setHistoricoColetaNaoConformidade: (state, action: PayloadAction<HistoricoColetaNaoConformidade[]>) => {
            state.historicoColetaNaoConformidade = action.payload
        },
        setPontosColeta: (state, action: PayloadAction<PontoColetaType[]>) => {
            state.pontosColeta = action.payload
        },
        setAbastecimento: (state, action: PayloadAction<FonteAbastecimentoType[]>) => {
            state.abastecimento = action.payload
        },

        setListaFiltered: (state, action: PayloadAction<ClienteType[]>) => {
            state.filteredLista = action.payload
        },
        setFieldFiltered: (state, action: PayloadAction<string>) => {
            state.fieldFiltered = action.payload
        },
        setAnoInicial: (state, action: PayloadAction<string>) => {
            state.anoInicial = action.payload
        },
        setAnoFinal: (state, action: PayloadAction<string>) => {
            state.anoFinal = action.payload
        },
        setFieldFilteredFlagAtivo: (state, action: PayloadAction<any>) => {
            state.fieldFilteredFlagAtivo = action.payload
        },
        setFieldFilteredMedidor: (state, action: PayloadAction<any>) => {
            state.fieldFilteredFlagMedidor = action.payload
        },
    }
})

export const {  
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,

    //Medidor de Vazão
    setMedVazao, setCurrentMedVazao,
    setRequestGetMedVazaoData, setRequestGetMedVazaoLoading, setRequestGetMedVazaoError, setRequestGetMedVazaoMessage,
    setRequestCreateMedVazaoData, setRequestCreateMedVazaoLoading, setRequestCreateMedVazaoError, setRequestCreateMedVazaoMessage,
    setRequestEditMedVazaoData, setRequestEditMedVazaoLoading, setRequestEditMedVazaoError, setRequestEditMedVazaoMessage,

    //Hidrometro
    setPessoaHidrometro, setCurrentPessoaHidrometro,
    setRequestGetPessoaHidrometroData, setRequestGetPessoaHidrometroLoading, setRequestGetPessoaHidrometroError, setRequestGetPessoaHidrometroMessage,
    setRequestCreatePessoaHidrometroData, setRequestCreatePessoaHidrometroLoading, setRequestCreatePessoaHidrometroError, setRequestCreatePessoaHidrometroMessage,
    setRequestEditPessoaHidrometroData, setRequestEditPessoaHidrometroLoading, setRequestEditPessoaHidrometroError, setRequestEditPessoaHidrometroMessage,
    setRequestRemovePessoaHidrometroData, setRequestRemovePessoaHidrometroLoading, setRequestRemovePessoaHidrometroError, setRequestRemovePessoaHidrometroMessage,

    //
    setRequestHistoricoColetaData, setRequestHistoricoColetaLoading, setRequestHistoricoColetaError, setRequestHistoricoColetaMessage,
    setRequestHistoricoColetaNaoConformidadeData, setRequestHistoricoColetaNaoConformidadeLoading, setRequestHistoricoColetaNaoConformidadeError, setRequestHistoricoColetaNaoConformidadeMessage,
    setRequestGetPontosColetaData, setRequestGetPontosColetaLoading, setRequestGetPontosColetaError, setRequestGetPontosColetaMessage,
    setRequestGetAbastecimentoData, setRequestGetAbastecimentoLoading, setRequestGetAbastecimentoError, setRequestGetAbastecimentoMessage,
    setRequestGetRoteiroData, setRequestGetRoteiroLoading, setRequestGetRoteiroError, setRequestGetRoteiroMessage,
    setRequestGetRamoAtividadeData, setRequestGetRamoAtividadeLoading, setRequestGetRamoAtividadeError, setRequestGetRamoAtividadeMessage,
    setRequestFilterData, setRequestFilterLoading, setRequestFilterError, setRequestFilterMessage, 

    setClients, setMedicoes, setHistoricoColeta, setPontosColeta, setAbastecimento, setHistoricoColetaNaoConformidade, setRoteiro, setRamoAtividade,
    setFieldFiltered, setFieldFilteredFlagAtivo, setListaFiltered, setCurrentData, setFieldFilteredMedidor, setAnoInicial, setAnoFinal,
} = MonitoramentoSlice.actions
export default MonitoramentoSlice.reducer