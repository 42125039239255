import { AddressPattern } from "../../types/Address"
import { getApi } from "../../utils/requestApi"

export default async function getAddress(cep: string){
    try {
        const endpoint = `https://viacep.com.br/ws/${cep}/json/`
        const params = ``
        const response = await getApi({ endpoint, params })
        return response ? response as AddressPattern : null
    } catch (error) {
        return null
    }
}