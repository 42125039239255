import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useAppSelector } from '../../../../../../store/hooks'
import generateColor from '../../scripts/generateColor'


const HistoricoChartDiversos: React.FC = () => {

    const { historicosDiversos } = useAppSelector(s => s.HistoricoLancamentoReducer)
    
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Últimos 12 meses',
            },
        },
    }

    const lines = historicosDiversos!.map((item,index) => {
        return {
            label: item.desc.split(":")[0],
            data: item.val.map(val => (val.volume>0? val.volume: 0)),
            borderColor: generateColor(index),
            backgroundColor: generateColor(index),
        }
    })

    let maiorConteudoDataLabel:string[] = []

    historicosDiversos?.forEach(element => {
        maiorConteudoDataLabel =  element.val.length > maiorConteudoDataLabel.length? element.val.map(item => (item.ref)):maiorConteudoDataLabel
    });



    const data = {
        labels: maiorConteudoDataLabel  ,
        datasets: lines
    }

    return <Line data={data} options={options} />

}

export default HistoricoChartDiversos