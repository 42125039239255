import React from 'react'

const Container: React.FC = ({ children }) => {

    return(

        <header id = "header">
            <nav>
                <ul id = "headerContainer">{children}</ul>
            </nav>
        </header>

    )

}

export default Container