import Container from './container'
import Section from './section'
import Option from './option'
import Link from './link'
import Loading from './loading'

const SidebarComponents = {
    Container, Section, Option, Link, Loading
}

export default SidebarComponents