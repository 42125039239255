import React, { useEffect } from 'react'
import Icon from '@mdi/react'
import { ModuleIconSelectorBoxProps, ModuleIconSelectorProps } from '../types'
import getIcon from '../../../../../../../scripts/modules/getIcon'

const ModuleIconSelectorBox: React.FC <ModuleIconSelectorBoxProps & ModuleIconSelectorProps> = ({ setCurrentIcon, currentIcon, defaultIcon, name }) => {

    const isActive = currentIcon === name

    useEffect(() => {
        defaultIcon && setCurrentIcon(defaultIcon)
    }, [defaultIcon, setCurrentIcon])

    return(

        <div className = {`moduleIconSelectorBox${isActive ? ' active' : ''}`} onClick = {() => setCurrentIcon(name)}>
            <Icon path = {getIcon(name)} size = "24px" />
        </div>

    )

}

export default ModuleIconSelectorBox