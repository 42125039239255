import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"

import {  setRequestGetError, setRequestGetLoading, setRequestGetMessage } from "../Reducer/UploadArquivoImportacaoReducer"
import { getApi } from "../../../../../utils/requestApi"
// // import { uploadArquivo } from "../tipes"

export default async function fileExist(dispatch: Function, filename:string){
    try {
        dispatch(setRequestGetLoading())
        
        const endpoint = DOMAIN
        const params = `/UploadArquivo/arquivoExiste/?nomeArquivo=${filename}&conteudo=Importacao_mylims`
        const response: RequestResponsePattern<boolean> = await getApi({ endpoint, params })

        if(response){
 
            if(!response.flagErro) {
                // dispatch(setArquivo(response.listaResultados))
                return response.listaResultados
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}