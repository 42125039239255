import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"

import { setLista, setRequestGetData, setRequestGetError, setRequestGetLoading, setRequestGetMessage } from "../Reducer/UploadArquivoImportacaoReducer"
import { getApi } from "../../../../../utils/requestApi"
import { uploadArquivoImportacao } from "../tipes"

export default async function getFiles(dispatch: Function){
    try {
        dispatch(setRequestGetLoading())
        
        const endpoint = DOMAIN
        const params = `/UploadArquivo?tipo=Importacao_mylims`
        const response: RequestResponsePattern<uploadArquivoImportacao[]> = await getApi({ endpoint, params })
  
        if(response){
            dispatch(setRequestGetData(response))
            if(!response.flagErro) dispatch(setLista(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}