import { setHistoricoColeta, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/MonitoramentoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import conformidade from "./conformidade"

//import { FilterParams } from "../../Parametro/Types/ClienteType"
    
export default async function filter(dispatch: Function, values: any){
    try {
        dispatch(setRequestFilterLoading())
        const response: RequestResponsePattern<any[]> = await getApi({ endpoint: `${DOMAIN}/HistoricoColeta/Industria`, params: `/?dtInicio=${values.dtInicio}&dtFim=${values.dtFim}` });
        
        if(response){

            const coletas = response.listaResultados
            const coletasComNaoConformidade = []

            for (const coleta of coletas) {
                let count = 0
                for (const key in coleta) {
                    const conforme = conformidade(key,(coleta as any)[key])
                    if(conforme === "status error") count ++
                }
                if(count>0) coletasComNaoConformidade.push(coleta)
            }
            dispatch(setRequestFilterData(response))
            localStorage.setItem("RelatorioNaoConformeLista", JSON.stringify(coletasComNaoConformidade))

            
            if(!response.flagErro) dispatch(setHistoricoColeta(coletasComNaoConformidade))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}