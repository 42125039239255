import { FormMap } from "../../../../../components/Form/types"
import { SelectItem } from "../../../../../components/Select/types"

export const medVazaoFormValues = {
    registro: '', 
    data: '', 
    calibracao: '', 
    verificar: '', 
    verificado: '', 
    validade: '', 
    fonte: '', 
    nroSerie: '', 
    codEquipamento: '', 
    modelo: '',
    motivo: '',
    lacre: ''
}

export const PessoaHidrometroFormValues = {
    registro: '',
    idFonteAbastecimento: 0
}

export type medVazaoSelects = {
    tecnico: string
    tipo: string
    certificado: string
    tipoHorimetro: string
    fonteAbastecimento: string
}

export type PessoaHidrometroSelects = {
    fonteAbastecimento: string
}

export const medVazaoTecnicos: SelectItem[] = [
    { value: 'Técnico 1', label: 'Técnico 1' },
    { value: 'Técnico 2', label: 'Técnico 2' },
]

export const medVazaoTipos: SelectItem[] = [
    { value: 'Eletromagnetico', label: 'Eletromagnético' },
]

export const medVazaoCertificados: SelectItem[] = [
    { value: '1', label: 'Sim' },
    { value: '2', label: 'Não' },
]

export const medVazaoTipoCobrancas: SelectItem[] = [
    { value: 'normal', label: 'Normal' },
]

export const medVazaoLogicas: SelectItem[] = [
    { value: '0,01 = 36 seg -> (idem ao 0.1 = 6 min...)', label: '0,01 = 36 seg -> (idem ao 0.1 = 6 min...)' },
]

export const medVazaoFormatos: SelectItem[] = [
    { value: '99.99', label: '99.99' },
]

export const medVazaoTiposHidrometro: SelectItem[] = [
    { value: 'CCEL E50', label: 'CCEL E50' },
]

export const cobrancaSituacaoFields: FormMap[] = [{
    grid: 'repeat(2, 1fr)',
    data: [
        {inputID: "categoriaCob", inputName: "categoriaCob", inputPlaceholder: "Categoria", label: "Categoria Cobrança"},
        {inputID: "relatorioComp", inputName: "relatorioComp", inputPlaceholder: "Completo", label: "Relatório Completo"},
    ]
},{
    grid: 'repeat(2, 1fr)',
    data: [
        {inputID: "sitCob", inputName: "sitCob", inputPlaceholder: "Situação", label: "Situação Cobrança"},
        {inputID: "sitCobAt", inputName: "sitCobAt", inputPlaceholder: "Situação", label: "Situação Cobrança Atual"},            
    ]
}, {
    grid: 'repeat(2, 1fr)',
    data: [
        {inputID: "lacre", inputName: "lacre", label: "Lacre"},
        {inputID: "inativo", inputName: "inativo", label: "Inativo em"},
    ]
}]

export const coletaFields: FormMap[] = [ {
    grid: 'repeat(2, 1fr)',
    data: [
        {inputID: "coleta1", inputName: "coleta1", label: "Ponto de Coleta 1"},
        {inputID: "coleta2", inputName: "coleta2", label: "Ponto de Coleta 2"},
        {inputID: "coleta3", inputName: "coleta3", label: "Ponto de Coleta 3"},
        {inputID: "coleta4", inputName: "coleta4", label: "Ponto de Coleta 4"},
    ]
}]

export const hdFields: FormMap[] = [{
    grid: '280px 280px',
    data: [
        {inputID: "hd1", inputName: "hd1", label: "HD1/CDC"},
        {inputID: "hd2", inputName: "hd2", label: "HD2/CDC"},            
    ]
}, {
    grid: '280px 280px',
    data: [
        {inputID: "hd3", inputName: "hd3", label: "HD3/CDC"},
        {inputID: "hd4", inputName: "hd4", label: "HD4/CDC"},
    ]
},{
    grid: '280px 280px',
    data: [
        {inputID: "hd5", inputName: "hd5", label: "HD5/CDC"},
        {inputID: "hd6", inputName: "hd6", label: "HD6/CDC"},            
    ]
}, {
    grid: '280px 280px',
    data: [
        {inputID: "hd7", inputName: "hd7", label: "HD7/CDC"},
        {inputID: "hd8", inputName: "hd8", label: "HD8/CDC"},
    ]
},{
    grid: '280px 280px',
    data: [
        {inputID: "hd9", inputName: "hd9", label: "HD9/CDC"},
        {inputID: "hd10", inputName: "hd10", label: "HD10/CDC"},            
    ]
}]

export const pontoColetaFields: FormMap[] = [{
    grid: '1fr 1fr ',
    data: [
        {inputID: 'pontoColeta', inputName: 'pontoColeta', inputPlaceholder: 'pontoColeta', label: 'Ponto de Coleta'},
        {inputID: 'planoAmostragem', inputName: 'planoAmostragem', inputPlaceholder: 'planoAmostragem', label: 'Plano de Amostragem'},
    ]
}]

export const faturamentoFields: FormMap[] = [{
    grid: '1fr 100px 200px ',
    data: [
        {inputID: 'endereco', inputName: 'endereco', inputPlaceholder: 'Endereço', label: 'Endereço'},
        {inputID: 'numero', inputName: 'numero', inputPlaceholder: 'Número', label: 'Número'},
        {inputID: 'complemento', inputName: 'complemento', inputPlaceholder: 'Complemento', label: 'Complemento'},
    ]
}, {
    grid: '150px 1fr 280px 150px',
    data: [
        {inputID: 'cep', inputName: 'cep', inputPlaceholder: 'CEP', label: 'CEP'},
        {inputID: 'bairro', inputName: 'bairro', inputPlaceholder: 'Bairro', label: 'Bairro'},
        {inputID: 'cidade', inputName: 'cidade', inputPlaceholder: 'Cidade', label: 'Cidade'},
        {inputID: 'uf', inputName: 'uf', inputPlaceholder: 'UF', label: 'UF'},
    ]
}
, {
    grid: '150px 150px 1fr',
    data: [
        {inputID: 'latitude', inputName: 'latitude', inputPlaceholder: 'latitude', label: 'Latitude'},
        {inputID: 'longitude', inputName: 'longitude', inputPlaceholder: 'longitude', label: 'longitude'},
        {inputID: 'linkMapa', inputName: 'linkMapa', inputPlaceholder: 'Link do Mapa', label: 'Link do Mapa'}
    ]
}]

export const cobrancaFields: FormMap[] = [{
    grid: '1fr 100px 200px',
    data: [
        {inputID: 'endereco', inputName: 'endereco', inputPlaceholder: 'Endereço', label: 'Endereço'},
        {inputID: 'numero', inputName: 'numero', inputPlaceholder: 'Número', label: 'Número'},
        {inputID: 'complemento', inputName: 'complemento', inputPlaceholder: 'Complemento', label: 'Complemento'},
    ]
}, {
    grid: '120px 1fr 300px 50px',
    data: [
        {inputID: 'cep', inputName: 'cep', inputPlaceholder: 'CEP', label: 'CEP'},
        {inputID: 'bairro', inputName: 'bairro', inputPlaceholder: 'Bairro', label: 'Bairro'},
        {inputID: 'cidade', inputName: 'cidade', inputPlaceholder: 'Cidade', label: 'Cidade'},
        {inputID: 'uf', inputName: 'uf', inputPlaceholder: 'Estado', label: 'UF'},
    ]
}]

export const statusItems: SelectItem[] = [
    {label: "Normal", value: "normal"},
    {label: "Bloqueado", value: "bloqueado"},
    {label: "Inativo", value: "inativo"},
    {label: "Excluído", value: "excluido"},
]

export const basicItems: SelectItem[] = [
    {label: 'Envelope Medio', value: 'envelope'},
    {label: 'Envelope Pequeno', value: 'envelope'}
]