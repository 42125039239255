import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import getClients from './scripts/getClients'
import { mdiMicrosoftExcel, mdiFilePdfBox, mdiMagnify, mdiProgressDownload, mdiCheck, mdiClose, mdiPlusCircle } from '@mdi/js'
import { Link } from 'react-router-dom'
import { TextField, Select as SelectMUI, MenuItem } from '@material-ui/core'
import { setFieldFiltered, setFieldFilteredFlagAtivo, setFieldFilteredMedidor, setListaId } from './Reducers/ClientsReducer'
import filter from './scripts/filter'
import TableListarClientes from './Components/TableListarClientes'
import { genearateClientExcel } from './scripts/genearateClientExcel'
import Modal from '../../../../components/Modal'
import AgrupamentoColetaComponent from '../AtualizacaoColetaManual/Components/AgrupamentoComponent'
import sendNovasColetas from '../AtualizacaoColetaManual/scripts/sendNovasColetas'
import { CircleSpinner } from 'react-spinners-kit'
import isEditable from '../../../../scripts/modules/isEditable'


const AcessGroup: React.FC = () => {

    const dispatch = useDispatch()
    const { requestGet, clients, fieldFiltered, filteredLista, fieldFilteredFlagAtivo, requestFilter, fieldFilteredFlagMedidor, listaIdColeta } = useAppSelector(state => state.ClientsReducer)
    const {listaNovaColeta} = useAppSelector(state => state.NovasColetasReducer)
    const [coletaCheck, setColetaCheck] = useState<boolean>(false)
    const API_CONTROLLER = "Pessoa"
    const PAGE_NAME = "Clientes"


    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'cliente')


    const SHOW_DATA = !!clients 
    const SHOW_FILTERED = SHOW_DATA && !!filteredLista && requestFilter.data
    const [openNova, setOpenNova] = React.useState<boolean>(false)
    const [load, setLoad] = React.useState<boolean>(false)
    const handleFilter = useCallback(async () => {
        await filter(dispatch, {descricao: fieldFiltered, flagAtivo: fieldFilteredFlagAtivo, flagMedidor:fieldFilteredFlagMedidor})
    }, [dispatch, fieldFiltered, fieldFilteredFlagAtivo, fieldFilteredFlagMedidor])

    useEffect(() => {
        (async() => {await getClients(API_CONTROLLER, dispatch)})()
    }, [dispatch])

    const keyPress =(async (e:any) => {
        if(e.keyCode === 13){
            handleFilter()
         }
    })

    const handleSend = useCallback(async () => {
        if(listaIdColeta?.length===0)return null
        setLoad(true)
        const data = {
            listaPessoa: listaIdColeta!
        }
        await sendNovasColetas(dispatch, data)

        setLoad(false)
        setOpenNova(true)

    }, [dispatch, listaIdColeta])

    return (
        <>
            <Section name = "clients">

                <SectionBox
                    title = {PAGE_NAME}
                    padding = {false} 
                    right = {
                        <>
                        {!coletaCheck && <SectionOption icon = {mdiProgressDownload} tooltip = "Buscar Coletas" onClick = {() => {setColetaCheck(true)}} />}
                            
                        {coletaCheck && <>
                            <SectionOption icon = {mdiClose}  tooltip = "Cancelar" onClick = {() => {setColetaCheck(false); dispatch(setListaId([]))}} />
                            {!load && <SectionOption icon = {mdiCheck} tooltip = "Buscar" onClick = {handleSend} />}
                            {load && <CircleSpinner size={20} color="grey" />}
                        </>}
                            <TextField id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Descrição" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value))} onKeyDown={keyPress}/>

                            <SelectMUI id="filterAtivo" value={fieldFilteredFlagMedidor} onChange={e => dispatch(setFieldFilteredMedidor(e.target.value as any))}>
                                <MenuItem value={'null'}>Possui medidor de vazão?</MenuItem>
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </SelectMUI>
                            <SelectMUI id="filterAtivo" value={fieldFilteredFlagAtivo} onChange={e => dispatch(setFieldFilteredFlagAtivo(e.target.value as any))}>
                                <MenuItem value={'null'}>Todos</MenuItem>
                                <MenuItem value={1}>Habilitado</MenuItem>
                                <MenuItem value={0}>Desabilitado</MenuItem>
                            </SelectMUI>
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {genearateClientExcel(clients)}} />
                            {canEdit && <Link to = "/cadastro/cliente/0">
                                <SectionOption icon = {mdiPlusCircle} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>}
                        </>
                    }
                >
                    <TableListarClientes check={coletaCheck} lista={SHOW_FILTERED ? filteredLista : clients} loading = {requestGet.loading} />
                </SectionBox>
            </Section>

            <Modal width = {`100%`} open = {openNova} setOpen = {setOpenNova} title = "Coletas publicadas que foram encontrada">
                <AgrupamentoColetaComponent loading={false} lista={listaNovaColeta} />
                </Modal>
        </>
    )

}

export default AcessGroup;