import { StyleSheet, View, Text } from '@react-pdf/renderer'
import React from 'react'

const TableFooter: React.FC = () => {

    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: '18px',
            left: '20px',
            right: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            borderTop: '1px solid black',
        },
        leftText: {
            fontSize: '8px',
        },
        rightText: {
            fontSize: '8px',
            fontWeight: 'bold',
        }
    })


    return(
<>
<Text fixed wrap={false} style={{ bottom: '30px',fontSize:'8px', position: 'absolute', left: '40%', marginTop: '48px'}}>053_FMEI Rev.06 - Apr. Nov/23</Text>
    
    
    <View style = {styles.container} fixed>

        <Text style = {styles.leftText} render={({ pageNumber, totalPages }) => (`pág ${pageNumber} de ${totalPages}`)} fixed />
    </View>

        <View style = {{marginTop: '40px'}}>
            <Text style={{fontSize:'10px'}}>“De acordo com o Decreto Estadual 8.468/76 , em seu art. 19a, § 1º, informamos que a Estação de Tratamento de Esgotos de Jundiaí – ETEJ,
consegue manter seu bom funcionamento mesmo que receba via rede coletora de esgotos, concentrações de Zinco de até 10 mg/L.”</Text>
        </View>
        </>
    )

}

export default TableFooter