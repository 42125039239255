import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { mdiAccountCircleOutline, mdiBrightness4, mdiBrightness5, mdiLogout, mdiMenu } from '@mdi/js'

import './styles.scss'

import { useAppSelector } from '../../store/hooks'
import { toggleTheme } from '../../store/reducers/theme/themeReducer'
import { setAuthLogout } from '../../store/reducers/auth/authReducer'
import { toggleSidebarOpen } from '../../store/reducers/layout/layoutReducer'

import HeaderComponents from './components'

import Logo from '../../assets/images/logo.png'

const Header: React.FC = () => {

    const dispatch = useDispatch()
    const { userData } = useAppSelector(state => state.auth)
    const { theme } = useAppSelector(state => state.theme)
    const { sidebarOpen } = useAppSelector(state => state.layout)
    
    return(
        
        <HeaderComponents.Container>
            <HeaderComponents.Content name = "menu">
                <HeaderComponents.Option
                    icon = {mdiMenu}
                    tooltip = {`${sidebarOpen ? 'Fechar' : 'Abrir'} menu`}
                    onClick = {() => dispatch(toggleSidebarOpen())}
                />
            </HeaderComponents.Content>
            <HeaderComponents.Content name = "logo">
                <Link to = "/">
                    <img src = {Logo} alt = "CSJ" />
                </Link>
            </HeaderComponents.Content>
            <HeaderComponents.Content name = "search">
                <HeaderComponents.Search />
            </HeaderComponents.Content>
            <HeaderComponents.Content name = "options">
                {/* <HeaderComponents.Option icon = {mdiCalendarBlankOutline} tooltip = "Teste" onClick = {() => {}} />
                <HeaderComponents.Option icon = {mdiBellOutline} tooltip = "Teste" onClick = {() => {}} /> */}
                <HeaderComponents.Option
                    icon = {theme === 'dark-mode' ? mdiBrightness5 : mdiBrightness4}
                    tooltip = {theme === 'dark-mode' ? 'Tema claro' : 'Tema escuro'}
                    onClick = {() => dispatch(toggleTheme())}
                />
                <HeaderComponents.Option
                    icon = {mdiLogout}
                    tooltip = "Encerrar sessão"
                    onClick = {() => dispatch(setAuthLogout())}
                />
                {/* <HeaderComponents.Option icon = {mdiCogOutline} tooltip = "Teste" onClick = {() => {}} /> */}
            </HeaderComponents.Content>
            <HeaderComponents.Content name = "user">
                <HeaderComponents.Option
                    icon = {mdiAccountCircleOutline}
                    label = {userData!.nome.split(' ')[0] ?? userData!.nome}
                    tooltip = {userData!.nome.split(' ')[0] ?? userData!.nome}
                    onClick = {() => {}}
                />
            </HeaderComponents.Content>
        </HeaderComponents.Container>

    )

}

export default Header