import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Form as FormikContainer, Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { ResultadoApi, ResultadoApiBuscaParametro } from '../types'
import '../styles.scss'
import { SectionBoxData } from '../../../../../components/Section'
import List, { ListSection, ListRow, ListColumn } from '../../../../../components/List'
import { getApi, postApi } from '../../../../../utils/requestApi'
import { RequestResponsePattern } from '../../../../../types/Request'
import { useAppSelector } from '../../../../../store/hooks'
import { DOMAIN } from '../../../../../var_env.'

const TabParametro: React.FC = () => {

    const { idRamo } = useParams<any>()

    const [resultadoApi, setResultadoApi] = useState<ResultadoApi>()
    const [resultadoApiBusca, setresultadoApiBusca] = useState<null | ResultadoApiBuscaParametro>()
    const [listaIds] = useState<number[]>([])
    const [sendMessage, setSendMessage] = useState<string | null>(null)
    const [loadingSend, setLoadingSend] = useState<boolean>(false)
    const {userData} = useAppSelector(state => state.auth)
    useEffect(() => {
        (async () => {
            var response = await getApi({ endpoint: `${DOMAIN}/`, params: `ramoAtividadeParametro?idRamoAtividade=` + idRamo })
            setResultadoApi(response)

            setresultadoApiBusca(await getApi({ endpoint: `${DOMAIN}/`, params: 'parametro' }))
        })()
    }, [idRamo])

    //useEffect(() => { (async () => { setresultadoApiBusca(await getApi({ endpoint: '${DOMAIN}/', params: 'parametros' })) })() }, [idRamo])

    const handleEditarCheck = useCallback((Checkbox: ChangeEvent<HTMLInputElement>, idParametro: number) => {
        if(Checkbox.target.checked) {
            // alert(`o ${idParametro} marcou`)
            listaIds.push(idParametro)
            // listaIds?.push(idParametro)
            //alert(listaIds)
        }else{
            var indice = listaIds.indexOf(idParametro)
            while (indice >= 0) {
                listaIds.splice(indice, 1)
                indice = listaIds.indexOf(idParametro)
            }
            //listaIds?.splice(indice,1)
            //alert(listaIds)
        }
    }, [listaIds])

    const handleSalvar = useCallback(async () => {

        setLoadingSend(true)

        let newLista: any[] = []

        newLista = listaIds.filter(function (elem, index, self) {
            return index === self.indexOf(elem)
        })

        const listaResultadoDados: any[] = []
        newLista.forEach(element => {
            listaResultadoDados.push({ idParametro: element })
        })
        //alert(listaIds)
        const dados = {
            idRamoAtividade: idRamo,
            idUsuario: userData?.id,
            listaParametros: listaResultadoDados
        }
        //alert(dados.listaParametros)

        const response: RequestResponsePattern<any> = await postApi({ endpoint: `${DOMAIN}/ramoAtividadeParametro`, data: dados })
        if(response) setSendMessage('Salvo com sucesso')
        else setSendMessage(`Erro ${(response as any).listaMensagens[0]}`)

        setLoadingSend(false)
    }, [listaIds, idRamo, userData])

    resultadoApi?.listaResultados.map(ListaParametros => listaIds.push(ListaParametros.idParametro))

    return (

        <>
            <SectionBoxData title="Parâmetros">
                <Formik initialValues = {{}} onSubmit = {() => handleSalvar()}>
                    <FormikContainer>
                        <FormGroup>
                            <List name = "parametros">
                                <ListSection>
                                    {resultadoApiBusca?.listaResultados.map((ListaParametros, index) => (
                                        <ListRow key = {index} grid = {`1fr`} zebra = {index%2===0}>
                                            <ListColumn>
                                                <FormControlLabel control={<Checkbox defaultChecked={listaIds.indexOf(ListaParametros.id) !== -1} onChange={(e) => handleEditarCheck(e, ListaParametros.id)} />} label={ListaParametros.descricao} />
                                            </ListColumn>
                                        </ListRow>
                                    ))}
                                </ListSection>
                            </List>
                        </FormGroup>
                        {sendMessage && (
                            <div id = "message">
                                <span className = {`status ${sendMessage.includes('Erro') ? 'error' : 'success'}`}>{sendMessage}</span>
                            </div>
                        )}
                        <div id = "control">
                            <button type = "submit" className = {`default ${loadingSend ? 'status disabled' : ''}`}>{loadingSend && <div className = "spinner" />}Salvar</button>
                        </div>
                    </FormikContainer>
                </Formik>
            </SectionBoxData>
        </>

    )

}

export default TabParametro