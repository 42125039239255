import { DOMAIN } from "../../../../../../var_env."

import { setGrupoLoading } from "../../../Reducers/GrupoAcessoReducer"

export default async function removeModule(dispatch: Function, id: number, handleReloadModules: Function){
    try {
        dispatch(setGrupoLoading(true))

        const response = await fetch(`${DOMAIN}/Modulo/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const json = await response.json()
        if(json) await handleReloadModules()
    } catch (error) {
        console.log(error)
    } finally {
        dispatch(setGrupoLoading(false))
    }
}