import { Field, Form as FormikContainer, Formik } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { mdiDelete, mdiEye, mdiPencil, mdiPlusCircle } from '@mdi/js'
import './styles.scss'
import { useAppSelector } from '../../../../../store/hooks'
import { setFields } from '../../../../../store/reducers/modules/modulesReducer'
import Section, { SectionBox, SectionOption } from '../../../../../components/Section'
import Modal from '../../../../../components/Modal'
import List, { ListSection, ListRow, ListColumn, ListOption } from '../../../../../components/List'
import Form from '../../../../../components/Form'
import getAppAccessAuthorizedModules from '../../../../../scripts/modules/getAppAccessAuthorizedModules'
import reloadModules from '../../../../../scripts/modules/reloadAppModules'
import { handleSubmitAccess, handleSubmitMenus, handleSubmitModules } from './scripts/submit'
import removeModule from './scripts/removeModule'
import removeMenu from './scripts/removeMenu'
import updateMenu from './scripts/updateMenu'
import updateModule from './scripts/updateModule'
import ModuleIconSelector from '../Components/ModuleIconSelector'
import { Icon } from '@mdi/react'

const Grupo: React.FC = () => {

    let { id } = useParams<any>()

    const dispatch = useDispatch()
    const { userData } = useAppSelector(state => state.auth)
    const { modules, fields, accessAuthorizedModules, formFields } = useAppSelector(state => state.modules)
    const { grupoLoading } = useAppSelector(state => state.GrupoAcessoReducer)

    const [modalName, setModalName] = useState<string>('')
    const [modalID, setModalID] = useState<number>(0)
    const [modalPath, setModalPath] = useState<string>('')
    const [modalIcon, setModalIcon] = useState<string | null>(null)
    
    const [iconAtivo, setIconAtivo] = useState<string>('')
    
    const [openModalModules, setOpenModalModules] = useState<boolean>(false)
    const [openModalMenus, setOpenModalMenus] = useState<boolean>(false)
    const [openModalUpdateModule, setOpenModalUpdateModule] = useState<boolean>(false)
    const [openModalUpdateMenu, setOpenModalUpdateMenu] = useState<boolean>(false)

    const SectionHeaderRight: React.FC <{handleDelete: (e: React.MouseEvent<HTMLDivElement>) => void, handleEdit: (e: React.MouseEvent<HTMLDivElement>) => void, handleCreate: (e: React.MouseEvent<HTMLDivElement>) => void}> = ({ handleDelete, handleEdit, handleCreate }) => (
        <>
            <SectionOption icon = {mdiDelete} tooltip = "Remover" onClick = {e => handleDelete(e)} />
            <SectionOption icon = {mdiPencil} tooltip = "Editar" onClick = {e => handleEdit(e)} />
            <SectionOption icon = {mdiPlusCircle} tooltip = "Criar" onClick = {e => handleCreate(e)} />
        </>
    )

    const FormActions: React.FC <{label: string}> = ({ label }) => {
        const isLoading = grupoLoading
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => handleCloseModal()}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>  
        )
    }

    const handleCloseModal = () => {
        setOpenModalModules(false)
        setOpenModalMenus(false)
        setOpenModalUpdateModule(false)
        setOpenModalUpdateMenu(false)
    }

    const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>, setOpen: Function, name: string, path: string, id: number, icon: string | null) => {
        e.preventDefault()
        setModalID(id)
        setModalName(name)
        setModalPath(path)
        setModalIcon(icon)
        setOpen(true)
    }

    const handleIsEdit = useCallback((idMenu: number) => {
        if(accessAuthorizedModules){
            const modulo = accessAuthorizedModules.find(x=> x.ModuloMenu.find(x =>x.id  === idMenu && x.flagEditar === true))

            return  modulo ? true : false
        }
        return false

    }, [accessAuthorizedModules])

    const handleReloadModules = async () => {

        await reloadModules(dispatch, userData!.GrupoAcesso.id, Number(id))

        handleCloseModal()

    }

    useEffect(() => {
        (async () => { await getAppAccessAuthorizedModules(dispatch, Number(id), userData!.GrupoAcesso.id=== Number(id)) })()
    }, [dispatch,userData, id])

    useEffect(() => {
        if(modules && accessAuthorizedModules){
            const newFields: any = {}
            modules.map(modulo => modulo.ModuloMenu.map(menu => menu.id)).flat(1).map(obj => newFields[obj] = accessAuthorizedModules.map(authModulo => authModulo.ModuloMenu.map(authMenu => authMenu.id)).flat(1).includes(obj))
            
            for (const module of modules) {
                for (const menu of module.ModuloMenu) {
                   const hasEdit = handleIsEdit(menu.id)
                   if(hasEdit) newFields[`${menu.id}toEdit`] = true
                }
            }
            dispatch(setFields(newFields))
        }
    }, [dispatch, id, modules, accessAuthorizedModules, handleIsEdit])

    return(

        <>
            {formFields && (
                <>
                    <Section name="access">
                        <Formik
                            initialValues={formFields}
                            onSubmit={values => handleSubmitAccess(dispatch, values, handleReloadModules, Number(id), fields)}
                        >
                            <FormikContainer>
                                {modules && modules.map((modulo, index) => (
                                    <SectionBox 
                                        key={index} 
                                        padding = {false} 
                                        title={modulo.nome} 
                                        goBack = {index === 0 ? true : false}
                                        right = {
                                            <SectionHeaderRight
                                                handleDelete = {() => removeModule(dispatch, modulo.id, handleReloadModules)}
                                                handleEdit = {e => handleOpenModal(e, setOpenModalUpdateModule, modulo.nome, modulo.path, modulo.id, modulo.icone)}
                                                handleCreate = {e => handleOpenModal(e, setOpenModalMenus, modulo.nome, modulo.path, modulo.id, modulo.icone)}
                                            />
                                        }
                                    >
                                        <List name = "grupo">
                                            <ListSection>
                                            <ListRow key = {0} grid = {`auto 1fr auto`}>
                                            <ListColumn>  
                                            <Icon path= {mdiEye} size = "20px"/>
                                            <Icon path= {mdiPencil} size = "20px"/>
                                                        </ListColumn>
                                            </ListRow>
                                                {modulo.ModuloMenu.map((menuRoute, index) => (
                                                    <ListRow key = {index} grid = {`auto 1fr auto`} zebra = {index%2===0}>
                                                        <ListColumn>  
                                                            <Field name={menuRoute.id.toString()} type="checkbox" />
                                                            <Field  name={menuRoute.id.toString()+'toEdit'} id={menuRoute.id.toString()} type="checkbox" />
                                                        </ListColumn>
                                                        <ListColumn>{menuRoute.nome}</ListColumn>
                                                        <ListColumn>    
                                                            {modulo.ModuloMenu.length > 1 && <ListOption icon = {mdiDelete} status = "error" onClick = {() => removeMenu(dispatch, menuRoute.id, handleReloadModules)} />}
                                                            <ListOption icon = {mdiPencil} onClick = {e => handleOpenModal(e, setOpenModalUpdateMenu, menuRoute.nome, menuRoute.path, menuRoute.id, modulo.icone)} />
                                                        </ListColumn>
                                                    </ListRow>
                                                ))}
                                            </ListSection>
                                        </List>
                                    </SectionBox>
                                ))}
                                <div id = "control">
                                    <button className = "default" onClick={e => handleOpenModal(e, setOpenModalModules, id!, '', 0, null)}>Criar Módulo</button>
                                    <button type="submit" className = {`status ${grupoLoading ? 'disabled' : 'success'}`}>{grupoLoading && <div className = "spinner" />}Salvar Permissões</button>
                                </div>
                            </FormikContainer>
                        </Formik>
                    </Section>
                </>
            )}

            <>
                <Modal open={openModalModules} setOpen={setOpenModalModules} title={`Criar Modulo - ${modalName}`}>
                    <Formik
                        initialValues={{ moduleName: '', modulePath: '' }}
                        onSubmit={values => handleSubmitModules(dispatch, values, handleReloadModules)}
                    >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "moduleName" inputName = "moduleName" label = "Nome do Módulo" />
                                    <Form.Group inputID = "modulePath" inputName = "modulePath" label = "Caminho do Módulo" />
                                </Form.Row>
                                <FormActions label = "Criar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal open={openModalMenus} setOpen={setOpenModalMenus} title={`Criar Menu - ${modalName}`}>
                    <Formik
                        initialValues={{ menuName: '', menuPath: '' }}
                        onSubmit={values => handleSubmitMenus(dispatch, values, handleReloadModules, modalID)}
                    >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "menuName" inputName = "menuName" label = "Nome do Menu" />
                                    <Form.Group inputID = "menuPath" inputName = "menuPath" label = "Caminho do Menu" />
                                </Form.Row>
                                <FormActions label = "Criar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal open={openModalUpdateMenu} setOpen={setOpenModalUpdateMenu} title={`Alterar ${modalName}`}>
                    <Formik
                        initialValues={{ nome: modalName, path: modalPath }}
                        onSubmit={values => updateMenu(values, handleReloadModules, modalID)}
                    >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome do Menu" />
                                    <Form.Group inputID = "path" inputName = "path" label = "Caminho do Menu" />
                                </Form.Row>
                                <FormActions label = "Alterar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal open={openModalUpdateModule} setOpen={setOpenModalUpdateModule} title={`Alterar ${modalName}`}>
                    <Formik
                        initialValues={{ nome: modalName, path: modalPath }}
                        onSubmit={values => updateModule({...values, icone: iconAtivo}, handleReloadModules, modalID)}
                    >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2}>
                                    <Form.Group inputID = "nome" inputName = "nome" label = "Nome do Módulo" />
                                    <Form.Group inputID = "path" inputName = "path" label = "Caminho do Módulo" />
                                </Form.Row>
                                <ModuleIconSelector setCurrentIcon={setIconAtivo} defaultIcon={modalIcon} currentIcon={iconAtivo} />
                                <FormActions label = "Alterar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
            </>
        </>

    )

}

export default Grupo