import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { TableColetaColumn } from './types'
import handleTheme from '../scripts/handleTheme'
import { coletasNaoConformidades } from '../types'

const TableConformidades: React.FC<{coletasNaoConformes: coletasNaoConformidades[]}> = ({coletasNaoConformes}) => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: '24px',
        },
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '10px',
            textAlign: 'center',
        }
    })

    const Column: React.FC <TableColetaColumn> = ({text, width, theme, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0, backgroundColor: theme ?? '#ffffff'}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

        <View style = {styles.container}>
            <View style = {styles.row}>
                <Column text = "Não Conformidades" width = "100%" last />
            </View>
            <View style = {styles.row}>
                <Column text = "Data Coleta" width = "25%" />
                <Column text = "Valor Máximo" width = "25%" />
                <Column text = "Valor Encontrado" width = "50%" last />
            </View>

            {!!coletasNaoConformes && coletasNaoConformes.map((coleta)=>
                <View style = {styles.row}>
                    <Column text = {`${new Date(coleta.dtExecucao).toLocaleDateString()}`} width = "25%" />
                    <Column text = {coleta.idParametro===29? `${coleta.sigla} (${coleta.minimo} a ${coleta.maximo})` : `${coleta.sigla} ${coleta.maximo}`} width = "25%" />
                    <Column text ={`${coleta.valorParametro}`} width = "50%" theme = {handleTheme(4)} last />
                </View>
            )}
        
            <View style = {styles.row}>
                <Column text = "Valores Máximos Permitidos de acordo com o Artigo 19-A do decreto 8468/76. Unidades em mg/L, para o parâmetro RS mL/L" width = "100%" last />
            </View>
        </View>

    )

}

export default TableConformidades