import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiPencil } from '@mdi/js'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'
import { PessoaHidrometroType } from '../Types/PessoaHidrometroType'
import { setCurrentData } from '../Reducers/PessoaHidrometroReducer'

interface Props {
    lista: PessoaHidrometroType[] | null
    loading: boolean               
    canEdit: boolean                    
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
}
    
const TableListarHidrometroComponents: React.FC<Props> = ({ lista, loading, setOpenEditar, setOpenRemover, canEdit }) => {

    const dispatch = useDispatch()

    const grid = "100px repeat(5, 1fr) 70px"

    const SHOW_TABLE = !!lista && !loading
    const SHOW_LOADING = loading

    const handleEdit = useCallback((item: PessoaHidrometroType) => {
        dispatch(setCurrentData(item))
        setOpenEditar(true)
    }, [dispatch, setOpenEditar])

    const handleRemove = useCallback((item: PessoaHidrometroType) => {
        dispatch(setCurrentData(item))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    return(
    
        <>
            <List name = "list">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["ID", "Registro", "Fonte", "UC", "Situação", "Medidor de Vazão?", canEdit && "Opções"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow key = {index} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.id}</ListColumn>
                            <ListColumn>{item.registro}</ListColumn>
                            <ListColumn>{item.fonteAbastecimento}</ListColumn>
                            <ListColumn>{item.uc}</ListColumn>
                            <ListColumn>{item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'}</ListColumn>
                            <ListColumn>{item.flagMedidorVazao === 0 ? 'Não' : 'Sim'}</ListColumn>
                            {canEdit && <ListColumn>
                                <ListOption icon = {mdiPencil} onClick = {() => handleEdit(item)} />    
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemove(item)} />
                            </ListColumn>}
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>
    )
}

export default TableListarHidrometroComponents