import {  TextField } from '@material-ui/core'
import React, {  useCallback, useEffect } from 'react'
import {  mdiMicrosoftExcel, mdiFilePdfBox, mdiMagnify } from '@mdi/js'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import TableListarLogUser  from './Components/TableListarLogUser'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import get from './Scripts/get'
import { setFieldDtFim, setFieldDtInicio, setFieldFiltered } from './Reducers/logUserReducer'
import { Link } from 'react-router-dom'
import filter from './Scripts/filter'
import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'



const LogUser: React.FC = () => {

    const dispatch = useDispatch()
    const { lista, requestGet, requestSave, requestEdit, requestRemove, fieldFiltered, filteredLista, fieldDtInicio, requestFilter, fieldDtFim } = useAppSelector(state => state.logUserReducer)


    const API_CONTROLLER = "logUser"
    const PAGE_NAME = "Log de Usuários"
    const SHOW_TABLE = !!lista
    const SHOW_FILTERED = SHOW_TABLE && !!filteredLista && filteredLista.length>0
    
    useEffect(() => {
        (async () => {await get(API_CONTROLLER, dispatch)})()
    }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data])  

    const handleFilter = useCallback(async () => {
        await filter(API_CONTROLLER, dispatch, {descricao: fieldFiltered, dtPesquisa:fieldDtInicio, fim:fieldDtFim})
    }, [dispatch, fieldFiltered, fieldDtInicio, fieldDtFim])
    
    // useEffect(() => {
    //     (async () => {await getSinonimo(API_CONTROLLERS, dispatch, currentData?.id)})();
    // }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data, currentData])


    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {false} 
                    right = {
                        <>
                           <TextField id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Descrição" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value as string))}  />
                           <TextField title='inicio'  id="Inicio"  type={"date"} className="fieldFilter" placeholder="Inicio"  defaultValue={fieldDtInicio} onChange={(e)=> dispatch(setFieldDtInicio(e.target.value as string))}  />
                           <TextField title='inicio' style={{marginRight:"30px"}} id="Inicio"  type={"date"} className="fieldFilter" placeholder="Inicio"  defaultValue={fieldDtFim} onChange={(e)=> dispatch(setFieldDtFim(e.target.value as string))}  />
                           <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                           <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Salvar excel" onClick = {() => defaultRelatorioExcel(SHOW_FILTERED ? filteredLista : lista, "Log_usuario")} />
                        </>
                    }
                >
                    <TableListarLogUser 
                        lista={SHOW_FILTERED ? filteredLista : lista} 
                        loading = {requestGet.loading || requestFilter.loading} 
                    />
                </SectionBox>
            </Section>
            <>  




            </>
        </>
    )
}

export default LogUser
