import React from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            top: '20px',
            right: '20px',
            left: '20px',
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: '4px',
            borderBottom: '3px solid black',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        },
        text: {
            fontSize: '8px',
        }
    })

    return(

        <View style = {styles.container} fixed>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>FICHA DE LEITURA</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>

    )

}

export default TableBanner