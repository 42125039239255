import React, { useCallback } from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { ClienteType } from "../../Parametro/Types/ClienteType"
import { Link } from 'react-router-dom'
import { SectionOption } from '../../../../../components/Section'
import { mdiFilePdfBox } from '@mdi/js'
import getDadosDBO from '../scripts/getDadosDBO'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'

interface Props {
    lista: ClienteType[] | null
    loading: boolean
}

const TableListarClientes: React.FC<Props> = ({ lista, loading }) => {

    const SHOW_DATA = !!lista && !loading
    const SHOW_LOADING = loading
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)
    
    const handleSalvarDadosDBOParaPDF = useCallback(async (idCliente:any) => {
        await getDadosDBO( Number(idCliente))
    }, [])
    
    return (
        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead >
                    <TableRow >
                        {["Código", "Cliente", "Situação", "Data Cadastro", "PDF"].map((column, index) => <TableCell key={index} >{column}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody >
                    {SHOW_LOADING && (
                        <TableRow  >
                            <TableCell ><CircleSpinner size={32} color="grey" /></TableCell>
                        </TableRow>
                    )} 
                    {SHOW_DATA && lista.map((item, index) => (
                        <TableRow key={index} className = {selectedLine?.nome === item.nome ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                            <TableCell> {item.codigo} </TableCell>
                            <TableCell> {item.nome} </TableCell>
                            <TableCell> {(item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado')} </TableCell> 
                            <TableCell> {new Date(item.dtCadastro).toLocaleDateString()} </TableCell>
                            <TableCell>
                                <Link to = "pdf" target = "_blank"> 
                                    <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {handleSalvarDadosDBOParaPDF(item.id)}} />
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default TableListarClientes