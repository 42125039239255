import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

export default async function getListaAquivos(idProtocolo: number) {
    try {


        const endpoint = DOMAIN
        const params = `/protocolo/getImage?idProtocolo=${idProtocolo}`
        const response: RequestResponsePattern<string[]> = await getApi({ endpoint, params })

        if (response) {
            
            if (!response.flagErro) {
                return response.listaResultados

            } else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')
    } catch (error: any) {
        return []

    }
}