import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableInfoTextContainer } from '../types'
import { HistoricoColetaElement } from '../../../../Types/HistoricoColeta'
import _ from 'lodash'


const TableInfo: React.FC<{ flagMostrar: any }> = ({ flagMostrar }) => {

    const [cliente, setCliente] = useState<any | null>(null)
    const [endereco, setEndereco] = useState<any | null>(null)
    const [coletas, setColetas] = useState<HistoricoColetaElement[] | null>(null)
    //const [soma, setSoma] = useState<number | null>()
    const [pontosColeta, setPontosColeta] = useState<string[] | null>(null)
    //const [ids, setIds] = useState<number[] | null>(null)
    const [hidrometro, setHidrometro] = useState<any[] | null>(null)
    const [resultado, setResultado] = useState<number | null>(null)
    const [sequencia, setSequencia] = useState<{ sequencial: string } | null>(null)
    useEffect(() => {

        //soma  && ids && ((soma/(ids.length))/1000).toFixed(3).replace(".",",")
        setCliente(JSON.parse(localStorage.getItem('ClienteHistorico')!))
        setEndereco(JSON.parse(localStorage.getItem('EnderecoHistorico')!))
        setColetas(JSON.parse(localStorage.getItem('HistoricoColeta')!))
        setHidrometro(JSON.parse(localStorage.getItem('PessoaHidrometro')!))

        setSequencia(JSON.parse(localStorage.getItem('sequencia')!))

        const ultimaCoelta: { data: string } = JSON.parse(localStorage.getItem('ultimaColeta')!)
        let anoAtras: any = null
        if (flagMostrar !== "1")
            anoAtras = new Date((new Date().getTime() - (1.08e7 * 8 * 365)))
        else
            anoAtras = new Date((new Date(ultimaCoelta?.data).getTime() - (1.08e7 * 8 * 365)))

        const col: HistoricoColetaElement[] = JSON.parse(localStorage.getItem('HistoricoColeta')!)
        let coletasDbo = col.filter(x => new Date(x.dtInstalaco) >= anoAtras && x.DBO > 0).map(i => (i.DBO))
        let resultadosAano = col.filter(x => new Date(x.dtInstalaco) >= anoAtras && x.DBO > 0).map(i => (i.id))


        if (coletasDbo.length === 2)
            coletasDbo.push(col.filter(x => x.DBO > 0)?.at(-3)?.DBO!)
        else if (coletasDbo.length === 1) {
            coletasDbo.push(col.filter(x => x.DBO > 0)?.at(-2)?.DBO!)
            coletasDbo.push(col.filter(x => x.DBO > 0)?.at(-3)?.DBO!)
        }
        else if (coletasDbo.length === 0) {
            coletasDbo.push(col.filter(x => x.DBO > 0)?.at(-1)?.DBO!)
            coletasDbo.push(col.filter(x => x.DBO > 0)?.at(-2)?.DBO!)
            coletasDbo.push(col.filter(x => x.DBO > 0)?.at(-3)?.DBO!)
        }

        if (resultadosAano.length === 2)
            resultadosAano.push(col.filter(x => x.DBO > 0)?.at(-3)?.id!)

        else if (resultadosAano.length === 1) {
            resultadosAano.push(col.filter(x => x.DBO > 0)?.at(-2)?.id!)
            resultadosAano.push(col.filter(x => x.DBO > 0)?.at(-3)?.id!)
        }
        else if (resultadosAano.length === 0) {
            resultadosAano.push(col.filter(x => x.DBO > 0)?.at(-1)?.id!)
            resultadosAano.push(col.filter(x => x.DBO > 0)?.at(-2)?.id!)
            resultadosAano.push(col.filter(x => x.DBO > 0)?.at(-3)?.id!)
        }

        coletasDbo = coletasDbo.filter(x => x > 0)
        resultadosAano = resultadosAano.filter(x => x > 0)


        // var soma = 0;

        // for (var i = 0; i < coletasDbo.length; i++) {
        //     soma += coletasDbo[i];
        // }

        // setIds(resultadosAano)

        //setSoma(soma)
    }, [flagMostrar])

    useEffect(() => {

        function mudaPonto(valor: string) {

            const primeiraLetra = valor[0]
            const isNotNumber = isNaN(Number(primeiraLetra))
            if(isNotNumber){
                return valor?.trim() ?? " " 
            }
            valor = valor?.replaceAll('0', '')
            valor = valor?.replaceAll('1', '')
            valor = valor?.replaceAll('2', '')
            valor = valor?.replaceAll('3', '')
            valor = valor?.replaceAll('4', '')
            valor = valor?.replaceAll('5', '')
            valor = valor?.replaceAll('6', '')
            valor = valor?.replaceAll('7', '')
            valor = valor?.replaceAll('8', '')
            valor = valor?.replaceAll('9', '')
            valor = valor?.replaceAll('.', '')
            return valor?.trim() ?? " "
        }


        setResultado(JSON.parse(localStorage.getItem('resultadoSoma')!))

        const pontos: string[] = JSON.parse(localStorage.getItem('valorPonto')!)
        setPontosColeta(_.uniq(pontos.map(i => mudaPonto(i))))

        const valoresComparação = _.uniq(pontos).map((item, index) => (
            {
                i: index + 1,
                valor: mudaPonto(item)
            }
        ))
        localStorage.setItem("Pontos", JSON.stringify(valoresComparação))
    }, [coletas, flagMostrar])

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '4px',
            flexWrap: "wrap"
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            marginRight: '4px',
            fontSize: '10px',
        },
        textField: {
            flex: 1,
            padding: '2px',
            border: '1px solid black',
        },
        textFieldNoBorder: {
            flex: 1,
            padding: '2px',

        },
        text: {
            fontSize: '10px',
        },
        dateBox: {
            paddingVertical: '8px',
            paddingHorizontal: '12px',
            border: '1px solid black',
            fontSize: '10px',
        },
        containerMax: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            border: '1px solid black',
            height: "10%",
            alignItems: 'center',
            justifyContent: 'center'
        }
    })

    const TextContainer: React.FC<TableInfoTextContainer> = ({ label, text, containerWidth, labelWidth, marginRight, border }) => (
        <View break style={[styles.textContainer, { width: containerWidth ?? '100%', marginRight: marginRight ? '8px' : '0' }]}>
            <Text style={[styles.label, { width: labelWidth ?? 'auto' }]}>{label}</Text>
            <View style={border ? styles.textField : styles.textFieldNoBorder}>
                <Text style={styles.text}>{text}</Text>
            </View>
        </View>
    )

    return (
        <>

            <View style={styles.containerMax}>
                {

                    flagMostrar === "0" ?
                        <>
                            <Text style={{}}>HISTÓRICO DAS ANÁLISES PELA CSJ</Text>
                            <Text style={{fontSize:"10px",}}>O Histórico completo pode ser solicitado junto à CSJ</Text>
                        </> :
                        <>
                            <Text style={{ position: 'absolute', top: '20px', left: '50px' }}>HISTÓRICO DAS ANÁLISES PELA CSJ</Text>
                            <Text style={{ fontSize: "10px", position: 'absolute', top: '40px', left: '75px' }}>O Histórico completo pode ser solicitado junto à CSJ</Text>
                        </>
                }


                {flagMostrar === "1" && <View style={{ position: 'absolute', top: '10px', right: '20px', border: '1px solid black', padding: "15px" }}>
                    <View style={{ border: "1px solid black" }}>
                        <Text style={{ paddingLeft: "4px", paddingRight: "4px" }} >{sequencia?.sequencial??'oii'}</Text>
                    </View>

                </View>}
            </View>
            <View style={{ marginTop: "10px" }}></View>
            <View style={styles.container}  >
                <View style={styles.row}>
                    <TextContainer
                        label="Empresa: "
                        text={cliente?.nome}
                        labelWidth="50px"
                        containerWidth="100%"
                        marginRight
                    />
                </View>

                <View style={styles.row}>

                    <TextContainer
                        label="Rua: "
                        text={endereco?.logradouro + ", " + endereco?.numero}
                        labelWidth="30px"
                        containerWidth="75%"
                        marginRight
                    />
                    {/* <TextContainer 
                            label = "Nº:" 
                            text = {endereco?.numero}
                            containerWidth = "20%"
                            marginRight 
                        /> */}
                </View>
                <View style={styles.row}>
                    {/* <TextContainer 
                            label = "Bairro:" 
                            text = {endereco?.bairro}
                            labelWidth = "30px"
                            containerWidth = "90%"
                            marginRight 
                        /> */}
                    <TextContainer label="Cidade:"
                        text={endereco?.cidade + "," + endereco?.uf}
                        containerWidth="35%"
                        marginRight
                    />

                    {/* <TextContainer 
                            label = "UF:" 
                            text = {endereco?.uf}
                            containerWidth = "20%"
                            marginRight
                        /> */}
                </View>

                <View style={styles.row}>
                    {pontosColeta?.map((item, index) => (
                        <TextContainer
                            label={"p" + (index + 1).toString() + ":"}
                            text={` ${item}`}
                            labelWidth="15px"
                            containerWidth='150px'

                        />
                    ))}
                </View>
            </View>
            <View style={{ marginTop: "10px" }}></View>
            {
                hidrometro && hidrometro?.length > 0 && hidrometro?.find((x: { flagAtivo: number; flagMedidorVazao: number }) => x.flagAtivo === 1 && x.flagMedidorVazao === 1)
                &&
                <View style={styles.row}>
                    <TextContainer
                        label={"UC:"}
                        text={`${hidrometro?.find((x: { flagAtivo: number; flagMedidorVazao: number }) => x.flagAtivo === 1 && x.flagMedidorVazao === 1).uc}`}
                        labelWidth="30px"
                        containerWidth='20%'
                        border={true}

                    />
                </View>
            }

            {
                hidrometro && hidrometro?.length > 0 && !(hidrometro?.find((x: { flagAtivo: number; flagMedidorVazao: number }) => x.flagAtivo === 1 && x.flagMedidorVazao === 1))
                &&
                <View style={styles.row}>
                    {hidrometro.filter(x => x.flagAtivo === 1).map((item, index) => (
                        <TextContainer
                            label={`UC${index + 1}:`}
                            text={`${item.uc ?? " "}`}
                            labelWidth="30px"
                            containerWidth='35%'
                            marginRight
                        />
                    ))}



                </View>
            }


            {
                hidrometro?.length === 0 &&

                <View style={styles.row}>
                    <TextContainer
                        label={"UC:"}
                        text={` `}
                        labelWidth="30px"
                        containerWidth='35%'

                    />
                </View>
            }

            <View style={styles.row}>
                <TextContainer
                    label="DBO média considerada para cobrança (kg/m³): "
                    text={` ${resultado! < 0.3 ? "0,300" : resultado?.toFixed(3).replace(".", ",")}`}
                    labelWidth="250px"
                    containerWidth='60%'
                    marginRight
                    border
                />
            </View>
        </>
    )

}

export default TableInfo