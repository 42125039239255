import { StyleSheet, View, Text } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { TableInfoTextContainer } from '../types'


const TableLegend: React.FC<{pontos:{i:number, valor:string}[]}> = ({pontos}) => {


    const styles = StyleSheet.create({
        container: {

            left: '20px',
            right: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '70%',
            border: '1px solid black',
            marginTop:"20px"
        },
        leftText: {
            fontSize: '8px',
        },
        rightText: {
            fontSize: '8px',
            fontWeight: 'bold',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',

        },
        label: {
            fontSize: '8px',
        },
        textField: {
            flex: 2,
            padding: '4px',
            border: '1px solid black',
        },
        text: {
            fontSize: '10px',
        },
        dateBox: {
            paddingVertical: '8px',
            paddingHorizontal: '12px',
            border: '1px solid black',
            fontSize: '10px',
        },
        containerAgrupamento:{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',

        },
    })
    const [max, setMax]  = useState<number | null>(null)

    useEffect(() => {

        setMax(pontos.length)
        // setParametros(JSON.parse(localStorage.getItem("Parametro")!))
   }, [pontos])
    
    const TextContainer: React.FC <TableInfoTextContainer> = ({ label, text, containerWidth, labelWidth, marginRight, color }) => (
        <View break style = {[styles.textContainer, {width: containerWidth ?? '100%', marginRight: marginRight ? '13px' : '0'}]}>
            <View style = {[styles.textField, {backgroundColor: color?? '#FFFFFF', marginRight:"8px"}]}>
                <Text style = {styles.text}>{text}</Text>
            </View>
            <Text style = {[styles.label, {width: labelWidth ?? 'auto'}]}>{label}</Text>
        </View>
    )

    return(
<>


        <View style = {styles.container} wrap={false}>
            <View style={{flexDirection: 'row', width:'30%', marginTop:"20px", marginBottom:"20px", marginLeft:"20px"}}>
            <View>
            <TextContainer 
                            label = "Ponto de Coleta:"
                            text = "P"
                            labelWidth = "100px"
                            containerWidth = "100%"
                            marginRight 
                        />
                {pontos.map((item, index)=>(
                    
                    (index+1 <= Number((max!/3).toFixed(0)) || !max) &&
                    <>                    
                    <TextContainer 
                            label = {item?.valor} 
                            text = {`${item?.i}`}
                            labelWidth = "100px"
                            containerWidth = "100%"
                            marginRight 
                        />
                    </>
                   
                ))}
                </View>

            </View>

            {max && 
                <View style={{flexDirection: 'row', width:'30%', marginTop:"20px", marginBottom:"20px", marginLeft:"20px"}}>
                    <>
                    <View>
                    {pontos.map((item, index)=>(
                    
                    (index+1 > Number((max!/3).toFixed(0)) && index+1 <= Number((max!/3).toFixed(0))*2) &&
                    <>                    
                    <TextContainer 
                            label = {item?.valor} 
                            text = {`${item?.i}`}
                            labelWidth = "100px"
                            containerWidth = "100%"
                            marginRight 
                        />
                    </>
                   
                ))}
                    </View>
                    </>
                </View>}

                {max && 
                <View style={{flexDirection: 'row', width:'30%', marginTop:"20px", marginBottom:"20px", marginLeft:"20px"}}>
                    <>
                    <View>
                    {pontos.map((item, index)=>(
                    
                    (index+1 >  Number((max!/3).toFixed(0))*2) &&
                    <>                    
                    <TextContainer 
                            label = {item?.valor} 
                            text = {`${item?.i}`}
                            labelWidth = "100px"
                            containerWidth = "100%"
                            marginRight 
                        />
                    </>
                   
                ))}
                    </View>
                    </>
                </View>}

        </View>

        <View  style={styles.containerAgrupamento}>
        <View style = {[styles.container, {width:'55%'}]} wrap={false}>

        <View style={{flexDirection: 'row', width:'45%' , marginTop:"20px", marginBottom:"20px", marginLeft:"10px", fontSize:"8px"}}>
                    <>
                    <View>
                    <TextContainer 
                            label = "Sequência de amostragem"
                            text = "N"
                            labelWidth = "100px"
                            containerWidth = "100%"
                            marginRight 
                        />
                            <TextContainer 
                            label = "Controle de monitoramento"
                            text = "CM"
                            labelWidth = "100px"
                            containerWidth = "100%"
                            marginRight 
                        />
                    </View>
                    </>
            </View>

            <View style={{flexDirection: 'row', width:'45%', marginTop:"20px", marginBottom:"20px", marginLeft:"10px"}}>
                    <>
                    <View>
                    <TextContainer 
                            label = " Erro de análise"
                            text = " "
                            labelWidth = "160px"
                            containerWidth = "100%"
                            marginRight 
                            color='green'
                        />
                                            <TextContainer 
                            label = " Coleta não representativa"
                            text = " "
                            labelWidth = "160px"
                            containerWidth = "100%"
                            marginRight 
                            color='blue'
                        />
                            <TextContainer 
                            label = "-"
                            text = " "
                            labelWidth = "160px"
                            containerWidth = "100%"
                            marginRight 
                            color='white'
                        />
                                            <TextContainer 
                            label = " Valores considerados para DBO de cobrança"
                            text = " "
                            labelWidth = "160px"
                            containerWidth = "100%"
                            marginRight 
                            color='pink'
                        />
                                            <TextContainer 
                            label = "Resultado não conforme erro de análise"
                            text = " "
                            labelWidth = "160px"
                            containerWidth = "100%"
                            marginRight 
                            color='red'
                        />
                        
                    </View>
                    </>
            </View>

        </View>

        <View   style = {{left: '20px',right: '20px',display: 'flex',flexDirection: 'column',justifyContent: 'space-between',alignItems: 'center',width: '30%',borderTop: '1px solid black',marginTop:"20px"}} wrap={false}>
            <Text style = {{marginBottom: '20px', fontSize: '10px'}}>FABIO HENRIQUE ERCOLIN</Text>
            <Text style = {{fontSize: '10px'}}>Coordenador de Monitoramento CRQ: 04472326</Text>
            <Text style = {{fontSize: '10px'}}>fabio@saneamento.com.br</Text>
        </View>

        </View>
        </>
    )

}

export default TableLegend