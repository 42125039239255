import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ParametroType } from '../../../Types/ParametroType'

const TableRow: React.FC <ParametroType> = props => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },          
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '8px',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })

    const Column: React.FC <{text: string, width: string, last?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

        <View style = {styles.row}>
            <Column text = {props.id.toString()} width = "6%" />
            <Column text = {props.sigla} width = "6%" />
            <Column text = {props.descricao} width = "38%" />
            <Column text = {props.minimo.toString()} width = "10%" last />
            <Column text = {props.maximo.toString()} width = "10%" last />
            <Column text = {props.incerteza.toString()+"%"} width = "15%" last />
            <Column text = {props.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'} width = "15%" last />
        </View>

    )

}

export default TableRow