import React from 'react'
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const TableFooter: React.FC = () => {

    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: '0',
            left: '20px',
            right: '20px',
            height: '80px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        boxContainer: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
            padding: '4px',
            border: '1px solid black',
            borderRight: '0px'
        },
        boxTitle: {
            fontSize: '8px',
        },
        data: {
            fontSize: '14px',
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            paddingTop: '4px',
            paddingBottom: '16px',
        },
        leftText: {
            fontSize: '8px',
        },
        rightText: {
            fontSize: '8px',
            fontWeight: 'bold',
        }
    })

    const Box: React.FC <{width: string, label: string, last?: boolean}> = ({ children, width, label, last }) => (
        <View style = {[styles.box, { width, borderRight: last ? '1px solid black' : undefined }]}>
            <Text style = {styles.boxTitle}>{label}</Text>
            {children}
        </View>
    )

    return(

        <View style = {styles.container} fixed>
            <View style = {styles.boxContainer}>
                <Box width = '33%' label = 'Responsável pela Leitura'>
                    <Text style = {styles.data}>____/____/____</Text>
                </Box>
                <Box width = '34%' label = 'Atendimento' />
                <Box width = '33%' label = 'Lançamento de Dados' last>
                    <Text style = {styles.data}>____/____/____</Text>
                </Box>
            </View>
            <View style = {styles.infoContainer}>
                <Text style = {styles.leftText}>{`056_FMEI Rev.02 `}</Text>
                <Text style = {styles.rightText} render={({ pageNumber, totalPages }) => (`pág ${pageNumber} de ${totalPages}`)} fixed />
            </View>
        </View>

    )

}

export default TableFooter