import { DOMAIN } from "../../../../../var_env."
import { setRequestRemoveData, setRequestRemoveError, setRequestRemoveLoading, setRequestRemoveMessage } from "../../Reducers/UsuarioReducer"
import { RequestResponsePattern } from "../../../../../types/Request"

export default async function remove(dispatch: Function, id: number){
    try {
        dispatch(setRequestRemoveLoading())
        
        const endpoint = `${DOMAIN}/Usuario/${id}`
        const response = await fetch(endpoint, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
        const json: RequestResponsePattern<any> = await response.json()
        
        if(json){
            dispatch(setRequestRemoveData(json))
            if(!json.flagErro) dispatch(setRequestRemoveData(json))
            else throw new Error(json.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestRemoveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestRemoveError())
    }
}