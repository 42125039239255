import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from "../Reducers/ParametroReducer"
import { EditParams } from "../Types/ParametroParams"
import { ParametroType } from "../Types/ParametroType"

export default async function edit(apiController: string, dispatch: Function, values: EditParams){
    try {
        dispatch(setRequestEditLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`

        const data = { 
            id: values.id, 
            sigla: values.sigla,
            descricao: values.descricao,
            minimo: values.minimo,
            maximo: values.maximo,
            incerteza: values.incerteza,
            flagAtivo: values.flagAtivo ? 1 : 0, 
            idUsuario: values.idUsuario
        }
        
        const response: RequestResponsePattern<ParametroType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestEditData(response))
            if(response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}