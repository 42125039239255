import React from 'react'
import { ClickAwayListener } from '@material-ui/core'
import { MenuWrapperProps } from '../types'

const MenuWrapper: React.FC <MenuWrapperProps> = ({ children, setOpen }) => {

    return(

        <ClickAwayListener onClickAway = {() => setOpen(false)}>
            <div className = "menuWrapper">{children}</div>
        </ClickAwayListener>

    )

}

export default MenuWrapper