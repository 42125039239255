import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import {  postApi } from "../../../../../utils/requestApi"
import { ficha } from "../../Cliente/Components/PDF_FICHA/types"


export default async function getDadosFicha( data:any){
    try {

        const endpoint = `${DOMAIN}/PessoaHidrometroLancamento/fichaTecnica`
        const response: RequestResponsePattern<ficha[]> = await postApi({ endpoint, data })
        
        if(response){
            
            if(!response.flagErro) {
                localStorage.setItem("PDF_FICHA", JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        
    }
}