import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'

import List, { ListColumn, ListRow, ListSection } from '../../../../../components/List'
import { RelatorioDbo } from '../Types'

interface Props {
    lista: RelatorioDbo[] | null
    loading: boolean
}
   
const TableListarRelatorioDBO: React.FC<Props> = ({ lista, loading }) => {


    
    const grid = "300px 1fr  10px  80px  "

    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !loading

    

    return(
    
        <>
            <List name = "">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {[ "Indústria", "Endereço", "Dbo de cobrança"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow  zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.nome}</ListColumn>
                            <ListColumn>{item.endereco}</ListColumn>
                            <ListColumn>{item.dbo?.toFixed(3).replace(".",",")}</ListColumn>
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>

    )

}

export default TableListarRelatorioDBO