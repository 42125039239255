import React, {  useEffect } from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import Pagination from '../../../../../components/Pagination'
import getHistoricoColeta from '../scripts/getIndustriaMonitorada'
import getParametro from '../scripts/getParametro'
import { useDispatch } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import { mdiFilePdfBox } from '@mdi/js'
import { SectionOption } from '../../../../../components/Section'

interface Props {
    id: number
    lista: any
    loading: boolean
}

const TableListarIndustriasMonitoradas: React.FC <Props> = ({ id, lista, loading }) => {

    const SHOW_TABLE = !!lista && !loading 
    const SHOW_LOADING = loading
    const dispatch = useDispatch()
    const final:any[] =[]
    const finalEmpresa:any[] =[]
    const naoConformidade:any[] =[]
   
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)

    useEffect(() => {
        (async () => {await getParametro("Parametro", dispatch)})()
    }, [dispatch]) 
 
    useEffect(() => {
        if(final.length > 0) {
            localStorage.setItem("flagVermelho", JSON.stringify(naoConformidade))
            localStorage.setItem("MonitoramentoIndustria", JSON.stringify(finalEmpresa))
            localStorage.setItem("paramsTabela", JSON.stringify(final))
        }
    }) 
    
    return(
        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow >
                        {['Indústria', 'CNPJ', 'Coleta', 'Relatorio'].map((item, index) => <TableCell key = {index}>{item}</TableCell>)}

                    </TableRow>
                </TableHead>
                <TableBody>
                    {SHOW_LOADING && (
                        <TableRow>
                            <TableCell colSpan = {30}>
                                <div className = "center">
                                    <CircleSpinner size = {32} color = "grey" />
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {SHOW_TABLE && lista.map((item :any, index:any) => (
                        <TableRow key = {index} className = {selectedLine?.id === item.id ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                            <TableCell style={{fontSize:"12px"}}>{item.nome}</TableCell>
                            <TableCell style={{fontSize:"12px"}}>{item.nroDocumento}</TableCell>
                            <TableCell style={{fontSize:"12px"}}>{new Date(item.dtExecucao).toLocaleDateString()}</TableCell>
                        <TableCell>
                        <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {localStorage.setItem("RelatorioNaoConformeLista", JSON.stringify([item]))}} />
                            </Link>
                        </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                {lista && lista.paginas > 0 && <Pagination pages = {lista.paginas} id = {id} onClick = {getHistoricoColeta} />}
            </div>
        </TableContainer>
    )

}

export default TableListarIndustriasMonitoradas