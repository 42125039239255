import React from 'react'

const Container: React.FC = ({ children }) => {

    return(

        <aside id = "sidebar">
            <nav id = "sidebarSectionGroup" className = "customScroll overlayScroll">{children}</nav>
        </aside>

    )

}

export default Container