import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setLista, setRequestGetData, setRequestGetError, setRequestGetLoading, setRequestGetMessage } from "../Reducers/RelatorioPontoColetaReducer"
import { getApi } from "../../../../../utils/requestApi"
import { RelatorioPontoColeta } from "../types"


export default async function get(dispatch: Function, filtrar:boolean = true){
    try {
        dispatch(setRequestGetLoading())

        const endpoint = DOMAIN
        const params = `/RelatorioPessoaPontoColeta`
        const response: RequestResponsePattern<RelatorioPontoColeta[]> = await getApi({ endpoint, params })
        if(response){
            dispatch(setRequestGetData(response))
            if(!response.flagErro) {
                dispatch(setLista(filtrar? response.listaResultados.filter(x=> x.flagAtivo===1) : response.listaResultados))
                localStorage.setItem('relatorioPontoColeta', JSON.stringify(filtrar? response.listaResultados.filter(x=> x.flagAtivo===1) : response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}