import { Text, View, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

const TableFooter: React.FC = () => {
    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },          
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '5px',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        leftText: {
            fontSize: '8px',
        }, container:{
            position: 'absolute',
            bottom: '18px',
            left: '20px',
            right: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            borderTop: '1px solid black',
        }
        
    })

    const Column: React.FC <{text: string, width: string, last?: boolean, header?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )
    return(
        <>
        <View style = {styles.section}>
            <View style = {styles.row} wrap={false}>
                <Column text='ABREV.' width='20%'/>
                <Column text='DESCRIÇÃO' width='20%' last/>
            </View>
            <View style = {styles.row} wrap={false}>
                <Column text='Cx Inspeção' width='20%'/>
                <Column text='Caixa de Inspeção' width='20%' last/>
            </View>
            <View style = {styles.row} wrap={false}>
                <Column text='PV' width='20%'/>
                <Column text='Poço de visita' width='20%' last/>
            </View>
            <View style = {styles.row} wrap={false}>
                <Column text='C. Parshall' width='20%'/>
                <Column text='Calha Parshall' width='20%' last/>
            </View>
            <View style = {styles.row} wrap={false}>
                <Column text='Cx Saída' width='20%'/>
                <Column text='Caixa de Saída' width='20%' last/>
            </View>
            <View style = {styles.row} wrap={false}>
                <Column text='Tq Saída' width='20%'/>
                <Column text='Tanque de Saída' width='20%' last/>
            </View>
            <View style = {styles.row} wrap={false}>
                <Column text='Tq Recalque' width='20%'/>
                <Column text='Tanque de Recalque' width='20%' last/>
            </View>
        </View>


<Text fixed  style={{ bottom: '30px',fontSize:'8px', position: 'absolute', left: '40%'}}>045_FMEI Rev.06 - Apr. jul/22</Text>
    <View style = {styles.container} fixed>
        <Text style = {styles.leftText} render={({ pageNumber, totalPages }) => (`pág ${pageNumber} de ${totalPages}`)} fixed />
    </View>

        </>

    )

}

export default TableFooter