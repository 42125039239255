import React , { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getApi } from '../../../../../utils/requestApi/index'
import { DOMAIN } from '../../../../../var_env.'

const MonMensal: React.FC = () => {

    const [grupos, setGrupos] = useState<any>(null)
    const { idCliente } = useParams<any>()

    useEffect(() => {
        (async () => {
            let Atividades = await getApi({ endpoint: `${DOMAIN}/RelatorioMonitoramento/mensal/${idCliente}`, params: '' })
            Atividades = Atividades.listaResultados
            setGrupos(Atividades)
        })()
    }, [idCliente])

    const SHOW_GRUPOS = !!grupos

    return(

        <>
            <table className = "customTable">
                <thead>
                    <tr>
                        <th>Mês/Ano</th>
                        <th>DBO anterior</th>
                        <th>DBO atual</th>
                        <th>Esgoto m³/mês</th>
                        <th>KG DBO anterior</th>
                        <th>KG DBO atual</th>
                        <th>KG DBO variação</th>
                        <th>Coletas</th>
                    </tr>
                </thead>
                {SHOW_GRUPOS &&
                    //console.log(grupos)&&
                    <>
                        <tbody>
                            
                            {grupos.map((item:any, index:number) => {
                                const valorAnterior = index-1 >=0? grupos[index-1].dboTotal: '0,0'
                                return(
                                    <tr key = {index}>
                                        <td>{item.mes+'/'+item.ano}</td>
                                        <td>{valorAnterior}</td>
                                        <td>{item.dboTotal}</td>
                                        <td>{item.volumeLancamento }</td>
                                        <td>{isNaN(valorAnterior)? '0,0': valorAnterior}</td>
                                        <td>{item.dboTotal}</td>
                                        <td>{isNaN((item.dboTotal)-(valorAnterior))? '0,0': ((item.dboTotal)-(valorAnterior)).toFixed(2)}</td>
                                        <td>{item.qtdeTotal}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </>

                }
                
            </table>
        </>

    )

}

export default MonMensal