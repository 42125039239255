import React, { useCallback, useEffect, useState } from 'react'
import { formatValue } from 'react-currency-input-field'
import { Link } from 'react-router-dom'
import { mdiCalculatorVariant, mdiCommentText, mdiContentSave, mdiDelete, mdiFile } from '@mdi/js'
import { useDispatch } from 'react-redux'
import { vazaoLancamento } from '../types'
import { useAppSelector } from '../../../../../store/hooks'
import { setCurrentLancamento, setEditValues } from '../Reducers/VazaoLancamentoReducer'
import { ListColumn, ListInput, ListOption, ListRow } from '../../../../../components/List'
import Select from '../../../../../components/Select'
import getVolumecorrigido from '../scripts/getVolumecorrigido'
import getHoraFinal from '../scripts/getHoraFinal'
import Modal from '../../../../../components/Modal'
import Form from '../../../../../components/Form'
import getDadosFicha from '../scripts/getDadosFicha'
import isEditable from '../../../../../scripts/modules/isEditable'
import { usePrompt } from '../../../../../hooks/usePromptBlocker'
//import { usePrompt } from '../../../../../hooks/usePromptBlocker'

interface Props {
    grid: string
	lancamento: vazaoLancamento,
	values: any,
    initialValues: any,
	handleExcluir: Function,
	handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const TableRow: React.FC<Props> = ({ grid, lancamento, values, initialValues, handleExcluir, handleSubmit, setFieldValue }) => {

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'lancamento')

	const dispatch = useDispatch()
	const { requestSave, requestEdit, requestRemove, fieldFilteredMes, fieldFilteredAno, categorias, currentLancamento } = useAppSelector(state => state.VazaoLancamentoReducer)
    const [hasChanges, setHasChanges] = useState<boolean>(false)
    const [openObs, setOpenObs] = useState<boolean>(false)
    const [loadingCalc, setLoadingCalc] = useState<boolean>(false)

    const [tipoCobranca, setTipoCobranca] = useState<string>(values.idCategoriaCobranca ? values.idCategoriaCobranca.toString() : '1')
    const [horaTotal, setHoraTotal] = useState<string | undefined>(lancamento.tempoFinal)

    //usePrompt(`Você tem alterações não salvas em ${lancamento.registro}, deseja sair?`,hasChanges)

    const handleGerarDadosUnicoPDF = useCallback(async (idPessoa: Number ) => {
        localStorage.setItem("PDF_FICHA", JSON.stringify([]))
		const data = {
			idPessoa:idPessoa,
			idMes:fieldFilteredMes,
			idAno: fieldFilteredAno
		}
		await getDadosFicha(data)
	}, [ fieldFilteredMes, fieldFilteredAno]) 

	const handleValorCorrigido = useCallback(async ( logica: string, medidor: number) => {
        setLoadingCalc(true)
        const aHora = await getHoraFinal(dispatch, {logica: values.logica, tempoAnterior: values.tempoAnterior.replace(',','.'), tempoAtual: values.tempoAtual})
		if(Number(logica) === 1) setFieldValue("volumeCorrigido", (values.leituraAtual - values.leituraAnterior).toFixed(2))
		else{	
			const data = { 
                regra: Number(logica),
                logica: values.logica, 
                tempoAtual: values.tempoAtual, 
                tempoAnterior: values.tempoAnterior.replace(',','.'),
                volumeLido: (values.leituraAtual - values.leituraAnterior), 
                idMes: fieldFilteredMes, 
                idAno: fieldFilteredAno,
                idHidrometro: values.idHidrometro, 
                idPessoa: values.idPessoa
            }
			const volumeCorrigoRegra = await getVolumecorrigido(dispatch, data)
 setFieldValue("volumeCorrigido", volumeCorrigoRegra?.result) 
 setFieldValue("flagMedia", volumeCorrigoRegra?.flagMedia) 
		}

        medidor===null || medidor===0? setHoraTotal("00:00:00") :setHoraTotal(aHora?.horaFormatada.toString())
        medidor===null || medidor===0?  setFieldValue("tempoFinal", '00:00:00') : setFieldValue("tempoFinal", aHora?.horaFormatada.toString())
        setLoadingCalc(false)
	}, [dispatch, setFieldValue, fieldFilteredAno, fieldFilteredMes, values])
    
    useEffect(() => {
        let fieldsChanges = false
        let categoriaChanges = initialValues.idCategoriaCobranca.toString() !== tipoCobranca

        if(values.registro === initialValues.registro){
            Object.keys(values).forEach(key => {
                if(initialValues[key] !== values[key]){
                    //console.log('Changed',key,values.registro)
                    //console.log('initial',initialValues[key])
                    //console.log('current',values[key])
                    //console.log(JSON.stringify({...values, leituraAtual: Number(values.leituraAtual)}) !== JSON.stringify(initialValues))
                    if(typeof initialValues[key] === 'number' && (initialValues[key] as number).toString() === values[key]) fieldsChanges = false
                    else fieldsChanges = true
                }
            })
    
            const changes = (fieldsChanges || categoriaChanges)
    
            setHasChanges(changes)
        }

        return () => {
            setHasChanges(false)
        }   
    }, [dispatch, tipoCobranca, values, initialValues])


    const sortearCor = (): string => {
        return '#' + Math.floor(Math.random() * 16777215).toString(16); // Gera uma cor aleatória hexadecimal
    };

    const obterCor = (nome: string): string => {
        return atualizarCores({nome, cor: sortearCor()})
    };

    const sortearNomeComCor = (nome: string): any => {
        //style={{ color: sortearNomeComCor(lancamento.nome).cor }}
        //colocar na tag <p>

        //, borderBottom: '1px solid #ccc'
        //colocar no style do <div> hasChanges
        const cor = obterCor(nome);

        return { nome, cor };
    };


    const atualizarCores = (val: any): any => {
       const color = localStorage.getItem('colVal')

       console.log('colVal', color)
       if(color === null) {
        localStorage.setItem('colVal', JSON.stringify([{cor:val.cor,nome: val.nome }]))
        return val.cor
    }

       const coresCliente : {cor: string, nome: string}[] = JSON.parse(color)

       const corEspecifica = coresCliente.find(x=> x.nome === val.nome)

       if(corEspecifica === undefined) {
        coresCliente.push({cor:val.cor,nome: val.nome })
         localStorage.setItem('colVal', JSON.stringify(coresCliente))
        return val.cor
    }


        coresCliente.push({cor:val.cor,nome: val.nome })
         localStorage.setItem('colVal', JSON.stringify(coresCliente))
       return corEspecifica.cor
    };

	return (

		<div className = {hasChanges ? 'notSaved' : ''} style = {{width: '100%'}} data-registro = {lancamento.registro}>
            <ListRow grid={grid}>
                <ListColumn>
                    <p >
                    {lancamento.nome}
                    </p>
                    {}</ListColumn>
                <ListColumn>{lancamento.registro}</ListColumn>
                <ListColumn>
                    {formatValue({
                        value: lancamento.leituraAnterior ? lancamento.leituraAnterior.toString() : '',
                        groupSeparator: '.',
                        decimalSeparator: ',',
                    })}
                </ListColumn>
                <ListColumn>
                    <ListInput inputID="leituraAtual" inputName="leituraAtual" inputMask={'currency'} setFieldValue = {setFieldValue} value = {values.leituraAtual} />
                </ListColumn>
                <ListColumn>
                    {formatValue({
                        value: (values.leituraAtual - values.leituraAnterior).toFixed(2),
                        groupSeparator: '.',
                        decimalSeparator: ',',
                    })}
                </ListColumn>
                <ListColumn>
                    <Select 
                        type="outline" 
                        value={tipoCobranca} 
                        setValue={setTipoCobranca} 
                        items={categorias!.map(item => { return { value: item.id.toString(), label: item.descricao } })} 
                    />
                </ListColumn>
                <ListColumn>{lancamento.tempoAnterior.replace(',','.')}</ListColumn>
                <ListColumn>
                    {lancamento.flagMedidorVazao===1?
                    <ListInput inputID="tempoAtual" inputName="tempoAtual"/>:
                    0
                    }
                    
                </ListColumn>
                <ListColumn>{horaTotal?.includes("NaN") ? "-": horaTotal}</ListColumn>
                {lancamento.flagMedidorVazao===1?
                <ListColumn>
                    <ListInput inputID="volumeCorrigido" inputName="volumeCorrigido" inputMask={'currency'} setFieldValue = {setFieldValue} value = {values.volumeCorrigido} />
                </ListColumn>:
                <ListColumn>
                {formatValue({
                    value: (values.leituraAtual - values.leituraAnterior).toFixed(2),
                    groupSeparator: '.',
                    decimalSeparator: ',',
                })}
            </ListColumn>
            }
                {canEdit && <ListColumn>    
                    {<ListOption tooltip="Calcular" loading={loadingCalc} icon={mdiCalculatorVariant} status="info" onClick={() => { dispatch(setCurrentLancamento(lancamento)); handleValorCorrigido(tipoCobranca, lancamento.flagMedidorVazao) }} />}
                    {<ListOption tooltip="Observações" icon={mdiCommentText} status={lancamento.obs ? 'warning' : undefined} onClick={() => { dispatch(setCurrentLancamento(lancamento)); setOpenObs(true) }} />}

                    {<ListOption tooltip="Salvar" loading={(requestSave.loading || requestEdit.loading) && currentLancamento?.id === lancamento.id} icon={mdiContentSave} status="success" onClick={() => { dispatch(setCurrentLancamento(lancamento)); dispatch(setEditValues({tipoCobranca, volumeCorrigido: values.volumeCorrigido})); handleSubmit() }} />}
                    <Link to = "ficha-pdf" target = "_blank">
                        <ListOption tooltip="Ficha de Leitura" icon = {mdiFile}  onClick = {() => {handleGerarDadosUnicoPDF(lancamento.idPessoa)}} />
                    </Link>
                </ListColumn>
                }
            </ListRow>
            <>
                <Modal open = {openObs} setOpen = {setOpenObs} title = "Observações">
                    <Form.Container padding = {false}>
                        <Form.Group inputID='obs' inputName='obs' inputType='textarea' inputRows={4} label='Obervação' />
                        <div className = "modalBoxMainActionsContainer">
                            <button className = "default" onClick = {e => { e.preventDefault(); setOpenObs(false) }}>Ok</button>
                        </div>
                    </Form.Container>
                </Modal>
            </>
		</div>

	)

}

export default TableRow