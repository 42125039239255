import ExcelJS from 'exceljs'
import * as fs from 'file-saver'
import { ParametroType } from '../pages/system/Pessoas/Parametro/Types/ParametroType'


export function ExcelIndustriaMonitorada(item: any) {
    const listaParametros = ['Ag', 'As_', 'Cd', 'CN', 'Cr', 'Cr6', 'Cu','DBO', 'DQO' ,'Fe', 'Fenol','Fluoreto' ,'P','Pb','Hg', 'Ni', 'N', 'Og', 'pH',  'Se', 'Sn', 'SS', 'Sulfato', 'Sulfeto','°C' ,'Tox','Zn']
    const parametros : ParametroType[] | null = JSON.parse(localStorage.getItem("Parametro")!)
    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0 || Number(value)=== 0 ? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    let json : any =[]


    json = item.map((item: any) => ({
        codigoDoCliente: item.codigoPessoa,
        Industria: item.nome,
        rua : item.enderecoCompleto?.split("-")[0],
        bairro: item.enderecoCompleto?.split("-")[1],
        cnpj: item.nroDocumento,
        data: new Date(item.dtExecucao).toLocaleDateString(),
        hora: new Date(item.dtExecucao).toLocaleTimeString(),
        Ag: handleValue(item.Ag),
        As: handleValue(item.As_) ,
        Cd:handleValue(item.Cd) ,
        CN:handleValue(item.CN) ,
        Cr:handleValue(item.Cr) ,
        Cr6:handleValue(item.Cr6) ,
        Cu: handleValue(item.Cu) ,
        DBO:handleValue(item.DBO) ,
        DQO:handleValue(item.DQO) ,
        Fe:handleValue(item.Fe) ,
        Fenol:handleValue(item.Fenol) ,
        Flureto: handleValue(item.Fluoreto) ,
        P: handleValue(item.P) ,
        pb: handleValue(item.Pb),
        Hg:handleValue(item.Hg) ,
        Ni:handleValue(item.Ni) ,
        N: handleValue(item.N) ,
        Og: handleValue(item.Og) ,
        PH: handleValue(item.pH) ,
        Se:handleValue(item.Se) ,
        Sn: handleValue(item.Sn) ,
        SS: handleValue(item.Rs) ,
        Sulfato: handleValue(item.Sulfato) ,
        Sulfeto: handleValue(item.Sulfeto)  ,
        C: handleValue(item.temperatura) ,
        Toxicidade: handleValue(item.Toxicidade) ,
        Zn : handleValue(item.Zn)
    }))

    const workbook = new ExcelJS.Workbook();

    const sheet = workbook.addWorksheet('Excel', { properties: { tabColor: { argb: 'FFC0000' } } });

    sheet.columns = Object.keys(json[0]).map(key => {

        if (key === 'codigoDoCliente') return { header: 'Codigo do Cliente', key, width: 10 }
        if(key.toUpperCase() === 'SS') return { header: `${key} (ml/L)`, key, width: 10 }
        if(key.toUpperCase() === 'C') return { header: `${key} (°C)`, key, width: 10 }
        if (!(key.toUpperCase() === 'PH' || key.toUpperCase() === '°C' || key.toUpperCase() === 'TOXICIDADE') && listaParametros.includes(key) ) return { header: `${key} (mg/L)`, key, width: 10 }
        return { header: key, key, width: 10 }

    })

    sheet.addRows(json)

    let i = 0;
    for (const P of listaParametros) {
        const oParametro = parametros?.find(x => x.descricao.toUpperCase() === P.toUpperCase() || x.sigla.toUpperCase() === P.toUpperCase() )
        i++
        sheet.getColumn(i+7).eachCell(cell => {
            const valorParametro = Number(cell.value?.toString().replace("<","").replace(",","."))
            if(cell.value && valorParametro>40 && P==="°C") cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00FF0000' } }, font: { bold: true, color: { argb: 'ffffff' } } }
            if (cell.value && cell.value.toString() !== "-" &&  (Number(oParametro?.maximo) !==0  || Number(oParametro?.minimo) !== 0)  && (valorParametro> oParametro?.maximo || valorParametro < oParametro?.minimo)) {
                cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00FF0000' } }, font: { bold: true, color: { argb: 'ffffff' } } }
            }
        })
    }

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, `industria_monitorada.xlsx`);
    })

}