import { utils, writeFile } from 'xlsx';

export function defaultRelatorioExcel(item: any | null, Controler: string) {
     
     const fileName = `${Controler}.xlsx`;


     let valoresLimpos 
     if(Controler==="relatorio_parametro"){

          valoresLimpos = item.map((item: any) => ({
               descricao: item.descricao.replace("(MG/L)","").replace("(ML/L)","").replace("( °C )",""),
               qtde: item.qtde,
               qtdeConforme : item.qtdeConforme,
               qtdeNaoConforme: item.qtdeNaoConforme

          }))

     }else if(Controler==="Relatorio_regiao"){

          valoresLimpos = item.map((item: any) => ({
               codigoCliente: item.codigoCliente,
               nome: item.nome,
               nroDocumento: item.nroDocumento,
               status : item.flagAtivo===1?"Habilitado":"Desabilitado",
               descricaoRamoAtividade: item.descricaoRamoAtividade,
               descricaoRoteiro: item.descricaoRoteiro,
               telefone: item.telefone,
               email: item.email,
               enderecoCompleto: item.enderecoCompleto
          }))
     }
     else if(Controler==="relatorio_AtualizacaoDBO"){

          valoresLimpos = item.map((item: any) => ({
               nome: item.nome,
               dbo: item.dbo,
               endereco: item.endereco,
               uc: item.uc.join("/")
          }))
     }
     else if(Controler==="ponto_coleta"){

          valoresLimpos = item.map((item: any) => ({
               codigoCliente: item.codigo,
               status : item.flagAtivo===1?"Habilitado":"Desabilitado",
               nome: item.nome,
               pontoColeta: item.descricaoPontoColeta
          }))
     }
     else if(Controler==="Log_usuario"){

          valoresLimpos = item.map((item: any) => ({
               usuario: item.login,
               acao : repararDiv(item.acao),
               data: new Date(item.dtAcao)?.toLocaleString(),
          }))
     }
     else if(Controler==="relatorio_pessoa"){
          valoresLimpos = item.map((item: any) => ({
               status : item.flagAtivo===1?"Habilitado":"Desabilitado",
               codigoCliente: item.codigoCliente,
               nome: item.nome,
               nroDocumento: item.nroDocumento,
               descricaoRamoAtividade: item.descricaoRamoAtividade,
               descricaoRoteiro: item.descricaoRoteiro,
               telefone: item.telefone,
               email: item.email,
               enderecoCompleto: item.enderecoCompleto
          }))
     }
     else if(Controler==="Relatorio_ranking"){
          valoresLimpos = item.map((item: any) => ({
               nome: item.nome,
               ramoAtividade: item.ramoAtividade,
               volumeEsgoto: Number(item.volumeEsgoto).toFixed(0),
               dboTotal: Number(item.dboTotal).toFixed(3).replace(".",","),
               cargaOrganica: Number(item.cargaOrganica).toFixed(3).replace(".",","),
               qtdMin: item.qtdMin,
               col: item.col,
               meta: item.meta,
               regraColeta: item.regraColeta
          }))
     }
     else{
          valoresLimpos = item
     }

     const worksheet = utils.json_to_sheet(valoresLimpos);
     const workbook = utils.book_new();

     utils.book_append_sheet(workbook, worksheet, Controler)
     writeFile(workbook, fileName)

}

function repararDiv(text: any){

     text = text.replaceAll("<div class=\"negrito\">","")
     text = text.replaceAll("<div/>","")
     text = text.replaceAll("<br/>","\n")
     text = text.replaceAll("<br/><br/>","")
     text = text.replaceAll("<div>","")
     return text
}