import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { RelatorioPessoaType } from "../types"


interface State {
    requestGet: RequestStatusPattern<RequestResponsePattern<RelatorioPessoaType[]>>
    requestSave: RequestStatusPattern<RequestResponsePattern<RelatorioPessoaType>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<RelatorioPessoaType>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<RelatorioPessoaType>>
    requestFilter: RequestStatusPattern<RequestResponsePattern<RelatorioPessoaType[]>>

    lista: RelatorioPessoaType[] | null;
    listaSinonimo : any[] | null;
    filteredLista: RelatorioPessoaType[] | null;
    fieldDtInicio: string;
    fieldDtFim: string ;
    currentData: RelatorioPessoaType | null;
}

const initialState: State = {
    requestGet: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestSave: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestEdit: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestRemove: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestFilter: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    lista: null,
    listaSinonimo: null,
    filteredLista: [],
    fieldDtInicio: '',
    fieldDtFim: '',
    currentData: null,
}

const DefaultSlice = createSlice({
    name: 'Parametro',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<RelatorioPessoaType[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },

        setRequestSaveData: (state, action: PayloadAction<RequestResponsePattern<RelatorioPessoaType>>) => {
            state.requestSave.data = action.payload
            state.requestSave.loading = false
            state.requestSave.error = false
        },
        setRequestSaveLoading: (state) => {
            state.requestSave.loading = true
            state.requestSave.error = false
        },
        setRequestSaveError: (state) => {
            state.requestSave.loading = false
            state.requestSave.error = true
        },
        setRequestSaveMessage: (state, action: PayloadAction<string>) => {
            state.requestSave.message = action.payload
        },

        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<RelatorioPessoaType>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<RelatorioPessoaType>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },
        setRequestFilterData: (state, action: PayloadAction<RequestResponsePattern<RelatorioPessoaType[]>>) => {
            state.requestFilter.data = action.payload
            state.requestFilter.loading = false
            state.requestFilter.error = false
        },
        setRequestFilterLoading: (state) => {
            state.requestFilter.loading = true
            state.requestFilter.error = false
        },
        setRequestFilterError: (state) => {
            state.requestFilter.loading = false
            state.requestFilter.error = true
        },
        setRequestFilterMessage: (state, action: PayloadAction<string>) => {
            state.requestFilter.message = action.payload
        },

        setLista: (state, action: PayloadAction<RelatorioPessoaType[]>) => {
            state.lista = action.payload
        },
        setListaSinonimo: (state, action: PayloadAction<RelatorioPessoaType[]>) => {
            state.listaSinonimo = action.payload
        },
        setListaFiltered: (state, action: PayloadAction<RelatorioPessoaType[]>) => {
            state.filteredLista = action.payload
        },
        setFieldDtInicio: (state, action: PayloadAction<string>) => {
            state.fieldDtInicio = action.payload
        },
        setFieldDtFim: (state, action: PayloadAction<string>) => {
            state.fieldDtFim = action.payload
        },
        setCurrentData: (state, action: PayloadAction<RelatorioPessoaType>) => {
            state.currentData = action.payload
        }
    }
})

export const { 
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,
    setRequestSaveData, setRequestSaveLoading, setRequestSaveError, setRequestSaveMessage,
    setRequestEditData, setRequestEditLoading, setRequestEditError, setRequestEditMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,
    setRequestFilterData, setRequestFilterLoading, setRequestFilterError, setRequestFilterMessage,
    setLista, setFieldDtInicio, setListaFiltered, setCurrentData, setFieldDtFim,setListaSinonimo
} = DefaultSlice.actions

export default DefaultSlice.reducer