import { TextField, Select as SelectMUI, MenuItem } from '@material-ui/core'
import React, { useCallback } from 'react'
import {  mdiFilePdfBox, mdiMagnify, mdiMicrosoftExcel, mdiReload } from '@mdi/js'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import { Link } from 'react-router-dom'
import TableListarRelatorio from './components/TableListarRelatorio'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import { setAno, setIdFiltered } from './Reducers/RelatorioRankingReducer'
import filter from './scripts/filter'
import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'
import atualizar from './scripts/atualizar'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { CircleSpinner } from 'react-spinners-kit'

const RelatorioRanking: React.FC = () => {

  const dispatch = useDispatch()
  const {filteredLista ,requestFilter , ano, idFiltered}= useAppSelector(state => state.RelatorioRankingReducer)

    const handleFilter = useCallback(async () => {
        
        filter(dispatch,ano, idFiltered)
    }, [dispatch, ano,idFiltered])
 


const handleAtualizar = useCallback(async () => {
    await atualizar(dispatch,ano)
    await handleFilter()
    }, [dispatch, ano, handleFilter])

    const keyPress =(async (e:any) => {
        if(e.keyCode === 13){
            handleFilter()
         }
    }) 

  

    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {"RELAÇÃO DE INDÚSTRIAS INTERLIGADAS À ETEJ"}
                    padding = {false} 
                    right = {
                        <>
                            <TextField id="filterDescricao" variant="standard" type={"text"} className="fieldFilter" placeholder="Ano de consulta" onKeyPress={keyPress} defaultValue={ano} onChange={(e)=> dispatch(setAno(e.target.value as string))}  />

                            <SelectMUI id="filterAtivo" value={idFiltered} onChange={e => dispatch(setIdFiltered(e.target.value as any))}>
                                <MenuItem value={1}>Mínimo de Coletas</MenuItem>
                                <MenuItem value={2}>Coletas realizadas</MenuItem>
                                <MenuItem value={3}>Meta</MenuItem>
                            </SelectMUI>
                            <SectionOption icon = {mdiReload} tooltip = "Atualizar Dados" onClick = {handleAtualizar} />
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>

                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {defaultRelatorioExcel(filteredLista, 'Relatorio_ranking')}} />
                        </>
                    }
                >
                    {!!filteredLista && !requestFilter.loading &&
                    <>
                    <TableListarRelatorio
                    lista={filteredLista}
                    loading={requestFilter.loading}
                    />
                    </>
                    }

                    {(!!filteredLista && requestFilter.loading && filteredLista.length===0) && (
                        <TableRow >
                            <TableCell ><CircleSpinner size={32} color="grey" /></TableCell>
                        </TableRow>
                    )}
                </SectionBox>
            </Section>

        </>
    )
}

export default RelatorioRanking

