import React from 'react'

import Section, { SectionBox } from '../../../components/Section'

const Home: React.FC = () => {

    return(

        <>
            <Section name = "home">
                <SectionBox title = "Home">
                </SectionBox>
            </Section>
        </>

    )

}

export default Home