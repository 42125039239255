import React from 'react'
import { Link } from 'react-router-dom'

import Banner from '../../../../../assets/images/amico.png'

const InvalidLink: React.FC = () => {

    return(

        <div id="invalidContainer">
            <div id = "invalidBox">
                <h1>Link inválido</h1>
                <h2>O link que você tentou acessar expirou.</h2>
                <img src = {Banner} alt = "" />
                <Link to = "/">Ir para a home</Link>
            </div>
        </div>

    )

}

export default InvalidLink