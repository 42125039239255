import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"

const TableSection: React.FC = ({ children }) => {

    const styles = StyleSheet.create({
        row: {
            display: 'flex',
            flexDirection: "row",
            alignItems: "center",
            justifyContent: 'center',
            width: "100%",
            padding: '4px',
            marginBottom: '12px',
            border: "1px solid black",
        },
        text: {
            fontSize: '12px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })

    return(

        <View style = {styles.row}>
            <Text style = {styles.text}>{children}</Text>
        </View>

    )

}

export default TableSection