import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableHeader from './Table/header'

import TableBanner from './Table/banner'
import TableFooter from './Table/footer'
import TableSignature from './Table/signature'
import TableContent from './Table/content'

const RelatorioNaoConformidadePDF: React.FC = () => {

    const [cliente, setCliente] = useState<any[]>([])

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '60px',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (() => { setCliente(JSON.parse(localStorage.getItem('RelatorioNaoConformeLista')!)) })()
    }, [])
 

    return(

        <>
            { !!cliente && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        {cliente.map((item :any) => (
                        <Page size="A4" style={styles.page} break>
                                <TableBanner />
                                <TableHeader dados={item} />
                                <TableContent dados={item} />
                                <TableSignature/>
                                <TableFooter/>
                                
                        </Page>))}
                    </Doc>

                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioNaoConformidadePDF