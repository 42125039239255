import React, { useEffect } from 'react'
import { mdiFilePdfBox, mdiMicrosoftExcel } from '@mdi/js'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import getRelatorioRegiao from './scripts/getRelatorioRegiao'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import TableListarComponents from './Components/TableListarComponents'
import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'

const RelatorioRegiao: React.FC = () => {

    const dispatch = useAppDispatch()
    const { requestStatusGetRelatorioRegiao, relatorioRegiao } = useAppSelector(s => s.RelatorioRegiaoReducer)

    useEffect(() => {
        getRelatorioRegiao(dispatch)
    }, [dispatch])

    return(

        <>
            <Section name = "data">
                <SectionBox 
                    title = {`027_FMEI_Regiões Monitoradas`} 
                    padding = {false}
                    goBack
                    right={
                        <>
                        <Link to = "pdf" target = "_blank">
                            <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                        </Link>
                        <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {defaultRelatorioExcel(relatorioRegiao, 'Relatorio_regiao')}} />
                        </>
                    }
                >
                    <TableListarComponents
                        lista = {relatorioRegiao}
                        loading = {requestStatusGetRelatorioRegiao.loading}
                    />
                </SectionBox>
            </Section>
        </>

    )

}

export default RelatorioRegiao