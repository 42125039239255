import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setRequestFilterData,  setRequestGetError, setRequestGetMessage } from "../Reducers/RelatorioRankingReducer"
import { postApi } from "../../../../../utils/requestApi"
import { RelatorioRankingType } from "../types"

export default async function atualizar(dispatch: Function, values: any) {
    try {

        const endpoint = DOMAIN + `/RelatorioMonitoramento/anual/atualizar`
        const data = { ano: values }
        const response: RequestResponsePattern<RelatorioRankingType[]> = await postApi({ endpoint, data })
    if (response) {
        dispatch(setRequestFilterData(response))
        if (!response.flagErro) {


        } else throw new Error(response.listaMensagens[0])
    } else throw new Error('Erro na requisição')
} catch (error: any) {

    dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
    dispatch(setRequestGetError())
}
}