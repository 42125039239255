import React, {  useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import TableListar from '../../../Common/Components/TableListarDefaultComponents'

import Section, { SectionBox } from '../../../../components/Section'
import getProtocolos from './Scripts/get'
import loading from '../../../../components/Sidebar/loading'
import TableListarProtocolo from './Components/TableListarProtocolo'


const Protocolos: React.FC = () => {

    const dispatch = useDispatch()
    const { protocolos, requestGet} = useAppSelector(state => state.ProtocoloReducer)
    const { userData } = useAppSelector(state => state.auth)


    const API_CONTROLLER = "CategoriaCobranca"
    const PAGE_NAME = "Protocolos App"
    const SHOW_TABLE = !!protocolos

    useEffect(() => {
        (async () => {await getProtocolos(dispatch)})()
    }, [dispatch])  


    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {false} >
                    {protocolos && <TableListarProtocolo lista={ protocolos} loading = {requestGet.loading} />}
                </SectionBox>
            </Section>
            <>
            </>
        </>
    )
}

export default Protocolos
