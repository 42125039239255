import { setRequestFilterError, setRequestFilterMessage } from "../Reducers/VazaoLancamentoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import {  postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { resultadoVolume } from "../types"

export default async function getVolumecorrigido(dispatch: Function, data: any){
    try {

        const endpoint = `${DOMAIN}/RelatorioLeituraCobranca/Teste/Regra`
        const response: RequestResponsePattern<resultadoVolume> = await postApi({ endpoint, data })
        if(response){
            if(!response.flagErro) return response.listaResultados
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}