import React, { Fragment, useEffect } from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import Pagination from '../../../../../components/Pagination'
import getHistoricoColeta from '../scripts/getIndustriaMonitorada'
import getParametro from '../scripts/getParametro'
import { useDispatch } from 'react-redux'
import conformidade from '../scripts/conformidade'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

interface Props {
    id: number
    lista: any
    loading: boolean
}

const TableListarIndustriasMonitoradas: React.FC <Props> = ({ id, lista, loading }) => {

    const SHOW_TABLE = !!lista && !loading 
    const SHOW_LOADING = loading
    const dispatch = useDispatch()
    const final:any[] =[]
    const finalEmpresa:any[] =[]
    const naoConformidade:any[] =[]
    const parametros = [
        "DQO", "DBO", "pH", "temperatura", "Rs", "Og", "N", "P", "Cd", "Cu", 'Cr', "Ni","Pb","Zn","Ag",'As_',"Hg","Se", "Sn", "Fe", "Sulfato", "Sulfeto", "Fenol", "Fluoreto","Cr6","CN","Toxicidade"]
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)

    useEffect(() => {
        (async () => {await getParametro("Parametro", dispatch)})()
    }, [dispatch]) 
 
    useEffect(() => {
        if(final.length > 0) {
            localStorage.setItem("flagVermelho", JSON.stringify(naoConformidade))
            localStorage.setItem("MonitoramentoIndustria", JSON.stringify(finalEmpresa))
            localStorage.setItem("paramsTabela", JSON.stringify(final))
        }
    }) 
    
    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0 || Number(value)=== 0 ? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    const handleUnidade = (value: string ) => {
        if (value.toUpperCase() === 'PH' || value.toUpperCase() === '°C' || value.toUpperCase() === 'TOXICIDADE') return value
        if(value.toUpperCase() === 'SS') return `${value} ml/L`
        return `${value} mg/L`
    }

    return(
        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow >
                        {['Indústria', 'Logradouro', 'CNPJ', 'Coleta'].map((item, index) => <TableCell key = {index}>{item}</TableCell>)}
                        {parametros.map((item, index) => {
                            let label = item
                            if (item === 'temperatura') label = '°C'
                            if (item === 'As_') label = 'As'
                            if (item === 'Rs') label = "SS"
                            return <TableCell key={index}>{`${handleUnidade(label)}`}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {SHOW_LOADING && (
                        <TableRow>
                            <TableCell colSpan = {30}>
                                <div className = "center">
                                    <CircleSpinner size = {32} color = "grey" />
                                </div>
                            </TableCell>
                        </TableRow>
                    )}
                    {SHOW_TABLE && lista.map((item :any, index:any) => (
                        <TableRow key = {index} className = {selectedLine?.id === item.id ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                            <TableCell style={{fontSize:"12px"}}>{item.nome}</TableCell>
                            <TableCell style={{fontSize:"12px"}}>{`${item.enderecoCompleto  && `${item.enderecoCompleto.split("-")[0]}-${item.enderecoCompleto.split("-")[1]}`} `}</TableCell>
                            <TableCell style={{fontSize:"12px"}}>{item.nroDocumento}</TableCell>
                            <TableCell style={{fontSize:"12px"}}>{new Date (item.dtExecucao).toLocaleDateString()}</TableCell>
                            {Object.keys(item).map((key, index) => {
                                if (parametros.includes(key)) return  <TableCell  style={{fontSize:"12px"}} className={conformidade(key,(item as any)[key])} key={index}>{handleValue((item as any)[key])}</TableCell>
                                return <Fragment key = {index} />
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div>
                {lista && lista.paginas > 0 && <Pagination pages = {lista.paginas} id = {id} onClick = {getHistoricoColeta} />}
            </div>
        </TableContainer>
    )

}

export default TableListarIndustriasMonitoradas