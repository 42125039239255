import { Text, View } from '@react-pdf/renderer'
import React from 'react'

const TableSignature: React.FC = () => {

    return(

        <View break style = {{position: 'absolute' , width: '30%', borderTop: '1px solid black', left: '65%', bottom: '100px'}} wrap={false}>
            <Text style = {{marginBottom: '20px', fontSize: '10px'}}>FABIO HENRIQUE ERCOLIN</Text>
            <Text style = {{fontSize: '10px'}}>Coordenador de Monitoramento CRQ: 04472326</Text>
            <Text style = {{fontSize: '10px'}}>fabio@saneamento.com.br</Text>
        </View>



    )

}

export default TableSignature