import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'


import TableRow from './Table/row'
import TableBanner from './Table/banner'
import { RelatorioDbo } from '../../Types'
import TableFooter from './Table/footer'

const RelatorioDBOPdf: React.FC = () => {

    const [relatorio, setRelatorio] = useState<RelatorioDbo[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            backgroundColor: '#FFFFFF',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '60px',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setRelatorio(JSON.parse(localStorage.getItem('RelatorioDboAtualizacao')!)) })()
    }, [])

    return(

        <>
            {!!relatorio && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} break>
   
                                <TableBanner />
                                <TableRow relatorio={relatorio}/>
                                <TableFooter/>

                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioDBOPdf