import { DOMAIN } from '../../var_env.'

import { RequestResponsePattern } from '../../types/Request'
import { ModuloType } from '../../types/Modules'

import { setModules, setRequestModulesData, setRequestModulesError, setRequestModulesLoading, setRequestModulesMessage } from '../../store/reducers/modules/modulesReducer'

import { getApi } from '../../utils/requestApi'
import toResponsePattern from '../../utils/toResponsePattern'

export default async function getAppModules(dispatch: Function, reload: boolean){
    try {
        dispatch(setRequestModulesLoading())
        console.log(reload)
        const sessionModules = sessionStorage.getItem('modules')
        if(!!sessionModules && !reload){
            const modules: ModuloType[] = JSON.parse(sessionModules)
            dispatch(setRequestModulesData(toResponsePattern<ModuloType[]>(modules)))
            dispatch(setModules(modules))
        }else{

            const endpoint = DOMAIN
            const params = `/Modulo`
            const result: RequestResponsePattern<ModuloType[]> = await getApi({ endpoint, params })

            if(result){
                dispatch(setRequestModulesData(result))
                if(!result.flagErro){
                    sessionStorage.setItem('modules', JSON.stringify(result.listaResultados))
                    dispatch(setModules(result.listaResultados))
                }else throw new Error(result.listaMensagens[0])
            }else throw new Error('Não foi possível obter os módulos')
        }
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestModulesMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestModulesError())
    }
}