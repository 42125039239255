import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setLista, setRequestGetData, setRequestGetError, setRequestGetMessage } from "../Reducers/RelatorioDBOReducer"
import { getApi } from "../../../../../utils/requestApi"


export default async function get(dispatch: Function){
    try {

        const endpoint = DOMAIN
        const params = `/relatorioAtualizacaoDBO/getLista`
        const response: RequestResponsePattern<any[]> = await getApi({ endpoint, params })
        console.log(response)
        if(response){
            dispatch(setRequestGetData(response))
            if(!response.flagErro) {
                dispatch(setLista(response.listaResultados))
                localStorage.setItem('ramoAtividade', JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}