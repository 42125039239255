import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { RelatorioPontoColeta } from '../types'
import TableContainer from '@mui/material/TableContainer'
import List, { ListColumn, ListRow, ListSection } from '../../../../../components/List'


interface Props {
    lista: RelatorioPontoColeta[] | null
    loading: boolean
}

const TableListarRelatorio: React.FC<Props> = ({ lista, loading }) => {

    const SHOW_DATA = !!lista && !loading && lista.length>0
    const SHOW_LOADING = loading

    const grids = "80px 1fr 150px  140px"
    return (
        <TableContainer sx={{ maxHeight: 640 }}>
            <List name="clients">
                                
                <ListSection >
                    <ListRow grid={grids}>
                        {SHOW_DATA && [ "Código","CLIENTE", "PONTO DE COLETA"].map((column, index) => <ListColumn text={column}/>)}
                    </ListRow>
                </ListSection>

                <ListSection >
                    {SHOW_LOADING && (
                        <ListRow grid={grids} >
                            <ListColumn >
                            <div className = "center">
                                    <CircleSpinner size = {32} color = "grey" />
                                </div>
                            </ListColumn>
                        </ListRow>
                    )}
                    {SHOW_DATA && lista.map((item, index) => (
                        <ListRow grid={grids} zebra= {(index%2===0)}  >
                            <ListColumn text={item.codigo}  />
                            <ListColumn text={item.nome} /> 
                            <ListColumn text={item.descricaoPontoColeta}  />
                        </ListRow>
                    ))}
                </ListSection>
            </List>
            
        </TableContainer>
    )

}

export default TableListarRelatorio