import React, { useEffect, useState } from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import conformidadePDF from '../../../scripts/conformidadePDF'


const TableContent: React.FC<{dados:any}> = ({dados}) => {


    const [listaParametros, setListaParametros] = useState<string[]>([])
    const styles = StyleSheet.create({
        text: {
            fontSize: "8px",
            paddingTop: '15px'
        },
        textNegrito: {
            fontSize: 8,
            paddingTop: '15px',
            fontWeight: 1000
        },

    })


    useEffect(() => {
        (() => { 
            const localDados = dados


            const localParametros :string[]= []

            for (const key in localDados) {
                const result = conformidadePDF(key,(localDados as any)[key])
                if(result.length>0) localParametros.push(result)
            }

            setListaParametros(localParametros)

        
        })()
    }, [dados])

    return (

        <>


            {dados &&
                <View style={{marginTop: '10px'}}>
                    <Text style={styles.text}>Prezados Senhores,</Text>
                    <Text style={styles.text}>A CSJ, Compahia de Saneamento de Jundiaí, concessionária responsável pela operação da ETEJ, Estação de Tratamento de Esgoto de
                        Jundiaí, entre suas atribuições tem o PROGRAMA DE MONITORAMENTO DE ESGOTO INDUSTRIAL, com a finalidade de avaliar e fiscalizar a
                        emissão de efluentes em rede pública coletora de esgotos.</Text>
                    <Text style={styles.text}>
                        Os limites de aceitação dos efluentes destinados à ETEJ são baseados nos padrões de emissão preconizados no Artigo 19A da Lei
                        Estadual n° 997 de 31 de maio de 1976, regulamentada pelo Decreto Estadual 8.468 de 08/09/1976 e suas alterações
                    </Text>
                    <Text style={styles.text}>Conforme amostragem do descarte gerado pelas atividades produtivas da empresa, apurou-se NÃO CONFORMIDADE.</Text>


                    <View style={{ marginTop : '30px'}}>
                        <Text style={styles.textNegrito}> DATA DA AMOSTRAGEM: {new Date(dados.dtExecucao).toLocaleDateString()} </Text>
                        <Text style={styles.textNegrito}> PARÂMETROS NÃO CONFORMES: {listaParametros.join(' , ')} </Text>
                    </View>
                </View>
            }
        </>


    )

}

export default TableContent