import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/CargasReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

//import { FilterParams } from "../../Parametro/Types/ClienteType"

export default async function filter(dispatch: Function, values: any) {
    try {

        localStorage.setItem('dataBuscaCarga', JSON.stringify({inicio:values.dtInicio, fim: values.dtFim}))
        dispatch(setRequestFilterLoading())
        const response: RequestResponsePattern<any> = await getApi({ endpoint: `${DOMAIN}/RelatorioMonitoramentoCarga`, params: `/?dtInicio=${values.dtInicio}&dtFim=${values.dtFim}` });



        if (response) {
            dispatch(setRequestFilterData(response))
            localStorage.setItem("MonitoramentoCargas", JSON.stringify(response.listaResultados))


            if (!response.flagErro) {
                console.log(response.listaResultados)
                dispatch(setListaFiltered(response.listaResultados as any))
            }
            else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}