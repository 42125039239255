import { TextField } from '@material-ui/core'
import React, { useCallback } from 'react'
import { mdiFilePdfBox, mdiMagnify, mdiMicrosoftExcel } from '@mdi/js'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import { Link } from 'react-router-dom'
import TableListarRelatorio from './components/TableListarRelatorio'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import { setFieldDtFim, setFieldDtInicio } from './Reducers/RelatorioParametroReducer'
import filter from './scripts/filter'
import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'

const RelatorioParametro: React.FC = () => {

    const dispatch = useDispatch()
    const { filteredLista, requestFilter, fieldDtInicio, fieldDtFim } = useAppSelector(state => state.RelatorioParametroReducer)



    const handleFilter = useCallback(async () => {
        filter(dispatch, { dtInicio: fieldDtInicio, dtFim: fieldDtFim })
    }, [dispatch, fieldDtInicio, fieldDtFim])



    const keyPress = (async (e: any) => {
        if (e.keyCode === 13) {
            handleFilter()
        }
    })

    return (
        <>
            <Section name="NameSection">
                <SectionBox
                    title={"PARÂMETROS ANALISADOS"}
                    padding={false}
                    right={
                        <>
                            <TextField id="filterDescricao" variant="standard" type={"date"} className="fieldFilter" placeholder="Inicio" onKeyPress={keyPress} defaultValue={fieldDtInicio} onChange={(e) => dispatch(setFieldDtInicio(e.target.value as string))} />
                            <TextField id="filterDescricao" variant="standard" type={"date"} className="fieldFilter" placeholder="Fim" onKeyPress={keyPress} defaultValue={fieldDtFim} onChange={(e) => dispatch(setFieldDtFim(e.target.value as string))} />
                            <SectionOption icon={mdiMagnify} tooltip="Filtrar" onClick={handleFilter} />
                            <Link to="pdf" target="_blank">
                                <SectionOption icon={mdiFilePdfBox} tooltip="Visualizar PDF" onClick={() => { }} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {defaultRelatorioExcel(filteredLista, 'relatorio_parametro')}} />
                        </>
                    }
                >
                    {!!filteredLista && !requestFilter.loading &&
                        <>
                            <TableListarRelatorio
                                lista={filteredLista}
                                loading={requestFilter.loading}
                            />
                        </>
                    }
                </SectionBox>
            </Section>

        </>
    )
}

export default RelatorioParametro

