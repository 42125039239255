import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/auth/authReducer'
import modulesReducer from './reducers/modules/modulesReducer'
import RamoAtividadeReducer from '../pages/system/Pessoas/RamoAtividade/Reducers/RamoAtividadeReducer'
import themeReducer from './reducers/theme/themeReducer'
import ClientsReducer from '../pages/system/Pessoas/Cliente/Reducers/ClientsReducer'
import UsuarioReducer from '../pages/system/Permissoes/Reducers/UsuarioReducer'
import GrupoAcessoReducer from '../pages/system/Permissoes/Reducers/GrupoAcessoReducer'
import DefaultReducer from './reducers/default/DefaultReducer'
import AmostradorReducer from './reducers/default/AmostradorReducer'
import ParametroReducer from '../pages/system/Pessoas/Parametro/Reducers/ParametroReducer'
import alertReducer from './reducers/alert/alertReducer'
import layoutReducer from './reducers/layout/layoutReducer'
import EnderecoReducer from '../pages/system/Pessoas/Cliente/Reducers/EnderecoReducer'
import VazaoLancamentoReducer from '../pages/system/Pessoas/VazaoLancamento/Reducers/VazaoLancamentoReducer'
import PessoaHidrometroReducer from '../pages/system/Pessoas/Cliente/Reducers/PessoaHidrometroReducer'
import ClienteDadosReducer from '../pages/system/Pessoas/Cliente/Reducers/ClienteDadosReducer'
import ClienteContatoReducer from '../pages/system/Pessoas/Cliente/Reducers/ClienteContatoReducer'
import HistoricoAnaliseReducer from '../pages/system/Pessoas/Cliente/Reducers/HistoricoAnaliseReducer'
import UploadArquivoReducer from '../pages/system/Importacao/ImportacaoAnalise/Reducer/UploadArquivoReducer'
import RelatorioParametroReducer from '../pages/system/Pessoas/RelatorioParametro/Reducers/RelatorioParametroReducer'
import MonitoramentoReducer from '../pages/system/Pessoas/IndustriasMonitoradas/Reducers/MonitoramentoReducer'
import CargasReducer from '../pages/system/Pessoas/MonitoramentoCargas/Reducers/CargasReducer'
import RelatorioPessoaReducer from '../pages/system/Pessoas/RelatorioPessoa/Reducers/RelatorioPessoaReducer'
import RelatorioRankingReducer from '../pages/system/Pessoas/RelatorioRanking/Reducers/RelatorioRankingReducer'
import RelatorioPontoColetaReducer from '../pages/system/Pessoas/RelatorioPontoColeta/Reducers/RelatorioPontoColetaReducer'
import RelatorioRegiaoReducer from '../pages/system/Pessoas/RelatorioRegiao/Reducers/RelatorioRegiaoReducer'
import AtualizacoDboReducer from '../pages/system/Pessoas/AnaliseDBO/Reducers/AtualizacoDboReducer'
import HistoricoLancamentoReducer from '../pages/system/Pessoas/VazaoHistoricos/Reducers/HistoricoLancamentoReducer'
import MonAnualReducer from '../pages/system/Pessoas/Cliente/Reducers/MonAnualReducer'
import RelatorioSequencialReducer from '../pages/system/Pessoas/RelatorioSequencial/Reducers/RelatorioSequencialReducer'
import RelatorioDBOReducer from '../pages/system/Pessoas/RelatorioDBO/Reducers/RelatorioDBOReducer'
import logUserReducer from '../pages/system/Pessoas/LogUsuario/Reducers/logUserReducer'
import NovasColetasReducer from '../pages/system/Pessoas/AtualizacaoColetaManual/Reducers/NovasColetasReducer'
import UploadArquivoImportacaoReducer from '../pages/system/Importacao/ImportacaoMylims/Reducer/UploadArquivoImportacaoReducer'
import RelaotioNaoConformidadeReducer from '../pages/system/Pessoas/RelatorioNaoConformidade/Reducers/MonitoramentoReducer'
import ProtocoloReducer from '../pages/system/App/Protocolo/Reducers/ProtocoloReducer'

const store = configureStore({
    reducer: {
        auth: authReducer,
        modules: modulesReducer,
        theme: themeReducer,
        RamoAtividadeReducer: RamoAtividadeReducer,
        ClientsReducer: ClientsReducer,
        UsuarioReducer: UsuarioReducer,
        GrupoAcessoReducer: GrupoAcessoReducer,
        DefaultReducer: DefaultReducer,
        AmostradorReducer: AmostradorReducer,
        ParametroReducer: ParametroReducer,
        alert: alertReducer,
        layout: layoutReducer,
        EnderecoReducer: EnderecoReducer,
        VazaoLancamentoReducer: VazaoLancamentoReducer,
        PessoaHidrometroReducer: PessoaHidrometroReducer,
        ClienteDados: ClienteDadosReducer,
        ClienteContato: ClienteContatoReducer,
        HistoricoAnaliseReducer:HistoricoAnaliseReducer,
        UploadArquivoReducer: UploadArquivoReducer,
        UploadArquivoImportacaoReducer: UploadArquivoImportacaoReducer,
        RelatorioParametroReducer:RelatorioParametroReducer,
        RelatorioPessoaReducer:RelatorioPessoaReducer,
        MonitoramentoReducer: MonitoramentoReducer,
        CargasReducer: CargasReducer,
        RelatorioRankingReducer: RelatorioRankingReducer,
        RelatorioPontoColetaReducer:RelatorioPontoColetaReducer,
        RelatorioRegiaoReducer: RelatorioRegiaoReducer,
        AtualizacoDboReducer: AtualizacoDboReducer,
        HistoricoLancamentoReducer: HistoricoLancamentoReducer,
        MonAnualReducer:MonAnualReducer,
        RelatorioSequencialReducer:RelatorioSequencialReducer,
        RelatorioDBOReducer: RelatorioDBOReducer,
        logUserReducer:logUserReducer,
        NovasColetasReducer:NovasColetasReducer,
        RelaotioNaoConformidadeReducer: RelaotioNaoConformidadeReducer,
        ProtocoloReducer: ProtocoloReducer
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store