import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import {  setListaFiltered, setRequestFilterData, setRequestFilterLoading, setRequestGetError, setRequestGetMessage } from "../Reducers/RelatorioParametroReducer"
import { getApi } from "../../../../../utils/requestApi"
import { RelatorioParametroType } from "../types"


export default async function filter(dispatch: Function, values:any){
    try {
        dispatch(setRequestFilterLoading())

        const endpoint = DOMAIN
        const params = `/ParametroRelatorio/?dtInicio=${values.dtInicio}&dtFim=${values.dtFim}`
        const response: RequestResponsePattern<RelatorioParametroType[]> = await getApi({ endpoint, params })
        console.log(values)
        
        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) {
                dispatch(setListaFiltered(response.listaResultados))
                localStorage.setItem('relatorioParametro', JSON.stringify(response.listaResultados))
                localStorage.setItem('dataBusca', JSON.stringify({inicio:values.dtInicio, fim: values.dtFim}))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}