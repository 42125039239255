import React, { ChangeEvent, useEffect,useCallback } from 'react'
import { mdiFilePdfBox, mdiMicrosoftExcel } from '@mdi/js'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import { Link } from 'react-router-dom'
//import TableListarRelatorio from './components/TableListarRelatorio'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import filter from './scripts/filter'
//import { defaultRelatorioExcel } from '../../../../scripts/defaultRelatorioExcel'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import excelPessoa from './scripts/excelPessoa'

const RelatorioPessoa: React.FC = () => {

    const dispatch = useDispatch()
    const { filteredLista } = useAppSelector(state => state.RelatorioPessoaReducer)

    useEffect(() => {
        filter(dispatch)
    }, [dispatch])  

    const handleEditarCheck = useCallback((Checkbox: ChangeEvent<HTMLInputElement>) => {
 
        if(Checkbox.target.checked) { 
            filter(dispatch, false)
            
        }else{
            filter(dispatch)
        }


        
    }, [dispatch])

    return (
        <>
            <Section name="NameSection">
                <SectionBox
                    title={"051_FMEI_Clientes"}
                    padding={false}
                    right={
                        <>
                        {filteredLista!.length===0 && <p>Carregando Clientes...</p> }
                            {filteredLista!.length>0 &&  
                                <>
                                <Link to="pdf" target="_blank">
                                <SectionOption icon={mdiFilePdfBox} tooltip="Visualizar PDF" onClick={() => { }} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {filteredLista && excelPessoa(filteredLista)}} />
                                </>
                        }
                            <FormControlLabel control={<Checkbox className='sectionBoxHeaderOption' defaultChecked={false}  onChange={(e) => handleEditarCheck(e)}  />} label={`Desabilitadas`} />
                        </>
                    }
                >
                    {/*!!filteredLista && !requestFilter.loading &&
                        <>
                            <TableListarRelatorio
                                lista={filteredLista}
                                loading={requestFilter.loading}
                            />
                        </>
                */}
                </SectionBox>
            </Section>

        </>
    )
}

export default RelatorioPessoa

