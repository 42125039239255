import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiPencil } from '@mdi/js'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'
import { setCurrentData } from '../../../../../store/reducers/default/AmostradorReducer'
import { AmostradorType } from '../../../../../store/types/AmostradorType'

interface Props {
    lista: AmostradorType[] | null
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
}
    
const TableListarAmostradorComponents: React.FC<Props> = ({ lista, loading, setOpenEditar, setOpenRemover }) => {

    const dispatch = useDispatch()

    const grid = "100px repeat(4, 1fr) 70px"

    const SHOW_TABLE = !!lista && !loading
    const SHOW_LOADING = loading

    const handleEdit = useCallback((item: AmostradorType) => {
        dispatch(setCurrentData(item))
        setOpenEditar(true)
    }, [dispatch, setOpenEditar])

    const handleRemove = useCallback((item: AmostradorType) => {
        dispatch(setCurrentData(item))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    return(
    
        <>
            <List name = "list">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["ID", "Código", "Amostrador", "Aquisição", "Situação" ,"Opções"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow key = {item.id} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.id}</ListColumn>
                            <ListColumn>{item.codigo}</ListColumn>
                            <ListColumn>{item.descricao}</ListColumn>
                            <ListColumn>{new Date(item.dtAquisicao).toLocaleDateString()}</ListColumn>
                            <ListColumn>{item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado'}</ListColumn>
                            <ListColumn>
                                <ListOption icon = {mdiPencil} onClick = {() => handleEdit(item)} />    
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemove(item)} />
                            </ListColumn>
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>
    )
}

export default TableListarAmostradorComponents