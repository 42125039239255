import { setHistoricoColeta, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/MonitoramentoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

//import { FilterParams } from "../../Parametro/Types/ClienteType"
    
export default async function filter(dispatch: Function, values: any){
    try {
        dispatch(setRequestFilterLoading())
        const response: RequestResponsePattern<any> = await getApi({ endpoint: `${DOMAIN}/HistoricoColeta/Industria`, params: `/?dtInicio=${values.dtInicio}&dtFim=${values.dtFim}` });
        
        if(response){
            dispatch(setRequestFilterData(response))
            localStorage.setItem("MonitoramentoIndustria", JSON.stringify(response.listaResultados))
            localStorage.setItem("IntervaloDeTempo", JSON.stringify({Inicio: values.dtInicio, Fim: values.dtFim}))
            
            if(!response.flagErro) dispatch(setHistoricoColeta(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}