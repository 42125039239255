import { Text, View, StyleSheet } from '@react-pdf/renderer'
import React from 'react'

const TableFooter: React.FC = () => {

    const styles = StyleSheet.create({
        container: {
            position: 'absolute',
            bottom: '0',
            left: '20px',
            right: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            width: '100%',
            borderTop:"1px solid black"
        },
        leftText: {
            fontSize: '8px',
        },
        rightText: {
            fontSize: '8px',
            fontWeight: 'bold',
        }
    })
 
    return(
<>
<View style = {{marginTop: '34px', width: '40%',}}>
            <Text style = {{marginBottom: '16px', fontSize: '10px', borderTop: '1px solid black'}}>FÁBIO HENRIQUE ERCOLIN </Text>
            <Text style = {{fontSize: '10px'}}>Coordenador de Monitoramento Industrial</Text>
            <Text style = {{fontSize: '10px'}}>fabio@saneamento.com.br </Text>
            <Text style = {{fontSize: '10px'}}>monitoramento@saneamento.com.br</Text>
        </View>

        <View style = {{marginTop: '48px'}}>
            <Text style = {{borderBottom: '1px solid black',marginTop: '5px', marginBottom: '5px', fontSize: '8px'}}><b>OBSERVAÇÕES:</b> </Text>
            <Text style = {{marginTop: '5px', marginBottom: '5px', fontSize: '8px'}}>1) A quantidade de coletas por indústria obedece à Norma Técnica NT 001/DAE-Jundiaí;</Text>
            <Text style = {{marginBottom: '5px', fontSize: '8px'}}>2) "Resultados Conforme" são aqueles que atendem ao artigo 19A do Decreto Estadual n° 8.468 que regulamenta a Lei 997 de 1976;</Text>
            <Text style = {{marginBottom: '5px', fontSize: '8px'}}>3) Como ação corretiva os resultados "Não Conforme" são comunicados à CETESB e a DAE S/A.</Text>
        </View>

        <Text fixed wrap={false} style={{ bottom: '15px',fontSize:'8px', position: 'absolute', left: '40%', marginTop: '48px'}}>012_FMEI Rev.07 - Apr. Nov/23</Text>
<View style = {styles.container} fixed>
<Text style = {styles.rightText} render={({ pageNumber, totalPages }) => (`pág ${pageNumber} de ${totalPages}`)} fixed />
</View>
</>

    )

}

export default TableFooter