import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"

const TableHeader: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '8px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })

    const Column: React.FC <{text: string, width: string, last?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text wrap>{text}</Text>
        </View>
    )

    return(

        <>
        

    
        <View style = {styles.row}>
            <Column text = "#" width = "10%" />
            <Column text = "INDÚSTRIA" width = "50%"  />
            <Column text = "RAMO DE ATIVIDADE" width = "30%"  />
            <Column text = "ESGOTO MENSAL MÉDIO ANO ANTERIOR (m³)" width = "20%" />
            <Column text = "DBO EM DEZEMBRO ANO ANTERIOR (Kg/m³)" width = "20%" />
            <Column text = "CARGA ORGÂNICA DIÁRIA (Kg/DBO)" width = "20%" />
            <Column text = "MÍNIMO DE COLETAS" width = "15%" />
            <Column text = "COLETAS REALIZADAS" width = "15%" />
            <Column text = "META" width = "15%" last/>
            <Column text = "REGRA DE COLETA" width = "20%" last/>
        </View>


        </>

    )

}

export default TableHeader