import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Formik, Form as FormikContainer } from 'formik'
import { useAppSelector } from '../../../../../store/hooks'
import { SectionBoxData } from '../../../../../components/Section'
import getRamoAtividadeEspecifica from '../Scripts/getRamoAtividadeEspecifica'
import Form from '../../../../../components/Form'
import Select from '../../../../../components/Select'
import sendRamoAtividade from '../Scripts/sendRamoAtividade'
import sendMailTeste from '../Scripts/sendMailTeste'

const TabDados: React.FC = () => {

    const { idRamo } = useParams<any>()

    const dispatch = useDispatch()
    const { userData } = useAppSelector(state => state.auth)
    const { ramoAtividade, requestSaveRamoAtividade } = useAppSelector(state => state.RamoAtividadeReducer)

    const [flagAtivo, setFlagAtivo] = useState<string>('1')

    const SHOW_DATA = !!ramoAtividade

    const handleSave = useCallback(async (values: {descricao: string, corpoEmail: string}) => {
        await sendRamoAtividade(dispatch, { ...values, flagAtivo: Number(flagAtivo), idRamo: Number(idRamo), idUsuario: userData!.id })
    }, [dispatch, userData, flagAtivo, idRamo])

    const enviar = useCallback(async (email: string) => {
        await sendMailTeste(dispatch, email )
    }, [dispatch])


    useEffect(() => {
        (async() => {await getRamoAtividadeEspecifica(dispatch, idRamo!)})()
    }, [dispatch, idRamo, requestSaveRamoAtividade.data])

    return(

        <>
            {SHOW_DATA && (
                <>
                    <Formik
                        initialValues = {{descricao: ramoAtividade.descricao, corpoEmail: ramoAtividade.corpoEmailVisitaRealizada}}
                        onSubmit = {values => handleSave(values)}
                    >
                        <FormikContainer className = "formContainer">
                            <Form.Container padding = {false}>
                                <SectionBoxData title = "Inforamções">
                                    <Form.Row columns = {2}>
                                        <Form.Group inputID = "descricao" inputName = "descricao" label = "Descrição" />
                                        <Select 
                                            type = "outline" 
                                            defaultValue = {ramoAtividade.flagAtivo.toString()}
                                            value = {flagAtivo} 
                                            setValue = {setFlagAtivo} 
                                            label = "Habilitado" 
                                            items = {[
                                                {value: '1', label: 'Sim'},
                                                {value: '0', label: 'Não'}
                                            ]}
                                        />  
                                    </Form.Row>
                                    <Form.Row columns = {1}>
                                        <Form.Group inputID = "corpoEmail" inputName = "corpoEmail" inputType='textarea' label = "Config. Mensagens de E-mails" inputRows = {10} />
                                    </Form.Row>
                                </SectionBoxData>
                                <div className = "formActionsContainer">
                                    <button type = "submit" className = {`default ${requestSaveRamoAtividade.loading ? 'status disabled' : ''}`}>{requestSaveRamoAtividade.loading && <div className = "spinner" />}Salvar</button>
                                </div>
                            </Form.Container>
                        </FormikContainer>
                    </Formik>


                </>

            )}
                                <Formik
                        initialValues = {{email:''}}
                        onSubmit = {values => (enviar(values.email))}
                    >
                        <FormikContainer className = "formContainer">
                            <Form.Container padding = {false}>
                                <SectionBoxData title = "Envio de email">
                                    <Form.Row columns = {2}>
                                    <Form.Group inputID = "email" inputName = "email"  label = "email para testes"  />
                                    <div className = "formActionsContainer">
                                    <button type = "submit" >Enviar</button>
                                </div>
                                    </Form.Row>
                                </SectionBoxData>
                                
                            </Form.Container>
                        </FormikContainer>
                    
                        </Formik>
        </>
        

    )

}

export default TabDados