import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import {mdiFilePdfBox, mdiMagnify  } from '@mdi/js'
import { useDispatch } from 'react-redux'
import './styles.scss'
import { useAppSelector } from '../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import getIndustriaMonitorada from './scripts/getIndustriaMonitorada' 
import TableListarRelatorioNaoConformidade from './Components/TableListarRelatorioNaoConformidade'
import { Link } from 'react-router-dom'
import { TextField } from '@material-ui/core'
import {setAnoInicial, setAnoFinal } from './Reducers/MonitoramentoReducer'
import filter from './scripts/filter'


const RelatorioNaoConformidade: React.FC = () => {    

    const { idCliente } = useParams<any>()
    const dispatch = useDispatch()
    const {anoInicial, anoFinal, requestFilter, historicoColeta } = useAppSelector(state => state.RelaotioNaoConformidadeReducer)

    useEffect(() => {
        getIndustriaMonitorada(dispatch, Number(idCliente), 1)
    }, [dispatch, idCliente])

    const handleFilter = useCallback(async () => {
        await filter(dispatch, { dtInicio:anoInicial , dtFim: anoFinal})
    }, [dispatch,anoInicial, anoFinal ])

    return(
        <> 
            <Section name = "data">
                <SectionBox 
                    title = {`053_FMEI_Relatório de Não Conformidades`} 
                    goBack
                    padding = {false}
                    right={
                        <>
                            <TextField type="date" id="filterAnoInicial" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoInicial} onChange={e => dispatch(setAnoInicial(e.target.value))}/>
                            <TextField type="date" id="filterAnoFinal" variant="standard" className="fieldFilter" placeholder="Cliente" value={anoFinal} onChange={e => dispatch(setAnoFinal(e.target.value))}/>
                            
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {localStorage.setItem("RelatorioNaoConformeLista", JSON.stringify(historicoColeta))}} />
                            </Link>
                        </>
                    }
                >
                    <TableListarRelatorioNaoConformidade 
                        id = {Number(idCliente)}
                        lista = { historicoColeta} 
                        loading = {requestFilter.loading} 
                    />
                </SectionBox>
            </Section>
        </>
    )

}

export default RelatorioNaoConformidade