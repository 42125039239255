import { postApi } from "../../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../../var_env."
import { RequestResponsePattern } from "../../../../../../types/Request"
import { setRequestSaveData} from "../../Reducers/MonAnualReducer"
export default async function saveAnual(dispatch:Function, values: any, idCliente:any, Usuario:number){
    try {

        
        const endpoint = `${DOMAIN}/RelatorioMonitoramento/anual/inserir`
        const data = {
            idPessoa:idCliente,
            ano: values.ano,
            volume: Number(values.Volume.replace(",",".")),
            dboAtual: Number(values.DBOatual.replace(",",".")),
            cargaOrganica: Number(values.carga.replace(",",".")),
            qtdMinima: Number(values.qtde.split("-")[0]),
            regraColeta:values.qtde.split("-").at(-1), 
            coletas:values.coleta,
            Meta:values.meta,
            AnoUltimaColeta:values.UltimaColeta,
            idUsuario: Usuario
        }
        console.log(data)
        
         const response: RequestResponsePattern<any> = await postApi({ endpoint, data })
        // console.log(response)
         if(!response.flagErro){
             dispatch(setRequestSaveData(response))
         }
    } catch (error: any) {

    }
}