import { setRequestData, setRequestError, setRequestMessage } from "../Reducers/EnderecoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."


export default async function saveColor(dispatch: Function, data: any){
    try {

        
        const endpoint = `${DOMAIN}/HistoricoColeta/saveColor`

        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestData(response))
            if(!response.flagErro) {dispatch(setRequestData(response))}
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestError())
    }
}