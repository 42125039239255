import {  setRequestError, setRequestGetArquivoImportacaoData, setRequestLoading, setRequestMessage } from "../Reducer/UploadArquivoImportacaoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."

import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import createAlert from "../../../../../scripts/alert/createAlert"
import { uploadArquivoImportacao } from "../tipes"

export default async function saveFile(dispatch: Function, data: any){
    try {
        dispatch(setRequestLoading())
        
        const endpoint = `${DOMAIN}/UploadArquivo`
    
        const response: RequestResponsePattern<uploadArquivoImportacao> = await postApi({ endpoint, data })

        if(response){
            
            dispatch(setRequestGetArquivoImportacaoData(response))
            if(!response.flagErro){
                dispatch(setRequestGetArquivoImportacaoData(response))
                dispatch(setAlert(createAlert('success', 'Arquivo enviado com sucesso')))
                return response.listaResultados
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setRequestMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestError())
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
    }
}