import React, {useState, useCallback, Fragment} from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { NovasColetas } from '../types'
import TableListarComponents from './TableListarComponents'

interface Props {
    lista: NovasColetas[] | null
    loading: boolean
}

const AgrupamentoColetaComponent: React.FC <Props> = ({ lista, loading}) => {


  

    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !SHOW_LOADING  && lista.length>0
    const [listaIndex, setListaIndex] = useState<number[]>([])

    const handleEditarCheck = useCallback(( index: number) => {
        let novaListaId = [...listaIndex!]
 
         if(!listaIndex.includes(index)) { 
            novaListaId?.push(index)
            
         }
         else{
             const indexLista = listaIndex?.indexOf(index)
             novaListaId.splice(indexLista!, 1)
        //     //alert(novaListaId)
         }

        setListaIndex([...novaListaId!])
        
    }, [listaIndex])

    return(
      <>
            {SHOW_TABLE && 
            <>
        <div className="container">
        <div className="accordion" id="accordionPanelsStayOpenExample">

        {SHOW_TABLE &&  lista?.map((item, index) =>(
                        <Fragment key = {index}>
    <div className=" sectionBox">
    <h2 className=" sectionBoxHeader" id="panelsStayOpen-headingOne">
      
      <button className={`sectionBoxHeaderOption accordion-button ${listaIndex?.includes(index)? "collapsed":""}`} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded={"true"} aria-controls="panelsStayOpen-collapseOne"
      onClick={() => handleEditarCheck(index)}>
        {`${item.Cliente}`}
      </button>
    </h2>

    <div id="panelsStayOpen-collapseOne" className={`accordion-collapse collapse ${lista.length>0&& listaIndex?.includes(index)? "show":""}`} aria-labelledby="panelsStayOpen-headingOne">
      <div className="accordion-body">
      <TableListarComponents
              novasColetas = {item.coletas}
              loading = {loading}
          />
      </div>
    </div>
  </div>
  </Fragment>
        ))}


        </div>
    </div> </>}
    {
            SHOW_LOADING &&
            <div> 
            <CircleSpinner size = {32} color = "grey" />
            </div>
        }
      
      </>


    )

}

export default AgrupamentoColetaComponent