import { DOMAIN } from "../../../../../var_env."
import { RemoveParams } from "../types"
import { setRequestRemoveData, setRequestRemoveError, setRequestRemoveLoading, setRequestRemoveMessage } from "../Reducers/EnderecoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { deleteApi } from "../../../../../utils/requestApi"
import { EnderecoType } from "../../Parametro/Types/EnderecoType"

export default async function remove(dispatch: Function, values: RemoveParams){
    try {
        dispatch(setRequestRemoveLoading())
        
        const endpoint = `${DOMAIN}/PessoaEndereco/Delete/${values.id}`
        const params = { id: values.id, idUsuario: values.idUsuario }
        const response: RequestResponsePattern<EnderecoType> = await deleteApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestRemoveData(response))
            if(!response.flagErro) dispatch(setRequestRemoveData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestRemoveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestRemoveError())
    }
}