import React, {Fragment} from 'react'
import { mdiCheck, mdiClose } from '@mdi/js'
import  { Icon as MdiIcon } from '@mdi/react'
import { useDispatch } from 'react-redux'
import {Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { HistoricoColetaElement } from '../../Cliente/Types/HistoricoColeta'
import TableContainer from '@mui/material/TableContainer'

interface Props {
    novasColetas: HistoricoColetaElement[]
    loading: boolean
}

const TableListarComponents: React.FC <Props> = ({ novasColetas, loading }) => {

    const dispatch = useDispatch()
    const parametros = ['DQO', 'DBO', 'pH', 'temperatura', 'Ag', 'As_', 'Cd', 'CN', 'Cr', 'Cr6', 'Cu', 'Fe', 'Fenol', 'Hg', 'Ni', 'N', 'Pb', 'P', 'Se', 'Sn', 'Zn', 'Rs', 'Og', 'Sulfato', 'Sulfeto', 'Fluoreto', 'Toxicidade']
    const SHOW_LOADING = loading
    const SHOW_TABLE = !!novasColetas && !SHOW_LOADING

    const grid = "  110px  1fr 110px 110px 110px 110px 110px"

    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0  || Number(value)=== 0? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    const handleDate = (date: Date) => {
        const novaData = new Date(date.toISOString().replace(/\.\d{3}Z$/, ''))
        return new Date((novaData.getTime() - (1.08e7))).toLocaleString()
    }
    const handleUnidade = (value: string ) => {
        let unidadeNova = ''
        if (value.toUpperCase() === 'PH' || value.toUpperCase() === '°C' || value.toUpperCase() === 'TOXICIDADE') unidadeNova =  value
        else if(value.toUpperCase()==="SS") unidadeNova = `${value} ml/L`
        else unidadeNova = `${value} mg/L`
        if (!(value.toUpperCase() === 'PH' || value.toUpperCase() === '°C' || value.toUpperCase() === 'TOXICIDADE' || value.toUpperCase() === 'SS' || value.toUpperCase() === 'OG' || value.toUpperCase() === 'SULFETO' || value.toUpperCase() === 'SULFATO' || value.toUpperCase() === 'FENOL' 
        || value.toUpperCase() === 'FLUORETO' || value.toUpperCase() === 'CR6' || value.toUpperCase() === 'CN' )) unidadeNova = `${unidadeNova} *`
        return unidadeNova
    }

    const handleFormat = (valor: string ) => {
        if(valor==="-" || valor==="ND")return valor
        if(valor.includes('<')){
            valor = valor.replaceAll('<', '')
            const newValor = Number(valor.replace(",",".")).toLocaleString('pt-BR');
            return '<' + newValor
        }
        return Number(valor.replace(",",".")).toLocaleString('pt-BR');
    }


    return(

        <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {['N', 'P', 'Instalação', 'ΔT', 'Retirada', 'Q', 'CM', 'Chuva', 'Leitura Inicial', 'Leitura Final', 'Volume Medido '].map((item, index) => <TableCell key={index}>{item}</TableCell>)}
                        {parametros.map((item, index) => {
                            let label = item
                            if (item === 'temperatura') label = '°C'
                            if (item === 'As_') label = 'As'
                            if (item === 'Rs') label = "SS"
                            return <TableCell key={index}>{handleUnidade(label)}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {novasColetas.length>0 && novasColetas.map((item, index) => (
                        <TableRow key={index} className = { ''}  >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{item.pontoColeta}</TableCell>
                            <TableCell>{item.dtInstalaco ? handleDate(new Date(item.dtInstalaco)) : '-'}</TableCell>
                            <TableCell>{handleValue(item.tempoInstalacao)}</TableCell>
                            <TableCell>{item.dtRetirada ? handleDate(new Date(item.dtRetirada)) : '-'}</TableCell>
                            <TableCell>{handleValue(item.qtdeColeta)}</TableCell>
                            <TableCell>{item.codigoAmostra.trim() === "" || item.codigoAmostra===null ? '-': item.codigoAmostra}</TableCell>
                            <TableCell>
                                <MdiIcon className={`status ${item.flagChuva === 1 ? 'success' : 'error'}`} path={item.flagChuva === 1 ? mdiCheck : mdiClose} size="24px" />
                            </TableCell>
                            <TableCell>{handleFormat(handleValue(item.medidorInicial))}</TableCell>
                            <TableCell>{handleFormat(handleValue(item.medidorFinal))}</TableCell>
                            <TableCell>{handleFormat(handleValue(item.volumeMedido))}</TableCell>
                            {Object.keys(item).map((key, index) => {
                                if (parametros.includes(key)) {
                                        return <TableCell  key={index}>{handleValue((item as any)[key])}</TableCell>  
                                }
                                return <Fragment key = {index} />
                                
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </TableContainer>
    )

}

export default TableListarComponents