import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { RamoAtividadeEspecifica } from "../Types/RamoAtividadeEspecifica"
import { setRamoAtividade, setRequestGetRamoAtividadeData, setRequestGetRamoAtividadeError, setRequestGetRamoAtividadeLoading, setRequestGetRamoAtividadeMessage } from "../Reducers/RamoAtividadeReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getRamoAtividadeEspecifica(dispatch: Function, idRamo: string){
    try {
        dispatch(setRequestGetRamoAtividadeLoading())

        const endpoint = DOMAIN
        const params = `/RamoAtividade/${idRamo}`
        const response: RequestResponsePattern<RamoAtividadeEspecifica> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestGetRamoAtividadeData(response))
            if(!response.flagErro) dispatch(setRamoAtividade(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetRamoAtividadeMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetRamoAtividadeError())
    }
}