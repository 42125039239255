import { RelatorioPessoaType } from "../types";
import { WorkSheet, utils, writeFile } from 'xlsx';
export default function excelPessoa(dados: RelatorioPessoaType[]) {

    const dadosEmpresa = dados.map(i => ({
        "Código": i.codigoCliente,
        "ID da Conta": i.idIntegracao,
        "Nome": i.nome,
        "Status": i.flagAtivo === 1 ? "Habilitado" : "Desabilitado",
        "Ramo de Atividade": i.descricaoRamoAtividade,
        "Roteiro": i.descricaoRoteiro,
        "CNPJ": i.nroDocumento

    }))
    const workbook = utils.book_new();

    const worksheetDadosEmpresa = utils.json_to_sheet(dadosEmpresa);
    utils.book_append_sheet(workbook, worksheetDadosEmpresa, 'Dados')

    const dadosEndereco = dados.map(i => ({
        "Nome Empresa": i.nome,
        "Endereço": i.logradouro,
        "Número": i.numero,
        "Complemento": i.enderecoCompleto,
        "CEP": i.cep,
        "Bairro": i.bairro,
        "Cidade": i.cidade,
        "Latitude": i.latitude,
        "Longitude": i.longitude,
        "Link Mapa": i.linkMapa
    }))

    const worksheetEndereco = utils.json_to_sheet(dadosEndereco);
    utils.book_append_sheet(workbook, worksheetEndereco, 'endereço')

    const dadosContato = dados.map(i => ({
        "Nome Empresa": i.nome,
        "Departamento": i.listaDepartamento?.replaceAll(';', ';\n'),
        "Nome": i.listaNome?.replaceAll(';', ';\n'),
        "E-mail": i.listaEmail?.replaceAll(';', ';\n'),
        "Telefone": i.listaTelefone?.replaceAll(';', ';\n'),
        "Obs": i.listaObs?.replaceAll(';', ';\n')

    }))

    const worksheetContato = utils.json_to_sheet(dadosContato);
    utils.book_append_sheet(workbook, worksheetContato, 'contato')
    ajustarAltura(dadosContato, worksheetContato)
    const dadoPonto = dados.map(i => ({
        "Nome Empresa": i.nome,
        "Ponto de coleta": i.descicaoPontoColeta?.replaceAll(';', ';\n'),
        "plano de amostragem": i.planoAmostragem?.replaceAll(';', ';\n')
    }))

    const worksheetPonto = utils.json_to_sheet(dadoPonto);
    ajustarAltura(dadoPonto, worksheetPonto)
    utils.book_append_sheet(workbook, worksheetPonto, 'Ponto')


    const dadoVazao = dados.map(i => ({
        "Nome Empresa": i.nome,
        "Registro": i.listaRegistro?.replaceAll(';', ';\n'),
        "Fonte": i.listaFonte?.replaceAll(';', ';\n'),
        "Uc": i.listaUC?.replaceAll(';', ';\n'),
        "Situação": i.listaSituacao?.replaceAll('1', 'Habilitado')?.replaceAll('0', 'Desabilitado')?.replaceAll(';', ';\n'),
        "Medidor de Vazão": i.listaFlagMedidorVazao?.replaceAll('1', 'Sim')?.replaceAll('0', 'Não')?.replaceAll(';', ';\n'),


    }))

    const worksheetVazao = utils.json_to_sheet(dadoVazao);
    ajustarAltura(dadoVazao, worksheetVazao)
    utils.book_append_sheet(workbook, worksheetVazao, 'Vazão')

    writeFile(workbook, 'relatorio_de_clientes.xlsx')
}


// function getTamanhoCedula(data: any[]) {
//     const columnWidths: number[] = Array(data.length ? Object.keys(data[0]).length : 0).fill(0);

//     // Itera sobre os dados para encontrar a largura máxima de cada coluna
//     data.forEach((rowData: any) => {
//         Object.keys(rowData).forEach((key, columnIndex) => {
//             const cellContentLength = String(rowData[key]).length;
//             if (cellContentLength > columnWidths[columnIndex]) {
//                 columnWidths[columnIndex] = cellContentLength;
//             }
//         });
//     });

//     return columnWidths.map((width) => ({ wch: width > 10 ? width : 10 }));
// }

function ajustarAltura(data: any[], ws: WorkSheet) {
    const rowHeights: number[] = Array(data.length).fill(0);

    data.forEach((rowData, rowIndex) => {
        Object.values(rowData).forEach((cellValue, columnIndex) => {

            if (String(cellValue).includes(';')) {
                rowHeights[rowIndex] = String(cellValue).split(";").length
                console.log(String(cellValue).split(";").length - 1)
            }
            // if (cellContentLength > rowHeights[rowIndex+1]) {
            //     rowHeights[rowIndex+1] = cellContentLength;
            // }
        });
    });

    rowHeights.forEach((height, rowIndex) => {
        if (rowIndex !== 0) {
            ws['!rows'] = ws['!rows'] || [];
            ws['!rows'][rowIndex + 1] = { hpt: height * 12, hpx: height * 12 };
        }

    });
}