import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { RelatorioRankingType } from '../types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'

interface Props {
    lista: RelatorioRankingType[] | null
    loading: boolean
}

const TableListarRelatorio: React.FC<Props> = ({ lista, loading }) => {


    const SHOW_DATA = !!lista && !loading && lista.length>0
    const SHOW_LOADING = loading
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)

    return (
        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                                
                <TableHead >
                    <TableRow >
                        {SHOW_DATA && [ "#","INDÚSTRIA", "RAMO DE ATIVIDADE", "ESGOTO MENSAL MÉDIO ANO ANTERIOR", "DBO EM DEZEMBRO ANO ANTERIOR", "CARGA ORGÂNICA DIÁRIA", "MÍNIMO DE COLETAS", "COLETAS REALIZADAS", "META"].map((column, index) => <TableCell key={index}>{column}</TableCell>)}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {SHOW_LOADING && (
                        <TableRow >
                            <TableCell ><CircleSpinner size={32} color="grey" /></TableCell>
                        </TableRow>
                    )}
                    {SHOW_DATA && lista.map((item, index) => (
                        <TableRow key={index} className = {selectedLine?.nome === item.nome ? 'selected' : ''} onClick = {() => setSelectedLine(item)} >
                            <TableCell> {(index+1).toString()} </TableCell>
                            <TableCell> {item.nome} </TableCell>
                            <TableCell> {item.ramoAtividade} </TableCell>
                            <TableCell> {Number(item.volumeEsgoto).toFixed(3).replace(".",",")} </TableCell>
                            <TableCell> {Number(item.dboTotal).toFixed(3).replace(".",",")} </TableCell>
                            <TableCell> {Number(item.cargaOrganica).toFixed(3).replace(".",",")} </TableCell>
                            <TableCell> {item.qtdMin} </TableCell>
                            <TableCell> {item.col} </TableCell>
                            <TableCell> {item.meta} </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            
        </TableContainer>
    )

}

export default TableListarRelatorio