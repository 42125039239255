import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'


const TableBanner: React.FC = () => {

    const [referencia, setReferencia] = useState<{ano:string} | null>(null)
    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '24px',
            borderBottom:"1px solid black"
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
        },
        text: {
            fontSize: '8px',
        },title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px',
            justifyContent:'center'
        }
    })

    useEffect(() => {
        (async() => { setReferencia(JSON.parse(localStorage.getItem('anoRef')!)) })()
    }, [])

    return(
        <>
            <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}> 013_FMEI - Ranking </Text>
                <Text style = {styles.subTitle}>PARA O ANO - {referencia?.ano}</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>

        </>
    )

}

export default TableBanner