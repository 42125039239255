import { utils, writeFile } from 'xlsx';
import { ClienteType } from '../../Parametro/Types/ClienteType';


export function genearateClientExcel(Clientes: ClienteType[] | null) {


     const fileName = `Clientes.xlsx`;
     let valoresLimpos : any
     
    valoresLimpos = Clientes?.map((cliente)=> (
        { 
            id :cliente.codigo,
            nome: cliente.nome,
            razaoSocial: cliente.razaoSocial,
            codigo: cliente.codigo,
            status: cliente.flagAtivo===1?"Habilitado":"Desabilitado",
            dtCadastro : new Date(cliente.dtCadastro).toLocaleString(),
            OBS: cliente.observacao
        }
      ));

     const worksheet = utils.json_to_sheet(valoresLimpos, {header:['id','nome','razaoSocial','codigo', 'status','dtCadastro','OBS']});
     const workbook = utils.book_new();

     utils.book_append_sheet(workbook, worksheet, 'Clientes')
     writeFile(workbook, fileName)

}