import React from 'react'

import { ListSectionProps } from '../types'

const ListSection: React.FC <ListSectionProps> = ({ children, type }) => {

    return <ul className = {`listSection ${type === 'header' ? type : 'main scroll'}`}>{children}</ul>
    
}

export default ListSection