import React from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginBottom: '24px',
            borderBottom: '1px solid black',

        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        text: {
            fontSize: '10px',
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        }
    })

    return(

        <View>


        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>MONITORAMENTO DE EFLUENTES INDUSTRIAIS</Text>
                <Text style = {styles.subTitle}>RELATÓRIO TÉCNICO (SIMPLIFICADO)</Text>
            </View>
           
        </View>
         <Text style={{fontSize:'8px', position: 'absolute', left: '80%', top:'45px'}}>{`${new Date().toLocaleString()}`}</Text>
         </View>
    )

}

export default TableBanner