import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { Contato } from "../Types/Contatos"

interface State {
    requestEdit: RequestStatusPattern<RequestResponsePattern<Contato>>
    requestData: RequestStatusPattern<RequestResponsePattern<Contato>>

    currentData : Contato | null

    requestRemove : RequestStatusPattern<RequestResponsePattern<Contato>>

    requestGetContato: RequestStatusPattern<RequestResponsePattern<Contato[]>>
    Contato : Contato[] | null
}

const initialState: State = {
    requestEdit: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestRemove: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestData: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestGetContato: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    currentData: null,
    Contato: null
}

const ClienteDadosSlice = createSlice({
    name: 'Contatos',
    initialState,
    reducers: {
        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<Contato>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestData: (state, action: PayloadAction<RequestResponsePattern<Contato>>) => {
            state.requestData.data = action.payload
            state.requestData.loading = false
            state.requestData.error = false
        },
        setRequestContato: (state, action: PayloadAction<RequestResponsePattern<Contato[]>>) => {
            state.requestGetContato.data = action.payload
            state.requestGetContato.loading = false
            state.requestGetContato.error = false
        },
        setContato: (state, action: PayloadAction<Contato[]>) => {
            state.Contato = action.payload
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },
        setRequestContatoError: (state) => {
            state.requestGetContato.loading = false
            state.requestGetContato.error = true
        },
        setRequestContatoMessage: (state, action: PayloadAction<string>) => {
            state.requestGetContato.message = action.payload
        },
        setRequestGetLoading: (state) => {
            state.requestGetContato.loading = true
            state.requestGetContato.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestLoading: (state) => {
            state.requestData.loading = true
            state.requestData.error = false
        },
        setRequestRemove: (state, action: PayloadAction<RequestResponsePattern<Contato>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setCurrentData: (state, action: PayloadAction<Contato>) => {
            state.currentData = action.payload
        }
    }
})

export const {  
    setRequestContato,setContato, setRequestContatoError, setRequestContatoMessage, setRequestData, setRequestEditData,
    setRequestGetLoading, setRequestRemoveLoading, setRequestEditLoading, setRequestLoading, setCurrentData,
    setRequestEditMessage, setRequestEditError, setRequestRemove
} = ClienteDadosSlice.actions
export default ClienteDadosSlice.reducer