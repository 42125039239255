import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import PageRender from '../../components/PageRender'
import AlterarSenha from '../../pages/auth/ChangePassword'
import LoginPage from '../../pages/auth/Login'
import RecoverPassword from '../../pages/auth/RecoverPassword'

const AuthRoute: React.FC = () => {

    return (

        <Routes>
            <Route
                index
                element={<PageRender pageName="Auth_Login" pageTitle="Simon - Login" renderType="form" renderComponent={<LoginPage />} />}
            />
            <Route
                path="alterar-senha/:userUuid/:linkUuid"
                element={<PageRender pageName="Auth_ChangePassword" pageTitle="Simon - Alterar senha" renderType="form" renderComponent={<RecoverPassword />} />}
            />
            <Route
                path="redefinir-senha"
                element={<PageRender pageName="Auth_NewPassword" pageTitle="Simon - Redefinir senha" renderType="form" renderComponent={<AlterarSenha />} />}
            />

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>

    )

}

export default AuthRoute