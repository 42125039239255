import React from 'react'
import { View, StyleSheet, Image } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '24px',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
        },
        text: {
            fontSize: '10px',
        },
        BorderBottom: {
            borderBottom: '1px solid black',
        }
    })

    return(

        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
        </View>

    )

}

export default TableBanner