import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, Form as FormikContainer, useFormikContext } from 'formik'
import { useParams } from 'react-router-dom'
import { mdiPlusCircle } from '@mdi/js'
import { useAppSelector } from '../../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../../components/Section'
import Modal from '../../../../../components/Modal'
import Form from '../../../../../components/Form'
import Select from '../../../../../components/Select'
import edit from '../scripts/Hidrometro/edit'
import remove from '../scripts/Hidrometro/remove'
import get from '../scripts/Hidrometro/get'
import TableListarHidrometroComponents from '../Components/TableListarHidrometroComponents'
import { setListaFiltered } from '../Reducers/PessoaHidrometroReducer'
import create from '../scripts/Hidrometro/create'
import createMedVazao from '../scripts/MedidorVazao/createMedVazao'
import { activeItems, validItems, medVazao } from '../../../../../constants/selects'
import { medVazaoFormValues } from '../scripts/constants'
import { getApi } from '../../../../../utils/requestApi'
import { DOMAIN } from '../../../../../var_env.'
import { MedVazaoOneFormType } from '../Types/MedVazaoOneForm'
import formValues from '../scripts/Form/createMedVazaoValues'

const FormObserver: React.FC = () => {
    const { values, setFieldValue } = useFormikContext()
    useEffect(() => {
        if(!!(values as any).calibracao){
            const newVerificar = new Date((values as any).calibracao)
            newVerificar.setFullYear(newVerificar.getFullYear() + 2)
            setFieldValue('verificar', newVerificar.toISOString().substring(0, 10))
            setFieldValue('validade', newVerificar.toISOString().substring(0, 10))
        }
    }, [setFieldValue, values])
    return null
}

const TabHidrometro: React.FC<{canEdit: boolean}> = ({canEdit}) => {

    const dispatch = useDispatch()
    const { currentData, lista, requestGet, requestSave, requestEdit, requestRemove, filteredLista, requestFilter } = useAppSelector(state => state.PessoaHidrometroReducer)
    const { abastecimento, requestCreateMedVazao } = useAppSelector(state => state.ClientsReducer)
    const { userData } = useAppSelector(state => state.auth)

    const { idCliente } = useParams<any>()

    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [,setOpenCriarUnidade] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [, setOpenEditarUnidade] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    const [openRemoverUnidade, setOpenRemoverUnidade] = useState<boolean>(false)

    const [getHorimetro, setGetHorimetro] = useState<any[]>([])
    const [getTecnicos, setGetTecnicos] = useState<any[]>([])
    const [getTipoHidrometro, setGetTipoHidrometro] = useState<any[]>([])
    const [getFormValues, setGetFormValues] = useState<MedVazaoOneFormType | null>(null)
    const [, setGetUnidade] = useState<any[]>([])
    
    const [flagAtivo, setFlagAtivo] = useState<string>('1')
    const [flagMedVazao, setFlagMedVazao] = useState<string>('1')
    const [idFonteAbastecimento, setFonteAbastecimento] = useState<string>('')
    const [tipoHorimetro, setTipoHorimetro] = useState<string>('')
    const [tecnico, setTecnico] = useState<string>('')
    const [tipoHidrometro, setTipoHidrometro] = useState<string>('')
    const [certificado, setCertificado] = useState<string>('')

    const API_CONTROLLER = "PessoaHidrometro"
    const SHOW_TABLE = !!lista
    const SHOW_FILTERED = SHOW_TABLE && !!filteredLista && requestFilter.data

    const FormActions: React.FC <{label: string}> = ({ label }) => {
        const isLoading = requestEdit.loading || requestRemove.loading || requestSave.loading
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>  
        )
    }

    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenCriarUnidade(false)
        setOpenEditar(false)
        setOpenEditarUnidade(false)
        setOpenRemover(false)
        setOpenRemoverUnidade(false)
        setFlagAtivo('1')
        setFonteAbastecimento('')
    }, [])

    const handleCriarMedVazao = useCallback(async (values: typeof medVazaoFormValues, id: any) => {
        console.log(id)
        const selects = {
            tecnico,
            tipo: tipoHidrometro,
            tipoHorimetro,
            fonteAbastecimento: '',
            certificado,
        }
        await createMedVazao(dispatch, values,id, selects, Number(idCliente), userData!.id)
    }, [dispatch, idCliente, userData, tecnico, certificado, tipoHidrometro, tipoHorimetro])

    const handleCriar = useCallback(async (values: {registro: string, uc: string}) => {
        await create(API_CONTROLLER, dispatch, {...values, idCliente, idFonteAbastecimento, flagAtivo: Number(flagAtivo), idUsuario: userData!.id, flagMedidorVazao: Number(flagMedVazao)})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, userData, idFonteAbastecimento, idCliente, flagMedVazao])

    const handleEditar = useCallback(async (values: {registro: string, uc: string}) => {
        await edit(API_CONTROLLER, dispatch, {...values, idCliente, idFonteAbastecimento, flagAtivo: Number(flagAtivo), idUsuario: userData!.id, id: currentData!.id, flagMedidorVazao: Number(flagMedVazao)})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, currentData, userData, idFonteAbastecimento, idCliente, flagMedVazao])

    const handleRemover = useCallback(async () => {
        dispatch(setListaFiltered([]))
        await remove(API_CONTROLLER, dispatch, {id: currentData!.id, idUsuario: userData!.id})
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentData, userData])

    const handleRemoverUnidade = useCallback(async () => {
        console.log(currentData)
        await remove("PessoaUnidadeControladora", dispatch, {id: currentData!.id, idUsuario: userData!.id})
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentData, userData])

    useEffect(() => {
        (async () => {
            await get(API_CONTROLLER, Number(idCliente), dispatch)

            setGetHorimetro((await getApi({ endpoint: `${DOMAIN}/CalculoHorimetro`, params: '' })).listaResultados)
            setGetUnidade((await getApi({ endpoint: `${DOMAIN}/PessoaUnidadeControladora`, params: `/?idPessoa=${idCliente}` })).listaResultados)
            setGetTecnicos((await getApi({ endpoint: `${DOMAIN}/Usuario`, params: '' })).listaResultados)
            setGetTipoHidrometro((await getApi({ endpoint: `${DOMAIN}/PessoaMedidorVazaoTipo`, params: `/?idPessoa=${idCliente}` })).listaResultados)
            setGetFormValues((await getApi({ endpoint: `${DOMAIN}/PessoaMedidorVazao`, params: `/${idCliente}` })).listaResultados)
        })()
    }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data, idCliente])

    return(
        <>
            <Section name = "SectionPessoaHidrometro">
                {!!getFormValues && !!getTipoHidrometro && !!getTecnicos && !!getHorimetro && (
                    <SectionBox title = "Medidor de Vazão" padding = {false}>
                        <Formik initialValues = {formValues(getFormValues)} onSubmit = {values => handleCriarMedVazao(values, getFormValues.id)}> 
                        <>
                            <FormikContainer>
                                <FormObserver />
                                <Form.Container>
                                    <Form.Row columns = {4}>
                                        <Form.Group inputID = "registro" inputName = "registro" label = "Registro" />
                                        <Form.Group inputID = "data" inputName = "data" inputType = "date" label = "Data" />
                                        <Select type = "outline" value = {tecnico} setValue = {setTecnico} defaultValue = {getFormValues.idUsuarioTecnico ? getFormValues.idUsuarioTecnico.toString() : undefined} label = "Técnico" items = {getTecnicos.map(item => { return { value: item.id.toString(), label: item.nome } })} />
                                        <Select type = "outline" value = {tipoHidrometro} setValue = {setTipoHidrometro} defaultValue = {getFormValues.idTipo ? getFormValues.idTipo.toString() : undefined} label = "Tipo" items = {getTipoHidrometro.map(item => { return { value: item.id.toString(), label: item.descricao } } )} />
                                    </Form.Row>
                                    <Form.Row columns = {4}>
                                        <Form.Group inputID = "calibracao" inputName = "calibracao" inputType = "date" label = "Calibração" />
                                        <Form.Group inputID = "verificar" inputName = "verificar" inputType = "date" label = "Verificar em" />
                                        <Form.Group inputID = "verificado" inputName = "verificado" label = "Verificado em" />
                                        <Form.Group inputID = "validade" inputName = "validade" inputType = "date" label = "Validade" />
                                    </Form.Row>
                                    <Form.Row columns = {4}>
                                    <Form.Group inputID = "lacre" inputName = "lacre" label = "Lacre" />
                                        <Form.Group inputID = "nroSerie" inputName = "nroSerie" label = "Nº Série" />
                                        <Form.Group inputID = "codEquipamento" inputName = "codEquipamento" label = "Cód. Equip" />
                                        <Form.Group inputID = "modelo" inputName = "modelo" label = "Modelo" />
                                    </Form.Row>
                                    <Form.Row columns = {3}>
                                        <Select type = "outline" value = {tipoHorimetro} setValue = {setTipoHorimetro} defaultValue = {getFormValues.idCalculoHorimetro ? getFormValues.idCalculoHorimetro.toString() : undefined} label = "Cálculo" items = {getHorimetro.map(item => { return { value: item.id.toString(), label: item.descricao } })} />
                                        <Select type = "outline" value = {certificado} setValue = {setCertificado} defaultValue = {getFormValues.flagCertificado ? getFormValues.flagCertificado.toString() : undefined} label = "Certificado" items = {validItems} />
                                        <Form.Group inputID = "motivo" inputName = "motivo" label = "Motivo" />
                                    </Form.Row>
                                    {canEdit && <div className = "formActionsContainer">
                                        <button type = "submit" className = {`default ${requestCreateMedVazao.loading ? 'status disabled' : ''}`}>{requestCreateMedVazao.loading && <div className = "spinner" />}Salvar</button>
                                    </div>}
                                </Form.Container>
                            </FormikContainer></>
                        </Formik>
                    </SectionBox>
                )}
                <SectionBox title = "Hidrômetros" padding = {false} right = {
                <>
                {canEdit && <SectionOption icon = {mdiPlusCircle} tooltip = "Criar" onClick = {() => setOpenCriar(true)} />}
                </>
                }>
                    <TableListarHidrometroComponents canEdit={canEdit} lista={SHOW_FILTERED ? filteredLista : lista} loading = {requestGet.loading || requestFilter.loading} setOpenEditar = {setOpenEditar} setOpenRemover={setOpenRemover} />
                </SectionBox>
            </Section>
            <>  
                <Modal width = {1200} open = {openCriar} setOpen = {setOpenCriar} title = "Novo">
                    <Formik initialValues = {{registro: '', uc: '', idFonteAbastecimento: '', flagAtivo: '1'}} onSubmit = {values => handleCriar(values)}>
                        <FormikContainer>
                        <Form.Container padding = {false}>
                                <Form.Row columns = {3} grid = "200px 1fr 300px">
                                    <Form.Group inputID = "registro" inputName = "registro" label = "Registro" />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.idFonteAbastecimento.toString() : undefined} value = {idFonteAbastecimento} label = "Fonte Abastecimento" setValue = {setFonteAbastecimento} items = {abastecimento ? abastecimento.map(item => { return { value: item.id.toString(), label: item.descricao } }) : []} />
                                    <Select type = "outline" defaultValue={openCriar ? flagMedVazao.toString() : undefined} value = {flagMedVazao} label = "Medidor de Vazão?" setValue = {setFlagMedVazao} items = {medVazao} />
                                </Form.Row>
                                <Form.Row columns = {2} grid = "200px 300px">
                                    <Form.Group inputID = "uc" inputName = "uc" label = "UC" />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>

                <Modal width = {1200} open = {openEditar} setOpen = {setOpenEditar} title = "Edição">
                    <Formik initialValues = {{registro: currentData?.registro ?? '', uc: currentData?.uc ?? ''}} onSubmit = {values => handleEditar(values)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {3} grid = "200px 1fr 300px">
                                    <Form.Group inputID = "registro" inputName = "registro" label = "Registro" />
                                    <Select type = "outline" defaultValue={currentData ? currentData.idFonteAbastecimento.toString() : undefined} value = {idFonteAbastecimento} label = "Fonte Abastecimento" setValue = {setFonteAbastecimento} items = {abastecimento ? abastecimento.map(item => { return { value: item.id.toString(), label: item.descricao } }) : []} />
                                    <Select type = "outline" defaultValue={currentData ? currentData.flagMedidorVazao?.toString() : undefined} value = {flagMedVazao} label = "Medidor de Vazão?" setValue = {setFlagMedVazao} items = {medVazao} />
                                </Form.Row>
                                <Form.Row columns = {2} grid = "200px 300px">
                                    <Form.Group inputID = "uc" inputName = "uc" label = "UC" />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>

                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover Hidrômetro">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>

                <Modal width = {600} open = {openRemoverUnidade} setOpen = {setOpenRemoverUnidade} title = "Remover Unidade Controladora">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemoverUnidade()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>
            </>
        </>
    )

}

export default TabHidrometro