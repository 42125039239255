import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { RelatorioDbo } from '../../../Types'


const TableRow: React.FC <{relatorio: RelatorioDbo[]}> = ({relatorio}) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
            borderTop: '1px solid black',
            marginBottom:"20px",
            display:"flex",
            paddingBottom:"20px"
        },          
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '8px',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })


    return(
<>
        {relatorio.map(item=>(

        <View style = {styles.row} wrap={false}>
            <View style={{flexDirection: "column", marginTop:"10px"}}>
            <Text style={{fontSize:"8px", marginTop:"10px"}}>INDÚSTRIA: {item.nome}</Text>
            <Text style={{fontSize:"8px", marginTop:"10px"}}>ENDEREÇO: {item.endereco}</Text>
            <Text style={{fontSize:"8px", marginTop:"10px"}}>UC:  </Text>
            {item.uc.map(i=> (
                <Text style={{fontSize:"8px"}}>{i}  </Text>
            ))}
            </View>
            <View style={{width:"100%",flexDirection: "column",  marginTop:"10px", alignItems:"flex-end" }}>
            <Text style={{fontSize:"8px",  marginTop:"10px"}}>DBO (kg/m³): {item.dbo<0.3? "0,300": item.dbo.toFixed(3).replace(".",",")} </Text>
            </View>
        </View>
        ))}

        <View wrap={false}>
                <Text style={{fontSize:"8px"}}> Cordialmente, </Text>

                <Text style={{fontSize:"8px", marginTop:"20px"}}> Fabio Henrique Ercolin, </Text>
                <Text style={{fontSize:"8px"}}>  Coordenador de Monitoramento</Text>
        </View>
</>


    )

}

export default TableRow