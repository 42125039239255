import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { RelatorioRankingType } from '../../../types'
import Column from '../../../../../../../components/PDF/Column'
import somataorio from '../scripts/somataorio'

const TableRow: React.FC <{relatorios: RelatorioRankingType[]}> =  ({relatorios}) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        }
    })

    return(
        <>
            {relatorios.map((relatorio, index)=>
                <View style = {styles.row} wrap={false}>
                    <Column text = {(index+1).toString()} width = "10%" />
                    <Column text = {relatorio.nome} width = "50%" align = "left" />
                    <Column text = {relatorio.ramoAtividade} width = "30%"  />
                    <Column text = {Number(relatorio.volumeEsgoto).toFixed(3).replace(".",",")} width = "20%" />
                    <Column text = {Number(relatorio.dboTotal).toFixed(3).replace(".",",")} width = "20%" />
                    <Column text = {Number(relatorio.cargaOrganica).toFixed(3).replace(".",",")} width = "20%" />
                    <Column text = {relatorio.qtdMin?.toString()} width = "15%" />
                    <Column text = {relatorio.col?.toString()} width = "15%" />

                    <Column text = {relatorio.meta?.toString()} width = "15%" />
                    <Column text = {relatorio?.regraColeta} width = "20%" last/>
                </View>
            )}
                <View style = {styles.row} wrap={false}>
                    <Column text = {"#"} width = "10%" />
                    <Column text = {"Somatório"} width = "50%" align = "left" />
                    <Column text = {"-"} width = "30%"  />
                    <Column text = {"-"} width = "20%" />
                    <Column text = {"-"} width = "20%" />
                    <Column text = {"-"} width = "20%" />
                    <Column text = {somataorio(relatorios.map(item=> Number(item.qtdMin)))} width = "15%" />
                    <Column text = {somataorio(relatorios.map(item=> Number(item.col)))} width = "15%" />
                    <Column text = {somataorio(relatorios.map(item=> Number(item.meta)))} width = "15%" last/>
                    <Column text = {"-"} width = "20%" last/>
                </View>
        </>
    )

}

export default TableRow