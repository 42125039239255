import React, { useReducer } from 'react'
import { SidebarSectionProps } from '../types'
import SidebarComponents from '../components'
import sidebarReducer from '../scripts/reducer/sidebarReducer'

const Section: React.FC <SidebarSectionProps> = ({ children, tooltip, icon, title }) => {

    const [state, dispatch] = useReducer(sidebarReducer, {open: false})

    return(

        <ul className = "sidebarSection">
            <SidebarComponents.Option
                type = "header"
                tooltip = {tooltip}
                icon = {icon}
                label = {title}
                //open = {children ? open : undefined}
                open = {state.open}
                func = {() => dispatch({type: 'TOGGLE_OPEN'})}
            />
            {state.open && children}
        </ul>

    )

}

export default Section