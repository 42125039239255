import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { vazaoLancamento } from '../../../types'
import Column from '../../../../../../../components/PDF/Column'

const TableRow: React.FC <{props:vazaoLancamento,id:number, zebra: boolean  }> = props => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        }
    })

    return( 

        <View style = {[styles.row, {backgroundColor: props.zebra ? '#C0C0C0' : '#ffffff'}]} break = {props.id % 22 === 0}>
            <Column text = {props.id.toString()} width = "5%" />
            <Column text = {props.props.nome.toString()} align="left" width = "35%" />
            <Column text = {props.props.uc??""} width = "10%" />
            <Column text = {props.props.leituraAnterior=== undefined ? "":props.props.leituraAnterior.toString() } width = "10%" />
            <Column text = {props.props.leituraAtual=== undefined ? "":props.props.leituraAtual.toString() } width = "10%" />
            <Column text = {props.props.volumeMedido=== undefined ? "":props.props.volumeMedido.toString() } width = "10%" />
            <Column text = {props.props.volumeCorrigido=== undefined ? "":props.props.volumeCorrigido.toString() } width = "10%" />
            <Column text = {props.props.obs??""} width = "15%" last/>
        </View>

    )

}

export default TableRow