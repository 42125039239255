import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { RelatorioParametroType } from '../types'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'

interface Props {
    lista: RelatorioParametroType[] | null
    loading: boolean
}

const TableListarRelatorio: React.FC<Props> = ({ lista, loading }) => {


    const handleValue = (value: string, sigla: string  ) => {
        if(value?.toUpperCase()==="DEMANDA BIOLÓGICA DE OXIGÊNIO") return "DBO"
        if(value?.toUpperCase()==="DEMANDA QUÍMICA DE OXIGÊNIO") return "DQO"
        if(value?.toUpperCase()==="POTENCIAL HIDROGENIÔNICO") return "pH"
 
        const valoresSeparados = value.split('_')
        if(valoresSeparados.length> 1){
         value = `${sigla}_${valoresSeparados[1]}`
        }
     //    if(value.toUpperCase()==="CIANETO (MG/L)") return "CN"
     //    if(value.toUpperCase()==="TFÓSFORO TOTAL") return "P"
     //    if(value.toUpperCase()==="NITROGÊNIO TOTAL") return "N"
     //    if(value.toUpperCase()==="POTENCIAL HIDROGENIÔNICO") return "pH"
 
        return value.replace("(MG/L)","") //.replace("( °C )","")
     }

    const SHOW_DATA = !!lista && !loading && lista.length>0
    const SHOW_LOADING = loading
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)
    return (

        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table">
                                
                <TableHead >
                    <TableRow >
                        {SHOW_DATA && [ "Parâmetros Analisados", "Quantidade de ensaios", "Conformes", "Não Conformes"].map((column, index) => <TableCell key={index}  > {column}</TableCell>)}
                    </TableRow>
                </TableHead>

                <TableBody >
                    {SHOW_LOADING && (
                        <TableRow >
                            <TableCell>
                                <div className = "center">
                                    <CircleSpinner size = {32} color = "grey" />
                                </div></TableCell>
                        </TableRow>
                    )}
                    {SHOW_DATA && lista.map((item, index) => (
                        <TableRow key={index} className = {selectedLine?.id === item.id ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                            <TableCell  ><div style={{ textAlign: "left" }}>{handleValue(item.descricao, item.sigla)}</div> </TableCell>
                            <TableCell  >{(item.qtde.toString())}</TableCell>
                            <TableCell  >{(item.qtdeConforme.toString())}</TableCell>
                            <TableCell  > {(item.qtdeNaoConforme.toString())}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            
        </TableContainer>
    )

}

export default TableListarRelatorio