import { MenuItem, Select as SelectMUI, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { mdiDelete, mdiMicrosoftExcel, mdiFilePdfBox, mdiMagnify, mdiPlusCircle } from '@mdi/js'
import { Formik, Form as FormikContainer } from 'formik'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import TableListar  from './Components/TableListarParametroComponents'
import filter from './Scripts/filter'
import send from './Scripts/send'
import remove from './Scripts/remove'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import Modal from '../../../../components/Modal'
import get from './Scripts/get'
import { activeItems } from '../../../../constants/selects'
import { setFieldFiltered, setFieldFilteredFlagAtivo, setListaFiltered } from './Reducers/ParametroReducer'
import edit from './Scripts/edit'
import { Link } from 'react-router-dom'
import sendSinonimos from './Scripts/sendSinonimos'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../components/List'
import removeSinonimo from './Scripts/removeSinonimo'
import { genearateParametroExcel } from './Scripts/genearateParametroExcel'
import isEditable from '../../../../scripts/modules/isEditable'

const Parametro: React.FC = () => {

    const dispatch = useDispatch()
    const { currentData, lista, listaSinonimo, requestGet, requestSave, requestEdit, requestRemove, fieldFiltered, filteredLista, fieldFilteredFlagAtivo, requestFilter } = useAppSelector(state => state.ParametroReducer)
    const { userData } = useAppSelector(state => state.auth)

    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openCriarS, setOpenCriarS] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    const [flagAtivo, setFlagAtivo] = useState<string>('1')

    const API_CONTROLLER = "Parametro"
    const API_CONTROLLERS = "RamoAtividadeParametroSinonimo"
    const PAGE_NAME = "Parâmetros"
    const SHOW_TABLE = !!lista
    const SHOW_SINONIMO = !!listaSinonimo
    const SHOW_FILTERED = SHOW_TABLE && !!filteredLista && requestFilter.data

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'Parametro')

    const FormActions: React.FC <{label: string}> = ({ label }) => {
        const isLoading = requestEdit.loading || requestRemove.loading || requestSave.loading
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>  
        )
    }

    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenEditar(false)
        setOpenRemover(false)
        setOpenCriarS(false)
        setFlagAtivo('1')
    }, [])

    const handleCriarS = useCallback(async (values: {
        idParametro: string | undefined,
        descricao: string, 
        flagAtivo: string, 
        idUsuario: string
    }) => {
        await sendSinonimos(API_CONTROLLERS, dispatch, {...values, flagAtivo: Number(flagAtivo), idUsuario: userData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, userData])

    const handleCriar = useCallback(async (values: {
        sigla: string, 
        descricao: string, 
        minimo: string, 
        maximo: string,
        incerteza: string
    }) => {
        await send(API_CONTROLLER, dispatch, {...values, flagAtivo: Number(flagAtivo), idUsuario: userData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, userData])

    const handleEditar = useCallback(async (values: {
        sigla: string, 
        descricao: string, 
        minimo: string, 
        maximo: string,
        incerteza: string
    }) => {
        await edit(API_CONTROLLER, dispatch, {...values, flagAtivo: Number(flagAtivo), idUsuario: userData!.id, id: currentData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, currentData, userData])

    const handleRemoverS = useCallback(async (sinonimo:any) => {
        dispatch(setListaFiltered([]))
        await removeSinonimo(API_CONTROLLERS, dispatch, {id: sinonimo.id, idUsuario: sinonimo.idUsuario})
        handleFecharModal()
    }, [dispatch, handleFecharModal])

    const handleRemover = useCallback(async () => {
        dispatch(setListaFiltered([]))
        await remove(API_CONTROLLER, dispatch, {id: currentData!.id, idUsuario: userData!.id})
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentData, userData])
    
    const handleFilter = useCallback(async () => {
        await filter(API_CONTROLLER, dispatch, {descricao: fieldFiltered, flagAtivo: fieldFilteredFlagAtivo})
    }, [dispatch, fieldFiltered, fieldFilteredFlagAtivo])
    
    useEffect(() => {
        (async () => {await get(API_CONTROLLER, dispatch)})()
    }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data])  

    // useEffect(() => {
    //     (async () => {await getSinonimo(API_CONTROLLERS, dispatch, currentData?.id)})();
    // }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data, currentData])

    const keyPress =(async (e:any) => {
        if(e.keyCode === 13) handleFilter()
    }) 

    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {false} 
                    right = {
                        <>
                            <TextField id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Descrição" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value as string))} onKeyDown={keyPress} />
                            <SelectMUI id="filterAtivo" value={fieldFilteredFlagAtivo} onChange={e => dispatch(setFieldFilteredFlagAtivo(e.target.value as any))}>
                                <MenuItem value={'null'}>Todos</MenuItem>
                                <MenuItem value={1}>Habilitado</MenuItem>
                                <MenuItem value={0}>Desabilitado</MenuItem>
                            </SelectMUI>
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                           <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Salvar excel" onClick = {() => genearateParametroExcel(lista)} />
                            {canEdit && <SectionOption icon = {mdiPlusCircle} tooltip = "Criar" onClick = {() => setOpenCriar(true)} />}
                        </>
                    }
                >
                    <TableListar 
                        lista={SHOW_FILTERED ? filteredLista : lista} 
                        loading = {requestGet.loading || requestFilter.loading} 
                        setOpenEditar = {setOpenEditar} 
                        setOpenRemover={setOpenRemover} 
                        setOpenCriarS = {setOpenCriarS} 
                        canEdit={canEdit}
                    />
                </SectionBox>
            </Section>
            <>  
                <Modal width = {1400} open = {openCriar} setOpen = {setOpenCriar} title = "Novo">
                    <Formik 
                        initialValues = {{
                            sigla: '', 
                            descricao: '', 
                            minimo: '0.00', 
                            maximo: '0.00', 
                            incerteza: '0.00'
                        }} 
                        onSubmit = {values => handleCriar(values)}
                    >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2} grid = "140px 1fr">
                                    <Form.Group inputID = "sigla" inputName = "sigla" label = "Sigla" />
                                    <Form.Group inputID = "descricao" inputName = "descricao" label = "Descrição" />
                                </Form.Row>

                                <Form.Row columns = {4} grid = "300px 300px 300px 1fr">
                                    <Form.Group inputID = "minimo" inputName = "minimo" label = "Mínimo" inputType='number' />
                                    <Form.Group inputID = "maximo" inputName = "maximo" label = "Máximo" inputType='number' />
                                    <Form.Group inputID = "incerteza" inputName = "incerteza" label = "Incerteza" inputType='number' />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Adicionar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>

                <Modal width={600} open={openCriarS} setOpen={setOpenCriarS} title="Adicionar Sinônimo">
                    <Formik 
                        initialValues={{
                            idParametro:currentData?.id.toString() ,
                            descricao: '', 
                            flagAtivo: '1', 
                            idUsuario: userData!.id.toString()
                        }} 
                        onSubmit={values => handleCriarS(values)}
                    >
                        <FormikContainer>
                            <Form.Container padding={false} >
                                <Form.Row columns={1} grid="1fr ">
                                    <Form.Group inputID="descricao" inputName="descricao" label="Sinonimo" />
                                </Form.Row>
                                <FormActions label="Adicionar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                    {SHOW_SINONIMO && (
                        <List name = "Sinonimos">
                            <ListSection type = "header">
                                <ListRow grid={"1fr 60px"} padding = {false}>
                                    {['Sinônimo', 'Opções'].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}    
                                </ListRow>
                            </ListSection>
                            <ListSection>
                                {listaSinonimo.map((item, index) => (
                                    <ListRow key={index} padding = {false} grid={"1fr 60px"} zebra={index%2===0}>
                                        <ListColumn>{item.descricao}</ListColumn>
                                        <ListColumn>
                                            <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemoverS({id: item.id, idUsuario:userData?.id})} /> 
                                        </ListColumn> 
                                    </ListRow>
                                ))}
                            </ListSection>
                        </List>
                    )}
                </Modal>

                <Modal width = {1200} open = {openEditar} setOpen = {setOpenEditar} title = "Edição">
                    <Formik 
                        initialValues = {{
                            sigla: currentData?.sigla ?? '', 
                            descricao: currentData?.descricao ?? '', 
                            minimo: currentData?.minimo ?? '0.00', 
                            maximo: currentData?.maximo ?? '0.00', 
                            incerteza: currentData?.incerteza ?? '0.00'
                        }} 
                        onSubmit = {values => handleEditar(values)}
                    >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2} grid = "140px 1fr">
                                    <Form.Group inputID = "sigla" inputName = "sigla" label = "Sigla" />
                                    <Form.Group inputID = "descricao" inputName = "descricao" label = "Descrição" />
                                </Form.Row>

                                <Form.Row columns = {4} grid = "300px 300px 300px 1fr">
                                    <Form.Group inputID = "minimo" inputName = "minimo" label = "Mínimo" inputType='number' />
                                    <Form.Group inputID = "maximo" inputName = "maximo" label = "Máximo" inputType='number' />
                                    <Form.Group inputID = "incerteza" inputName = "incerteza" label = "Incerteza" inputType='number' />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>

                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover cobrança">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>
            </>
        </>
    )
}

export default Parametro
