import { MenuItem, Select as SelectMUI, TextField } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { mdiMicrosoftExcel, mdiFilePdfBox, mdiMagnify, mdiPlusCircle } from '@mdi/js'
import { Formik, Form as FormikContainer } from 'formik'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../store/hooks'
import TableListar from '../../../Common/Components/TableListarDefaultComponents'
import edit from '../../../Common/Scripts/edit'
import filter from '../../../Common/Scripts/filter'
import send from '../../../Common/Scripts/send'
import remove from '../../../Common/Scripts/remove'
import Form from '../../../../components/Form'
import Select from '../../../../components/Select'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import Modal from '../../../../components/Modal'
import get from '../../../Common/Scripts/get'
import { activeItems } from '../../../../constants/selects'
import { setFieldFiltered, setFieldFilteredFlagAtivo, setListaFiltered } from '../../../../store/reducers/default/DefaultReducer'
import { Link } from 'react-router-dom'
import { genearateCommonExcel } from '../../../Common/Scripts/genearateCommonExcel'
import isEditable from '../../../../scripts/modules/isEditable'

const CategoriaCobranca: React.FC = () => {

    const dispatch = useDispatch()
    const { currentData, lista, requestGet, requestSave, requestEdit, requestRemove, fieldFiltered, filteredLista, fieldFilteredFlagAtivo, requestFilter } = useAppSelector(state => state.DefaultReducer)
    const { userData } = useAppSelector(state => state.auth)

    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    const [flagAtivo, setFlagAtivo] = useState<string>('1')

    const API_CONTROLLER = "CategoriaCobranca"
    const PAGE_NAME = "Tipos de Cobrança"
    const SHOW_TABLE = !!lista
    const SHOW_FILTERED = SHOW_TABLE && !!filteredLista && requestFilter.data

    const FormActions: React.FC <{label: string}> = ({ label }) => {
        const isLoading = requestEdit.loading || requestRemove.loading || requestSave.loading
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>  
        )
    }

    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenEditar(false)
        setOpenRemover(false)
        setFlagAtivo('1')
    }, [])

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'categoria-cobranca')
    

    const handleCriar = useCallback(async (descricao: string) => {
        await send(API_CONTROLLER, dispatch, {descricao, flagAtivo: Number(flagAtivo), idUsuario: userData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, userData])

    const handleEditar = useCallback(async (descricao: string) => {
        await edit(API_CONTROLLER, dispatch, {descricao, flagAtivo: Number(flagAtivo), idUsuario: userData!.id, id: currentData!.id})
        dispatch(setListaFiltered([]))
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, currentData, userData])

    const handleRemover = useCallback(async () => {
        dispatch(setListaFiltered([]))
        await remove(API_CONTROLLER, dispatch, {id: currentData!.id, idUsuario: userData!.id})
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentData, userData])
    
    const handleFilter = useCallback(async () => {
        await filter(API_CONTROLLER, dispatch, {descricao: fieldFiltered, flagAtivo: fieldFilteredFlagAtivo})
    }, [dispatch, fieldFiltered, fieldFilteredFlagAtivo])
    
    useEffect(() => {
        (async () => {await get(API_CONTROLLER, dispatch)})()
    }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data])  

    const keyPress =(async (e:any) => {
        if(e.keyCode === 13){
            handleFilter()
         }
    })

    return (
        <>
            <Section name = "NameSection">
                <SectionBox 
                    title = {PAGE_NAME}
                    padding = {false} 
                    right = {
                        <>
                            <TextField id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Descrição" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value as string))} onKeyDown={keyPress} />
                            <SelectMUI id="filterAtivo" value={fieldFilteredFlagAtivo} onChange={e => dispatch(setFieldFilteredFlagAtivo(e.target.value as number))}>
                                <MenuItem value={'null'} disabled>Selecionar</MenuItem>
                                <MenuItem value={1}>Habilitado</MenuItem>
                                <MenuItem value={0}>Desabilitado</MenuItem>
                            </SelectMUI>
                            <SectionOption icon = {mdiMagnify} tooltip = "Filtrar" onClick = {handleFilter} />
                            <Link to = "pdf" target = "_blank">
                                <SectionOption icon = {mdiFilePdfBox} tooltip = "Visualizar PDF" onClick = {() => {}} />
                            </Link>
                            
                            <SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {genearateCommonExcel(lista,'Categoria_Cobranca')}} />
                            {canEdit && <SectionOption icon = {mdiPlusCircle} tooltip = "Criar" onClick = {() => setOpenCriar(true)} />}
                        </>
                    }
                >
                    <TableListar canEdit={canEdit} lista={SHOW_FILTERED ? filteredLista : lista} loading = {requestGet.loading} setOpenEditar = {setOpenEditar} setOpenRemover={setOpenRemover} />
                </SectionBox>
            </Section>
            <>
                <Modal width = {600} open = {openCriar} setOpen = {setOpenCriar} title = "Novo">
                    <Formik initialValues = {{descricao: ''}} onSubmit = {values => handleCriar(values.descricao)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2} grid = "1fr 140px">
                                    <Form.Group inputID = "descricao" inputName = "descricao" label = "Descrição" />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Adicionar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openEditar} setOpen = {setOpenEditar} title = "Edição">
                    <Formik initialValues = {{descricao: currentData?.descricao ?? ''}} onSubmit = {values => handleEditar(values.descricao)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns = {2} grid = "1fr 140px">
                                    <Form.Group inputID = "descricao" inputName = "descricao" label = "Descrição" />
                                    <Select type = "outline" defaultValue={openEditar && currentData ? currentData.flagAtivo.toString() : undefined} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label = "Salvar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover cobrança">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>
            </>
        </>
    )
}

export default CategoriaCobranca
