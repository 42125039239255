import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from "../../../../../store/reducers/default/AmostradorReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { AmostradorType } from '../../../../../store/types/AmostradorType'
import { EditParams } from "../types"

export default async function edit(apiController: string, dispatch: Function, values: EditParams){
    try {
        dispatch(setRequestEditLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`

        const data = { 
            id: values.id, 
            codigo: values.codigo,
            descricao: values.descricao,
            obs: values.obs,
            dtAquisicao: values.dtAquisicao,
            flagAtivo: values.flagAtivo ? 1 : 0, 
            idUsuario: values.idUsuario
        }
        
        const response: RequestResponsePattern<AmostradorType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestEditData(response))
            if(response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}