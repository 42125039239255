import { utils, writeFile } from 'xlsx';
import { ParametroType } from '../Types/ParametroType';


export function genearateParametroExcel(Parametros: ParametroType[] | null) {


     const fileName = `Parametro.xlsx`;
     let valoresLimpos : any
     
    valoresLimpos = Parametros?.map((parametro)=> (
        { 
            id :parametro.id,
            sigla: parametro.sigla,
            descricao: parametro.descricao,
            minimo: parametro.minimo,
            maximo: parametro.maximo,
            incerteza: `${parametro.incerteza} %`,
            status: parametro.flagAtivo===1?"Habilitado":"Desabilitado",
            dtCadastro : new Date(parametro.dtCadastro).toLocaleString()
        }
      ));

     const worksheet = utils.json_to_sheet(valoresLimpos, {header:['id','sigla',"descricao",'minimo','maximo','incerteza' ,'status','dtCadastro']});
     const workbook = utils.book_new();

     utils.book_append_sheet(workbook, worksheet, 'Parametro')
     writeFile(workbook, fileName)

}