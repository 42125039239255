import React, { useEffect } from 'react'
import { BrowserRouter } from "react-router-dom"
import { reactLocalStorage as ls } from 'reactjs-localstorage'
import { useDispatch } from 'react-redux'
import { User } from '../types/User'
import { useAppSelector } from '../store/hooks'
import { setAuthLoading, setAuthLogin, setAuthLogout } from '../store/reducers/auth/authReducer'
import { setTheme, Theme } from '../store/reducers/theme/themeReducer'
import AuthRoute from './auth'
import SystemRoute from './system'
import getAppModules from '../scripts/modules/getAppModules'
import getAppAuthorizedModules from '../scripts/modules/getAppAuthorizedModules'
import SnackbarAlert from '../components/SnackbarAlert'

const AppRoute: React.FC = () => {

    const dispatch = useDispatch()
    const { isLogged, userData, loading } = useAppSelector(state => state.auth)

    useEffect(() => {
        const lsTheme = ls.get('theme') as Theme | undefined
        const matchTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? Theme.Dark : Theme.Light
        if (lsTheme) dispatch(setTheme(lsTheme))
        else dispatch(setTheme(matchTheme))
    }, [dispatch])

    useEffect(() => {
        localStorage.removeItem('modules')
        const lsUser = ls.getObject('user') as User | undefined
        !!lsUser && !!lsUser.login ? dispatch(setAuthLogin(lsUser)) : dispatch(setAuthLogout())
    }, [dispatch, isLogged])

    useEffect(() => {
        if(userData && userData.GrupoAcesso?.id){
            (async () => {
                getAppModules(dispatch, false)
                await getAppAuthorizedModules(dispatch, userData.GrupoAcesso.id)
            })()
        }
        dispatch(setAuthLoading(false))
    }, [dispatch, userData])

    return(

        <BrowserRouter>
            {(!loading && ((!isLogged && <AuthRoute />) || <SystemRoute />))}
            <SnackbarAlert />
        </BrowserRouter>

    )

}

export default AppRoute