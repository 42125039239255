import { ModuloType } from "../../types/Modules"

export default  function isEditable(accessAuthorizedModules : ModuloType[] | null, pathMenu: string){
    try {

        const localModulosAcessos :ModuloType[] | null = JSON.parse(localStorage.getItem('modulosAutorizados') ?? 'null') 
        
        
         accessAuthorizedModules = localModulosAcessos


        if(accessAuthorizedModules){
            const modulo = accessAuthorizedModules.find(x=> x.ModuloMenu.find(x =>x.path  === pathMenu && x.flagEditar === true))

            return  modulo ? true : false
        }
        return false
    } catch (error) {
        return false
    }

}