import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer, View } from '@react-pdf/renderer'
import { HistoricoColetaElement } from '../../../Types/HistoricoColeta'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import TableInfo from './Table/info'
import _ from 'lodash'
import TableLegend from './Table/legend'
import TableFooter from './Table/footer'
import { useParams } from 'react-router-dom'

const HistoricoCompletosPdf: React.FC = () => {
    
    const { flagLimite } = useParams<any>()
    const [historico, setHistorico] = useState<HistoricoColetaElement[] | null>(null)
    const [historicoComDBO, setHistoricoComDBO] = useState<HistoricoColetaElement[] | null>(null)
    const [ponto, setPonto] = useState<any[] | null>(null)
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '55px',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { 
            function mudaPonto(valor: string){

                const primeiraLetra = valor[0]
                const isNotNumber = isNaN(Number(primeiraLetra))
                if(isNotNumber){
                    return valor?.trim() ?? " " 
                }
                
                valor = valor?.replaceAll('0', '')
                valor = valor?.replaceAll('1', '')
                valor = valor?.replaceAll('2', '')
                valor = valor?.replaceAll('3', '')
                valor = valor?.replaceAll('4', '')
                valor = valor?.replaceAll('5', '')
                valor = valor?.replaceAll('6', '')
                valor = valor?.replaceAll('7', '')
                valor = valor?.replaceAll('8', '')
                valor = valor?.replaceAll('9', '')
                valor = valor?.replaceAll('.', '')
                return valor?.trim()
            }

            
            let historico : HistoricoColetaElement[] = JSON.parse(localStorage.getItem('HistoricoColetaCompleto')!)
            if(flagLimite==="1"){
                let anoAtras = new Date((new Date().getTime() - (1.08e7 * 8 * 365*2)))
                historico = historico.filter(x=> new Date(x.dtInstalaco) >= anoAtras)
            }
            setHistorico(historico) 

            let pontos = historico?.map(item=> (mudaPonto(item.pontoColeta)))  
            pontos = _.uniq(pontos)
            const pontosIndexidados = pontos.map((item,index) => (
                {
                    i:index+1,
                    valor: item
                }
            ))
            setPonto(pontosIndexidados)

            let historicoDbo = historico = historico.filter(x=> x.DBO> 0)
            setHistoricoComDBO(historicoDbo)
            
    })()
    }, [flagLimite])

    


    return(

        <>
            {!!historico && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} orientation={"landscape"} break>
  
                                <TableBanner />
                                <TableInfo flagMostrar={flagLimite!}/>
                                <TableHeader flagMostrar={flagLimite} coleta={historico!} />
                                <TableRow mostrar={flagLimite!} historicoDBO={historicoComDBO!}  props={historico} pontos={ponto!} ></TableRow>
                                <View style = {{marginBottom: '24px'}} />
                                <TableLegend pontos={ponto!}/>

                                <TableFooter/>
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default HistoricoCompletosPdf