import { mdiArrowLeft } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { SectionBoxProps } from '../types'

const SectionBox: React.FC <SectionBoxProps> = ({ children, goBack, padding, title, routerTitle, right }) => {

    const navigate = useNavigate()
    const location = useLocation()
    //console.log(location.pathname.split('/').slice(1).join(' > '))

    return(

        <div className = "sectionBox">
            {(!!routerTitle || !!title) &&
                <div className = "sectionBoxHeader">
                    <div className = "sectionBoxHeaderLeftContent">
                        {!!goBack && (
                            <span className = "sectionBoxHeaderGoBack" onClick = {() => navigate(-1)}>
                                <Icon path = {mdiArrowLeft} size = "24px" />
                            </span>
                        )}
                        {(routerTitle && <h2>{location.pathname.split('/').slice(1).join(' > ')}</h2>) || (title && <h2>{title}</h2>)}
                    </div>
                    {!!right && <div className = "sectionBoxHeaderRightContent">{right}</div>}
                </div>
            }
            <div className = {`sectionBoxContent ${typeof padding === 'undefined' ? 'padding' : padding ? 'padding' : ''}`}>{children}</div>
        </div>

    )

}

export default SectionBox