import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setRequestContato, setContato, setRequestContatoError, setRequestContatoMessage} from '../Reducers/ClienteContatoReducer'
import { Contato } from "../Types/Contatos"


export default async function getPessoaContato(dispatch: Function, idCilente: any){
    try {
        

        const endpoint = `${DOMAIN}/PessoaContato`
        const params = `/?idPessoa=${idCilente}`
        const response: RequestResponsePattern<Contato[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestContato(response))
            if(!response.flagErro) {
                dispatch(setContato(response.listaResultados))
            }else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestContatoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestContatoError())
    }
}