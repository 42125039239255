import { utils, writeFile } from 'xlsx';
import { relatorio } from '../types';


export function generateExcelHistorico( historico: relatorio|null, historicoOutros: relatorio[]|null) {

    console.error(historico)
     const fileName = `Historico_hidrometro_lancamento.xlsx`;
     let valoresLimposEsgoto 

     let a: string[][] = []
     let valoreLimposOutros: string[] [] = []

     for (const element of  historicoOutros!) {
        const refs =  element?.val.map(item => (item.ref))
        const vals =  element?.val.map(item => (item.volume.toString()))
        a = [["REGISTRO ", " ",...refs], [element.desc," ",...vals],[" "]]
        console.log(a)
        valoreLimposOutros = [...valoreLimposOutros, ...a]
     }
     
    valoresLimposEsgoto = [
        
        [historico?.desc, " "].concat(historico?.val.map(item => (item.ref))),
        [historico?.desc, " "].concat(historico?.val.map(item => (item.volume.toString()))),
    ]

    const worksheetOutros = utils.aoa_to_sheet(valoreLimposOutros);
    const worksheet = utils.aoa_to_sheet(valoresLimposEsgoto);
    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheetOutros, 'Historico_Outros')
    utils.book_append_sheet(workbook, worksheet, 'Historico_Esgoto')
    writeFile(workbook, fileName)

}