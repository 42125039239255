import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { HistoricoAnalise } from "../Components/PDF_RELATORIO/types"

interface State {
    requestGet : RequestStatusPattern<RequestResponsePattern<HistoricoAnalise>>
    dadosEmpresa : HistoricoAnalise | null
}

const initialState: State = {
    requestGet: {
        data: null,
        loading: false,
        error: false,
        message: null,
    }, dadosEmpresa: null
}

const HistoricoAnaliseSlice = createSlice({
    name: 'HistoricoAnalise',
    initialState,
    reducers: {
        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestEditError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },
        setDadosEmpresa: (state, action: PayloadAction<HistoricoAnalise>) => {
            state.dadosEmpresa = action.payload
        }
    }
})

export const {  
    setRequestEditData, setRequestEditLoading, setRequestEditError, setRequestEditMessage,setDadosEmpresa
} = HistoricoAnaliseSlice.actions
export default HistoricoAnaliseSlice.reducer