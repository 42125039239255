import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { TableColetaColumn } from './types'
import handleTheme from '../scripts/handleTheme'
import { dadosDBO } from '../types'

const TableColetas: React.FC<{listaColetaDBO: dadosDBO[]}> = ({listaColetaDBO}) => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: '24px',
        },
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '10px',
            textAlign: 'center',
        }
    })

    const Column: React.FC <TableColetaColumn> = ({text, width, theme, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0, backgroundColor: theme ?? '#ffffff'}]}>
            <Text>{text}</Text>
        </View>
    )

    return(

        <View style = {styles.container}>
            <View style = {styles.row}>
                <Column text = "Coletas que compõem a média" width = "20%" />
                <Column text = "P" width = "20%" />
                <Column text = "DBO mg/L" width = "60%" last />
            </View>
            {!!listaColetaDBO && listaColetaDBO.map((coleta)=>
                <View style = {styles.row}>
                    <Column text = {`${new Date(coleta.dtColeta).toLocaleDateString()}`} width = "20%" />
                    <Column text = {`${coleta.descricao===''?'0':coleta.descricao.split('.')[0]}`} width = "20%" />
                    <Column text = {`${Number(coleta.dboTotal).toFixed(2)}`} width = "60%" theme = {handleTheme(3)} last />
                </View>
            )}
        </View>

    )

}

export default TableColetas