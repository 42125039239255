import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { EnderecoType } from "../../Parametro/Types/EnderecoType"

interface State {
    requestGet: RequestStatusPattern<RequestResponsePattern<EnderecoType[]>>
    requestSave: RequestStatusPattern<RequestResponsePattern<EnderecoType>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<EnderecoType>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<EnderecoType>>
    requestFilter: RequestStatusPattern<RequestResponsePattern<EnderecoType[]>>

    lista: EnderecoType[] | null
    filteredLista: EnderecoType[] | null
    fieldFiltered: string
    fieldFilteredFlagAtivo: any
    currentData: EnderecoType | null
}

const initialState: State = {
    requestGet: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestSave: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestEdit: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestRemove: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestFilter: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    lista: null,
    filteredLista: [],
    fieldFiltered: '',
    fieldFilteredFlagAtivo: 1,
    currentData: null,
}

const RamoAtividadeSlice = createSlice({
    name: 'RamoAtividade',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<EnderecoType[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },

        setRequestData: (state, action: PayloadAction<RequestResponsePattern<EnderecoType>>) => {
            state.requestSave.data = action.payload
            state.requestSave.loading = false
            state.requestSave.error = false
        },
        setRequestLoading: (state) => {
            state.requestSave.loading = true
            state.requestSave.error = false
        },
        setRequestError: (state) => {
            state.requestSave.loading = false
            state.requestSave.error = true
        },
        setRequestMessage: (state, action: PayloadAction<string>) => {
            state.requestSave.message = action.payload
        },

        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<EnderecoType>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<EnderecoType>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },

        setRequestFilterData: (state, action: PayloadAction<RequestResponsePattern<EnderecoType[]>>) => {
            state.requestFilter.data = action.payload
            state.requestFilter.loading = false
            state.requestFilter.error = false
        },
        setRequestFilterLoading: (state) => {
            state.requestFilter.loading = true
            state.requestFilter.error = false
        },
        setRequestFilterError: (state) => {
            state.requestFilter.loading = false
            state.requestFilter.error = true
        },
        setRequestFilterMessage: (state, action: PayloadAction<string>) => {
            state.requestFilter.message = action.payload
        },

        setLista: (state, action: PayloadAction<EnderecoType[]>) => {
            state.lista = action.payload
        },
        setListaFiltered: (state, action: PayloadAction<EnderecoType[]>) => {
            state.filteredLista = action.payload
        },
        setFieldFiltered: (state, action: PayloadAction<string>) => {
            state.fieldFiltered = action.payload
        },
        setFieldFilteredFlagAtivo: (state, action: PayloadAction<number>) => {
            state.fieldFilteredFlagAtivo = action.payload
        },
        setCurrentData: (state, action: PayloadAction<EnderecoType>) => {
            state.currentData = action.payload
        }
    }
})

export const { 
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,
    setRequestData, setRequestLoading, setRequestError, setRequestMessage,
    setRequestEditData, setRequestEditLoading, setRequestEditError, setRequestEditMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,
    setRequestFilterData, setRequestFilterLoading, setRequestFilterError, setRequestFilterMessage,
    setLista, setFieldFiltered, setListaFiltered, setCurrentData, setFieldFilteredFlagAtivo
} = RamoAtividadeSlice.actions

export default RamoAtividadeSlice.reducer