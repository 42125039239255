import React, {useEffect} from 'react'
import Section, { SectionBox, SectionOption } from '../../../../../../components/Section'
import Tab from '../../../../../../components/Tab'
import { TabData } from '../../../../../../components/Tab/types'
import ListarHistorico from '../ListarHistorico'
import { useParams } from 'react-router-dom'
import ListarHistoricoDiversos from '../ListarHistoricoDiversos'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import getHistoricoLancamentoDiversos from '../../scripts/getHistoricoLancamentoDiversos'
import { mdiMicrosoftExcel } from '@mdi/js'
import { generateExcelHistorico } from '../../scripts/generateExcelHistorico'

const VazaoHistorico: React.FC = () => {

    const [tabIndex, setTabIndex] = React.useState<number>(0)
    const { HistoricoExcel, historicosDiversosExcel } = useAppSelector(s => s.HistoricoLancamentoReducer)

    const tabs: TabData[] = [
        {label: 'Esgoto', component: <ListarHistorico  />},
        {label: 'Outros', component: <ListarHistoricoDiversos  />},
    ]
    const { idCliente } = useParams<any>()
    const dispatch = useAppDispatch()
    useEffect(() => {
        getHistoricoLancamentoDiversos(idCliente, dispatch)
    }, [ dispatch, idCliente])

    return(

        <>
            <Section name="historico">
                <SectionBox
                    title="Cliente"
                    padding={false}
                    goBack
                    right={<SectionOption icon = {mdiMicrosoftExcel} tooltip = "Baixar Excel" onClick = {() => {generateExcelHistorico(HistoricoExcel,historicosDiversosExcel )}} />}
                >
                    <Tab data = {tabs} index = {tabIndex} setIndex = {setTabIndex} /> 
                </SectionBox>
            </Section>
        </>

    )

}

export default VazaoHistorico