import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import { RelatorioPontoColeta } from '../../types'
import TableFooter from './Table/footer'


const RelatorioPontoColetaPdf: React.FC = () => {

    const [relatorio, setRelatorio] = useState<RelatorioPontoColeta[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingBottom: '55px',
            paddingHorizontal: '50px',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setRelatorio(JSON.parse(localStorage.getItem('relatorioPontoColeta')!)) })()
    }, [])

    return(

        <>
            {!!relatorio && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} wrap={false} break>

                                <TableBanner />
                                <TableHeader  />
                                <TableRow relatorios={relatorio}/>
                                <TableFooter/>
  
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioPontoColetaPdf