import React, { useEffect, useState } from 'react'
import { Page, View, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import { RelatorioParametroType } from '../../types'
import TableFooter from './Table/footer'


const RelatorioParametroPdf: React.FC = () => {

    const [relatorio, setRelatorio] = useState<RelatorioParametroType[] | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { let parametros:RelatorioParametroType[] = JSON.parse(localStorage.getItem('relatorioParametro')!)

        const outrosParametros = parametros.filter(x=> x.id !==25 && x.id !==26)
        const novosParamteros =[parametros.find(x=> x.id === 26)!, parametros.find(x=> x.id === 25)!, ...outrosParametros ]
            setRelatorio(novosParamteros) 
        })()
    }, [])

    return(

        <>
            {!!relatorio && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} wrap={false} break>
                            <View style={styles.section}>
                                <TableBanner />
                                <TableHeader coletas={relatorio.map((oRelatorio=> oRelatorio.qtde))} />
                                <TableRow relatorios={relatorio}/>
                                <TableFooter/>
                            </View>
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioParametroPdf