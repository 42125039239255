import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"
import { PontoColetaType } from "../Types/PontosColeta"
import { ClienteType } from "../../Parametro/Types/ClienteType"

interface State {
    //Cliente
    requestGet: RequestStatusPattern<RequestResponsePattern<ClienteType[]>>

    //
    requestFilter: RequestStatusPattern<RequestResponsePattern<ClienteType[]>>

    //
    clients: ClienteType[] | null

    //
    requestSave: RequestStatusPattern<RequestResponsePattern<PontoColetaType>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<PontoColetaType>>

    filteredLista: ClienteType[] | null
    fieldFiltered: string
    anoInicial: any
    anoFinal: any
}

const requestStatusInitial = {
    data: null,
    loading: false,
    error: false,
    message: null,
}

const initialState: State = {
    requestGet: requestStatusInitial,
    requestFilter: requestStatusInitial,
    requestSave: requestStatusInitial,
    requestEdit: requestStatusInitial,

    filteredLista: [],
    fieldFiltered: '',
    anoFinal: '',
    anoInicial: '',
    
    //
    clients: null,
}

const AtualizacoDbo = createSlice({
    name: 'AtualizacoDboReducer',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<ClienteType[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },
        setAnoInicial: (state, action: PayloadAction<any>) => {
            state.anoInicial = action.payload
        },
        setAnoFinal: (state, action: PayloadAction<any>) => {
            state.anoFinal = action.payload
        },

        setRequestFilterData: (state, action: PayloadAction<RequestResponsePattern<ClienteType[]>>) => {
            state.requestFilter.data = action.payload
            state.requestFilter.loading = false
            state.requestFilter.error = false
        },
        setRequestFilterLoading: (state) => {
            state.requestFilter.loading = true
            state.requestFilter.error = false
        },
        setRequestFilterError: (state) => {
            state.requestFilter.loading = false
            state.requestFilter.error = true
        },
        setRequestFilterMessage: (state, action: PayloadAction<string>) => {
            state.requestFilter.message = action.payload
        },
        setClients: (state, action: PayloadAction<ClienteType[]>) => {
            state.clients = action.payload
        },

        setListaFiltered: (state, action: PayloadAction<ClienteType[]>) => {
            state.filteredLista = action.payload
        },
        setFieldFiltered: (state, action: PayloadAction<string>) => {
            state.fieldFiltered = action.payload
        },
    }
})

export const {  
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,

    setRequestFilterData, setRequestFilterLoading, setRequestFilterError, setRequestFilterMessage, 

    setClients,
    setFieldFiltered, setListaFiltered, setAnoFinal, setAnoInicial
} = AtualizacoDbo.actions
export default AtualizacoDbo.reducer