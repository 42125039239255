import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"

import { uploadArquivo } from "../tipes"

interface State {
    requestGet: RequestStatusPattern<RequestResponsePattern<uploadArquivo[]>>
    requestGetArquivo: RequestStatusPattern<RequestResponsePattern<uploadArquivo>>

    requestSave: RequestStatusPattern<RequestResponsePattern<uploadArquivo[]>>
    requestEdit: RequestStatusPattern<RequestResponsePattern<uploadArquivo[]>>
    requestRemove: RequestStatusPattern<RequestResponsePattern<uploadArquivo[]>>

    requestFilter: RequestStatusPattern<RequestResponsePattern<uploadArquivo[]>>

    lista: uploadArquivo[] | null
    arquivoUnico: uploadArquivo | null


    filteredLista: uploadArquivo[] | null
    fieldFiltered: string

    currentData: uploadArquivo[] | null
    laboratorioTeceiro: number
}

const initialState: State = {
    requestGet: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestGetArquivo: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestSave: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestEdit: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestRemove: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
    requestFilter: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },

    
    lista: null,
    arquivoUnico: null,
    filteredLista: [],
    fieldFiltered: '',
    currentData: null,
    laboratorioTeceiro:1
}
const uploadArquivoSlice = createSlice({
    name: 'uploadArquivo[]',
    initialState,
    reducers: {
        setRequestGetData: (state, action: PayloadAction<RequestResponsePattern<uploadArquivo[]>>) => {
            state.requestGet.data = action.payload
            state.requestGet.loading = false
            state.requestGet.error = false
        },
        setRequestGetArquivoData: (state, action: PayloadAction<RequestResponsePattern<uploadArquivo>>) => {
            state.requestGetArquivo.data = action.payload
            state.requestGetArquivo.loading = false
            state.requestGetArquivo.error = false
        },
        setRequestGetLoading: (state) => {
            state.requestGet.loading = true
            state.requestGet.error = false
        },
        setRequestGetError: (state) => {
            state.requestGet.loading = false
            state.requestGet.error = true
        },
        setRequestGetMessage: (state, action: PayloadAction<string>) => {
            state.requestGet.message = action.payload
        },

        setRequestData: (state, action: PayloadAction<RequestResponsePattern<uploadArquivo[]>>) => {
            state.requestSave.data = action.payload
            state.requestSave.loading = false
            state.requestSave.error = false
        },
        setRequestLoading: (state) => {
            state.requestSave.loading = true
            state.requestSave.error = false
        },
        setRequestError: (state) => {
            state.requestSave.loading = false
            state.requestSave.error = true
        },
        setRequestMessage: (state, action: PayloadAction<string>) => {
            state.requestSave.message = action.payload
        },

        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<uploadArquivo[]>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },

        setRequestRemoveData: (state, action: PayloadAction<RequestResponsePattern<uploadArquivo[]>>) => {
            state.requestRemove.data = action.payload
            state.requestRemove.loading = false
            state.requestRemove.error = false
        },
        setRequestRemoveLoading: (state) => {
            state.requestRemove.loading = true
            state.requestRemove.error = false
        },
        setRequestRemoveError: (state) => {
            state.requestRemove.loading = false
            state.requestRemove.error = true
        },
        setRequestRemoveMessage: (state, action: PayloadAction<string>) => {
            state.requestRemove.message = action.payload
        },

        setRequestFilterData: (state, action: PayloadAction<RequestResponsePattern<uploadArquivo[]>>) => {
            state.requestFilter.data = action.payload
            state.requestFilter.loading = false
            state.requestFilter.error = false
        },
        setRequestFilterLoading: (state) => {
            state.requestFilter.loading = true
            state.requestFilter.error = false
        },
        setRequestFilterError: (state) => {
            state.requestFilter.loading = false
            state.requestFilter.error = true
        },
        setRequestFilterMessage: (state, action: PayloadAction<string>) => {
            state.requestFilter.message = action.payload
        },

        setLista: (state, action: PayloadAction<uploadArquivo[]>) => {
            state.lista = action.payload
        },

        setArquivo: (state, action: PayloadAction<uploadArquivo>) => {
            state.arquivoUnico = action.payload
        },
        setListaFiltered: (state, action: PayloadAction<uploadArquivo[]>) => {
            state.filteredLista = action.payload
        },

        setFieldFiltered: (state, action: PayloadAction<string>) => {
            state.fieldFiltered = action.payload
        },

        setCurrentData: (state, action: PayloadAction<uploadArquivo[]>) => {
            state.currentData = action.payload
        },
        setLaboratorio: (state, action: PayloadAction<number>) => {
            state.laboratorioTeceiro = action.payload
        },
    }
})

export const { 
    setRequestGetData, setRequestGetLoading, setRequestGetError, setRequestGetMessage,
    setRequestData, setRequestLoading, setRequestError, setRequestMessage,
    setRequestEditData, setRequestEditLoading, setRequestEditError, setRequestEditMessage,
    setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveError, setRequestRemoveMessage,
    setRequestFilterData ,setRequestFilterLoading, setRequestFilterError, setRequestFilterMessage,
    setLista ,setFieldFiltered, setListaFiltered ,setCurrentData, setArquivo,setRequestGetArquivoData,
    setLaboratorio
} = uploadArquivoSlice.actions

export default uploadArquivoSlice.reducer