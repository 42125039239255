import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { reactLocalStorage as ls } from 'reactjs-localstorage'
import { RequestResponsePattern, RequestStatusPattern } from "../../../types/Request"

import { User } from "../../../types/User"

interface State {
    requestLogin: RequestStatusPattern<RequestResponsePattern<User>>

    userData: User | null

    loading: boolean
    isLogged: boolean
    redirect: boolean
}

const initialState: State = {
    requestLogin: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },

    userData: null,

    loading: true,
    isLogged: false,
    redirect: false
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setRequestLoginData: (state, action: PayloadAction<RequestResponsePattern<User>>) => {
            state.requestLogin.data = action.payload
            state.requestLogin.loading = false
            state.requestLogin.error = false
        },
        setRequestLoginLoading: (state) => {
            state.requestLogin.loading = true
            state.requestLogin.error = false
        },
        setRequestLoginError: (state) => {
            state.requestLogin.loading = false
            state.requestLogin.error = true
        },
        setRequestLoginMessage: (state, action: PayloadAction<string>) => {
            state.requestLogin.message = action.payload
        },

        setAuthLogin: (state, action: PayloadAction<any>) => {
            state.userData = action.payload
            state.isLogged = true
            ls.setObject('user', action.payload)
        },
        setAuthLogout: (state) => {
            state.userData = null
            state.isLogged = false
            ls.remove('user')
        },
        setAuthLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setAuthRedirect: (state, action: PayloadAction<boolean>) => {
            state.redirect = action.payload
        },
        setAuthUserData: (state, action: PayloadAction<any>) => {
            state.userData = action.payload
        }
    }
})

export const { 
    setRequestLoginData, setRequestLoginLoading, setRequestLoginError, setRequestLoginMessage,
    setAuthLogin, setAuthLogout, setAuthLoading, setAuthRedirect, setAuthUserData 
} = authSlice.actions
export default authSlice.reducer