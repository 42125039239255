import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'


const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '12px',
            borderBottom: '1px solid black',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
        },
        text: {
            fontSize: '10px',
            marginTop:"10px"
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        }
        , Container: {
            
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '12px',
        },
    })

    return(
        <>
        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>MONITORAMENTO DE EFLUENTES INDUSTRIAIS</Text>
                <Text style = {styles.subTitle}>PONTOS DE AMOSTRAGEM INDUSTRIAL</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>
        </>
    )

}

export default TableBanner