import { DOMAIN } from "../../../var_env."
import { RequestResponsePattern } from "../../../types/Request"
import { DefaultType } from '../../../store/types/DefaultType'
import { FilterParams } from "../types"
import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../../../store/reducers/default/DefaultReducer"
import { getApi } from "../../../utils/requestApi"

export default async function filter(apiController: string, dispatch: Function, values: FilterParams){
    try {
        dispatch(setRequestFilterLoading())

        const response: RequestResponsePattern<DefaultType[]> = await getApi({ endpoint: `${DOMAIN}/${apiController}`, params: `/?descricao=${values.descricao}&flagAtivo=${values.flagAtivo === 'null' ? '' : values.flagAtivo}` });

        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) dispatch(setListaFiltered(response.listaResultados.filter(item => item.descricao.toLowerCase().includes(values.descricao.toLowerCase()))))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}