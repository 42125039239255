import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setProtocolos, setRequestGetData, setRequestGetLoading } from "../Reducers/ProtocoloReducer"
import { IProtocolo } from "../Types/IProtocolo"


export default async function getProtocolos(dispatch: Function) {
    try {
        dispatch(setRequestGetLoading())

        const endpoint = DOMAIN
        const params = `/protocolo/getAll`
        const response: RequestResponsePattern<IProtocolo[]> = await getApi({ endpoint, params })

        if (response) {
            dispatch(setRequestGetData(response))
            if (!response.flagErro) {
                dispatch(setProtocolos(response.listaResultados))

            } else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)

    }
}