import React, { useEffect, useState } from 'react'
import { Page, View, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableBanner from './Table/banner'
import TableHeader from './Table/header'
import TableInfo from './Table/info'
import TableColetas from './Table/coletas'
import TableConformidades from './Table/conformidades'
import TableStatus from './Table/status'
import { HistoricoAnalise } from './types'

const RelatorioPDF: React.FC = () => {

    const [dados, setDados] = useState<HistoricoAnalise | null>(null)

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { 
            setDados(JSON.parse(localStorage.getItem('DADOS_ANALISE')!)) 
        })()
    }, [])

    return(

        <>
            {!!dados && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} break>
                            <View style={styles.section}>
                                <TableBanner />
                                <TableHeader />
                                <TableInfo dadosEmpresa={dados} />
                                <TableStatus />

                                <TableColetas listaColetaDBO={dados.listaColetaDBO} />
                                <TableConformidades coletasNaoConformes={dados.coletasNaoConformes} />
                            </View>
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default RelatorioPDF