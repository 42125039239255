import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import {  setListaFiltered, setListaId, setRequestFilterData, setRequestFilterLoading, setRequestGetError, setRequestGetMessage } from "../Reducers/RelatorioSequencialReducer"
import { getApi } from "../../../../../utils/requestApi"



export default async function filter(dispatch: Function, values:any){
    try {
        dispatch(setRequestFilterLoading())

        const endpoint = DOMAIN
        const params = `/RelatorioSequencial/?dtInicio=${values.dtInicio}&dtFim=${values.dtFim}`
        const response: RequestResponsePattern<any[]> = await getApi({ endpoint, params })
        
        console.log(response.listaResultados)
        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) {
                dispatch(setListaId(response.listaResultados.map(i => i.idCliente)))
                dispatch(setListaFiltered(response.listaResultados))

                localStorage.setItem('dataBusca', JSON.stringify({inicio:values.dtInicio, fim: values.dtFim}))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}