import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAINmylims } from "../../../../../var_env."

export default async function coletaEspecifica( data: any){
    try {

        
        const endpoint = `${DOMAINmylims}/Migracao/?idPessoa=${data}`

        const response: RequestResponsePattern<any[]> = await getApi({ endpoint, params:"" })

        if(response){

            if(!response.flagErro) {
                return response.listaResultados
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        return []
    }
}