import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const TableHeader: React.FC = () => {

    const styles = StyleSheet.create({
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            paddingVertical: '12px',
            paddingHorizontal: '24px',
            marginBottom: '12px',
            border: '1px solid black',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            fontSize: '16px',
            fontWeight: 'bold',
        },
        subTitle: {
            fontSize: '10px',
        },
        codeContainer: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: '10px',
            border: '1px solid black',
        },
        codeText: {
            fontSize: '10px',
        },
    })

    return(

        <View style = {styles.header}>
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>HISTÓRICO DAS ANÁLISES PELA CSJ</Text>
                <Text style = {styles.subTitle}>O Histórico completo pode ser solicitado junto à CSJ</Text>
            </View>
            <View style = {styles.codeContainer}>
                <Text style = {styles.codeText}>0000/00</Text>
            </View>
        </View>

    )

}

export default TableHeader