export default function createParams(lista: string[] ,data: any){
    try {

        const value :any = {}

        for (const key of lista) {
            value[key] = data[key]
        }

        return value
        
    } catch (error: any) {

        return {}
    }
}