import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { Formik, Form as FormikContainer } from 'formik'
import { mdiPlusCircle } from '@mdi/js'
import { useAppSelector } from '../../../../../store/hooks'
import Section, { SectionBox, SectionOption } from '../../../../../components/Section'
import Modal from '../../../../../components/Modal'
import edit from '../scripts/edit'
import send from '../scripts/send'
import remove from '../scripts/remove'
import getRamoAtividade from '../scripts/getPessoaEndereco'
import Form from '../../../../../components/Form'
import TableListarComponents from './components/TableListarComponents'
import { clienteEnderecoFormValues } from '../scripts/Form/clienteEnderecoFormValues'
import getAddress from '../../../../../scripts/address/getAddress'

const Endereco: React.FC<{canEdit: boolean}> = ({canEdit}) => {

    const dispatch = useDispatch()
    const { requestGet,lista, currentData, requestSave, requestEdit, requestRemove } = useAppSelector(state => state.EnderecoReducer)
    const { idCliente } = useParams<any>()
    const { userData } = useAppSelector(state => state.auth)

    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    const [flagAtivo, setFlagAtivo] = useState<string>('1')

    const FormActions: React.FC<{ label: string }> = ({ label }) => {
        const isLoading = requestEdit.loading || requestRemove.loading || requestSave.loading
        return (
            <div className="modalBoxMainActionsContainer">
                <button type="reset" className="outline" onClick={() => handleFecharModal()}>Cancelar</button>
                <button type="submit" className={`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className="spinner" />}{label}</button>
            </div>
        )
    }

    const handleFecharModal = useCallback(() => {
        setOpenCriar(false)
        setOpenEditar(false)
        setOpenRemover(false)
        setFlagAtivo('1')
    }, [])

    const handleCriar = useCallback(async (descricao: any) => {
        await send(dispatch, { descricao, flagAtivo: Number(flagAtivo), idUsuario: Number(idCliente!) })
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, idCliente])

    const handleEditar = useCallback(async (descricao: any) => {
        await edit(dispatch, { descricao, flagAtivo: Number(flagAtivo), idUsuario: userData!.id, id: currentData!.id })
        handleFecharModal()
    }, [dispatch, handleFecharModal, flagAtivo, currentData, userData])

    const handleRemover = useCallback(async () => {
        await remove(dispatch, { id: currentData!.id, idUsuario: userData!.id })
        handleFecharModal()
    }, [dispatch, handleFecharModal, currentData, userData])

    const handleCEP = useCallback(async (values: typeof clienteEnderecoFormValues, setFieldValue: Function) => {
        if(values.cep.length === 8){
            const address = await getAddress(values.cep)
            if(!!address){
                setFieldValue('endereco', address.logradouro)
                setFieldValue('bairro', address.bairro)
                setFieldValue('cidade', address.localidade)
                setFieldValue('uf', address.uf)
            }
        }
    }, [])

    useEffect(() => {
        (async () => { await getRamoAtividade(dispatch, idCliente) })()
    }, [dispatch, requestSave.data, requestEdit.data, requestRemove.data, idCliente])

    return (
        <>
            <Section name="Endereco">
                <SectionBox title="Endereços"  right={
                <>
                {canEdit && <SectionOption icon={mdiPlusCircle} tooltip="Criar" onClick={() => setOpenCriar(true)} /> }
                </>

                }>
                    <TableListarComponents canEdit={canEdit} lista={lista} loading = {requestGet.loading} setOpenEditar = {setOpenEditar} setOpenRemover={setOpenRemover} />
                </SectionBox>
            </Section>
            <>
                <Modal width={800} open={openCriar} setOpen={setOpenCriar} title="Adicionar endereço">
                    <Formik initialValues={clienteEnderecoFormValues} enableReinitialize={true} onSubmit={values => handleCriar(values)}>
                        {({ values, setFieldValue }) => (
                            <FormikContainer>
                                <Form.Container padding={false} >
                                    <Form.Row columns={1} grid = "160px">
                                        <Form.Group inputID="cep" inputName="cep" label="CEP" onChange={() => handleCEP(values, setFieldValue)} />
                                    </Form.Row>
                                    <Form.Row columns={3} grid = "1fr 100px 200px">
                                        <Form.Group inputID="endereco" inputName="endereco" label="Endereço" />
                                        <Form.Group inputID="numero" inputName="numero" label="Número" />
                                        <Form.Group inputID="complemento" inputName="complemento" label="Complemento" />
                                    </Form.Row>
                                    <Form.Row columns={3} grid = "1fr 100px 300px">
                                        <Form.Group inputID="bairro" inputName="bairro" label="Bairro" />
                                        <Form.Group inputID="uf" inputName="uf" label="UF" />
                                        <Form.Group inputID="cidade" inputName="cidade" label="Cidade" />
                                    </Form.Row>
                                    <Form.Row columns={2} grid = "150px 150px">
                                        <Form.Group inputID="latitude" inputName="latitude" label="Latitude" />
                                        <Form.Group inputID="longitude" inputName="longitude" label="Longitude" />
                                        <Form.Group inputID="linkMapa" inputName="linkMapa" label="Link do Mapa" />
                                    </Form.Row>
                                    <FormActions label="Adicionar" />
                                </Form.Container>
                            </FormikContainer>
                        )}
                    </Formik>
                </Modal>

                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover endereço">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => handleFecharModal()}>Cancelar</button>
                        <button className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>

                <Modal width = {600} open = {openEditar} setOpen = {setOpenEditar} title = "Editar endereço">
                    <Formik enableReinitialize={true} initialValues = {{endereco: currentData?.logradouro, numero: currentData?.numero, complemento: currentData?.complemento, cep: currentData?.cep, bairro: currentData?.bairro, cidade: currentData?.cidade, uf: currentData?.uf, latitude: currentData?.latitude, longitude:currentData?.longitude, linkMapa: currentData?.linkMapa}} onSubmit = {values => handleEditar(values)}>
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <Form.Row columns={1}>
                                    <Form.Group inputID="cep" inputName="cep" label="CEP" />
                                </Form.Row>
                                <Form.Row columns={3} grid = "1fr 100px 200px">
                                    <Form.Group inputID="endereco" inputName="endereco" label="Endereço" />
                                    <Form.Group inputID="numero" inputName="numero" label="Número" />
                                    <Form.Group inputID="complemento" inputName="complemento" label="Complemento" />
                                </Form.Row>
                                <Form.Row columns={3} grid = "1fr 100px 300px">
                                    <Form.Group inputID="bairro" inputName="bairro" label="Bairro" />
                                    <Form.Group inputID="uf" inputName="uf" label="UF" />
                                    <Form.Group inputID="cidade" inputName="cidade" label="Cidade" />
                                </Form.Row>
                                <Form.Row columns={2} grid = "150px 150px 1fr">
                                        <Form.Group inputID="latitude" inputName="latitude" label="Latitude" />
                                        <Form.Group inputID="longitude" inputName="longitude" label="Longitude" />
                                        <Form.Group inputID="linkMapa" inputName="linkMapa" label="Link do Mapa" />
                                    </Form.Row>
                                <FormActions label = "Editar" />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
            </>
        </>
    )

}

export default Endereco