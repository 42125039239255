import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import {  postApi } from "../../../../../utils/requestApi"
import { dadosRelatorioDBO } from "../Components/PDF_DBO/types" 

export default async function getDadosDBO(  idPessoa:Number){
    try {

        const endpoint = `${DOMAIN}/relatorio-dbo`
        const response: RequestResponsePattern<dadosRelatorioDBO[]> = await postApi({ endpoint, data:{idPessoa:idPessoa} })
        
        if(response){
            
            if(!response.flagErro) {
                localStorage.setItem("DADOS_DBO", JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        
    }
}