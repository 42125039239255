import { DOMAIN } from "../../../../../var_env."
import {  setRequestRemoveError, setRequestRemoveData, setRequestRemoveLoading, setRequestRemoveMessage } from "../Reducer/UploadArquivoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { deleteApi } from "../../../../../utils/requestApi"

import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import createAlert from "../../../../../scripts/alert/createAlert"
import { uploadArquivo } from "../tipes"

export default async function remove(dispatch: Function, values: any){
    try {
        dispatch(setRequestRemoveLoading())
        
        const endpoint = `${DOMAIN}/UploadArquivo`
        const params = { id: values.id, idUsuario: values.idUsuario }
        const response: RequestResponsePattern<uploadArquivo[]> = await deleteApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestRemoveData(response))
            if(!response.flagErro) {
                dispatch(setRequestRemoveData(response))
                dispatch(setAlert(createAlert('success', 'Arquivo excluido com sucesso')))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestRemoveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestRemoveError())
    }
}