import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setRequestSaveData, setRequestSaveError, setRequestSaveLoading, setRequestSaveMessage } from "../Reducers/ParametroReducer"
import { ParametroType } from "../Types/ParametroType"

export default async function send(apiController: string, dispatch: Function, values: any){
    try {
        dispatch(setRequestSaveLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`

        const data = { 
            idParametro: values.idParametro,
            descricao: values.descricao,
            flagAtivo: values.flagAtivo ? 1 : 0, 
            idUsuario: values.idUsuario
        }

        const response: RequestResponsePattern<ParametroType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestSaveData(response))
            if(!response.flagErro) dispatch(setRequestSaveData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestSaveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestSaveError())
    }
}