import {  mdiPlusCircle } from '@mdi/js'
import { Formik, Form as FormikContainer } from 'formik'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import Form from '../../../../../components/Form'
import Modal from '../../../../../components/Modal'
import Section, {SectionBox, SectionOption } from '../../../../../components/Section'
import Select from '../../../../../components/Select'
import { activeItems } from '../../../../../constants/selects'
import { useAppSelector } from '../../../../../store/hooks'
import editContato from '../scripts/editContato'
import getPessoaContato from '../scripts/getPessoaContato'
import removeContato from '../scripts/removeContato'
import sendContato from '../scripts/sendContato'
import TableListarComponentsContatos from './components/TableListarComponentsContatos'

const Contato: React.FC<{canEdit: boolean}> = ({canEdit}) => {

    const { idCliente } = useParams<any>()
    
    const dispatch = useDispatch()
    const { currentData, Contato, requestEdit, requestData, requestRemove } = useAppSelector(state => state.ClienteContato)
    const { userData } = useAppSelector(state => state.auth)

    const [openCriar, setOpenCriar] = useState<boolean>(false)
    const [openEditar, setOpenEditar] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    const [flagAtivo, setFlagAtivo] = useState<string>('')

    const FormActions: React.FC <{label: string, setFechar: any}> = ({ label, setFechar }) => {
        const isLoading = requestEdit.loading || requestData.loading 
        return(
            <div className = "modalBoxMainActionsContainer">
                <button type = "reset" className = "outline" onClick = {() => setFechar(false)}>Cancelar</button>
                <button type = "submit" className = {`default ${isLoading ? 'status disabled' : ''}`}>{isLoading && <div className = "spinner" />}{label}</button>
            </div>  
        )
    }

    const handleCriar = useCallback(async (values: any) => {
        const data = {
            idPessoa: Number(idCliente),
            obs: values.obs,
            nome: values.nome,
            departamento: values.departamento,
            telefone: values.telefone,
            email: values.email,
            flagAtivo: Number(flagAtivo),
            idUsuario: userData!.id
        }
        await sendContato(dispatch, data)
        setOpenCriar(false)
    }, [dispatch, idCliente, userData, flagAtivo])

    const handleEditar = useCallback(async (values: any) => {
        const data = {
            id: currentData?.id,
            idPessoa: Number(idCliente),
            obs: values.obs,
            nome: values.nome,
            departamento: values.departamento,
            telefone: values.telefone,
            email: values.email,
            flagAtivo: Number(flagAtivo),
            idUsuario: userData!.id
        }
        await editContato(dispatch, data)
        setOpenEditar(false)
    }, [dispatch, idCliente, userData, currentData, flagAtivo])

    const handleRemover = useCallback(async () => {
        const data = {
            id: currentData?.id,
            idUsuario: userData!.id
        }
        await removeContato(dispatch, data)
        setOpenRemover(false)
    }, [dispatch, userData, currentData])
 
    useEffect(() => {
        (async() => { await getPessoaContato(dispatch, idCliente) })()
    }, [dispatch, idCliente, requestData, requestEdit, requestRemove])
    
    return(
        <>
            <Section name="contatos">
                <SectionBox title='Contatos' padding={false} right={
                    <>
                    {canEdit && <SectionOption icon={mdiPlusCircle} tooltip="Criar" onClick={() => setOpenCriar(true)} />}
                    </>
                
                }>
                    <TableListarComponentsContatos 
                        lista={Contato}
                        loading={requestData.loading}
                        setOpenRemover={setOpenRemover}
                        setOpenEditar={setOpenEditar}
                        canEdit={canEdit}
                    />
                </SectionBox>
            </Section>
            <>
                <Modal width={1200} open={openCriar} setOpen={setOpenCriar} title="Novo">
                    <Formik
                        initialValues={{
                            nome: '',
                            departamento: '',
                            telefone: '',
                            email: '',
                            obs:''
                        }}
                        onSubmit={values => handleCriar(values)}
                    >
                        <FormikContainer>
                            <Form.Container padding={false}>
                                <Form.Row columns={2} grid={"1fr 1fr"}>
                                    <Form.Group inputID="nome" inputName="nome" label="Nome" />
                                    <Form.Group inputID="departamento" inputName="departamento" label="Departamento" />
                                </Form.Row>

                                <Form.Row columns={3} grid={"1fr 1fr"}>
                                    <Form.Group inputID="telefone" inputName="telefone" label="Telefone"  />
                                    <Form.Group inputID="email" inputName="email" label="E-mail" />
                                </Form.Row>

                                <Form.Row columns={2} grid={"1fr 150px"}>
                                    <Form.Group inputID="obs" inputName="obs" label="OBS"  />
                                    <Select type = "outline" defaultValue={'1'} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>

                                <FormActions label = "Adicionar"
                                setFechar={setOpenCriar}
                                />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width={1200} open={openEditar} setOpen={setOpenEditar} title="Editar">
                    <Formik
                        initialValues={{
                            nome: currentData?.nome,
                            departamento: currentData?.departamento,
                            telefone: currentData?.telefone,
                            email: currentData?.email,
                            obs: currentData?.obs
                        }}
                        onSubmit={values => handleEditar(values)}
                    >
                        <FormikContainer>
                            <Form.Container padding={false}>
                                <Form.Row columns={2} grid="1fr 1fr">
                                    <Form.Group inputID="nome" inputName="nome" label="Nome" />
                                    <Form.Group inputID="departamento" inputName="departamento" label="Departamento" />
                                </Form.Row>

                                <Form.Row columns={2} grid="1fr 1fr">
                                    <Form.Group inputID="telefone" inputName="telefone" label="Telefone"  />
                                    <Form.Group inputID="email" inputName="email" label="E-mail" />
                                </Form.Row>

                                <Form.Row columns={2} grid="1fr 150px">
                                    <Form.Group inputID="obs" inputName="obs" label="OBS"  />
                                    <Select type = "outline" defaultValue={currentData?.flagAtivo.toString()} value = {flagAtivo} label = "Situação" setValue = {setFlagAtivo} items = {activeItems} />
                                </Form.Row>
                                <FormActions label="Editar" setFechar={setOpenEditar} />
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                </Modal>
                <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover cobrança">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => setOpenRemover(false)}>Cancelar</button>
                        <button  className = {`default ${requestRemove.loading ? 'status disabled' : ''}`} onClick = {() => handleRemover()}>{requestRemove.loading && <div className = "spinner" />}Remover</button>
                    </div>
                </Modal>
            </>
        </>
    )

}

export default Contato