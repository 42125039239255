import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/AtualizacoDboReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { ClienteType } from "../../Parametro/Types/ClienteType"
//import { FilterParams } from "../../Parametro/Types/ClienteType"
    
export default async function filter(dispatch: Function, values: any){
    try {
        dispatch(setRequestFilterLoading())
        const response: RequestResponsePattern<ClienteType[]> = await getApi({ endpoint: `${DOMAIN}/Pessoa/AtualizacaoDBO`, params: `/?descricao=${values.descricao}&dtInicio=${values.anoInicial === '' ? '' : values.anoInicial}&dtFim=${values.anoFinal === '' ? '' : values.anoFinal}` });
        
        if(response){
            
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) dispatch(setListaFiltered(response.listaResultados.filter(item => item.nome.toLowerCase().includes(values.descricao.toLowerCase()))))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}