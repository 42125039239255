import { setAuthLogin, setAuthRedirect, setAuthUserData, setRequestLoginData, setRequestLoginError, setRequestLoginLoading, setRequestLoginMessage } from "../../store/reducers/auth/authReducer"
import { RequestResponsePattern } from "../../types/Request"
import { User } from "../../types/User"
import { postApi } from "../../utils/requestApi"
import { DOMAIN } from "../../var_env."
import getAppAccessAuthorizedModules from "../modules/getAppAccessAuthorizedModules"

export default async function setLogin(dispatch: Function, values: { login: string, password: string }) {
    try {
        dispatch(setRequestLoginLoading())

        const endpoint = `${DOMAIN}/Usuario/login`
        const data = {
            login: values.login,
            senha: values.password
        }
        const result: RequestResponsePattern<User> = await postApi({endpoint, data})
        if(result){
            dispatch(setRequestLoginData(result))
            if(!result.flagErro){
                getAppAccessAuthorizedModules(dispatch, result.listaResultados.GrupoAcesso.id, true)
                dispatch(setAuthUserData(result.listaResultados))
                result.listaResultados.flagAlterarSenha ? dispatch(setAuthRedirect(true)) : dispatch(setAuthLogin(result.listaResultados))
           
            }else throw new Error(result.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestLoginMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestLoginError())
    }
}