import { mdiAccount, mdiLock } from '@mdi/js'
import { Icon as MdiIcon } from '@mdi/react'
import { Field, Form as FormikContainer, Formik } from 'formik'
import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import Logo from '../../../assets/images/logo.png'
import Modal from '../../../components/Modal'
import setLogin from '../../../scripts/auth/setLogin'
import { useAppSelector } from '../../../store/hooks'
import { postApi } from '../../../utils/requestApi'
import { DOMAIN } from '../../../var_env.'
import './styles.scss'

const LoginPage: React.FC = () => {

    const dispatch = useDispatch()
    const { requestLogin, redirect } = useAppSelector(state => state.auth)

    const [open, setOpen] = React.useState(false)

    const handleSubmitEmail = useCallback(async (email: string) => { await postApi({ endpoint: `${DOMAIN}/Usuario/recoverPassword`, data: { email } }); setOpen(false) }, [])

    return (

        <>
            {redirect && <Navigate to="redefinir-senha" />}

            <Modal open={open} setOpen={setOpen} title="Digite o seu email">
                <Formik
                    initialValues={{ email: '' }}
                    onSubmit={values => handleSubmitEmail(values.email)}
                >
                    <FormikContainer style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                        <p>Enviaremos um link ao seu email para que você possa redefinir sua senha.</p>

                        <Field name="email" type="text" placeholder="Email" />
                        <input type="submit" value="Enviar" />
                    </FormikContainer>
                </Formik>
            </Modal>

            <div id="formLoginContainer">
                <div className="loginContent">
                    <h1>Monitoramento de</h1>
                    <h2>efluentes industriais</h2>
                </div>
                <div className="loginContent">
                    <div className="custom-shape-divider-top-1640025532">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                            <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                        </svg>
                    </div>
                </div>

                <div className="loginContent">
                    <Formik
                        initialValues={{ login: '', password: '' }}
                        onSubmit={values => setLogin(dispatch, values)}
                    >
                        <FormikContainer>
                            <div id="formContainer">
                                <img src={Logo} alt="Logo" />
                                <div className="inputContainer">
                                    <MdiIcon path={mdiAccount} size="24px" />
                                    <Field name="login" type="text" placeholder="Usuário" />
                                </div>
                                <div className="inputContainer">
                                    <MdiIcon path={mdiLock} size="24px" />
                                    <Field name="password" type="password" placeholder="Senha" />
                                </div>
                                {requestLogin.error && <span>{requestLogin.message}</span>}
                                <button type = "submit" className = {`default ${requestLogin.loading ? 'status disabled' : ''}`}>{requestLogin.loading && <div className = "spinner" />}Entrar</button>
                                <span className = "forgotPWD" onClick={(e) => { e.preventDefault(); setOpen(true) }}>Esqueceu a senha?</span>
                            </div>
                        </FormikContainer>
                    </Formik>
                </div>
            </div>


        </>

    )

}

export default LoginPage