import React, { useEffect, useState } from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { HistoricoColetaElement } from '../../../../Types/HistoricoColeta'

const TableHeader: React.FC<{coleta:HistoricoColetaElement[], flagMostrar:any}> = ({coleta, flagMostrar}) => {
    
    const [resultado, setResultado] = useState<number | null>(null)
    const [resultadoAnterior, setResultadoAnterior] = useState<number | null>(null)
    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '12px',
            fontWeight: 'bold',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        container: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
        }
    })

    useEffect(() => {
            setResultado(JSON.parse(localStorage.getItem('resultadoSoma')!))
            setResultadoAnterior(JSON.parse(localStorage.getItem('resultadoSomaAnterior')!))
}, [])
    return(
        <>
<View style={{marginBottom:'20px'}}></View>
        <View style = {styles.container}>
            <View style={{ width: '40%',flexDirection: 'column'}}>
            <Text style={{fontSize: '10px', marginBottom:'4px'}}> As regras para a determinação da DBO de cobrança são definidas na</Text>
            <Text style={{fontSize: '10px', marginBottom:'4px'}}>Norma Técnica Nt 001_DAE-Jundiaí e podem ser consultadas em:</Text>
            <Text style={{fontSize: '10px', marginBottom:'4px'}}>https://daejundiai.com.br/legislacao/normas-tecnicas/</Text>
            </View>
            <View style={{ width: '60%',flexDirection: 'column', alignItems:"center"}}>
  <View style={{flexDirection: 'row'}}>
                    <Text style={{fontSize: '10px', marginBottom:'4px', fontWeight:900}}>DBO. valor apurado pela CSJ (Kg/m³) </Text>
                    <Text style={{fontSize: '10px'}}>Anterior:  </Text>
                    <Text style={{fontSize: '10px', border: "1px solid black", padding:"2px"}}>{` ${resultadoAnterior!<0.3? "0,300": resultadoAnterior?.toFixed(3).replace(".",",")}`} </Text>  
                    <Text style={{fontSize: '10px'}}> {"  Atual"}:  </Text>  
                    <Text style={{fontSize: '10px',border: "1px solid black", padding:"2px"}}>{` ${resultado!<0.3? "0,300": resultado?.toFixed(3).replace(".",",")}`}</Text>  
                </View>

            <Text style={{fontSize: '10px', marginBottom:'4px'}}>Observação: DBO mínima industrial 0,300 kg/m³ (Norma Técnica NT001_DAE-Jundiaí)</Text>
            </View>
        </View>
            <View style={{marginBottom:'20px'}}></View>
            <Text>Histórico</Text>
        </>
    )

}

export default TableHeader