import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'


const TableBanner: React.FC = () => {

    const [data, setData] = useState<{inicio:string, fim:string}>()
    
    useEffect(() => {
        (async() => { setData(JSON.parse(localStorage.getItem('dataBusca')!)) })()
    }, [])

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '24px',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
        },
        text: {
            fontSize: '10px',
        },title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        }
    })

    return(
        <>
        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>PARÂMETROS ANALISADOS</Text>
                <Text style = {styles.subTitle}>{`PERÍODO DE: ${new Date(`${data?.inicio} 00:00:00`).toLocaleDateString()} ATÉ ${new Date(`${data?.fim} 00:00:00`).toLocaleDateString()}`}</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>
        </>
    )

}

export default TableBanner