import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { Users } from "../../Types/Users"
import { setUsuarios, setRequestGetUsersData, setRequestGetUsersError, setRequestGetUsersLoading, setRequestGetUsersMessage } from "../../Reducers/UsuarioReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getUsuarios(dispatch: Function){
    try {
        dispatch(setRequestGetUsersLoading())

        const endpoint = DOMAIN
        const params = `/Usuario`
        const response: RequestResponsePattern<Users[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestGetUsersData(response))
            if(!response.flagErro) dispatch(setUsuarios(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetUsersMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetUsersError())
    }
}