import React, { useEffect, useState } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableInfoTextContainer } from '../types'



const TableInfo: React.FC<{flagMostrar: string}> = ({flagMostrar}) => {
    const [cliente, setCliente] = useState<any | null>(null)
    const [endereco, setEndereco] = useState<any | null>(null)
    const [hidrometro, setHidrometro] = useState<any[] | null>(null)
    const [sequencia, setSequencia] = useState<{sequencial:string} | null>(null)

    useEffect(() => {

         setCliente(JSON.parse(localStorage.getItem('ClienteHistorico')!)) 
         setEndereco(JSON.parse(localStorage.getItem('EnderecoHistorico')!)) 
         setHidrometro(JSON.parse(localStorage.getItem('PessoaHidrometro')!))

         setSequencia(JSON.parse(localStorage.getItem('sequencia')!))

    }, [])


    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '85%',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '4px',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            marginRight: '4px',
            fontSize: '10px',
        },
        textField: {
            flex: 1,
            padding: '2px',
            border: '1px solid black',
        },
        text: {
            fontSize: '10px',
        },
        dateBox: {
            paddingVertical: '8px',
            paddingHorizontal: '12px',
            border: '1px solid black',
            fontSize: '10px',
        },
        Container: {
            display:"flex",
            flexDirection: "column",
            width: "15%",

        },
        containerAgrupamento:{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        }
    })
    
    const TextContainer: React.FC <TableInfoTextContainer> = ({ label, text, containerWidth, labelWidth, marginRight }) => (
        <View break style = {[styles.textContainer, {width: containerWidth ?? '100%', marginRight: marginRight ? '8px' : '0'}]}>
            <Text style = {[styles.label, {width: labelWidth ?? 'auto'}]}>{label}</Text>
            <View style = {styles.textField}>
                <Text style = {styles.text}>{text}</Text>
            </View>
        </View>
    )

    return(
        <>
        <View style={styles.containerAgrupamento}>

                <View style = {styles.container}  >  
                    <View style = {styles.row}>
                        <TextContainer 
                            label = "Cliente:" 
                            text = {cliente?.nome} 
                            labelWidth = "40px"
                            containerWidth = "100%"
                            marginRight 
                        />
                    </View>
                    <View style = {styles.row}>
                        <TextContainer 
                            label = "CEP:" 
                            text = {endereco?.cep}
                            labelWidth = "40px"
                            containerWidth = "30%"
                            marginRight
                        />
                        <TextContainer 
                            label = "Rua:" 
                            text = {endereco?.logradouro}
                            labelWidth = "20px"
                            containerWidth = "30%"
                            marginRight 
                        />
                        <TextContainer 
                            label = "Nº:" 
                            text = {endereco?.numero}
                            containerWidth = "20%"
                            marginRight 
                        />
                        <TextContainer 
                            label = "Compl:" 
                            text = {endereco?.complemento??" "}
                            containerWidth = "35%"
                            marginRight
                        />
                    </View>
                    <View style = {styles.row}>
                        <TextContainer 
                            label = "Bairro:" 
                            text = {endereco?.bairro}
                            labelWidth = "40px"
                            marginRight 
                        />
                        <TextContainer label = "Cidade:" text = {endereco?.cidade} marginRight />
                        <TextContainer 
                            label = "UF:" 
                            text = {endereco?.uf}
                            containerWidth = "25%"
                            marginRight
                        />
                    </View>
                {
                    hidrometro && hidrometro?.length>0 && hidrometro?.find((x: { flagAtivo: number; flagMedidorVazao: number }) =>x.flagAtivo===1 && x.flagMedidorVazao ===1)
                    &&
                    <View style = {styles.row}>
                    <TextContainer 
                    label = {"UC:" }
                    text = {`${hidrometro?.find((x: { flagAtivo: number; flagMedidorVazao: number }) =>x.flagAtivo===1 && x.flagMedidorVazao ===1).uc}`}
                    labelWidth = "40px"
                    containerWidth='20%'

                    />
                    </View>
                }

                {
                    hidrometro && hidrometro?.length>0 && !(hidrometro?.find((x: { flagAtivo: number; flagMedidorVazao: number }) =>x.flagAtivo===1 && x.flagMedidorVazao ===1))
                    &&
                    <View style = {styles.row}>
                        {hidrometro.filter(x=> x.flagAtivo===1).map((item, index) =>(
                    <TextContainer 
                    label = {`UC${index+1}:` }
                    text = {`${item.uc??" "}`}
                    labelWidth = "40px"
                    containerWidth='35%'
                    marginRight
                    />
                        ))}



                    </View>
                }


                {
                    hidrometro?.length===0 &&
                    
                    <View style = {styles.row}>
                    <TextContainer 
                    label = {"UC:" }
                    text = {` `}
                    labelWidth = "40px"
                    containerWidth='35%'

                    />
                    </View>
                }

                </View>

                {flagMostrar==="2" && 
                <View style={[styles.Container, { objectPositionX:"absolute"}]} >
  
                    <View style={{ border: '1px solid black', padding:"20px"}}>
                        <Text style={{border:"1px solid black", padding:"8px"}}>{sequencia?.sequencial}</Text>   
                     </View>

                </View>
                }

        </View>

        </>
    )

}

export default TableInfo