import React, { useCallback } from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiEye, mdiPencil } from '@mdi/js'
import { useDispatch } from 'react-redux'
import { Grupo } from '../../Types/Grupo'
import { setCurrentGrupo } from '../../Reducers/GrupoAcessoReducer'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'

interface Props {
    grupo: Grupo[] | null
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
    canEdit: boolean
}

const TableListarComponents: React.FC <Props> = ({ grupo, loading, setOpenEditar, setOpenRemover, canEdit }) => {

    const dispatch = useDispatch()

    const grid = "repeat(2, 1fr) 160px 92px"

    const SHOW_LOADING = loading
    const SHOW_DATA = !!grupo && !SHOW_LOADING

    const handleFunc = useCallback((grupo: Grupo, setOpen: (open: boolean) => void) => {
        dispatch(setCurrentGrupo(grupo))
        setOpen(true)
    }, [dispatch])

    return(

        <>
            <List name = "grupoAcesso">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["Perfil", "Status", "Cadastro", canEdit && 'Opções'].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = "1fr">
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_DATA && grupo.map((item, index) => (
                        <ListRow key = {item.id} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.nome}</ListColumn>
                            <ListColumn>{item.flagAtivo ? 'Habilitado' : 'Desabilitado'}</ListColumn>
                            <ListColumn>{new Date(item.dtCadastro).toLocaleString()}</ListColumn>
                            {canEdit && <ListColumn>
                                <ListOption icon = {mdiPencil} onClick = {() => handleFunc(item, setOpenEditar)} />
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleFunc(item, setOpenRemover)} />
                                <ListOption icon = {mdiEye} link = {`grupo/${item.id}`} onClick = {() => {}} />
                            </ListColumn>}
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>

    )

}

export default TableListarComponents