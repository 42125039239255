import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import handleTheme from '../scripts/handleTheme'

const TableStatus: React.FC = () => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            width: '100%',
            marginBottom: '24px'
        },
        statusContainer: {
            display: 'flex',
            flexDirection: 'column',
        },
        statusBoxContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '4px',
        },
        statusColor: {
            width: '20px',
            height: '20px',
            marginRight: '4px',
            border: '1px solid black',
        },
        statusLabel: {
            fontSize: '10px',
        },
    })

    const StatusBox: React.FC <{ theme: number, label: string }> = ({ theme, label }) => (
        <View style = {styles.statusBoxContainer}>
            <View style = {[styles.statusColor, {backgroundColor: handleTheme(theme)}]} />
            <Text style = {styles.statusLabel}>{label}</Text>
        </View>
    )

    return(

        <View style = {styles.container}>
            <View style = {styles.statusContainer}>
                <StatusBox theme = {0} label = "erro de análise" />
                <StatusBox theme = {1} label = "coleta não representativa" />
                <StatusBox theme = {2} label = "medidor inoperante" />
                <StatusBox theme = {3} label = "valores considerados para DBO de cobrança" />
                <StatusBox theme = {4} label = "resultado não conforme" />
            </View>
        </View>

    )

}

export default TableStatus