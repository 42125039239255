import React from 'react'
import { MenuContainerProps } from '../types'

const MenuContainer: React.FC <MenuContainerProps> = ({ children, label }) => {

    return(

        <div className = "menuContainer">
            {label && (
                <div className = "menuHeader">
                    <span className = "menuLabel">{label}</span>
                </div>
            )}
            <ul className = "menuList">{children}</ul>
        </div>
    
    )

}

export default MenuContainer