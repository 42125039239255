import { DOMAIN } from "../../../../../var_env."
import { setRequestEditData, setRequestEditError, setRequestEditLoading, setRequestEditMessage } from "../Reducers/VazaoLancamentoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { vazaoLancamento } from "../types"
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import createAlert from "../../../../../scripts/alert/createAlert"

export default async function edit(dispatch: Function, values: any, tipoCobranca: string, volumeCorrigido: string, idUsuario: number, mes: number, ano: number){
    try {
        dispatch(setRequestEditLoading())
    
        const endpoint = `${DOMAIN}/PessoaHidrometroLancamento`
        const data = {
            id: values.id,
            leituraAnterior: values.leituraAnterior,
            leituraAtual: values.leituraAtual,
            idHidrometro: values.idHidrometro, 
            flagAtivo: 1,
            idUsuario: idUsuario,
            horaInicial: values.horaInicial,
            horaFinal: values.horaFinal,
            tempoAnterior: values.tempoAnterior,
            tempoAtual: values.tempoAtual,
            tempoFinal: values.tempoFinal,
            volumeCorrido: values.volumeCorrido,
            idCategoriaCobranca: Number(tipoCobranca),
            volumeCorrigido,
            idMes: mes,
            idAno: ano,
            obs: values.obs,
            flagMedia: Number(values.flagMedia)
        }
        const response: RequestResponsePattern<vazaoLancamento> = await postApi({ endpoint, data })
        if(response){
            dispatch(setRequestEditData(response))
            if(!response.flagErro){
                dispatch(setRequestEditData(response))
                dispatch(setAlert(createAlert('success', 'Vazão alterada com sucesso')))
            }
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
    }
}