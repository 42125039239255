import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { FonteAbastecimentoType } from "../Types/FonteAbastecimento"
import { setAbastecimento, setRequestGetAbastecimentoData, setRequestGetAbastecimentoError, setRequestGetAbastecimentoLoading, setRequestGetAbastecimentoMessage } from "../Reducers/ClientsReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getAbastecimento(dispatch: Function){
    try {
        dispatch(setRequestGetAbastecimentoLoading())
        
        const endpoint = `${DOMAIN}/FonteAbastecimento`
        const params = ``
        const response: RequestResponsePattern<FonteAbastecimentoType[]> = await getApi({ endpoint, params })

        if(response){
            dispatch(setRequestGetAbastecimentoData(response))
            if(!response.flagErro) dispatch(setAbastecimento(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetAbastecimentoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetAbastecimentoError())
    }
}