import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableInfoTextContainer } from './types'
import { medidor } from '../types'

const TableEsgoto: React.FC<{medidores: medidor[]}> = ({medidores}) => {

    const styles = StyleSheet.create({
        sectionTitle: {
            marginBottom: '8px',
            fontSize: '10px',
            fontWeight: 'bold',
        },
        box: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            border: '1px solid black',
        },
        leftContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 1,
            padding: '8px',
            borderRight: '1px solid black',
            backgroundColor: '#C0C0C0',
        },
        rightContent: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: '4px',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '8px',
        },
        textContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            display: 'flex',
            flexDirection: 'column',
            marginRight: '4px',
            fontSize: '10px',
        },
        textField: {
            flex: 1,
            height: '100%',
            padding: '2px',
            border: '1px solid black',
        },
        text: {
            fontSize: '10px',
        },
    })

    const TextContainer: React.FC <TableInfoTextContainer> = ({ children, label, text, containerWidth, labelWidth, marginRight }) => (
        <View style = {[styles.textContainer, {width: containerWidth ?? '100%', marginRight: marginRight ? '8px' : '0'}]}>
            <Text style = {[styles.label, {width: labelWidth ?? 'auto'}]}>{label}</Text>
            <View style = {styles.textField}>
                {children ?? <Text style = {styles.text}>{text}</Text>}
            </View>
        </View>
    )

    return(

        <>
            <Text style = {styles.sectionTitle}>Medidor de Esgoto:</Text>
            {medidores.filter(medidor => medidor.flagMedidorVazao === 1).map((medidor, index) => (
                <View key = {index} style = {styles.box}>
                    <View style = {styles.leftContent}>
                        <Text style = {{marginBottom: '12px', fontSize: '8px'}}>MEDIDOR</Text>
                        <Text style = {{fontSize: '24px', fontWeight: 'bold'}}>A</Text>
                    </View>
                    <View style = {styles.rightContent}>
                        <View style = {styles.row}>
                            <TextContainer
                                label = "Tipo:"
                                text = {`${medidor.tipoMedidor}`}
                                containerWidth = "35%"
                                marginRight
                            />
                            <TextContainer
                                label = "HD1:"
                                text = {`${medidor.registro}`}
                                containerWidth = "35%"
                                marginRight
                            />
                            <TextContainer
                                label = "UC:"
                                text = {`${medidor.uc ?? ''}`}
                                containerWidth = "30%"
                            />
                        </View>
                        <View style = {styles.row}>
                            <TextContainer
                                label = "Cont. Tempo Anterior:"
                                text = {`${medidor.tempoAnterior}`}
                                containerWidth = "35%"
                                marginRight
                            />
                            <TextContainer
                                label = "Atual:"
                                text = {`${medidor.tempoAtual}`}
                                containerWidth = "20%"
                                marginRight
                            />
                            <TextContainer
                                label = "Vazão instantânea registrada:"
                                text = {""}
                                containerWidth = "45%"
                            />
                        </View>
                        <View style = {styles.row}>
                            <TextContainer
                                label = "Lacres:"
                                text = {medidor.lacre}
                                containerWidth = "100%"
                            />
                        </View>
                        <View style = {styles.row}>
                            <TextContainer
                                label = "Obs:"
                                text = {`${medidor.obs}`}
                                containerWidth = "40%"
                                marginRight
                            />
                            <TextContainer
                                label = "Volume Totalizado:"
                                text = {""}
                                containerWidth = "60%"
                                labelWidth = "50px"    
                            >
                                <TextContainer
                                    label = "Leit. anterior:"
                                    text = {`${medidor.leituraAnterior}`}
                                    containerWidth = "100%"
                                    labelWidth = "80px"
                                />
                                <TextContainer
                                    label = "Leit. atual:"
                                    text = {`${/*medidor.leituraAtual*/" "}`}
                                    containerWidth = "100%"
                                    labelWidth = "80px"
                                />
                                <TextContainer
                                    label = "Fat. anterior:"
                                    text = {`${medidor.volumeAnterior??"0.00"}`}
                                    containerWidth = "100%"
                                    labelWidth = "80px"
                                />
                                <TextContainer
                                    label = "Média (6 meses):"
                                    text = {`${medidor.calculomeses}`}
                                    containerWidth = "100%"
                                    labelWidth = "80px"
                                />
                            </TextContainer>
                        </View>
                    </View>
                </View>
            ))}
        </>

    )

}

export default TableEsgoto