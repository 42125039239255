import { utils, writeFile } from 'xlsx';

import { DefaultType } from "../../../store/types/DefaultType";


export function genearateCommonExcel(tipoGenerico: DefaultType[] | null, nomeArquivo: string) {

    // const date = (new Date()).toLocaleDateString().replaceAll(///g, '');
     const fileName = `${nomeArquivo}.xlsx`;
     let valoresLimpos : any
     valoresLimpos = tipoGenerico?.map((generico)=> (
        { 
            id :generico.id,
            descricao: generico.descricao,
            status: generico.flagAtivo===1?"Habilitado":"Desabilitado",
            dtCadastro : new Date(generico.dtCadastro).toLocaleString()
        }
      ));
     const worksheet = utils.json_to_sheet(valoresLimpos, {header:['id','descricao','status','dtCadastro']});
     const workbook = utils.book_new();

     utils.book_append_sheet(workbook, worksheet, nomeArquivo)
     writeFile(workbook, `${fileName}`)

}