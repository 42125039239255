import React from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "space-between",
            width: "100%",
            paddingBottom: '4px',
            marginBottom: '48px',
            borderBottom: '3px solid black',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
            alignItems: 'center',
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        },
        text: {
            fontSize: '8px',
        }
    })

    return(

        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>ATUALIZAÇÃO DE VALORES DE DBO</Text>
                <Text style = {styles.subTitle}>Período de: 00/00/0000 até 00/00/0000</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>

    )

}

export default TableBanner