import React from 'react'
import { View, StyleSheet } from "@react-pdf/renderer"
import { RelatorioParametroType } from '../../../types'
import somataorio from '../scripts/somataorio'
import Column from '../../../../../../../components/PDF/Column'

const TableRow: React.FC <{relatorios: RelatorioParametroType[]}> =  ({relatorios}) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        }
    })

    const handleValue = (value: string, sigla: string  ) => {
       if(value.toUpperCase()==="DEMANDA BIOLÓGICA DE OXIGÊNIO") return "DBO"
       if(value.toUpperCase()==="DEMANDA QUÍMICA DE OXIGÊNIO") return "DQO"
       if(value.toUpperCase()==="POTENCIAL HIDROGENIÔNICO") return "pH"

       const valoresSeparados = value.split('_')
       if(valoresSeparados.length> 1){
        value = `${sigla}_${valoresSeparados[1]}`
       }
    //    if(value.toUpperCase()==="CIANETO (MG/L)") return "CN"
    //    if(value.toUpperCase()==="TFÓSFORO TOTAL") return "P"
    //    if(value.toUpperCase()==="NITROGÊNIO TOTAL") return "N"
    //    if(value.toUpperCase()==="POTENCIAL HIDROGENIÔNICO") return "pH"

       return value.replace("(MG/L)","") //.replace("( °C )","")
    }

    return(
        <>
            {relatorios.map((relatorio)=>
                <View style = {styles.row}>
                    <Column align='left' text = {handleValue(relatorio.descricao, relatorio.sigla)} width = "40%" />
                    <Column text = {relatorio.qtde.toString()} width = "20%" />
                    <Column text = {relatorio.qtdeConforme.toString()} width = "20%" />
                    <Column text = {relatorio.qtdeNaoConforme.toString()} width = "20%" last />
                </View>
            )}
            <View style = {styles.row}>
                <Column text = {"Total"} width = "40%" />
                <Column text = { somataorio(relatorios.map((relatorio)=> relatorio.qtde))??""} width = "20%" />
                <Column text = { somataorio(relatorios.map((relatorio)=> relatorio.qtdeConforme))??""} width = "20%" />
                <Column text = { somataorio(relatorios.map((relatorio)=> relatorio.qtdeNaoConforme))??""} width = "20%" last />
            </View>
        </>
    )

}

export default TableRow