import FormContainer from "./container"
import FormRow from "./row"
import FormGroup from "./group"
import FormRender from "./render"

const Form = {
    Container: FormContainer,
    Row: FormRow,
    Group: FormGroup,
    Render: FormRender,
}

export default Form