import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RequestResponsePattern, RequestStatusPattern } from "../../../../../types/Request"

interface State {
    requestEdit: RequestStatusPattern<RequestResponsePattern<any>>
}

const initialState: State = {
    requestEdit: {
        data: null,
        loading: false,
        error: false,
        message: null,
    },
}

const ClienteDadosSlice = createSlice({
    name: 'ClienteDados',
    initialState,
    reducers: {
        setRequestEditData: (state, action: PayloadAction<RequestResponsePattern<any>>) => {
            state.requestEdit.data = action.payload
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
        setRequestEditLoading: (state) => {
            state.requestEdit.loading = true
            state.requestEdit.error = false
        },
        setRequestEditError: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = true
        },
        setRequestEditMessage: (state, action: PayloadAction<string>) => {
            state.requestEdit.message = action.payload
        },
        setRequestEditLoadingObs: (state) => {
            state.requestEdit.loading = false
            state.requestEdit.error = false
        },
    }
})

export const {  
    setRequestEditData, setRequestEditLoading,setRequestEditLoadingObs, setRequestEditError, setRequestEditMessage,
} = ClienteDadosSlice.actions
export default ClienteDadosSlice.reducer