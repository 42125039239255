import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'

const Loading: React.FC = () => {

    return(

        <div className = "sidebarLoading">
            <CircleSpinner size = {32} color = "#ffffff" />
        </div>

    )

}

export default Loading