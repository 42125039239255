import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface State {
    sidebarOpen: boolean
}

const initialState: State = {
    sidebarOpen: localStorage.getItem("sidebarOpen") ? JSON.parse(localStorage.getItem("sidebarOpen") as string) : true
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setSidebarOpen: (state, action: PayloadAction<boolean>) => {
            state.sidebarOpen = action.payload
            localStorage.setItem('sidebarOpen', JSON.stringify(state.sidebarOpen))
        },
        toggleSidebarOpen: (state) => {
            state.sidebarOpen = !state.sidebarOpen
            localStorage.setItem('sidebarOpen', JSON.stringify(state.sidebarOpen))
        }
    }
})

export const { setSidebarOpen, toggleSidebarOpen } = layoutSlice.actions
export default layoutSlice.reducer