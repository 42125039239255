import React from 'react'
import { View, StyleSheet } from '@react-pdf/renderer'

const TableLine: React.FC <{last?: boolean}> = ({ last }) => {

    const styles = StyleSheet.create({
        line: {
            width: '100%',
            height: '20px',
            border: '1px solid black',
            borderBottom: '0px',
        }
    })

    return <View style = {[styles.line, { borderBottom: !!last ? '1px solid black' : '0px' }]} />

}

export default TableLine