import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { CategoriaCobranca } from "../../Cliente/Types/CategoriaCobranca"
import { setCategorias, setRequestGetCategoriaData, setRequestGetCategoriaError, setRequestGetCategoriaLoading, setRequestGetCategoriaMessage } from "../Reducers/VazaoLancamentoReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getCategorias(dispatch: Function){
    try {
        dispatch(setRequestGetCategoriaLoading())
        
        const endpoint = `${DOMAIN}`
        const params = `/CategoriaCobranca`
        const response: RequestResponsePattern<CategoriaCobranca[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestGetCategoriaData(response))
            if(!response.flagErro){
                dispatch(setCategorias(response.listaResultados))
            }else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetCategoriaMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetCategoriaError())
    }
}