import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { HistoricoColetaNaoConformidade } from "../Types/HistoricoColetaNaoConformidade"
import { setHistoricoColetaNaoConformidade, setRequestHistoricoColetaNaoConformidadeData, setRequestHistoricoColetaNaoConformidadeError, setRequestHistoricoColetaNaoConformidadeLoading, setRequestHistoricoColetaNaoConformidadeMessage } from "../Reducers/ClientsReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getHistoricoColetaNaoConformidade(dispatch: Function, id: number){
    try {
        dispatch(setRequestHistoricoColetaNaoConformidadeLoading())

        const endpoint = `${DOMAIN}/HistoricoColetaNaoConforme`
        const params = `/${id}`
        const response: RequestResponsePattern<HistoricoColetaNaoConformidade[]> = await getApi({ endpoint, params })

        if(response){
            dispatch(setRequestHistoricoColetaNaoConformidadeData(response))
            if(!response.flagErro) dispatch(setHistoricoColetaNaoConformidade(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestHistoricoColetaNaoConformidadeMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestHistoricoColetaNaoConformidadeError())
    }
}