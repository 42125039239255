import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { HistoricoColeta, HistoricoColetaElement } from "../../Cliente/Types/HistoricoColeta"
import { getApi } from "../../../../../utils/requestApi"
import { ParametroType } from "../../Parametro/Types/ParametroType"
import _ from "lodash"


export default async function getHistoricoColetaSequencial(id: number, sequencial: string, inicio: string, fim: string) {
    try {

        // localStorage.setItem("resultadoSomaCompleto", JSON.stringify(null))
        // localStorage.setItem("resultadoSomaCompletoAnterior", JSON.stringify(null))

        const endpoint = `${DOMAIN}/HistoricoColeta/`
        const params = `${id}/${1}`
        const response: RequestResponsePattern<HistoricoColeta> = await getApi({ endpoint, params })

        if (response) {
            if (!response.flagErro) {
                let anoAtras = new Date((new Date().getTime() - (1.08e7 * 8 * 365 * 2)))

                const listaPorOrdem = response.listaResultados.result
                    .filter(x => new Date(x.dtInstalaco) <= new Date(`${fim} 23:59:59`))
                const ultimaColeta = listaPorOrdem.filter(x => x.DBO > 0 || x.idTipoDBO === 2 || x.idTipoDBO === 3).at(-1)
                localStorage.setItem("ultimaColeta", JSON.stringify({ data: `${ultimaColeta?.dtInstalaco}` }))

                getValoresDBO(listaPorOrdem, { data: `${ultimaColeta?.dtInstalaco}` })

                getHistoricoColetaNaoConforme(listaPorOrdem.filter(x => new Date(x.dtInstalaco) >= anoAtras))
                localStorage.setItem("sequencia", JSON.stringify({ sequencial }))
                localStorage.setItem("HistoricoColeta", JSON.stringify(listaPorOrdem.filter(x => new Date(x.dtInstalaco) >= new Date((new Date(ultimaColeta!.dtInstalaco).getTime() - (1.08e7 * 8 * 365 * 6))))))
                localStorage.setItem("HistoricoColetaCompleto", JSON.stringify(listaPorOrdem.filter(x => new Date(x.dtInstalaco) >= new Date((new Date(ultimaColeta!.dtInstalaco).getTime() - (1.08e7 * 8 * 365 * 6))))))

                //console.log(response)
            }
            else throw new Error(response.listaMensagens[0])
        } else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)

    }
}

function getValoresDBO(coleta: HistoricoColetaElement[], ultimaCoelta: { data: string }) {

    let anoAtras: any = null
    anoAtras = new Date(new Date((new Date(ultimaCoelta?.data).getTime() - (1.08e7 * 8 * 365))).toDateString())

    const resultadosAano = coleta.filter(x => new Date(x.dtInstalaco) >= anoAtras! && x.DBO > 0 && x.idTipoDBO !== 5 && (x.idTipoDBO === 1 || x.idTipoDBO === 4)).map(i => (i.id))
    const coletasDiferentedeZero = coleta.filter(x => x.DBO > 0 && (x.idTipoDBO === 1 || x.idTipoDBO === 4))

    const elemtent = coleta.filter(item => item.DBO > 0 && (resultadosAano?.length > 2 ? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).at(-1)!

    const valorDBOAntigo = coleta.filter(x => new Date(x.dtInstalaco) < new Date(elemtent.dtInstalaco))

    const coletasDiferentedeZero2 = valorDBOAntigo.filter(x => x.DBO > 0 && x.idTipoDBO === 1)

    const anoAtras2 = new Date(new Date((new Date(`${valorDBOAntigo.at(-1)?.dtInstalaco}`).getTime() - (1.08e7 * 8 * 365))).toDateString())
    const resultadosAnoAnterior = valorDBOAntigo.filter(x => new Date(x.dtInstalaco) >= anoAtras2! && x.DBO > 0 && x.idTipoDBO === 1).map(i => (i.id))
    const valoresAntigo = valorDBOAntigo.filter(item => item.DBO > 0 && (resultadosAnoAnterior?.length > 2 ? (resultadosAnoAnterior?.includes(item.id)) : (item.id === coletasDiferentedeZero2.at(-1)?.id || item.id === coletasDiferentedeZero2.at(-2)?.id || item.id === coletasDiferentedeZero2.at(-3)?.id)))
    const soma2 = _.sum(valoresAntigo.map(i => i.DBO))
    const resultado2 = soma2 / valorDBOAntigo.filter(item => item.DBO > 0 && (resultadosAnoAnterior?.length > 2 ? (resultadosAnoAnterior?.includes(item.id)) : (item.id === coletasDiferentedeZero2.at(-1)?.id || item.id === coletasDiferentedeZero2.at(-2)?.id || item.id === coletasDiferentedeZero2.at(-3)?.id))).map(I => I.DBO).length


    const valoresSoma = coleta.filter(item => item.DBO > 0 && (resultadosAano?.length > 2 ? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I => ({ id: I.id, dbo: I.DBO }))
    const valoresAdicionais = coleta.filter(x => x.idTipoDBO === 4).map(i => ({ id: i.id, dbo: i.DBO }))



    const listaId: number[] = []
    const valoresDuplos = [...valoresSoma, ...valoresAdicionais]
    const valoresUnicos = []
    for (const iterator of valoresDuplos) {
        if (listaId.includes(iterator.id)) {

        } else {
            valoresUnicos.push(iterator)
            listaId.push(iterator.id)
        }

    }
    //const valoresUnicos = _.uniq([...valoresSoma, ...valoresAdicionais])

    const soma = _.sum(valoresUnicos.map(i => i.dbo))
    const resultado = soma / valoresUnicos.length

    //coleta.filter(item=> item.DBO>0 && (resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I=> I.DBO).length
    const valoresPonto = coleta.filter(item => item.DBO > 0 && (resultadosAano?.length > 2 ? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I => (I.pontoColeta))
    const valoresAdicionaisPonto = coleta.filter(x => x.idTipoDBO === 4).map(I => (I.pontoColeta))

    localStorage.setItem("valorPonto", JSON.stringify([...valoresPonto, ...valoresAdicionaisPonto]))

    if (isNaN(resultado)) {
        localStorage.setItem("resultadoSoma", `${0}`)
    } else {
        localStorage.setItem("resultadoSoma", `${resultado / 1000}`)
    }

    if (isNaN(resultado2)) {
        localStorage.setItem("resultadoSomaAnterior", `${0}`)
    }
    else {
        localStorage.setItem("resultadoSomaAnterior", `${resultado2 / 1000}`)
    }
}

function getHistoricoColetaNaoConforme(coleas: (HistoricoColetaElement | undefined)[]) {
    const parametrosLista = ['DQO', 'DBO', 'pH', '°C', 'Ag', 'As_', 'Cd', 'CN', 'Cr', 'Cr6', 'Cu', 'Fe', 'Fenol', 'Hg', 'Ni', 'N', 'Pb', 'P', 'Se', 'Sn', 'Zn', 'Rs', 'Og', 'Sulfato', 'Sulfeto', 'Fluoreto', 'Toxicidade']
    const parametros: ParametroType[] | null = JSON.parse(localStorage.getItem("Parametro")!)
    let coletasNaoConformes: any[] = []
    for (const item of coleas) {
        for (const key of Object.keys(item!)) {
            if (parametrosLista.includes(key)) {
                let valorParametro: Number = 0
                if (typeof (item as any)[key] === 'number') valorParametro = Number((item as any)[key])
                if (typeof (item as any)[key] === 'string') valorParametro = Number((item as any)[key].replace('<', ''))
                let ParametroName = key
                if (ParametroName === "temperatura") ParametroName = "°C"
                if (ParametroName === "Rs") ParametroName = "SS"
                const oParametro = parametros?.find(x => x.descricao.toUpperCase() === ParametroName.toUpperCase() || x.sigla.toUpperCase() === ParametroName.toUpperCase())
                //console.log({p:key, a:(item as any)[key]})//p:key, a:(item as any)[key]
                if ((valorParametro > oParametro?.maximo || valorParametro < oParametro?.minimo) && !((item as any)[key] === null || (item as any)[key] === "" || Number((item as any)[key]) === 0 || Number(oParametro?.maximo) === 0)) coletasNaoConformes.push({ data: item?.dtInstalaco, maximo: `${oParametro?.sigla} (${oParametro?.minimo} a ${oParametro?.maximo})`, encontrado: (item as any)[key] })

            }
        }
    }



    localStorage.setItem("HistoricoColetaNaoConforme", JSON.stringify(coletasNaoConformes))
}