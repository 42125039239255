import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import { setRequestDeleteGrupoAcessoData, setRequestDeleteGrupoAcessoError, setRequestDeleteGrupoAcessoLoading, setRequestDeleteGrupoAcessoMessage } from "../../Reducers/GrupoAcessoReducer"
import { deleteApi } from "../../../../../utils/requestApi"
import createAlert from "../../../../../scripts/alert/createAlert"

export default async function removeGrupoAcesso(dispatch: Function, id: number, idUsuario: number){
    try {
        dispatch(setRequestDeleteGrupoAcessoLoading())

        const endpoint = `${DOMAIN}/GrupoAcesso`
        const params = { id, idUsuario }
        const response: RequestResponsePattern<any> = await deleteApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestDeleteGrupoAcessoData(response))
            if(!response.flagErro) dispatch(setAlert(createAlert('success', 'Grupo acesso removido com sucesso')))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestDeleteGrupoAcessoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestDeleteGrupoAcessoError())
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
    }
}