import { DOMAIN } from '../../../../../var_env.'
import { RequestResponsePattern } from "../../../../../types/Request"
import { RamoAtividadeType } from '../../RamoAtividade/Types/RamoAtividadeType'
import { setRequestGetRamoAtividadeData, setRequestGetRamoAtividadeError, setRequestGetRamoAtividadeLoading, setRequestGetRamoAtividadeMessage, setRamoAtividade } from '../Reducers/ClientsReducer'
import { getApi } from '../../../../../utils/requestApi'

export default async function getRamoAtividade(dispatch: Function){
    try {
        dispatch(setRequestGetRamoAtividadeLoading())

        const endpoint = `${DOMAIN}/RamoAtividade`
        const params = ``
        const response: RequestResponsePattern<RamoAtividadeType[]> = await getApi({ endpoint, params })

        if(response){
            dispatch(setRequestGetRamoAtividadeData(response))
            if(!response.flagErro) dispatch(setRamoAtividade(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetRamoAtividadeMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetRamoAtividadeError())
    }
}