import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"

const TableHeader: React.FC<{dados:any}> = ({dados}) => {



    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
            justifyContent:'space-between'
        },
        column: {
            flexDirection: "column",
            padding:'5px'
        },
        text: {
            fontSize: '8px',
            margin: '5px'
        },
        textREf: {
            fontSize: '8px',
            margin: '2px',
            marginLeft: '5px'
        },
        textDouble: {
            fontSize: '8px',
            margin: '5px',
            flexDirection: "column",
        },
            textNegrito: {
            fontSize: 8,
            paddingTop: '15px',
            fontWeight: 1000,
            marginLeft: '5px'
        },
    })


    return (
        <>


            {dados &&
            <> 
            <View>
                <Text style={styles.textREf}>REF:</Text>
            </View>
                <View style={styles.row}>

                    <View style={styles.column}>

                        <Text style={styles.textNegrito}>
                        EMPRESA: {dados.nome}
                        </Text>
                       
                       {dados.enderecoCompleto?.split('-')?.slice(0, 2).map((endereco:string, index: number) => (
                                                    <Text style={styles.text}>
                                                        {index===0? "RUA: " +endereco :"" + endereco}
                                                    </Text>
                                                ))}
                    </View>

                    <View style={styles.column}>
                        <Text style={styles.text}>
                        Cidade: JUNDIAI, SP
                        </Text>
                    </View>

                    <View style={styles.column}>
                        <Text style={styles.text}>
                        CNPJ: {dados.nroDocumento}
                        </Text>
                    </View>

                </View>
                </>}




        </>
    )

}

export default TableHeader