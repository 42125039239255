import React from 'react'
import { View, StyleSheet, Text } from "@react-pdf/renderer"
import Column from '../../../../../../../components/PDF/Column'
import _ from 'lodash'

const TableRow: React.FC <{relatorios: any[]}> = ({relatorios}) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },
        finalRow: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
            borderBottom: '1px solid black'
        }
    })

    return(
        <View>
        {relatorios && relatorios.map((props, index)=>
        <View style = {styles.row} wrap={false} >
            <Column text = {(index + 1).toString()} width = "3%" />
            <Column text = {props.nomeCliente} align = "left" width = "40%" />
            <Column text = {props.dboAnterior} width = "10%" />
            <Column text = {props.dboAtual} width = "10%" />
            <Column text = {props.diferenca} width = "10%" />
            <Column text = {props.coletas} width = "10%" />
            <Column text={props.parametrosNaoConforme }  width = "15%" last />
        </View>
        )}

        <View style = {styles.finalRow} wrap={false}>
            <Text style={{width:'3%', fontSize: '8px' , padding: '4px 2px', textAlign: 'center'}}>{''}</Text>
            <Text style={{width:'40%', fontSize: '8px', padding: '4px 2px' }}>{`Total de Indústrias Monitoradas: ${relatorios?.length}`}</Text>
            <Text style={{width:'10%', fontSize: '8px', padding: '4px 2px', textAlign: 'center'}}>{''}</Text>
            <Text style={{width:'10%', fontSize: '8px', padding: '4px 2px', textAlign: 'center'}}>{''}</Text>
            <Text style={{width:'15%', fontSize: '8px', padding: '4px 2px', textAlign: 'center'}}>{''}</Text>
            <Text style={{width:'10%', fontSize: '8px', padding: '4px 2px', textAlign: 'center'}}>{_.sum(relatorios?.map(i => Number(i.coletas))).toString()}</Text>
            <Text style={{width:'15%', fontSize: '8px', padding: '4px 2px', textAlign: 'center'}}>{''}</Text>
        </View>
</View>
    )
}
export default TableRow