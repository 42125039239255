import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { Grupo } from "../../Types/Grupo"
import { setGrupos, setRequestGetGrupoAcessoData, setRequestGetGrupoAcessoError, setRequestGetGrupoAcessoLoading, setRequestGetGrupoAcessoMessage } from "../../Reducers/GrupoAcessoReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getGrupoAcesso(dispatch: Function){
    try {
        dispatch(setRequestGetGrupoAcessoLoading())

        const endpoint = DOMAIN
        const params = `/GrupoAcesso`
        const response: RequestResponsePattern<Grupo[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestGetGrupoAcessoData(response))
            if(!response.flagErro) dispatch(setGrupos(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetGrupoAcessoMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetGrupoAcessoError())
    }
}