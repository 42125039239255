import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { CreateParams } from "../types"
import { setRequestCreateData, setRequestCreateError, setRequestCreateLoading, setRequestCreateMessage } from "../../Reducers/UsuarioReducer"
import { postApi } from "../../../../../utils/requestApi"
import { setAlert } from "../../../../../store/reducers/alert/alertReducer"
import createAlert from "../../../../../scripts/alert/createAlert"

export default async function create(dispatch: Function, values: CreateParams){
    try {
        dispatch(setRequestCreateLoading())

        const endpoint = `${DOMAIN}/Usuario`
        const data = {...values}
        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })
        
        if(response){
            dispatch(setRequestCreateData(response))
            if(!response.flagErro) dispatch(setRequestCreateData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setAlert(createAlert('error', error.message ?? JSON.stringify(error))))
        dispatch(setRequestCreateMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestCreateError())
    }
}