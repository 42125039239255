import React from 'react'
import { Link } from 'react-router-dom'
import Icon from '@mdi/react'
import { MenuOptionProps } from '../types'

const MenuOptionComponent: React.FC <MenuOptionProps> = ({ link, target, icon, label, setOpen, onClick }) => {

    const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
        onClick()
        setOpen(false)
    }

    return(

        <li className = "menuOption" onClick = {e => handleClick(e)}>
            <Icon path = {icon} size = "22px" />
            <span className = "label">{label}</span>
        </li>

    )

}

const MenuOption: React.FC <MenuOptionProps> = ({ link, target, ...props }) => {

    return(

        <>
            {(link && (
                <Link to = {link} target = {target}>
                    <MenuOptionComponent {...props} />
                </Link>
            )) || <MenuOptionComponent {...props} />}
        </>

    )

}

export default MenuOption