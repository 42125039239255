import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { HistoricoColeta } from "../Types/HistoricoColeta"
import { setHistoricoColeta, setRequestHistoricoColetaData, setRequestHistoricoColetaError, setRequestHistoricoColetaLoading, setRequestHistoricoColetaMessage } from "../Reducers/CargasReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getMonitoramentoCargas(dispatch: Function){
    try {
        dispatch(setRequestHistoricoColetaLoading())

        const endpoint = `${DOMAIN}/RelatorioMonitoramento`
        const params = `/`
        const response: RequestResponsePattern<HistoricoColeta> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestHistoricoColetaData(response))
            localStorage.setItem("MonitoramentoCargas", JSON.stringify(response.listaResultados))
            if(!response.flagErro) dispatch(setHistoricoColeta(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestHistoricoColetaMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestHistoricoColetaError())
    }
}