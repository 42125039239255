import React, { useCallback, useEffect } from 'react'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import { MenuItem, Select, TextField } from '@material-ui/core';
import { mdiFile, mdiMicrosoftExcel, mdiFilePdfBox, mdiMagnify, mdiDotsVertical } from '@mdi/js';
import { useDispatch } from 'react-redux'
import { setFieldFiltered, setFieldFilteredAno, setFieldFilteredMes, setFieldFilteredMedidor } from './Reducers/VazaoLancamentoReducer'
import edit from './scripts/edit';
import { useAppSelector } from '../../../../store/hooks'
import remove from './scripts/remove';
import filter from './scripts/filter';
import TableListarVazao from './Components/TableListarComponents';
import send from './scripts/send';
import filterLancamentos from './scripts/filterLancamentos';
import { setSidebarOpen } from '../../../../store/reducers/layout/layoutReducer';
import getCategorias from './scripts/getCategorias';
import { genearateLancamentoExcel } from './scripts/genearateLancamentoExcel';
import getDadosFicha from './scripts/getDadosFicha';
import MenuWrapper from '../../../../components/Menu/Wrapper';
import Menu from '../../../../components/Menu';
import MenuOption from '../../../../components/Menu/Option';
import { CircleSpinner } from 'react-spinners-kit'
//import { Link } from 'react-router-dom'

const VazaoLancamento: React.FC = () => {

    const dispatch = useDispatch()
    const { userData } = useAppSelector(state => state.auth)
    const { listaVazao, filteredLista, fieldFilteredPossuiMedidor, filteredMedidorLista, requestSave, fieldFiltered, fieldFilteredMes, fieldFilteredAno, requestRemove } = useAppSelector(state => state.VazaoLancamentoReducer)
    const [openMenu, setOpenMenu] = React.useState(false)
    const [loadingFicha, setLoadingFicha] = React.useState(false)
    const handleFilter = useCallback(async () => {
        await filter(dispatch, { nomeCliente: fieldFiltered, idMes: fieldFilteredMes, idAno: fieldFilteredAno, flagMedidorVazao: fieldFilteredPossuiMedidor })
        await filterLancamentos(dispatch, { idMes: fieldFilteredMes, idAno: fieldFilteredAno })
    }, [dispatch, fieldFiltered, fieldFilteredMes, fieldFilteredAno, fieldFilteredPossuiMedidor])

    const handleCheckChanges = useCallback(() => {
        /*if(document.querySelector('.notSaved')){
            if(window.confirm(`Você tem alterações não salvas em ${document.querySelector('.notSaved')?.getAttribute('data-registro')}, deseja continuar?`)) handleFilter()
        }else handleFilter()*/
        handleFilter()
    }, [handleFilter])

    const handleSalvarMedidorLancamento = useCallback(async (dado: any, tipoCobranca: string, volumeCorrigido: string) => {

        const data = {
            idHidrometro: Number(dado.idHidrometro),
            leituraAnterior: dado.leituraAnterior,
            leituraAtual: dado.leituraAtual,
            volumeMedido: Number(dado.volumeMedido),
            medidorStatusNome: dado.medidorStatusNome,
            volumeCorrigido,
            tempoAnterior: dado.tempoAnterior,
            tempoAtual: dado.tempoAtual,
            tempoFinal: dado.tempoFinal,
            flagAtivo: 1,
            idUsuario: userData?.id,
            idMes: fieldFilteredMes,
            idAno: fieldFilteredAno,
            idCategoriaCobranca: Number(tipoCobranca),
            obs: dado.obs,
            flagMedia: Number(dado.flagMedia)
        }
        await send(dispatch, data)
    }, [dispatch, userData, fieldFilteredMes, fieldFilteredAno])

    const handleGerarDadosPDF = useCallback(async () => {
        setLoadingFicha(true)
        localStorage.setItem("PDF_FICHA", JSON.stringify([]))
        const idClientes = listaVazao?.map((vazao: { idPessoa: any; }) => vazao.idPessoa)
        const data = {
            idPessoa: idClientes,
            idMes: fieldFilteredMes,
            idAno: fieldFilteredAno
        }
        await getDadosFicha(data)
        setLoadingFicha(false)

        const win = window.open('lancamento/ficha-pdf', '_blank')
        win?.focus()

    }, [fieldFilteredMes, fieldFilteredAno, listaVazao])

    const handleEditar = useCallback(async (data: any, tipoCobranca: string, volumeCorrigido: string, tempoFinal: string) => {
        console.log(data.flagMedia)
        data.tempoFinal = tempoFinal
        await edit(dispatch, data, tipoCobranca, volumeCorrigido, userData!.id, fieldFilteredMes, fieldFilteredAno)
    }, [dispatch, userData, fieldFilteredMes, fieldFilteredAno])

    const handleExcluirMedidorLancamento = useCallback(async (id?: Number) => {
        const data = { id, idUsuario: userData!.id }
        await remove(dispatch, data)
    }, [dispatch, userData])

    const keyPress = (async (e: any) => {
        if (e.keyCode === 13) handleFilter()
    })

    useEffect(() => {
        dispatch(setSidebarOpen(false))
    }, [dispatch])

    useEffect(() => {

        getCategorias(dispatch)
        filter(dispatch, { nomeCliente: fieldFiltered, idMes: fieldFilteredMes, idAno: fieldFilteredAno, flagMedidorVazao: fieldFilteredPossuiMedidor })
        filterLancamentos(dispatch, { idMes: fieldFilteredMes, idAno: fieldFilteredAno })

        // handleFilter()
    }, [dispatch, requestSave.data, requestRemove.data])

    return (
        <>

            <Section name="vazaoLancamento">
                <SectionBox
                    title='Lançamento de Vazão'
                    padding={false}
                    right={
                        <>
                            <TextField id="filterDescricao" variant="standard" className="fieldFilter" placeholder="Cliente" value={fieldFiltered} onChange={e => dispatch(setFieldFiltered(e.target.value as string))} onKeyDown={keyPress} />

                            <Select id="filterAtivo" value={fieldFilteredPossuiMedidor} onChange={e => dispatch(setFieldFilteredMedidor(e.target.value as any))}>
                                <MenuItem value={'null'}>Possui medidor de vazão?</MenuItem>
                                <MenuItem value={1}>Sim</MenuItem>
                                <MenuItem value={0}>Não</MenuItem>
                            </Select>

                            <Select id="filterAtivo" value={fieldFilteredMes} placeholder="Mes" onChange={e => dispatch(setFieldFilteredMes(e.target.value as number))}>
                                <MenuItem value={1}>Janeiro</MenuItem>
                                <MenuItem value={2}>Fevereiro</MenuItem>
                                <MenuItem value={3}>Março</MenuItem>
                                <MenuItem value={4}>Abril</MenuItem>
                                <MenuItem value={5}>Maio</MenuItem>
                                <MenuItem value={6}>Junho</MenuItem>
                                <MenuItem value={7}>Julho</MenuItem>
                                <MenuItem value={8}>Agosto</MenuItem>
                                <MenuItem value={9}>Setembro</MenuItem>
                                <MenuItem value={10}>Outubro</MenuItem>
                                <MenuItem value={11}>Novembro</MenuItem>
                                <MenuItem value={12}>Dezembro</MenuItem>
                            </Select>

                            <TextField id="fieldFilterAno" variant="standard" className="fieldFilterAno" placeholder="Ano" value={fieldFilteredAno} onChange={e => dispatch(setFieldFilteredAno(e.target.value as unknown as number))} onKeyDown={keyPress} />

                            <SectionOption icon={mdiMagnify} tooltip="Filtrar" onClick={handleCheckChanges} />
                            {loadingFicha && <CircleSpinner size={20} color="grey" />}
                            {!loadingFicha && <MenuWrapper setOpen={setOpenMenu}>

                                <SectionOption icon={mdiDotsVertical} tooltip="Menu" onClick={() => setOpenMenu(true)} />

                                <Menu label="Opções" open={openMenu}>
                                    <MenuOption
                                        link="pdf"
                                        target="_blank"
                                        label="Exportar para PDF"
                                        icon={mdiFilePdfBox}
                                        onClick={() => { }}
                                        setOpen={setOpenMenu}
                                    />
                                    <MenuOption
                                        label="Exportar para Excel"
                                        icon={mdiMicrosoftExcel}
                                        onClick={() => { genearateLancamentoExcel(listaVazao, fieldFilteredMes, fieldFilteredAno) }}
                                        setOpen={setOpenMenu}
                                    />
                                    <MenuOption
                                        label="Ficha de Leitura"
                                        icon={mdiFile}
                                        onClick={handleGerarDadosPDF}
                                        setOpen={setOpenMenu}
                                    />

                                </Menu>
                            </MenuWrapper>}
                        </>
                    }
                >
                    <TableListarVazao
                        lista={filteredMedidorLista}
                        listaLancados={filteredLista}
                        handleSalvarMedidorLancamento={handleSalvarMedidorLancamento}
                        handleEditar={handleEditar}
                        handleExcluirMedidorLancamento={handleExcluirMedidorLancamento}
                    />
                </SectionBox>
            </Section>
        </>
    )

}

export default VazaoLancamento

