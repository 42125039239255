import * as R from "../Reducers/VazaoLancamentoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { resultadoHora } from "../types"

export default async function getHoraFinal(dispatch: Function, values: any){
    try {
        dispatch(R.setRequestGetHoraFinalLoading())

        const endpoint = `${DOMAIN}/RelatorioLeituraCobranca/Teste/Logica`
        const data ={
            logica : values.logica,
            tempoAnterior: values.tempoAnterior, 
            tempoAtual: values.tempoAtual
        }
        const response: RequestResponsePattern<resultadoHora> = await postApi({ endpoint, data })
        
        if(response){
            if(!response.flagErro){
                dispatch(R.setRequestGetHoraFinalData(response))
                return response.listaResultados   
            }else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(R.setRequestGetHoraFinalMessage(error.message ?? JSON.stringify(error)))
        dispatch(R.setRequestGetHoraFinalError())
        return null
    }
}