import { utils, writeFile } from 'xlsx';
import { vazaoLancamento } from '../types';

export function genearateLancamentoExcel(Parametros: vazaoLancamento[] | null, mes:any, ano:any) {


     const fileName = `Hidrometro_Lancamento-ref(${mes}/${ano}).xlsx`;
     let valoresLimpos : any
     
    valoresLimpos = Parametros?.map((parametro)=> (
        { 
            cliente: parametro.nome,
            registro: parametro.registro,
            leituraAnterior: parametro.leituraAnterior,
            leituraAtual: parametro.leituraAtual,
            volumeMedido: parametro.volumeMedido,
            tempoAnterior: parametro.tempoAnterior,
            tempoAtual: parametro.tempoAtual,
            tempoFinal: parametro.tempoFinal?.includes("NaN")? "-":parametro.tempoFinal,
            volumeCorrigido: parametro.volumeCorrigido,
            OBS: parametro.obs
        }
      ));

     const worksheet = utils.json_to_sheet(valoresLimpos, {header:['cliente',"registro",'leituraAnterior','leituraAtual','volumeMedido' ,'tempoAnterior','tempoAtual','tempoFinal', 'volumeCorrigido','OBS']});
     const workbook = utils.book_new();

     utils.book_append_sheet(workbook, worksheet, 'Hidrometro_Lancamento')
     writeFile(workbook, fileName)

}