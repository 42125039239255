import React from 'react'
import { Tooltip } from '@material-ui/core'
import { Icon as MdiIcon } from '@mdi/react'

import { HeaderOptionProps } from '../types'

const Option: React.FC <HeaderOptionProps> = ({ children, optionRef, icon, label, tooltip, onClick }) => {

    return(

        <Tooltip title = {tooltip} arrow>
            <div className = "headerOption" onClick = {() => onClick()}>
                <MdiIcon path = {icon} size = "32px" color = {'#082756'} />
                {label && <span className = "headerOptionLabel">{label}</span>}
            </div>
        </Tooltip>

    )

}

export default Option