import { setRequestSaveData, setRequestSaveError, setRequestSaveLoading, setRequestSaveMessage } from "../../../../../store/reducers/default/AmostradorReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { AmostradorType } from '../../../../../store/types/AmostradorType'
import { SendParams } from "../types"

export default async function send(apiController: string, dispatch: Function, values: SendParams){
    try {
        dispatch(setRequestSaveLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`

        const data = { 
            codigo: values.codigo,
            descricao: values.descricao,
            obs: values.obs,
            dtAquisicao: values.dtAquisicao,
            flagAtivo: values.flagAtivo ? 1 : 0, 
            idUsuario: values.idUsuario
        }

        const response: RequestResponsePattern<AmostradorType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestSaveData(response))
            if(!response.flagErro) dispatch(setRequestSaveData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestSaveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestSaveError())
    }
}