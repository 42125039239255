import React from 'react'
import { ModuleIconSelectorProps } from './types'
import './styles.scss'
import { moduleIcons } from '../../../../../../constants/moduleIcons'
import ModuleIconSelectorContainer from './container'
import ModuleIconSelectorBox from './box'

const ModuleIconSelector: React.FC <ModuleIconSelectorProps> = props => {

    return(

        <>
            <ModuleIconSelectorContainer>
                {moduleIcons.map((item, index) => <ModuleIconSelectorBox {...props} {...item} key = {index} />)}
            </ModuleIconSelectorContainer>
        </>

    )

}

export default ModuleIconSelector