import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import TableHeader from './Table/header'
import TableRow from './Table/row'
import TableBanner from './Table/banner'
import TableFooter from './Table/footer'
import TableSignature from './Table/signature'

const IndustriasMonitoradasPDF: React.FC = () => {

    const [cliente, setCliente] = useState<any[] >([])

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingBottom: '60px',
            backgroundColor: '#FFFFFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    useEffect(() => {
        (async() => { setCliente(JSON.parse(localStorage.getItem('MonitoramentoIndustria')!)) })()
    }, [])
 

    return(

        <>
            { !!cliente && (
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <Doc>
                        <Page size="A4" style={styles.page} orientation={"landscape"} break>
                                <TableBanner />
                                <TableHeader />
                                 <TableRow  clientes={cliente.reverse()}  />
                                <TableFooter/>
                                <TableSignature/>
                        </Page>
                    </Doc>
                </PDFViewer>
            )}
        </>

    )

}

export default IndustriasMonitoradasPDF