import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import {  relatorio } from "../types"
import { setHistorico, setHistoricoExcel } from "../Reducers/HistoricoLancamentoReducer"

export default async function getHistoricoLancamento(idPessoa:any, dispatch:Function){
    try {


        const endpoint = DOMAIN
        let params = `/PessoaHidrometroLancamento/historico/?idPessoa=${idPessoa}`
        const response: RequestResponsePattern<relatorio> = await getApi({ endpoint, params })
        
        let paramsExcel = `/PessoaHidrometroLancamento/historico/?idPessoa=${idPessoa}&flgHistorico=1`
        const responseExcel: RequestResponsePattern<relatorio> = await getApi({ endpoint, params:paramsExcel })

        if(response){
            if(!response.flagErro) {
                dispatch(setHistorico(response.listaResultados))
            }
        }
        if(responseExcel){
            if(!responseExcel.flagErro) {
                dispatch(setHistoricoExcel(responseExcel.listaResultados))
            }
        }
    }
    catch{

    }
}