import React from 'react'
import { MenuContainerProps, MenuProps } from './types'
import './styles.scss'
import MenuContainer from './Container'

const Menu: React.FC <MenuProps & MenuContainerProps> = ({ children, label, open }) => {
    
    return(

        <>
            {open && <MenuContainer label = {label}>{children}</MenuContainer>}
        </>

    )

}

export default Menu