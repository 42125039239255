import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setRequestSaveRamoAtividadeData, setRequestSaveRamoAtividadeError, setRequestSaveRamoAtividadeLoading, setRequestSaveRamoAtividadeMessage } from "../Reducers/RamoAtividadeReducer"
import { SendRamoAtividadeParams } from "../types"

export default async function sendRamoAtividade(dispatch: Function, values: SendRamoAtividadeParams){
    try {
        dispatch(setRequestSaveRamoAtividadeLoading())
        
        const endpoint = `${DOMAIN}/RamoAtividade`
        const data = { 
            id: values.idRamo,
            descricao: values.descricao, 
            flagAtivo: values.flagAtivo, 
            idUsuario: values.idUsuario,
            corpoEmailVisitaRealizada: values.corpoEmail
        }
        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestSaveRamoAtividadeData(response))
            if(!response.flagErro) dispatch(setRequestSaveRamoAtividadeData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestSaveRamoAtividadeMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestSaveRamoAtividadeError())
    }
}