import { utils, writeFile } from 'xlsx';
import { Users } from '../../Types/Users';

export function genearateRelatorioTecnicosExcel(Tecnicos : Users[] | null) {

     const fileName = `Relatorio_Tecnicos.xlsx`;
     let valoresLimpos : any
     
    valoresLimpos = Tecnicos?.map((tecnico)=> (
        { 
            id :tecnico.id,
            nome: tecnico.nome,
            login:tecnico.login,
            status: tecnico.flagAtivo===1?"Habilitado":"Desabilitado",
            dtCadastro : new Date(tecnico.dtCadastro).toLocaleString()
        }
      ));

     const worksheet = utils.json_to_sheet(valoresLimpos, {header:['id','nome','login','status','dtCadastro']});
     const workbook = utils.book_new();

     utils.book_append_sheet(workbook, worksheet, 'Ramo_Atividade')
     writeFile(workbook, fileName)

}