import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '24px',
            borderBottom: '3px solid black',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        },
        text: {
            fontSize: '10px',
        }
    })

    const [data, setData] = useState<{Inicio:any, Fim:any}>()

    useEffect(() => {
        (async() => { setData(JSON.parse(localStorage.getItem('IntervaloDeTempo')!)) })()
    }, [])

    return(

        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>RELATÓRIO DAS INDÚSTRIAS MONITORADAS</Text>
                <Text style = {styles.subTitle}>Periodo de {new Date(`${data?.Inicio} 00:00:00`).toLocaleDateString()} até {new Date(`${data?.Fim} 00:00:00`).toLocaleDateString()}</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>

    )

}

export default TableBanner