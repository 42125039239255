import { DOMAIN } from "../../../../../var_env."
import { setRequestEditData, setRequestEditLoading, setRequestEditMessage, setRequestEditError } from "../Reducers/ClienteContatoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"

import { Contato } from "../Types/Contatos"

export default async function editContato(dispatch: Function, data: any){
    try {
        dispatch(setRequestEditLoading())
        
        const endpoint = `${DOMAIN}/PessoaContato`

        
        const response: RequestResponsePattern<Contato> = await postApi({ endpoint, data })
        
        if(response){
            dispatch(setRequestEditData(response))
            if(!response.flagErro) dispatch(setRequestEditData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        dispatch(setRequestEditMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestEditError())
    }
}