import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '24px',
            borderBottom: '3px solid black',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
        },
        text: {
            fontSize: '10px',
        },title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        }
    })
    const [data, setData] = useState<{inicio:any, fim:any}>()

    useEffect(() => {
        (async() => { setData(JSON.parse(localStorage.getItem('dataBuscaRelatorioDbo')!)) })()
    }, [])
    
    return(

        <>
        
        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>ATUALIZAÇÃO DE VALORES DE DBO</Text>
                <Text style = {styles.subTitle}>Periodo de {new Date(`${data?.inicio} 00:00:00`).toLocaleDateString()} até {new Date(`${data?.fim} 00:00:00`).toLocaleDateString()}</Text>
            </View>
            <Text style = {styles.text}>{`${new Date().toLocaleString()}`}</Text>
        </View>

        <View style = {{marginTop: '18px'}}>
            <Text style = {{ fontSize: '10px'}}>AT.: </Text>
            <Text style = {{marginTop: '5px', marginBottom: '5px', fontSize: '10px'}}>Setor de Contas e Controle</Text>
            <Text style = {{marginBottom: '5px', fontSize: '10px'}}>DAE S/A - água e esgoto</Text>
            <Text style = {{marginBottom: '5px', fontSize: '10px'}}>Rodovia Vereador Geraldo Dias, 1500 </Text>
            <Text style = {{marginBottom: '5px', fontSize: '10px'}}>Jundiaí-SP </Text>
            <Text style = {{marginBottom: '5px', fontSize: '10px'}}>Prezados (as) </Text>
            <Text style = {{marginBottom: '25px', fontSize: '10px'}}>A CSJ, Compahia de Saneamento de Jundiaí, na prática das ações exercidas no Programa de Monitoramento de Esgoto Industrial, 
solicita a atualização dos valores de DBO apurados no período descrito acima para as seguintes unidades consumidoras:
 </Text>
        </View>
        </>
    )

}

export default TableBanner