import React, { ChangeEvent, useCallback } from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { ClienteType } from "../../Parametro/Types/ClienteType"
import List, { ListColumn, ListRow, ListSection } from '../../../../../components/List'
import { Link } from 'react-router-dom'
import { Checkbox } from '@mui/material'
import { setListaId } from '../Reducers/ClientsReducer'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../store/hooks'

interface Props {
    lista: ClienteType[] | null
    loading: boolean
    check: boolean
}

const TableListarClientes: React.FC<Props> = ({ lista, loading, check }) => {

    const grids = "80px 1fr 1fr 100px 140px"
    const dispatch = useDispatch()
    const {listaIdColeta} = useAppSelector(state => state.ClientsReducer)
    const SHOW_DATA = !!lista && !loading
    const SHOW_LOADING = loading

    const handleEditarCheck = useCallback((Checkbox: ChangeEvent<HTMLInputElement>, idParametro: any) => {
        let novaListaId = [...listaIdColeta!]
 
        if(Checkbox.target.checked) { 
            novaListaId?.push(idParametro)
            
        }else{
            const index = listaIdColeta?.indexOf(idParametro)
            novaListaId.splice(index!, 1)
            //alert(novaListaId)
        }

        dispatch(setListaId([...novaListaId!]))
        
    }, [listaIdColeta,dispatch])
    
    
    return (
        <>
            <List name="clients">
                <ListSection type="header">
                    <ListRow grid={grids}>
                        {(check ?["","Código", "Cliente", "Situação", "Data de Cadastro"]:["Código", "Cliente", "Situação", "Data de Cadastro"]).map((column, index) => <ListColumn key={index} text={column} />)}
                    </ListRow>
                </ListSection>
                <ListSection type="main">
                    {SHOW_LOADING && (
                        <ListRow grid='1fr'>
                            <ListColumn center><CircleSpinner size={32} color="grey" /></ListColumn>
                        </ListRow>
                    )} 
                    {SHOW_DATA && lista.map((item, index) => (
                        <ListRow key={item.id}  zebra={index % 2 === 0} grid={grids}>
                            {check && <Checkbox checked={listaIdColeta?.includes(item.id)}  onChange={(e) => handleEditarCheck(e, item.id)}  />}
                            <Link to={"/cadastro/cliente/" + item.id.toString()}> <ListColumn text={item.codigo} /></Link>
                            <Link to={"/cadastro/cliente/" + item.id.toString()}><ListColumn text={item.nome} /></Link>
                            <Link to={"/cadastro/cliente/" + item.id.toString()}><ListColumn text={(item.flagAtivo === 0 ? 'Desabilitado' : 'Habilitado')} /></Link>
                            <Link to={"/cadastro/cliente/" + item.id.toString()}><ListColumn text={new Date(item.dtCadastro).toLocaleDateString()} /></Link>
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>
    )

}

export default TableListarClientes
 
