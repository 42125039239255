import { DOMAIN } from '../../../../../var_env.'
import React , { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { Formik, Form as FormikContainer } from 'formik'
import { useAppSelector } from '../../../../../store/hooks'
import { SectionBoxData } from '../../../../../components/Section'
import Form from '../../../../../components/Form'
import editClienteDados from '../scripts/editClienteDados'
import Select from '../../../../../components/Select'
import { getApi } from '../../../../../utils/requestApi/index'
import { activeItems } from '../../../../../constants/selects'
import { setClienteAtual } from '../Reducers/ClientsReducer'

const Dados: React.FC<{canEdit: boolean}> = ({canEdit}) => {
    
    const { idCliente } = useParams<any>()

    const dispatch = useDispatch()
    const { userData } = useAppSelector(state => state.auth)
    const { roteiro, ramoAtividade, requestGetRoteiro, requestGetPontosColeta, requestGetRamoAtividade } = useAppSelector(state => state.ClientsReducer)
    const { requestEdit } = useAppSelector(state => state.ClienteDados)

    const [grupos, setGrupos] = useState<any>(null)
    const [cliente, setCliente] = useState<any>(null)
    
    const [newStatus, setNewStatus] = useState<any>(null)
    const [newRamoAtividade, setNewRamoAtividade] = useState<string>('')
    const [newRoteiro, setNewRoteiro] = useState<string>('')
    const [obsAtual, setObsAtual] = useState<string>()

    const SHOW_CLIENTE = (!!cliente && requestGetRoteiro.data && requestGetPontosColeta.data && requestGetRamoAtividade.data) || parseInt(idCliente!) === 0

    useEffect(() => {
        (async () => {
            setGrupos((await getApi({ endpoint: `${DOMAIN}/Pessoa`, params: '' })).listaResultados)
        })()
    }, [idCliente])

    useEffect(() => {
        if(!!grupos){
            for(let i = 0; i < grupos.length; i++){
                if(grupos[i].id === parseInt(idCliente!)) 
                {
                    setCliente(grupos[i])
                    dispatch(setClienteAtual(grupos[i]))
                }
            }
            setObsAtual(cliente?.obsPrincipal!)

            localStorage.setItem("ClienteHistorico", JSON.stringify(cliente))
        }
    },[grupos,dispatch ,idCliente, cliente])

    return(
        <>
            {SHOW_CLIENTE &&
                <>
                    <Formik
                        initialValues = {{
                            code: cliente?.codigo, 
                            nome: cliente?.nome, 
                            status: cliente?.flagAtivo ===1 || parseInt(idCliente!) === 0?"Habilitado":"Desabilitado",
                            tipoPessoa: (cliente?.flagTipoPessoa==="J" || parseInt(idCliente!) === 0)?"Jurídica":"Física",
                            fantasyName: cliente?.razaoSocial,
                            rg: cliente?.rg,
                            cpf: cliente?.nroDocumento,
                            inscEst: cliente?.inscEstadual,
                            uf: cliente?.ufRg,
                            obsPrincipal: cliente?.obsPrincipal,
                            obsSecundaria: cliente?.obsSecundaria,
                            idIntegracao: cliente?.idIntegracao
                        }}
                        onSubmit = {values => editClienteDados(dispatch, cliente?.id??0, values, newRamoAtividade, newRoteiro, newStatus, userData!.id, obsAtual)}
                    >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <SectionBoxData title = "Informações básicas">
                                    <Form.Row columns = {4} grid = "150px 1fr 120px 120px">
                                        <Form.Group inputID='code' inputName='code' label='Código' />
                                        <Form.Group inputID='nome' inputName='nome' label='Nome' />
                                        <Select type='outline' value ={newStatus} setValue={setNewStatus} defaultValue={cliente?.flagAtivo===1 || parseInt(idCliente!) === 0?"1":"0"} label='Status' items={activeItems} />
                                        <Form.Group inputID='tipoPessoa' inputName='tipoPessoa' label='Tipo Pessoa' />
                                    </Form.Row>
                                    <Form.Row columns = {4} grid = "150px 1fr 235px 235px">
                                    <Form.Group inputID='idIntegracao' inputName='idIntegracao' label='ID conta' />
                                        <Form.Group inputID='fantasyName' inputName='fantasyName' label='Nome Fantasia' />
                                        <Select type='outline' value={newRamoAtividade} setValue={setNewRamoAtividade} defaultValue={cliente?.idRamoAtividade && cliente?.idRamoAtividade.toString()} label='Ramo de Atividade' items={ramoAtividade ? ramoAtividade.map(item => { return { value: item.id.toString(), label: item.descricao } }) : []} />
                                        <Select type='outline' value={newRoteiro} setValue={setNewRoteiro} defaultValue={cliente?.idRoteiro && cliente?.idRoteiro.toString()} label='Roteiro' items={roteiro ? roteiro.map(item => { return { value: item.id.toString(), label: item.descricao } }) : []} />
                                    </Form.Row>
                                    <Form.Row columns = {4} grid = "repeat(4, 1fr)">    
                                        <Form.Group inputID='rg' inputName='rg' label='RG' />
                                        <Form.Group inputID='cpf' inputName='cpf' label='CPF/CNPJ' />
                                        <Form.Group inputID='inscEst' inputName='inscEst' label='Inscrição Estadual' />
                                        <Form.Group inputID='uf' inputName='uf' label='UF' />
                                    </Form.Row>
                                </SectionBoxData>
                                <SectionBoxData title = "Observações">
                                    <Form.Row columns = {2}>
                                        <Form.Group inputID='obsPrincipal' inputName='obsPrincipal' inputType='textarea' inputRows={6} label='OBS Simon' />
                                        <Form.Group inputID='obsSecundaria' inputName='obsSecundaria' inputType='textarea' inputRows={6} label='OBS myLIMS' readonly />
                                    </Form.Row>
                                </SectionBoxData>
                                <div className = "formActionsContainer">
                                    {canEdit && <button type = "submit" className = {`default ${requestEdit.loading ? 'status disabled' : ''}`}>{requestEdit.loading && <div className = "spinner" />}Salvar</button>}
                                </div>
                            </Form.Container>
                        </FormikContainer>
                    </Formik>
                    {/* <div id = "basicInformationsGrid">
                        <div className = "basicInformationsContent">
                            
                        </div>
                        <div className = "atentionLevelContent">
                            <SectionBoxData title = "Nível de atenção">
                                <div id = "atentionLevelContainer">
                                    <div className = "atentionBar">
                                        <div className = "atentionBarTextContainer">
                                            <span>PH</span>
                                            <span>Temp</span>
                                        </div>
                                    </div>
                                    <div className = "atentionBar">
                                        <div className = "atentionBarTextContainer">
                                            <span>SS</span>
                                            <span>Og</span>
                                        </div>
                                    </div>
                                </div>
                            </SectionBoxData>
                            <Select type = "outline" value = {''} setValue = {() => {}} items = {basicItems} />
                        </div>
                    </div> */}

                </>
            }
        </>
    )

}

export default Dados