export default  function dataToReference(mes: number | undefined, ano:number| undefined){
    let textoFormatado: string = ''

    if(mes===1)textoFormatado="Jan"
    if(mes===2)textoFormatado="Fev"
    if(mes===3)textoFormatado="Mar"
    if(mes===4)textoFormatado="Abr"
    if(mes===5)textoFormatado="Mai"
    if(mes===6)textoFormatado="Jun"
    if(mes===7)textoFormatado="Jul"
    if(mes===8)textoFormatado="Ago"
    if(mes===9)textoFormatado="Set"
    if(mes===10)textoFormatado="Out"
    if(mes===11)textoFormatado="Nov"
    if(mes===12)textoFormatado="Dez"

    textoFormatado = textoFormatado + `/${ano?.toString().substring(2,4)}`

    return textoFormatado
}