import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { RelatorioRegiaoType } from '../Types/RelatorioRegiao'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableContainer from '@mui/material/TableContainer'

interface Props {
    lista: RelatorioRegiaoType[] | null
    loading: boolean
}

const TableListarComponents: React.FC <Props> = ({ lista, loading }) => {

    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !SHOW_LOADING
    const [selectedLine, setSelectedLine] = React.useState<any | null>(null)

    return(

        <TableContainer sx={{ maxHeight: 640 }}>
            <Table stickyHeader aria-label="sticky table" >
                <TableHead>
                    <TableRow >
                        {['ID', 'Nome', 'Endereço', 'Roteiro','Status'].map((item, index) => <TableCell key = {index}>{item}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody >
                    {SHOW_LOADING && (
                        <TableRow >
                            <TableCell ><CircleSpinner size = {32} color = "grey" /></TableCell>
                        </TableRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <TableRow key = {index} className = {selectedLine?.nome === item.nome ? 'selected' : ''} onClick = {() => setSelectedLine(item)}>
                            <TableCell>{item.id}</TableCell>
                            <TableCell ><div style={{ textAlign: "left" }}>{item.nome}</div></TableCell>
                            <TableCell><div style={{ textAlign: "left" }}>{item.enderecoCompleto}</div></TableCell>
                            <TableCell><div style={{ textAlign: "left" }}>{item.descricaoRoteiro}</div></TableCell>
                            <TableCell><div style={{ textAlign: "left" }}>{item.flagAtivo===1?"Habilitado":"Desabilitado"}</div></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    )

}

export default TableListarComponents