import React, { useLayoutEffect, useState } from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableInfoTextContainer } from './types'
import { medidor } from '../types'
import TableLine from './line'

const TableAgua: React.FC<{medidores: medidor[]}> = ({medidores}) => {

    const [medidoresPorPagina, setMedidoresPorPagina] = useState<medidor[][]>([])
    const [renderLista, setRenderLista] = useState<boolean>(false)

    const styles = StyleSheet.create({
        sectionTitle: {
            marginTop: '12px',
            marginBottom: '8px',
            fontSize: '10px',
            fontWeight: 'bold',
        },
        box: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            border: '1px solid black',
            marginBottom: '9px',
        },
        leftContent: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            flexShrink: 1,
            padding: '8px',
            borderRight: '1px solid black',
            backgroundColor: '#C0C0C0',
        },
        rightContent: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            padding: '4px',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '8px',
        },
        textContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            display: 'flex',
            flexDirection: 'column',
            marginRight: '4px',
            fontSize: '10px',
        },
        textField: {
            flex: 1,
            height: '100%',
            padding: '2px',
            border: '1px solid black',
        },
        text: {
            fontSize: '10px',
        },
    })

    const totalMedidores = medidores.filter(medidor => medidor.flagMedidorVazao === 0).length

    const TextContainer: React.FC <TableInfoTextContainer> = ({ children, label, text, containerWidth, labelWidth, marginRight }) => (
        <View style = {[styles.textContainer, {width: containerWidth ?? '100%', marginRight: marginRight ? '8px' : '0'}]}>
            <Text style = {[styles.label, {width: labelWidth ?? 'auto'}]}>{label}</Text>
            <View style = {styles.textField}>
                {children ?? <Text style = {styles.text}>{text}</Text>}
            </View>
        </View>
    )

    const determinaLinhas = (medidoresLinha: number) => {
        let linhas = 0
        if(medidoresLinha === 0) linhas = 11
        else{
            const medidoresPorLinha = 7 - medidoresLinha
            linhas = medidoresPorLinha === 0 ? 1 : (medidoresPorLinha*4)
        }
        return [...Array(linhas)].map((_, index) => <TableLine key = {index} last = {index === linhas - 1} />)
    }

    const determinaLinhasSimples = (medidoresLinha: number) => {
        let linhas = 15-(medidoresLinha*4)
        return [...Array(linhas)].map((_, index) => <TableLine key = {index} last = {index === linhas - 1} />)
    }

    useLayoutEffect(() => {
        const newMedidores = medidores.filter(medidor => medidor.flagMedidorVazao === 0)
        if(newMedidores.length > 3){
            const paginas = []
            newMedidores.splice(0, 3)
            for (let i = 0; i < newMedidores.length; i += 7) {
                paginas.push(newMedidores.slice(i, i + 7))
            }
            setMedidoresPorPagina(paginas)
        }
        setRenderLista(true)
    }, [medidores])

    return(

        <>
            <Text style = {styles.sectionTitle}>{`Fonte de Água (${totalMedidores}) :`}</Text>
            {totalMedidores === 0 && [...Array(3*4)].map((_, i) => <TableLine key = {i} last = {i + 1 === (3*4)} />)}
            {renderLista && medidores.filter(medidor => medidor.flagMedidorVazao === 0).map((medidor, index) => {

                const pagina = medidoresPorPagina.findIndex(grupo => grupo.find(medidorPagina => medidorPagina.idHidrometro === medidor.idHidrometro))
                const medidoresPagina = medidoresPorPagina[pagina]?.length ?? 0
                const indexMedidor = medidoresPorPagina[pagina]?.findIndex(medidorPagina => medidorPagina.idHidrometro === medidor.idHidrometro) ?? 0
                const medidorIsLast = indexMedidor === medidoresPagina - 1
                const tamanho = medidores.filter(medidor => medidor.flagMedidorVazao === 0).length
                
                return(
                    <React.Fragment key = {index}>
                        <View style = {styles.box} wrap={false}>
                            <View style = {styles.leftContent}>
                                <Text style = {{marginBottom: '12px', fontSize: '8px'}}>FONTE</Text>
                                <Text style = {{fontSize: '24px', fontWeight: 'bold'}}>{index + 1}</Text>
                            </View>
                            <View style = {styles.rightContent}>
                                <View style = {styles.row}>
                                    <TextContainer
                                        label = "Tipo:"
                                        text = {`${medidor.tipoFonte}`}
                                        containerWidth = "35%"
                                        marginRight
                                    />
                                    <TextContainer
                                        label = "HD:"
                                        text = {medidor.registro}
                                        containerWidth = "35%"
                                        marginRight
                                    />
                                    <TextContainer
                                        label = "UC:"
                                        text = {medidor.uc ?? ''}
                                        containerWidth = "30%"
                                    />
                                </View>
                                <View style = {styles.row}>
                                    <TextContainer
                                        label = "Obs:"
                                        text = {medidor.obs}
                                        containerWidth = "40%"
                                        marginRight
                                    />
                                    <TextContainer
                                        label = "Volume Totalizado:"
                                        text = {""}
                                        containerWidth = "60%"
                                        labelWidth = "50px"    
                                    >
                                        <TextContainer
                                            label = "Leit. anterior:"
                                            text = {`${medidor.leituraAnterior}`}
                                            containerWidth = "100%"
                                            labelWidth = "80px"
                                        />
                                        <TextContainer
                                            label = "Leit. atual:"
                                            text = {`${/*medidor.leituraAtual*/" "}`}
                                            containerWidth = "100%"
                                            labelWidth = "80px"
                                        />
                                    </TextContainer>
                                </View>
                            </View>
                        </View>
                        {/* {index < 3 && medidoresPagina === 0 && tamanho < 4 && (
                            console.log(tamanho),
                            [...Array((15-tamanho))].map((_, i) => <TableLine key = {i} last = {i + 1 === (15-(tamanho*3))} />)
                        )} */}
                        {((index + 1) === tamanho && tamanho < 4 && medidoresPagina === 0 && (
                            [...Array(15-(tamanho*5))].map((_, i) => <TableLine key = {i} last = {i + 1 === (15-(tamanho*5))} />)
                        ))}
                        {index === 2 && tamanho >= 3 && (
                            [...Array(2)].map((_, i) => <TableLine key = {i} last = {i + 1 === 2} />)
                        )}
                        {(index < 3 && medidorIsLast && determinaLinhasSimples(index)) || (medidorIsLast && determinaLinhas(medidoresPagina))}
                    </React.Fragment>
                )
            })}
        </>

    )

}

export default TableAgua