import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
    
const TableHeader: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },  
        column: {
            padding: '2px 1px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '6px',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        text: {
            fontSize: '10px',
        }
    })

    const Column: React.FC <{text: string, width: string, last?: boolean}> = ({text, width, last}) => (
        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )
 
    return(
        <>
        <Text style={styles.text}>Este é um relatório de circulação restrita, PROIBIDA a divulgação ou publicação de parte ou todo deste documento.</Text>
           


        <View style = {styles.row}>
            <Column text = "Indústria" width = "10%" />
            <Column text = "Logradouro" width = "11%" />
            <Column text = "CNPJ" width = "10%" />
            <Column text = "Instalação" width = "9%" />
            <Column text = "DQO mg/L *" width = "4%" />
            <Column text = "DBO mg/L *" width = "4%" />
            <Column text = "pH" width = "4%" />
            <Column text = "°C" width = "4%" />
            <Column text = "SS ml/L" width = "4%" />
            <Column text = "OG mg/L" width = "4%" />
            <Column text = "N mg/L *" width = "4%" />
            <Column text = "P mg/L *" width = "4%" />
            <Column text = "Cd mg/L *" width = "4%" />
            <Column text = "Cu mg/L *" width = "4%" />
            <Column text = "Cr mg/L *" width = "4%" />
            <Column text = "Ni mg/L *" width = "4%" />
            <Column text = "Pb mg/L *" width = "4%" />
            <Column text = "Zn mg/L *" width = "4%" />
            <Column text = "Ag mg/L *" width = "4%" />
            <Column text = "As mg/L *" width = "4%" />
            <Column text = "Hg mg/L *" width = "4%" />
            <Column text = "Se mg/L *" width = "4%" />
            <Column text = "Sn mg/ *L" width = "4%" />
            <Column text = "Fe mg/L *" width = "4%" />
            <Column text = "Sulfato mg/L *" width = "4%" />
            <Column text = "Sulfeto mg/L" width = "4%" />
            <Column text = "Fenol mg/L" width = "4%" />
            <Column text = "Fluoreto mg/L" width = "4%" />
            <Column text = "Cr6 mg/L" width = "4%" />
            <Column text = "Cn mg/L" width = "4%" />
            <Column text = "Toxidade" width = "4%" last />
        </View>
        </>
    )

}

export default TableHeader