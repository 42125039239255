import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { setListaFiltered, setRequestFilterData, setRequestFilterLoading, setRequestGetError, setRequestGetMessage } from "../Reducers/RelatorioPessoaReducer"
import { getApi } from "../../../../../utils/requestApi"
import { RelatorioPessoaType } from "../types"


export default async function filter(dispatch: Function, filtrar:boolean = true){
    try {
        dispatch(setListaFiltered([]))
        dispatch(setRequestFilterLoading())

        const endpoint = DOMAIN
        const params = `/RelatorioPessoa/`
        const response: RequestResponsePattern<RelatorioPessoaType[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) {
                dispatch(setListaFiltered(filtrar? response.listaResultados.filter(x=> x.flagAtivo===1) : response.listaResultados))
                localStorage.setItem('RelatorioPessoa', JSON.stringify(filtrar? response.listaResultados.filter(x=> x.flagAtivo===1) : response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetError())
    }
}