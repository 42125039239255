import { RequestResponsePattern } from '../../../../../../types/Request'
import { postApi } from '../../../../../../utils/requestApi'
import { DOMAIN } from '../../../../../../var_env.'
import { setRequestSaveData, setRequestSaveError, setRequestSaveLoading, setRequestSaveMessage } from '../../Reducers/PessoaHidrometroReducer'
import { PessoaHidrometroType, SendParams } from '../../Types/PessoaHidrometroType'

export default async function create(apiController: string, dispatch: Function, values: SendParams){
    try {
        dispatch(setRequestSaveLoading())
        
        const endpoint = `${DOMAIN}/${apiController}`

        const data = { 
            idPessoa: values.idCliente,
            registro: values.registro,
            uc: values.uc,
            idFonteAbastecimento: values.idFonteAbastecimento,
            flagAtivo: values.flagAtivo ? 1 : 0, 
            idUsuario: values.idUsuario,
            flagMedidorVazao: values.flagMedidorVazao
        }

        const response: RequestResponsePattern<PessoaHidrometroType> = await postApi({ endpoint, data })

        if(response){
            
            dispatch(setRequestSaveData(response))
            if(!response.flagErro) dispatch(setRequestSaveData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestSaveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestSaveError())
    }
}