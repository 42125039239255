import { ParametroType } from "../../Parametro/Types/ParametroType";

export default  function conformidade(ParametroName : string, values: any){
    if(ParametroName==="temperatura") ParametroName= "°C"
    if(ParametroName==="Rs") ParametroName= "SS"
    let valorParametro : Number = 0 
    if (typeof values === 'number') valorParametro = Number(values)
    if (typeof values === 'string') valorParametro=  Number(values.replace('<', ''))
    //if(values === null) return {background:'white'}
    const parametros : ParametroType[] | null = JSON.parse(localStorage.getItem("Parametro")!)
    
    const oParametro = parametros?.find(x => x.descricao.toUpperCase() === ParametroName.toUpperCase() || x.sigla.toUpperCase() === ParametroName.toUpperCase() )
    if(values === null || values === "" || Number(values) === 0 ||  (Number(oParametro?.maximo) ===0  && Number(oParametro?.minimo) === 0)) return ""
    //if( Number(oParametro?.maximo) ===0  && Number(oParametro?.minimo) === 0) return {background:'white'}
    if(valorParametro> oParametro?.maximo || valorParametro< oParametro?.minimo) return "status error"
    return ""
    //return {background:'white'}
}