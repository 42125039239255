import React, { useEffect, useState } from 'react'
import { Page, Document as Doc, StyleSheet, PDFViewer } from '@react-pdf/renderer'
import { ficha } from './types'
import TableInfo from './table/info'
import TableBanner from './table/banner'
import TableFooter from './table/footer'

const FichaPdf: React.FC = () => {

    const [dados, setDados] = useState<ficha[] | null>([])
    const [mesAno, setMesAno] = useState<{mes:number, ano:number}>()

    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            paddingTop: '20px',
            paddingHorizontal: '20px',
            paddingVertical: '80px',
            backgroundColor: '#FFFFFF',
        }
    })

    useEffect(() => {
        (async() => { 
            setDados(JSON.parse(localStorage.getItem("PDF_FICHA")!)) 
            setMesAno(JSON.parse(localStorage.getItem('anoMes')!))
        })()
    }, [])

    return(

        <>
            {(
                <PDFViewer style={{ width: '100vw', height: '100vh' }}>
                    <> 
                    {dados && dados.length>0 && <Doc>
                        <Page size="A4" style={styles.page} wrap>
                            <TableBanner />
                            <TableInfo dadosFicha={dados} dataReferente={mesAno} />
                            <TableFooter />
                        </Page>
                    </Doc>}
                    </>
                </PDFViewer>
            )}
        </>

    )

}

export default FichaPdf