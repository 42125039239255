import React from 'react'
import { Text, View, StyleSheet } from "@react-pdf/renderer"
import { ColumnProps } from './types'

const Column: React.FC <ColumnProps> = ({ text, width, last, align, Color }) => {

    const styles = StyleSheet.create({       
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '6px',
            fontfamily: 'Arial',
            textAlign: 'center',
        }
    })

    return(

        <View style = {[styles.column, {width, borderRight: !!last ? '1px solid black' : 0, textAlign: align ?? 'center', backgroundColor: Color?? '#FFFFFF', color: Color==="red"? "#FFFFFF" :"black", fontSize: Color==="red"? "8px" :"6px"}]}>
            <Text>{text}</Text>
        </View>

    )

}

export default Column