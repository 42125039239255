import React from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import ReactHtmlParser from 'react-html-parser'; 

import List, { ListColumn, ListRow, ListSection } from '../../../../../components/List'

interface Props {
    lista: any[] | null
    loading: boolean
}
   
const TableListarLogUser: React.FC<Props> = ({ lista, loading  }) => {

    
    const grid = "300px 400px  400px" 

    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !loading


    return(
    
        <>

            <List name = "list">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["Usuário","Ação", "Data"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>
                <ListSection type = "main">
                    {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                            <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                        </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow key = {item.id} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.login}</ListColumn>
                            <ListColumn><div>{ReactHtmlParser(item.acao)}</div></ListColumn>
                            <ListColumn>{new Date(item.dtAcao)?.toLocaleString()}</ListColumn>
 
                        </ListRow>
                    ))}
                </ListSection>
            </List>
        </>

    )

}

export default TableListarLogUser