import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { TableInfoTextContainer } from './types'
import { ficha } from '../types'
import TableEsgoto from './esgoto'
import TableAgua from './agua'

const TableInfo: React.FC <{dadosFicha:ficha[] | null, dataReferente?: {mes:number, ano:number}}> = ({dadosFicha, dataReferente}) => {

    const styles = StyleSheet.create({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '4px',
        },
        textContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        label: {
            marginRight: '4px',
            fontSize: '10px',
        },
        textField: {
            flex: 1,
            padding: '2px',
            border: '1px solid black',
        },
        text: {
            fontSize: '10px',
        },
        dateBox: {
            paddingVertical: '8px',
            paddingHorizontal: '12px',
            border: '1px solid black',
            fontSize: '10px',
        }
    })
    
    const TextContainer: React.FC <TableInfoTextContainer> = ({ label, text, containerWidth, labelWidth, marginRight }) => (
        <View break style = {[styles.textContainer, {width: containerWidth ?? '100%', marginRight: marginRight ? '8px' : '0'}]}>
            <Text style = {[styles.label, {width: labelWidth ?? 'auto'}]}>{label}</Text>
            <View style = {styles.textField}>
                <Text style = {styles.text}>{text}</Text>
            </View>
        </View>
    )

    return(
        <>
            {dadosFicha && dadosFicha.map((dados, index) => (
                <View key = {index} style = {styles.container} break = {index !== 0}>  
                    <View style = {styles.row}>
                        <TextContainer 
                            label = "Cliente:" 
                            text = {`${dados.empresa}`} 
                            labelWidth = "54px"
                            containerWidth = "84%"
                            marginRight 
                        />
                        <TextContainer
                            label = "Período:"
                            text = {`${dataReferente?.mes.toString().length === 1 ? `0${dataReferente?.mes}` : dataReferente?.mes}/${dataReferente?.ano}`}
                            containerWidth = "16%"
                        />
                    </View>
                    <View style = {styles.row}>
                        <TextContainer 
                            label = "CEP:" 
                            text = {dados.enderecos.map((oEndereço)=> oEndereço.cep).join(',')}
                            labelWidth = "54px"
                            marginRight 
                        />
                        <TextContainer 
                            label = "Nº:" 
                            text = {dados.enderecos.map((oEndereço)=> oEndereço.numero).join(',')}
                            containerWidth = "35%"
                            marginRight 
                        />
                        <TextContainer 
                            label = "Compl:" 
                            text = {(dados.enderecos.map((oEndereço)=> oEndereço.complemento)).join(',')}
                            containerWidth = "35%"
                        />
                    </View>
                    <View style = {styles.row}>
                        <TextContainer 
                            label = "Bairro:" 
                            text = {(dados.enderecos.map((oEndereço)=> oEndereço.bairro)).join(',')}
                            labelWidth = "54px"
                            marginRight 
                        />
                        <TextContainer label = "Cidade:" text = {(dados.enderecos.map((oEndereço)=> oEndereço.rua)).join(',')} marginRight />
                        <TextContainer 
                            label = "UF:" 
                            text = {(dados.enderecos.map((oEndereço)=> oEndereço.uf)).join(',')}
                            containerWidth = "25%"
                        />
                    </View>
                    <View style = {styles.row}>
                        <TextContainer 
                            label = "Fone:" 
                            text = {(dados.contatos.map((oContato)=> oContato.telefone)).join(' / ')}
                            labelWidth = "54px"
                            marginRight 
                        />
                        <TextContainer 
                            label = "Contato:" 
                            text = {dados.contatos.map((oContato)=> oContato.nome).join(' / ')}
                            containerWidth = "40%"
                        />
                    </View>
                    <View style = {styles.row}>
                        <TextContainer
                            label = "Calibração:"
                            text = {`${new Date(dados.dtAtualCalibragem).toLocaleString()}`}
                            containerWidth = "35%"
                            labelWidth = "54px"
                            marginRight
                        />
                        <TextContainer
                            label = "Verificação:"
                            text = {`${new Date(dados.dtValidadeCalibragem).toLocaleString()}`}
                            containerWidth = "35%"
                            marginRight
                        />
                        <TextContainer
                            label = "Validação:"
                            text =  {`${new Date(dados.dtValidadeCalibragem).toLocaleString()}`}
                            containerWidth = "30%"
                        />
                    </View>
                    <View style = {styles.row}>
                        <TextContainer
                            label = "Num. Série:"
                            text = {dados.nroSerie}
                            containerWidth = "30%"
                            labelWidth = "54px"
                            marginRight
                        />
                        <TextContainer
                            label = "Cód. Equipe:"
                            text = {dados.codigoEquipamento}
                            containerWidth = "30%"
                            marginRight
                        />
                        <TextContainer
                            label = "Modelo:"
                            text = {dados.modelo}
                            containerWidth = "40%"
                        />
                    </View>
                    <TableEsgoto medidores={dados.listaMedidores} />
                    <TableAgua medidores={dados.listaMedidores}/>
                </View>
            ))}
        </>
    )

}

export default TableInfo