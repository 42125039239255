import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { PessoaHidrometroType } from "../../Cliente/Types/PessoaHidrometroType"


export default async function getHIdrometro(idPessoa: number){
    try {

        const endpoint = DOMAIN
        const params = `/PessoaHidrometro/?idPessoa=${idPessoa}`
        const response: RequestResponsePattern<PessoaHidrometroType[]> = await getApi({ endpoint, params })
        
        if(response){
  
            if(!response.flagErro) {

                localStorage.setItem("PessoaHidrometro", JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)

    }
}