import { Text, View } from '@react-pdf/renderer'
import React from 'react'

const TableSignature: React.FC = () => {

    return(

        <View  style = {{marginTop:"200px", width: '50%', left: '50%', textAlign: 'right'}} >
            <Text style = {{marginBottom: '5px', fontSize: '10px'}}>FABIO HENRIQUE ERCOLIN</Text>
            <Text style = {{fontSize: '10px'}}>Coordenador de Monitoramento Industrial</Text>
            <Text style = {{fontSize: '10px'}}>fabio@saneamento.com.br</Text>
            <Text style = {{fontSize: '10px', marginBottom: '20px', }}>monitoramento@saneamento.com.br</Text>

            <Text style = {{fontSize: '10px'}}>Em cópia</Text>
            <Text style = {{fontSize: '10px'}}>CC: CETESB - Cia de Tecnologia de Saneamento Ambiental</Text>
            <Text style = {{fontSize: '10px'}}>DAE S/A - Água e Esgoto</Text>
        </View>



    )

}

export default TableSignature