import ExcelJS from 'exceljs'
import * as fs from 'file-saver'
import { ParametroType } from '../../Parametro/Types/ParametroType';
import { HistoricoColeta } from '../Types/HistoricoColeta';



export function generateHistoricoExcel(item: HistoricoColeta, valorDBOAnterior:number, valorDBOAtual:number) {

    const workbook = new ExcelJS.Workbook();

    const sheet = workbook.addWorksheet('Excel', { properties: { tabColor: { argb: 'FFC0000' } } });

    // const listaParametros = ["DQO"
    //     ,"DBO"
    //     ,"pH"
    //     ,"°C"
    //     ,"Ag"
    //     ,"As"
    //     ,"Cd"
    //     ,"CN"
    //     ,"Cr"
    //     ,"Cr6"
    //     ,"Cu"
    //     ,"Fe"
    //     ,"Fenol"
    //     ,"Hg"
    //     ,"Ni"
    //     ,"N"
    //     ,"Pb"
    //     ,"P"
    //     ,"Se"
    //     ,"Sn"
    //     ,"Zn"
    //     ,"SS"
    //     ,"Og"
    //     ,"Sulfato"
    //     ,"Sulfeto"
    //     ,"Fluoreto"
    //     ,"Toxicidade"]
     const parametros : ParametroType[] | null = JSON.parse(localStorage.getItem("Parametro")!)
    
    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0 || Number(value)=== 0 ? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    let json : any[] =[]
    const hidrometro:any[] = JSON.parse(localStorage.getItem('PessoaHidrometro')!)
    console.log(hidrometro)
    json = item.result.map(item =>(
        {
            nomeCliente: item.nomeCliente,
            DBOAnterior: valorDBOAnterior.toFixed(3).replace('.', ','),
            DBOAtual: valorDBOAtual.toFixed(3).replace('.', ','),
            pontoColeta: item.pontoColeta,
            RTPontoColeta3: item.RTPontoColeta3 ,
            amostrador: item.amostrador,
            dtInstalaco: new Date(item.dtInstalaco).toLocaleString(),
            tempoInstalacao: item.tempoInstalacao,
            dtRetirada: new Date(item.dtRetirada).toLocaleString(),
            qtdeColeta: item.qtdeColeta,
            codigoAmostra: item.codigoAmostra,
            flagChuva: item.flagChuva===1?"VERDADE":"FALSO",
            medidorInicial: handleValue(item.medidorInicial),
            medidorFinal: handleValue(item.medidorFinal),
            volumeMedido: handleValue(item.volumeMedido),
            DQO: handleValue(item.DQO),
            DBO: handleValue(item.DBO),
            pH: handleValue(item.pH),
            temperatura: handleValue(item.temperatura),
            Ag: handleValue(item.Ag),
            As: handleValue(item.As_),
            Cd: handleValue(item.Cd),
            CN: handleValue(item.CN),
            Cr: handleValue(item.Cr),
            Cr6: handleValue(item.Cr6),
            Cu: handleValue(item.Cu),
            Fe: handleValue(item.Fe),
            Fenol: handleValue(item.Fenol),
            Hg: handleValue(item.Hg),
            Ni: handleValue(item.Ni),
            N: handleValue(item.N),
            Pb: handleValue(item.Pb),
            P: handleValue(item.P),
            Se: handleValue(item.Se ),
            Sn: handleValue(item.Sn),
            Zn: handleValue(item.Zn),
            SS: handleValue(item.Rs),
            Og: handleValue(item.Og),
            Sulfato: handleValue(item.Sulfato),
            Sulfeto: handleValue(item.Sulfeto),
            Fluoreto: handleValue(item.Fluoreto),
            Toxicidade: handleValue(item.Toxicidade)
        }

    ))




    const colunas = Object.keys(json[0])
    const listaParametros = ["uc", ...colunas]

    sheet.columns = ["uc", ...colunas].map(key => {

        if (key === 'codigoDoCliente') return { header: 'Codigo do Cliente', key, width: 10 }
        return { header: key, key, width: 10 }

    })
    let valueUC =''
    if(hidrometro && hidrometro?.filter((x: { flagAtivo: number; flagMedidorVazao: number }) =>x.flagAtivo===1 && x.flagMedidorVazao ===1).length>0){
        valueUC = hidrometro?.filter((x: { flagAtivo: number; flagMedidorVazao: number }) =>x.flagAtivo===1 && x.flagMedidorVazao ===1)[0].uc
    }
    else{
        valueUC = hidrometro.filter((x: { flagAtivo: number}) =>x.flagAtivo===1).map((i: { uc: any; }) =>(
            i.uc
        )).join(",")
    }
    sheet.addRows([{uc:`${valueUC}`},...json])

   let i = 0;
    for (let P of listaParametros) {
        if(P==="temperatura") P="°C"
        const oParametro = parametros?.find(x => x.descricao.toUpperCase() === P.toUpperCase() || x.sigla.toUpperCase() === P.toUpperCase() )
        i++
        sheet.getColumn(i).eachCell(cell => {

            const valorParametro = Number(cell.value?.toString().replace("<","").replace(",","."))
            if(cell.value && valorParametro>40 && P==="°C") cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00FF0000' } }, font: { bold: true, color: { argb: 'ffffff' } } }
            if (cell.value && cell.value.toString() !== "-" &&  (Number(oParametro?.maximo) !==0  || Number(oParametro?.minimo) !== 0)  && (valorParametro> oParametro?.maximo || valorParametro < oParametro?.minimo)) {
                cell.style = { fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: '00FF0000' } }, font: { bold: true, color: { argb: 'ffffff' } } }
            }
        })
    }

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, `historico_coleta.xlsx`);
    })


 

}

