import React from 'react'
import { View, StyleSheet, Image, Text } from "@react-pdf/renderer"
import ImageBanner from '../../../../../../../assets/images/logo.png'

const TableBanner: React.FC = () => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: '24px',
            borderBottom: '3px solid black',
        },
        image: {
            width: '140px',
            height: 'auto',
        },
        textContainer: {
            flexDirection: 'column',
            textAlign: 'left',
        position: 'absolute', 
        left: '30%',
        width: '100%'
        },
        title: {
            fontSize: '12px'
        },
        subTitle: {
            fontSize: '10px'
        },
        text: {
            fontSize: '10px',
        }
    })

    return(

        <View style = {styles.row}>
            <Image source = {ImageBanner} style = {styles.image} />
            <View style = {styles.textContainer}>
                <Text style = {styles.title}>MONITORAMENTO DE ESGOTO INDUSTRIAL</Text>
                <Text style = {styles.subTitle}>RELATÓRIO DE NÃO CONFORMIDADE</Text>
            </View>
                    <Text style={{fontSize:'8px', position: 'absolute', left: '80%', top:'45px', width: '100%'}}>{`${new Date().toLocaleString()}`}</Text>
        </View>

    )

}

export default TableBanner