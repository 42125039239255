import React , { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Modal from '../../../../../components/Modal'
import { Formik, Form as FormikContainer } from 'formik'
import Section, { SectionBox, SectionBoxData, SectionOption } from '../../../../../components/Section'
import Form from '../../../../../components/Form'
import { mdiDelete, mdiPencil, mdiPlusCircle, mdiCalculatorVariant  } from '@mdi/js'
import saveAnual from '../scripts/MonAnual/saveAnual'
import BuscaDados from '../scripts/MonAnual/BuscaDados'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../../../store/hooks'
import getMon from '../scripts/MonAnual/getMon'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'
import {  setMonAnualAtual } from '../Reducers/MonAnualReducer'
import editAnual from '../scripts/MonAnual/editAnual'
import { CircleSpinner } from 'react-spinners-kit'
import deleteMon from '../scripts/MonAnual/deleteMon'
import Icon from '@mdi/react'

const MonAnual: React.FC<{canEdit: boolean}> = ({canEdit}) => {

    const { idCliente } = useParams<any>()
    const [openRelatorioColeta, setOpenRelatorioColeta] = useState<boolean>(false)
    const [openRemover, setOpenRemover] = useState<boolean>(false)
    
    
    const getDados = useCallback(async (setField: any, ano:any, values:any) => {
        if(ano ==="")return null
        var dados = await  BuscaDados(ano, idCliente!)

        if(values.DBOatual ==='') setField("DBOatual", (dados.soma/1000).toFixed(3).replace(".",",") )

        let dboAt = values.DBOatual ==='' ? (dados.soma/1000) :values.DBOatual
        setField("coleta", dados.coletas)
        setField("UltimaColeta", dados.ano)
        const valorCaga = (Number(values.Volume)*Number(dboAt.toString().replace(",",".")))/30

        const diferenca = values.ano as number -  dados.ano as number
        let qtdeMinima = 0 
        let mensagem =''

        if(valorCaga<10) 
        {
            qtdeMinima = diferenca>3? 1: 0
            mensagem = '01 a cada 04 anos'
        }
        else if(valorCaga<20) 
        {
            qtdeMinima = diferenca>1? 1: 0
            mensagem = '01 a cada 02 anos'
        }
        else if(valorCaga<50){
            qtdeMinima  = 1
            mensagem = '1 ao ano'
        } 
        else if(valorCaga<100) {
            qtdeMinima = 2
            mensagem = '2 ao ano'
        }
        else if(valorCaga<200){
            qtdeMinima = 3
            mensagem = '3 ao ano'
        } 
        else{
            qtdeMinima = Math.trunc(valorCaga as number/200)+3  
            mensagem = '3 ao ano + 01 cada 200Kg'
        } 
  
        setField("carga", valorCaga.toFixed(0))
        setField("qtde", `${qtdeMinima} - ${mensagem}`)
        const meta = qtdeMinima - dados.coletas as number
        setField("meta", meta<0? 0: meta)
    }, [idCliente])
    
    
    
    const dispatch = useDispatch()
    const { MonAnual, requestSave, MonAnualAtual, requestGet, requestRemove } = useAppSelector(state => state.MonAnualReducer)
    const { userData } = useAppSelector(state => state.auth)

    const SHOW_GRUPOS = !!MonAnual && !requestGet.loading
    const SHOW_LOADING =  requestGet.loading

    useEffect(() => {
        (async () => {
            await getMon(dispatch, idCliente!)
        })()
    }, [dispatch, idCliente, requestSave.data, requestRemove.data])

    const handleEdit = useCallback((item: any) => {
        dispatch(setMonAnualAtual(item))
        setOpenRelatorioColeta(true)
    }, [dispatch, setOpenRelatorioColeta])

    const handleRemove = useCallback((item: any) => {
        dispatch(setMonAnualAtual(item))
        setOpenRemover(true)
    }, [dispatch, setOpenRemover])

    const grid = "100px repeat(8, 1fr) 70px"


    return(

        <>
<Section name = "clients">

<SectionBox
    title = {"Monitoramento Anual"}
    padding = {false} 
    right={
        <>
        {canEdit && <SectionOption icon = {mdiPlusCircle} tooltip = "Histórico de coletas" onClick = {() => {dispatch(setMonAnualAtual(null));setOpenRelatorioColeta(true)}} />}
        </>
    }
>
<List name = "list">
                <ListSection type = "header">
                    <ListRow grid = {grid}>
                        <ListColumn>Ano</ListColumn>
                        <ListColumn>Volume (m³)</ListColumn>
                        <ListColumn>DBO em Dez. Ano Anterior (Kg/m³) </ListColumn>
                        <ListColumn>Carga Orgânica Diária (Kg DBO)</ListColumn>
                        <ListColumn>Qtd. mínima</ListColumn>
                        <ListColumn>Coletas efetuadas</ListColumn>
                        <ListColumn>Regra de coleta</ListColumn>
                        <ListColumn>Meta</ListColumn>
                        <ListColumn>Ano da última Coleta</ListColumn>
                        {canEdit && <ListColumn>Opções</ListColumn>}
                    </ListRow>

                </ListSection>
                <ListSection type = "main">
                {SHOW_LOADING && (
                        <ListRow grid='1fr'>
                            <ListColumn center><CircleSpinner size={32} color="grey" /></ListColumn>
                        </ListRow>
                    )}
                            {SHOW_GRUPOS && MonAnual.map((item:any, index:number) => {
                                return(
                                    <ListRow key = {index} grid={grid}>
                                        <ListColumn>{item.ano}</ListColumn>
                                        <ListColumn>{Number(item.volume).toFixed(0)}</ListColumn>
                                        <ListColumn>{item.dboAtual}</ListColumn>
                                        <ListColumn>{item.cargaOrganica}</ListColumn>
                                        <ListColumn>{item.qtdMinima}</ListColumn>
                                        <ListColumn>{item.coletas}</ListColumn>
                                        <ListColumn>{item.regraColeta}</ListColumn>
                                        <ListColumn>{item.Meta}</ListColumn>
                                        <ListColumn>{item.AnoUltimaColeta}</ListColumn>
                                        {canEdit && <ListColumn>
                                        <ListOption icon = {mdiPencil} onClick = {() => handleEdit(item)} />    
                                <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemove(item)} />
                                        </ListColumn>}
                                    </ListRow>
                                )
                            })}
                </ListSection>
            </List>
            </SectionBox>
            </Section>

            <Modal width = {600} open = {openRemover} setOpen = {setOpenRemover} title = "Remover ramo">
                    <span className = "modalBoxMessage">Deseja remover esse item?</span>
                    <div className = "modalBoxMainActionsContainer">
                        <button className = "outline" onClick = {() => setOpenRemover(false)}>Cancelar</button>
                        <button  onClick = {() => {deleteMon(dispatch, userData?.id!, MonAnualAtual.id ); setOpenRemover(false)}}>Remover</button>
                    </div>
                </Modal>

        <Modal width = {`80%`} open = {openRelatorioColeta} setOpen = {setOpenRelatorioColeta} title = {!!MonAnualAtual ?"Editar monitoramento Anual":"Salvar monitoramento Anual"}>
        <Formik
                        initialValues = {{
                            id: !!MonAnualAtual? MonAnualAtual.id : '',
                            ano: !!MonAnualAtual? MonAnualAtual.ano : '',
                            Volume: !!MonAnualAtual? Number(MonAnualAtual.volume).toFixed(0) :'',
                            DBOatual: !!MonAnualAtual? MonAnualAtual.dboAtual.toString().replace('.', ',') : '',
                            carga: !!MonAnualAtual? Number(MonAnualAtual.cargaOrganica).toFixed(0) : '',
                            qtde: !! MonAnualAtual? `${MonAnualAtual.qtdMinima} - ${MonAnualAtual.regraColeta}` : '',
                            coleta: !!MonAnualAtual? MonAnualAtual.coletas :  '',
                            meta: !!MonAnualAtual? MonAnualAtual.Meta : '',
                            UltimaColeta:!! MonAnualAtual? MonAnualAtual.AnoUltimaColeta : ''
                        }}
                        onSubmit = {values => {!!MonAnualAtual ? editAnual(dispatch,values, idCliente, userData!.id) : saveAnual(dispatch,values, idCliente, userData!.id);   setOpenRelatorioColeta(false)}}
                    >
                        {({  setFieldValue, values }) => (
                            <Section name = "clients">
                            <SectionBox
                    title = {" "}
                    padding = {false} 
                >
                        <FormikContainer>
                            <Form.Container padding = {false}>
                                <SectionBoxData >
                                    <Form.Row columns = {4} grid={"250px 250px 250px 250px"} >
                                        <Form.Group inputID='ano' inputName='ano' label='Ano' />
                                        <Form.Group  inputID='Volume' inputName='Volume' label='Volume' />
                                        <Form.Group inputID='DBOatual' inputName='DBOatual' label='DBO em Dez. Ano Anterior' />


                                        <div  className='sectionBoxHeaderOption' style={{marginTop:'10px', cursor:"pointer"}} onClick = {()=> getDados(setFieldValue, values.ano, values)} >

                                        <Icon path = {mdiCalculatorVariant} size = "40px" />
                                        </div>


                                    </Form.Row>
                                    <Form.Row columns = {5} grid={"250px 250px 250px 250px 250px"} >
                            
                                    <div style={{marginTop:'10px'}}> 
                                            <label htmlFor='carga'>Carga Orgânica Diária</label><br/><br/>
                                            <text id='carga'> {values.carga}</text>
                                        </div>
                                    <div style={{marginTop:'10px'}}> 
                                            <label htmlFor='qtde'>Quantidade Mínima</label><br/><br/>
                                            <text id='qtde'> {values.qtde}</text>
                                        </div>
                                        <div style={{marginTop:'10px'}}> 
                                            <label htmlFor='coleta'>Ano da última coleta</label><br/><br/>
                                            <text id='coleta'> {values.UltimaColeta}</text>
                                        </div>

                                        
                                    </Form.Row>
                                    <div style={{border: '1px solid black'}}></div>
                                    <Form.Row columns = {2} grid={"250px 250px"}>

                                        <div style={{marginTop:'10px'}}> 
                                            <label htmlFor='coleta'>Coletas Realizadas</label><br/><br/>
                                            <text id='coleta'> {values.coleta}</text>
                                        </div>
                                        <div style={{marginTop:'10px'}}> 
                                            <label htmlFor='meta'>Meta</label><br/><br/>
                                            <text id='meta'> {values.meta}</text>
                                        </div>
                                    </Form.Row>
                                <div className = "formActionsContainer">
                                    <button type = "submit" >Salvar</button>
                                </div>
                                </SectionBoxData>
                            </Form.Container>
                        </FormikContainer>
                        </SectionBox>
            </Section>
                    )}
                    </Formik>
                    
        </Modal>

        </>


    )

}

export default MonAnual