import React, { useCallback, useState } from 'react'
import { Field, Form as FormikContainer, Formik } from 'formik'
import { useAppSelector } from '../../../store/hooks'
import { saveNewPassword } from './scripts/saveNewPassword'
import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setAuthRedirect } from '../../../store/reducers/auth/authReducer'
import './styles.scss'
import { mdiLock } from '@mdi/js'
import { Icon as MdiIcon } from '@mdi/react'
import Banner from '../../../assets/images/cadeado.png'

const AlterarSenha: React.FC = () => {

    const dispatch = useDispatch()
    const { userData } = useAppSelector(state => state.auth)
    const [redirect, setRedirect] = useState(false)

    const handleSubmit = useCallback(async values => {
        if(userData){
            const user = await saveNewPassword(values.confirmarNovaSenha, userData.id)
            if (user) { dispatch(setAuthRedirect(false)); setRedirect(true) }
        }   
    }, [userData, dispatch])

    return (
        <>
            {redirect && <Navigate to="/" />}
            <div id="formChangeContainer">
                <div className="loginContent">
                    <img src={Banner} alt="Banner" />
                </div>
                <div className="loginContent">
                    <Formik
                        initialValues={{ novaSenha: '', confirmarNovaSenha: '' }}
                        onSubmit={values => handleSubmit(values)}
                    >
                        <FormikContainer>
                            <div id="formContainer">
                                <div className="formTitle">
                                    <h3>Redefinir senha</h3>
                                    <h4>Preencha os campos abaixo.</h4>
                                </div>
                                <div className="inputContainer">
                                    <MdiIcon path={mdiLock} size="24px" />
                                    <Field name="novaSenha" type="password" placeholder="Nova senha" />
                                </div>
                                <div className="inputContainer">
                                    <MdiIcon path={mdiLock} size="24px" />
                                    <Field name="confirmarNovaSenha" type="password" placeholder="Confirme a nova senha" />
                                </div>
                                <input type="submit" value="Entrar" />
                            </div>
                        </FormikContainer>
                    </Formik>
                </div>
            </div>
        </>

    )

}

export default AlterarSenha;