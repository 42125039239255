export const clienteEnderecoFormValues = {
    cep: '',
    endereco: '',
    numero: '',
    complemento: '',
    bairro: '',
    uf: '',
    cidade: '',
    longitude: '',
    latitude:'',
    linkMapa:''
}