import { ParametroType } from "../../Parametro/Types/ParametroType";
import { HistoricoColetaElement } from "../Types/HistoricoColeta";

export default  function conformidade(ParametroName : string, values: any, valoresDBO:HistoricoColetaElement[], idAtual:any, idTipo: number){


    if(ParametroName.toUpperCase()==="DBO"){
        if(idTipo===3) return "status representativa"
        if(idTipo===2) return "status analise"
        if(idTipo===4) return "status values"
        const anoAtras = new Date(new Date((new Date(`${valoresDBO.filter(x=> x.idTipoDBO !== 5 && (x.DBO>0 || x.idTipoDBO===2 || x.idTipoDBO===3)).at(-1)?.dtInstalaco}`).getTime() - (1.08e7 * 8 * 365))).toDateString())
        let coletasDbo = valoresDBO.filter(x=> new Date(x.dtInstalaco) >= anoAtras && x.DBO>0 && x.idTipoDBO !== 5  && (x.idTipoDBO===1 || x.idTipoDBO===4)).map(i => (i.id))
        
        if(coletasDbo.length===2) 
        coletasDbo.push(valoresDBO.filter(x => x.DBO>0 && (x.idTipoDBO===1 || x.idTipoDBO===4))?.at(-3)?.id!)
    else if(coletasDbo.length===1){
        coletasDbo.push(valoresDBO.filter(x => x.DBO>0 && (x.idTipoDBO===1 || x.idTipoDBO===4))?.at(-2)?.id!)
        coletasDbo.push(valoresDBO.filter(x => x.DBO>0 && (x.idTipoDBO===1 || x.idTipoDBO===4))?.at(-3)?.id!)
    } 
    else if(coletasDbo.length===0){
        coletasDbo.push(valoresDBO.filter(x => x.DBO>0 && (x.idTipoDBO===1 || x.idTipoDBO===4))?.at(-1)?.id!)
        coletasDbo.push(valoresDBO.filter(x => x.DBO>0 && (x.idTipoDBO===1 || x.idTipoDBO===4))?.at(-2)?.id!)
        coletasDbo.push(valoresDBO.filter(x => x.DBO>0 && (x.idTipoDBO===1 || x.idTipoDBO===4))?.at(-3)?.id!)
    } 
        
        if(coletasDbo.includes(idAtual))
        return "status values"
    }
    if(ParametroName==="temperatura") ParametroName= "°C"
    if (ParametroName === 'As_') ParametroName = 'As'
    if (ParametroName === 'Rs') ParametroName = "SS"
    
    let valorParametro : Number = 0 
    if (typeof values === 'number') valorParametro = Number(values)
    if (typeof values === 'string') valorParametro=  Number(values.replace('<', ''))
    //if(values === null) return {background:'white'}
    const parametros : ParametroType[] | null = JSON.parse(localStorage.getItem("Parametro")!)
    
    const oParametro = parametros?.find(x => x.descricao.toUpperCase() === ParametroName.toUpperCase() || x.sigla.toUpperCase() === ParametroName.toUpperCase() )
    if(values === null || values === "" || Number(values) === 0 ||  (Number(oParametro?.maximo) ===0  && Number(oParametro?.minimo) === 0)) return ""
    //if( Number(oParametro?.maximo) ===0  && Number(oParametro?.minimo) === 0) return {background:'white'}
    if(valorParametro> oParametro?.maximo || valorParametro< oParametro?.minimo) return "status error"
    return ""
    //return {background:'white'}
}