import React from 'react'
import { ErrorMessage, Field } from 'formik'

import { FormGroupProps } from '../types'

const FormGroup: React.FC <FormGroupProps> = ({ children, inputID, inputRows, inputName, inputType, inputPlaceholder, label, readonly, onChange }) => {

    const inputRef = React.createRef<HTMLInputElement>()

    const inputProps = {
        id: inputID,
        name: inputName,
        className: readonly ? 'status disabled' : '',
        type: inputType ?? 'text',
        placeholder: inputPlaceholder,
        step:'1'
    }

    React.useEffect(() => {
        if(inputRef.current && readonly) inputRef.current.setAttribute('readonly', 'readonly')
    }, [inputRef, readonly])

    return(

        <li className = {`formGroup ${inputName}${readonly ? ' status disabled' : ''}`}>
            <label htmlFor = {inputID}>{label}</label>
            {children ?? (
                (inputType === 'textarea' && <Field {...inputProps} innerRef = {inputRef} rows = {inputRows} component = "textarea" onKeyUp = {onChange} />) || <Field {...inputProps} innerRef = {inputRef} onKeyUp = {onChange} />
            )}
            <ErrorMessage name = {inputName} component = "h6" />
        </li>

    )

}

export default FormGroup