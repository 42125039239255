import React, {useCallback, useState} from 'react'
import { CircleSpinner } from 'react-spinners-kit'
import { mdiDelete, mdiFilePdfBox, mdiPencil, mdiPrinterSearch } from '@mdi/js'
import getHistoricoColetaSequencial from '../scripts/getHistoricoColetaSequencial'
import getHIdrometro from '../scripts/getHIdrometro'
import getClients from '../scripts/getClients'
import getPessoaEndereco from '../scripts/getPessoaEndereco'
import { Link } from 'react-router-dom'
import List, { ListColumn, ListOption, ListRow, ListSection } from '../../../../../components/List'
import { setFieldDtFim, setFieldDtInicio, setSequencial, setIdCliente, setListaFiltered, setIdSalvar } from '../Reducers/RelatorioSequencialReducer'
import { useDispatch } from 'react-redux'
import { SectionOption } from '../../../../../components/Section'

interface Props {
    lista: any[] | null
    loading: boolean
    setOpenEditar: (open: boolean) => void
    setOpenRemover: (open: boolean) => void
    canEdit : boolean
}

const TableListarComponents: React.FC <Props> = ({ lista, loading, setOpenEditar, setOpenRemover, canEdit }) => {

    const dispatch = useDispatch()
    
    const SHOW_LOADING = loading
    const SHOW_TABLE = !!lista && !SHOW_LOADING
    const [mostrar, setMostrar] = useState<boolean>(false)
    const [idMostrar, setIdMostrar] = useState<number>(0)

    const grid = "  110px  1fr 110px 110px 110px 110px 110px"

    const handleEdit = useCallback((item: any) => {
        dispatch(setIdSalvar(item.id))
        dispatch(setFieldDtFim(item.fim))
        dispatch(setFieldDtInicio(item.inicio))
        dispatch(setSequencial(item.sequencial))
        dispatch(setIdCliente(item.idCliente))
        dispatch(setListaFiltered([{idCliente: item.idCliente, nomeCliente: item.nome}]))
        console.log()
        setOpenEditar(true)
    }, [ dispatch, setOpenEditar])

    const handleRemove = useCallback((item: any) => {
        dispatch(setIdSalvar(item.id))
        setOpenRemover(true)
    }, [ setOpenRemover, dispatch])

    return(

        <List name = "list">

<ListSection type = "header">
                    <ListRow grid = {grid}>
                        {["Sequencial","Nome", "Inicio", "Fim", "Opções", "PDF"].map((item, index) => <ListColumn key = {index}>{item}</ListColumn>)}
                    </ListRow>
                </ListSection>

                <ListSection type = "main">
                {SHOW_LOADING && (
                        <ListRow grid = '1fr'>
                        <ListColumn center><CircleSpinner size = {32} color = "grey" /></ListColumn>
                    </ListRow>
                    )}
                    {SHOW_TABLE && lista.map((item, index) => (
                        <ListRow key = {item.id} zebra = {index%2===0} grid = {grid}>
                            <ListColumn>{item.sequencial}</ListColumn>
                           <ListColumn >{item.nome}</ListColumn>
                            <ListColumn>{new Date(`${item.inicio} 00:00:00`).toLocaleDateString()}</ListColumn>
                            <ListColumn>{new Date(`${item.fim} 23:59:59`).toLocaleDateString()}</ListColumn>
                            <ListColumn>
                            {false && <ListOption status='success' icon = {mdiPencil} onClick = {() => handleEdit(item)} />    }
                            {canEdit && <ListOption icon = {mdiDelete} status = "error" onClick = {() => handleRemove(item)} />}
                            </ListColumn>
                            <ListColumn>
                            <ListOption   icon = {mdiPrinterSearch } tooltip = "Criar" onClick = {async() => {
                                setIdMostrar(item.id)
                                setMostrar(false)
                                await getHistoricoColetaSequencial(item.idCliente, item.sequencial, item.inicio, item.fim)
                                await getHIdrometro(item.idCliente)
                                await getClients(item.idCliente)
                                await getPessoaEndereco(item.idCliente)
                                setMostrar(true)
                                }}/>

                                {mostrar && idMostrar === item.id && <>
                                <Link  to = "../../cadastro/cliente/historico-coletaSimples/pdf/1" target = "_blank">
                                    <SectionOption  icon = {mdiFilePdfBox} tooltip = "Visualizar PDF simples" onClick = {() => {}} />
                                </Link>
                                <Link to = "../../cadastro/cliente/historico-coletaCompleta/pdf/2" target = "_blank">
                                    <SectionOption  icon = {mdiFilePdfBox} tooltip = "Visualizar PDF completo" onClick = {() => {}} />
                                </Link>
                                </>}

                            </ListColumn>


                        </ListRow>
                    ))}
                </ListSection>

                </List>

    )

}

export default TableListarComponents