import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { setRequestSaveData, setRequestSaveError, setRequestSaveLoading, setRequestSaveMessage } from "../Reducers/RelatorioSequencialReducer"


export default async function send(dispatch: Function, data: any){
    try {
        dispatch(setRequestSaveLoading())
        
        const endpoint = `${DOMAIN}/RelatorioSequencial/save`

        const response: RequestResponsePattern<any> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestSaveData(response))
            if(!response.flagErro) dispatch(setRequestSaveData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestSaveMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestSaveError())
    }
}