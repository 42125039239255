import { utils, writeFile } from 'xlsx';
import { RamoAtividadeType } from '../Types/RamoAtividadeType';

export function genearateRamoAtividadeExcel(Ramos: RamoAtividadeType[] | null) {

     const fileName = `Ramo_Atividade.xlsx`;
     let valoresLimpos : any
     
    valoresLimpos = Ramos?.map((ramo)=> (
        { 
            id :ramo.id,
            descricao: ramo.descricao,
            status: ramo.flagAtivo===1?"Habilitado":"Desabilitado",
            dtCadastro : new Date(ramo.dtCadastro).toLocaleString()
        }
      ));

     const worksheet = utils.json_to_sheet(valoresLimpos, {header:['id','descricao','status','dtCadastro']});
     const workbook = utils.book_new();

     utils.book_append_sheet(workbook, worksheet, 'Ramo_Atividade')
     writeFile(workbook, fileName)

}