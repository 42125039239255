import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks'
import { SectionBoxData } from '../../../../../../components/Section'
import getHistoricoLancamentoDiversos from '../../scripts/getHistoricoLancamentoDiversos'
import HistoricoChartDiversos from '../HistoricoChart/indexDiversos'

const ListarHistoricoDiversos: React.FC = () => {

    const { historicosDiversos } = useAppSelector(s => s.HistoricoLancamentoReducer)
   
    const { idCliente } = useParams<any>()
    const dispatch = useAppDispatch()

    useEffect(() => {
        getHistoricoLancamentoDiversos(idCliente, dispatch)
    }, [ dispatch, idCliente])
    
    return(

        <>
            <SectionBoxData title = "Histórico">
                {historicosDiversos?.map((Historico, index) => (
                    <table key = {index} className = "customTable">
                        <thead>
                            <tr>
                                {Historico?.desc}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {Historico?.val.map((item, indexRef) => <td key = {indexRef}>{item.ref}</td>)}
                            </tr>
                            <tr>
                                {Historico?.val.map((item, indexVol) => <td key = {indexVol}>{item.volume>0? item.volume: 0}</td>)}
                            </tr>
                        </tbody>
                    </table>
                ))}
            </SectionBoxData>
            <SectionBoxData title = "Gráfico">
                {!!historicosDiversos && historicosDiversos.length > 0 && <HistoricoChartDiversos />}
            </SectionBoxData>
        </>

    )

}

export default ListarHistoricoDiversos