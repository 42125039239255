import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { Grupo } from "../../Types/Users"
import { setGrupos, setRequestGetGroupsData, setRequestGetGroupsError, setRequestGetGroupsLoading, setRequestGetGroupsMessage } from "../../Reducers/UsuarioReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getGrupos(dispatch: Function){
    try {
        dispatch(setRequestGetGroupsLoading())

        const endpoint = DOMAIN
        const params = `/GrupoAcesso`
        const response: RequestResponsePattern<Grupo[]> = await getApi({ endpoint, params })
        
        if(response){
            dispatch(setRequestGetGroupsData(response))
            if(!response.flagErro) dispatch(setGrupos(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetGroupsMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetGroupsError())
    }
}