import { MenuItem, Select } from '@material-ui/core'
import { mdiContentSave, mdiUpload } from '@mdi/js'
import React, { useCallback, useEffect }  from 'react'
import { useDispatch } from 'react-redux'
import { CircleSpinner } from 'react-spinners-kit'
import Section, { SectionBox, SectionOption } from '../../../../components/Section'
import SnackbarAlert from '../../../../components/SnackbarAlert'
import createAlert from '../../../../scripts/alert/createAlert'
import isEditable from '../../../../scripts/modules/isEditable'
import { useAppSelector } from '../../../../store/hooks'
import { setAlert } from '../../../../store/reducers/alert/alertReducer'
import { DOMAIN } from '../../../../var_env.'
import TableListarArquivos from './components/TableListarComponents'
import { setTipoArquivoImportacao } from './Reducer/UploadArquivoImportacaoReducer'
import fileExist from './scripts/fileExist'
import getFiles from './scripts/getFiles'
import remove from './scripts/remove'
import saveFile from './scripts/saveFile'
import { uploadArquivoImportacao } from './tipes'

const ImportacaoMylims: React.FC = () => {

    const { userData } = useAppSelector(state => state.auth)
    
    const dispatch = useDispatch()

    const { accessAuthorizedModules } = useAppSelector(state => state.modules)
    const canEdit = isEditable(accessAuthorizedModules, 'Importacao-mylims')
    
    const {lista , requestRemove,  requestGetArquivoImportacao, laboratorioTeceiro} = useAppSelector(state => state.UploadArquivoImportacaoReducer)
    const [file, setFile] = React.useState<any>();
    const [loading, setLoaging]  = React.useState<boolean>(false);
    const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const currentFile = e.target.files
        if (!!currentFile) setFile(currentFile[0])
    }
 
    const SincronizarDados = useCallback(async () => {

        (async() => {
            const endpoint = `${DOMAIN}/importar-mylims`
            const response: any = await fetch(endpoint,{method:'GET' })
            const resposta:{sucesso:boolean, msg:string} = await response.json()
            if(resposta.sucesso) dispatch(setAlert(createAlert('success', 'Dados foram sincronizados')))
            else dispatch(setAlert(createAlert('error', resposta.msg)))
            })()

    },[dispatch])

    const handleEnviarArquivo = useCallback(async (arquivo: any) => {

        (async() => {
 
            const dados ={
                filename: arquivo?.name,
                size: `${arquivo?.size}`,
                idUsuario:  userData!.id,
                conteudo: "Importacao_mylims"
            }
            setLoaging(true)
            let arquivoExiste =  await fileExist(dispatch, arquivo?.name)

            if(arquivoExiste){
                dispatch(setAlert(createAlert('error', 'o arquivo ja foi cadastrado')))
            }else{
                let arquivoSalvo : uploadArquivoImportacao|undefined  =  await saveFile(dispatch,dados)
                const formData = new FormData()

                formData.append('amostra', file)
                formData.append('idUsuario', userData!.id.toString())
                formData.append('idArquivoOrigem', `${arquivoSalvo?.id}`)
                formData.append('Laboratorio', `${laboratorioTeceiro}`)
                const endpoint = `${DOMAIN}/Importacao/Amostra`
                const response: any = await fetch(endpoint,{method:'POST',body:formData })
                const resposta:{sucesso:boolean, msg:string} = await response.json()
                if(resposta.sucesso) dispatch(setAlert(createAlert('success', 'Dados dos arquivo foram salvos')))
                else dispatch(setAlert(createAlert('error', resposta.msg)))
            }
            setLoaging(false)
        })()

    },[file, userData, dispatch, laboratorioTeceiro])
        
    const handleApagarFile = useCallback( async (idDelete: number|undefined) => {
        if(window.confirm("Apagando esse arquivo Apagara todos os dados referente a ele! Tem certeza?")){
            const dados = {
                id: idDelete,
                idUsuario: userData!.id
            }
            remove(dispatch, dados)
        }
    },[userData, dispatch])

    useEffect(() => {
        getFiles(dispatch)
    }, [requestGetArquivoImportacao,requestRemove ,dispatch])

    return(
        <>
            <Section name = "Arquivos">
                <SectionBox 
                    title='Arquivos com amostras do Mylims' 
                    padding = {false}
                    right={
                        canEdit?
                        <>
                        	<Select id="filterAtivo" value={laboratorioTeceiro} onChange={e => dispatch(setTipoArquivoImportacao(e.target.value as any))}>
                                <MenuItem value={1}>Amostra</MenuItem>
                                <MenuItem value={2}>Analise</MenuItem>
                                <MenuItem value={3}>Informação</MenuItem>
                            </Select>
                            <input id="fileInput" type="file" style={{ display: 'none' }} accept=".xlsx" name="analise" onChange={(e) => handleFile(e)} required />
                            <label htmlFor="fileInput" className={`btn default`}>{`${file ? file.name : 'Carregar excel'}`}</label>
                            {!loading && <SectionOption  icon = {mdiContentSave} tooltip = "Salvar" onClick = {() => handleEnviarArquivo(file)} />}
                        {loading && <CircleSpinner size={20} color="grey"/>}
                        <SectionOption  icon = {mdiUpload} tooltip = "Sincronizar" onClick = {() => SincronizarDados()} />
                        </> : <></>
                    }
                > 
                    <TableListarArquivos canEdit={canEdit} lista={lista} handleApagarFile={handleApagarFile}/>
                </SectionBox>
            </Section>
            <SnackbarAlert />
        </>
    
    )
}

export default ImportacaoMylims

