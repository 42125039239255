import { setRequestData, setRequestError, setRequestLoading, setRequestMessage } from "../Reducers/EnderecoReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { postApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { EnderecoType } from "../../Parametro/Types/EnderecoType"
import { SendParams } from "../types"

export default async function send(dispatch: Function, values: SendParams){
    try {
        dispatch(setRequestLoading())
        
        const endpoint = `${DOMAIN}/PessoaEndereco/${values.idUsuario}`
        const data = { 
            idPessoa:values.idUsuario,
            idTipoEndereco:1 ,
            logradouro:values.descricao.endereco,
            numero:values.descricao.numero,
            latitude:values.descricao.latitude,
            longitude:values.descricao.longitude,
            linkMapa:values.descricao.linkMapa,
            complemento:values.descricao.complemento,
            bairro: values.descricao.bairro,
            cep: values.descricao.cep,
            cidade: values.descricao.cidade,
            uf: values.descricao.uf,
            idIntegracao: 1,
            idUsuarioCadastro:2
        }
        const response: RequestResponsePattern<EnderecoType> = await postApi({ endpoint, data })

        if(response){
            dispatch(setRequestData(response))
            if(!response.flagErro) dispatch(setRequestData(response))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        dispatch(setRequestMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestError())
    }
}