import React, { Fragment } from 'react'
import './styles.scss'
import { useAppSelector } from '../../store/hooks'
import SidebarComponents from './components'
import getIcon from '../../scripts/modules/getIcon'

const Sidebar: React.FC = () => {

    const { authorizedModules, requestAuthorizedModules, requestModules } = useAppSelector(state => state.modules)

    const SHOW_MENUS = !!authorizedModules && authorizedModules.length > 0 && requestModules.data && requestAuthorizedModules.data && !requestModules.loading && !requestAuthorizedModules.loading
    const SHOW_LOADING = requestModules.loading || requestAuthorizedModules.loading

    return (

        <SidebarComponents.Container>
            {(SHOW_MENUS && authorizedModules.map((modulo, index) => (
                <Fragment key = {index}>
                    <SidebarComponents.Section tooltip={modulo.nome} icon={getIcon(modulo.icone)} title={modulo.nome}>
                        {modulo.ModuloMenu.filter(x => !x.flagIgnorar).map((menuRoute, menuIndex) => (
                            <SidebarComponents.Link 
                                key={menuIndex} 
                                link={`/${modulo.path}/${menuRoute.path}`} 
                                label={menuRoute.nome} 
                            />
                        ))}
                    </SidebarComponents.Section>
                </Fragment>
            )))}
            {(SHOW_LOADING && <SidebarComponents.Loading />)}
        </SidebarComponents.Container>
    )

}

export default Sidebar