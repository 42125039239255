import React from 'react'
import { View, StyleSheet} from "@react-pdf/renderer"
import { RelatorioPontoColeta } from '../../../types'
import Column from '../../../../../../../components/PDF/Column'

const TableRow: React.FC <{relatorios: RelatorioPontoColeta[]}> =  ({relatorios}) => {

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        }
    })

    return(
        <>
            {relatorios.map((relatorio, index)=>
                <View style = {styles.row} wrap={false}>
                    <Column text = {relatorio.codigo} width = "10%" />
                    <Column text = {relatorio.nome} width = "45%" align = "left"  />
                    <Column text = {relatorio.descricaoPontoColeta} width = "45%"  last/>
                </View>
            )}
        </>
    )

}

export default TableRow