import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { ClienteType } from "../../Parametro/Types/ClienteType"
import { getApi } from "../../../../../utils/requestApi"

export default async function getClients(idCliente: any){
    try {
        const endpoint = DOMAIN
        const params = `/Pessoa/${idCliente}`
        const response: RequestResponsePattern<ClienteType> = await getApi({ endpoint, params })
        
        if(response){
            if(!response.flagErro) {
                localStorage.setItem("ClienteHistorico", JSON.stringify(response.listaResultados))
            } else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
    } catch (error: any) {
        console.log(error)

    }
}