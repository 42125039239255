import { setListaFiltered, setRequestFilterData, setRequestFilterError, setRequestFilterLoading, setRequestFilterMessage } from "../Reducers/RamoAtividadeReducer"
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { DOMAIN } from "../../../../../var_env."
import { FilterParams } from "../types"
import { RamoAtividadeType } from "../Types/RamoAtividadeType"
    
export default async function filter(dispatch: Function, values: FilterParams){
    try {
        dispatch(setRequestFilterLoading())
        const response: RequestResponsePattern<RamoAtividadeType[]> = await getApi({ endpoint: `${DOMAIN}/RamoAtividade`, params: `/?descricao=${values.descricao}&flagAtivo=${values.flagAtivo === 'null' ? '' : values.flagAtivo}` });

        if(response){
            dispatch(setRequestFilterData(response))
            if(!response.flagErro) dispatch(setListaFiltered(response.listaResultados.filter(item => item.descricao.toLowerCase().includes(values.descricao.toLowerCase()))))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')

    } catch (error: any) {
        dispatch(setRequestFilterMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestFilterError())
    }
}