import { Text, View } from '@react-pdf/renderer'
import React from 'react'

const TableFooter: React.FC = () => {

    return(

        <View style = {{marginTop: '48px'}}>
            <Text style = {{marginBottom: '36px', fontSize: '10px'}}>Cordialmente,</Text>
            <Text style = {{fontSize: '10px'}}>Fabio Henrique Ercolin</Text>
            <Text style = {{fontSize: '10px'}}>Coordenador de Monitoramento</Text>
        </View>

    )

}

export default TableFooter