import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { PontoColetaType } from "../Types/PontosColeta"
import { setPontosColeta, setRequestGetPontosColetaData, setRequestGetPontosColetaError, setRequestGetPontosColetaLoading, setRequestGetPontosColetaMessage } from "../Reducers/ClientsReducer"
import { getApi } from "../../../../../utils/requestApi"

export default async function getPontosColeta(dispatch: Function, idCliente: number){
    try {
        dispatch(setRequestGetPontosColetaLoading())
        
        const endpoint = `${DOMAIN}/PessoaPontoColeta`
        const params = `/${idCliente}`
        const response: RequestResponsePattern<PontoColetaType[]> = await getApi({ endpoint, params })

        if(response){
            dispatch(setRequestGetPontosColetaData(response))
            if(!response.flagErro) dispatch(setPontosColeta(response.listaResultados))
            else throw new Error(response.listaMensagens[0])
        }else throw new Error('Erro na requisição')
        
    } catch (error: any) {
        console.log(error)
        dispatch(setRequestGetPontosColetaMessage(error.message ?? JSON.stringify(error)))
        dispatch(setRequestGetPontosColetaError())
    }
}