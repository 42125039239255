import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { HistoricoChartProps } from './types'

const HistoricoChart: React.FC <HistoricoChartProps> = ({ values, labels }) => {


    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Últimos 12 meses',
            },
        },
    }



    const data = {
        labels,
        datasets: [
            {
                label: 'Volume Corrigido',
                data: values,
                borderColor: 'rgb(23, 165, 111)',
                backgroundColor: 'rgb(23, 165, 111, 0.5)',
            }
        ]
    }

    return <Line data={data} options={options} />

}

export default HistoricoChart