import React from 'react'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

const TableInfo: React.FC = () => {

    const styles = StyleSheet.create({
        section: {
            width: '100%',
            marginBottom: '12px',
        },
        text: {
            fontSize: '10px',
        },
    })

    return(

        <View style = {{marginBottom: '24px'}}>
            <View style = {styles.section}>
                <Text style = {styles.text}>AT.:</Text>
                <Text style = {styles.text}>Setor de Contas e Controle</Text>
            </View>
            <View style = {styles.section}>
                <Text style = {styles.text}>DAE S/A - água e esgoto</Text>
                <Text style = {styles.text}>Rodovia Vereador Geraldo Dias, 1500</Text>
                <Text style = {styles.text}>Jundiaí-SP</Text>
            </View>
            <View style = {[styles.section, {marginTop: '48px'}]}>
                <Text style = {[styles.text, {marginBottom: '8px'}]}>Prezado Senhor,</Text>
                <Text style = {styles.text}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A CSJ, Compahia de Saneamento de Jundiaí, na prática das ações exercidas pelo Programa de Monitoramento de Esgoto Industrial, solicita a atualização dos valores de DBO apurados, no mês de FEVEREIRO/2022, para as seguintes indústrias:</Text>
            </View>
        </View>

    )

}

export default TableInfo