import { DOMAIN } from "../../../../../var_env."
import { RequestResponsePattern } from "../../../../../types/Request"
import { getApi } from "../../../../../utils/requestApi"
import { relatorio } from "../types"
import { setHistoricoDiversos, setHistoricoDiversosExcel } from "../Reducers/HistoricoLancamentoReducer"

export default async function getHistoricoLancamentoDiversos(idPessoa:any, dispatch:Function){
    try {

        const endpoint = DOMAIN
        const params = `/PessoaHidrometroLancamento/historico/outros/?idPessoa=${idPessoa}`
        const response: RequestResponsePattern<relatorio[]> = await getApi({ endpoint, params })

        const paramsExcel = `/PessoaHidrometroLancamento/historico/outros/?idPessoa=${idPessoa}&flgHistorico=1`
        const responseExcel: RequestResponsePattern<relatorio[]> = await getApi({ endpoint, params:paramsExcel })
        
        if(response){
            if(!response.flagErro) {
                dispatch(setHistoricoDiversos(response.listaResultados))
            }
        }

        if(responseExcel){
            if(!responseExcel.flagErro) {
                dispatch(setHistoricoDiversosExcel(responseExcel.listaResultados))
            }
        }
    }
    catch{

    }
}