import React, { useCallback, useEffect, useState } from 'react'
import { View, StyleSheet, Text } from "@react-pdf/renderer"
import { HistoricoColetaElement } from '../../../../Types/HistoricoColeta'
import conformidadePDF from '../../../../../IndustriasMonitoradas/scripts/conformidadePDF'
//import _ from 'lodash'

const TableRow: React.FC <{mostrar:string, props :HistoricoColetaElement[], historicoDBO: HistoricoColetaElement[] ,pontos:{i:number, valor:string}[]}> = ({props, pontos, historicoDBO, mostrar}) => {

    const [ids, setIds] = useState<number[] | null>(null)

    const styles = StyleSheet.create({
        row: {
            flexDirection: "row",
            alignItems: "stretch",
            width: "100%",
        },          
        column: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '4px',
            fontfamily: 'Arial',
            textAlign: 'center',
        },
        columnVermelha: {
            padding: '4px 2px',
            border: "1px solid black",
            borderRight: '0',
            fontSize: '4px',
            fontfamily: 'Arial',
            textAlign: 'center',
            backgroundColor: 'red',
            color:"#FFFFFF"
        }
    })

    const handleValue = (value: string | number) => {
        if (typeof value === 'number') return value === 0 ? '-' : value.toString().replace('.', ',')
        if (typeof value === 'string') return value.length === 0 || Number(value)=== 0 ? '-' : value.replace('.', ',')
        return value ?? '-'
    }

    const mudaPonto = useCallback( (valor: string ) => {

        const primeiraLetra = valor[0]
        const isNotNumber = isNaN(Number(primeiraLetra))
        if(isNotNumber){
            return valor?.trim() ?? " " 
        }
        valor = valor?.replaceAll('0', '')
        valor = valor?.replaceAll('1', '')
        valor = valor?.replaceAll('2', '')
        valor = valor?.replaceAll('3', '')
        valor = valor?.replaceAll('4', '')
        valor = valor?.replaceAll('5', '')
        valor = valor?.replaceAll('6', '')
        valor = valor?.replaceAll('7', '')
        valor = valor?.replaceAll('8', '')
        valor = valor?.replaceAll('9', '')
        valor = valor?.replaceAll('.', '')
        return valor?.trim()
    }, [])

    const Column: React.FC <{text: string, width: string, last?: boolean, flagVermelho?: any, rosa?: boolean, azul?: boolean, verde?: boolean}> = ({text, width, last, flagVermelho, rosa, azul, verde}) => (
        <View style = {[flagVermelho? styles.columnVermelha :styles.column,rosa?{backgroundColor:'#FDA5E4'}:{}, azul?{backgroundColor:'#519FF9'}:{}, verde?{backgroundColor:'#9BF45F'}:{}  ,{width, borderRight: !!last ? '1px solid black' : 0}]}>
            <Text>{text}</Text>
        </View>
    )

    const handleDate = (date: Date) => {
        const novaData = new Date(date.toISOString().replace(/\.\d{3}Z$/, ''))
        return new Date((novaData.getTime() - (1.08e7))).toLocaleString()
    }

    const handleFormat = (valor: string ) => {
        if(valor==="-" || valor==="ND")return valor
        if(valor.includes('<')){
            valor = valor.replaceAll('<', '')
            const newValor = Number(valor.replace(",",".")).toLocaleString('pt-BR');
            return '<' + newValor
        }
        return Number(valor.replace(",",".")).toLocaleString('pt-BR');
    }
    useEffect(() => {
        (async() => { 
            const ultimaCoelta: {data:string} = JSON.parse(localStorage.getItem('ultimaColeta')!)
            let anoAtras: any = null
                anoAtras = new Date(new Date((new Date(ultimaCoelta?.data).getTime() - (1.08e7 * 8 * 365))).toDateString())
            const resultadosAano = props.filter(x=> new Date(x.dtInstalaco) >= anoAtras! && x.DBO>0 && x.idTipoDBO!==5 && (x.idTipoDBO===1 || x.idTipoDBO===2 || x.idTipoDBO===3) ).map(i => (i.id))
            setIds(resultadosAano)
            //const coletasDiferentedeZero = props.filter(x=> x.DBO>0 && x.idTipoDBO===1)


            //const elemtent = props.filter(item=> item.DBO>0 && (resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id)))[0]
            
            //const valorDBOAntigo = props.filter(x => new Date(x.dtInstalaco)< new Date(elemtent.dtInstalaco))
           
            //const coletasDiferentedeZero2 = valorDBOAntigo.filter(x=> x.DBO>0 && x.idTipoDBO===1)
           
            //const anoAtras2 = new Date((new Date(`${valorDBOAntigo.at(-1)?.dtInstalaco}`).getTime() - (1.08e7 * 8 * 365))) 
             //const resultadosAnoAnterior = valorDBOAntigo.filter(x=> new Date(x.dtInstalaco) >= anoAtras2! && x.DBO>0 && x.idTipoDBO===1 ).map(i => (i.id))
            //const valoresAntigo =valorDBOAntigo.filter(item=> item.DBO>0 && (resultadosAnoAnterior?.length>2? (resultadosAnoAnterior?.includes(item.id)) : (item.id === coletasDiferentedeZero2.at(-1)?.id || item.id === coletasDiferentedeZero2.at(-2)?.id || item.id === coletasDiferentedeZero2.at(-3)?.id)))
            // const soma2 =_.sum(valoresAntigo.map(i=> i.DBO))
             //const resultado2 = soma2/ valorDBOAntigo.filter(item=> item.DBO>0 && (resultadosAnoAnterior?.length>2? (resultadosAnoAnterior?.includes(item.id)) : (item.id === coletasDiferentedeZero2.at(-1)?.id || item.id === coletasDiferentedeZero2.at(-2)?.id || item.id === coletasDiferentedeZero2.at(-3)?.id))).map(I=> I.DBO).length

            // const soma =_.sum(props.filter(item=> item.DBO>0 && (resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I=> I.DBO))
           // const resultado =  soma/ props.filter(item=> item.DBO>0 && (resultadosAano?.length>2? (resultadosAano?.includes(item.id)) : (item.id === coletasDiferentedeZero.at(-1)?.id || item.id === coletasDiferentedeZero.at(-2)?.id || item.id === coletasDiferentedeZero.at(-3)?.id))).map(I=> I.DBO).length
            
            // console.log(valoresAntigo[0])
            // localStorage.setItem("resultadoSomaCompleto", `${resultado/1000}`)
            // localStorage.setItem("resultadoSomaCompletoAnterior", `${resultado2/1000}`)
    })()
    }, [props, mostrar])

    return(
        <>
                   <View style = {styles.row} wrap={false} fixed>
                   <View style = {{width:"44%"}}>
        </View>

            <Column width = "56%" text = "VMP Parâmetros Artigo 19A Decreto 8468/76 e Resultados de Análises (mg/L)"  last />
        </View>
            <View style = {styles.row} wrap={false} fixed>
            <Column text = "N" width = "3%" />
            <Column text = "P" width = "3%" />
            <Column text = "Instalação" width = "10%" />
            <Column text = "Retirada" width = "10%" />
            <Column text = "CM" width = "10%" />
            <Column text = "Chuva" width = "5%" />
            <Column text = "DQO *" width = "4%" />
            <Column text = "DBO *" width = "4%" />
            <Column text = "pH 6 a 10" width = "4%" />
            <Column text = "°C <40°" width = "4%" />
            <Column text = "SS 20,0" width = "4%" />
            <Column text = "OG 150" width = "4%" />
            <Column text = "Cd 1,50 *" width = "4%" />
            <Column text = "Cu 1,50 *" width = "4%" />
            <Column text = "Cr 5,0 *" width = "4%" />
            <Column text = "Ni 2,00 *" width = "4%" />
            <Column text = "Pb 1,50" width = "4%" />
            <Column text = "Zn 5,00 *" width = "4%" />
            <Column text = "Ag 1,50 *" width = "4%" />
            <Column text = "Hg 1,50 *" width = "4%" />
            <Column text = "As 1,50 *" width = "4%" />
            <Column text = "Se 1,50 *" width = "4%" />
            <Column text = "Sn 4,00 *" width = "4%" />
            <Column text = "Fe 15,00 *" width = "4%" />
            <Column text = "Sulfato 1000 *" width = "4%" />
            <Column text = "Sulfeto 1,0" width = "4%" /> 
            <Column text = "Fenol 5,00" width = "4%" />
            <Column text = "Fluoreto 10,0" width = "4%" />
            <Column text = "Cr6 1,50" width = "4%" />
            <Column text = "Cn 1,50" width = "4%" />
            <Column text = "N *" width = "4%" />
            <Column text = "P *" width = "4%" />
            <Column text = "Toxidade" width = "4%" last />
        </View>
{props.map((item, index)=>(
<View style = {styles.row} wrap={false}>
            <Column text = {`${index+1}`} width = "3%" />
            <Column text = {`${pontos.find(x=> x.valor=== mudaPonto(item.pontoColeta))?.i}`} width = "3%" />
            <Column text={item.dtInstalaco ? handleDate(new Date(item.dtInstalaco)) : '-'} width = "10%"  />
            <Column text={item.dtRetirada ? handleDate(new Date(item.dtRetirada)) : '-'} width = "10%"  />
            <Column text={item.codigoAmostra} width = "10%"  />
            <Column text={item.flagChuva===1?"V":"X"} width = "5%"  />
            <Column text = {handleFormat(handleValue(item.DQO))} flagVermelho={conformidadePDF("DQO",item.DQO )} width = "4%" />
            <Column verde={item.idTipoDBO===2} azul={item.idTipoDBO===3} rosa={(ids && item.idTipoDBO !== 5 && ids?.length>2?  ((item.idTipoDBO===1 || item.idTipoDBO===4) && ids?.includes(item.id)) : (item.idTipoDBO===1 || item.idTipoDBO===4) && (item.id === historicoDBO.filter(x => (item.idTipoDBO===1 || item.idTipoDBO===4)).at(-1)?.id || item.id === historicoDBO.filter(x => (item.idTipoDBO===1 || item.idTipoDBO===4)).at(-2)?.id || item.id === historicoDBO.filter(x => (item.idTipoDBO===1 || item.idTipoDBO===4)).at(-3)?.id)) || item.idTipoDBO===4} text = {handleFormat(handleValue(item.DBO))} flagVermelho={false} width = "4%" />
            <Column text = {handleValue(item.pH)} flagVermelho={conformidadePDF("Ph",item.pH )}  width = "4%" />
            <Column text = {handleValue(item.temperatura)} flagVermelho={conformidadePDF("°C",item.temperatura )}  width = "4%" />
            <Column text = {handleValue(item.Rs)} flagVermelho={conformidadePDF("SS",item.Rs )}  width = "4%" />
            <Column text = {handleValue(item.Og)} flagVermelho={conformidadePDF("Og",item.Og )}  width = "4%" />
            <Column text = {handleValue(item.Cd)} flagVermelho={conformidadePDF("Cd",item.Cd )} width = "4%" />
            <Column text = {handleValue(item.Cu)} flagVermelho={conformidadePDF("Cu",item.Cu )} width = "4%" />
            <Column text = {handleValue(item.Cr)} flagVermelho={conformidadePDF("Cr",item.Cr )} width = "4%" />
            <Column text = {handleValue(item.Ni)} flagVermelho={conformidadePDF("Ni",item.Ni )}  width = "4%" />
            <Column text = {handleValue(item.Pb)} flagVermelho={conformidadePDF("Pb",item.Pb )} width = "4%" />
            <Column text = {handleValue(item.Zn)} flagVermelho={conformidadePDF("Zn",item.Zn )}  width = "4%" />
            <Column text = {handleValue(item.Ag)} flagVermelho={conformidadePDF("Ag",item.Ag )}  width = "4%" />
            <Column text = {handleValue(item.As_)} flagVermelho={conformidadePDF("As",item.As_ )} width = "4%" />
            <Column text = {handleValue(item.Hg)} flagVermelho={conformidadePDF("Hg",item.Hg )} width = "4%" />
            <Column text = {handleValue(item.Se)} flagVermelho={conformidadePDF("Se",item.Se )}  width = "4%" />
            <Column text = {handleValue(item.Sn)} flagVermelho={conformidadePDF("Sn",item.Sn )}  width = "4%" />
            <Column text = {handleValue(item.Fe)} flagVermelho={conformidadePDF("Fe",item.Fe )} width = "4%" />
            <Column text = {handleFormat(handleValue(item.Sulfato))} flagVermelho={conformidadePDF("Sulfato",item.Sulfato )}  width = "4%" />
            <Column text = {handleValue(item.Sulfeto)} flagVermelho={conformidadePDF("Sulfeto",item.Sulfeto )}  width = "4%" />
            <Column text = {handleValue(item.Fenol)} flagVermelho={conformidadePDF("Fenol",item.Fenol )} width = "4%" />
            <Column text = {handleValue(item.Fluoreto)} flagVermelho={conformidadePDF("Fluoreto",item.Fluoreto )} width = "4%" />
            <Column text = {handleValue(item.Cr6)} flagVermelho={conformidadePDF("Cr6",item.Cr6 )} width = "4%" />
            <Column text = {handleValue(item.CN)} flagVermelho={conformidadePDF("CN",item.CN )} width = "4%" />
            <Column text = {handleValue(item.N)} flagVermelho={conformidadePDF("N",item.N )}  width = "4%" />
            <Column text = {handleValue(item.P)} flagVermelho={conformidadePDF("P",item.P )} width = "4%" />
            <Column text = {handleValue(item.Toxicidade)} flagVermelho={conformidadePDF("Toxidade",item.Toxicidade )}  width = "4%" last />

    </View>
    
))}
<View style={{marginTop:"10px"}}/>
<Text style={{fontSize: '10px'}}>Valores máximos estabelecidos no Decreto Estadual 8.468/76, artigo 19a;</Text>
<Text style={{fontSize: '10px'}}>Valores expressos em mg/L, exceto sólidos sedimentáveis (ml/L), pH, Temperatura (°C) e toxicidade (CE(I)50 = %)</Text>
</>


    )

}

export default TableRow